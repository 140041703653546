import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// import Main from './containers/Main';
// import Feedback from './Containers/Feedback';
// import HiddenFeatures from './Containers/HiddenFeatures';
import LoginPage from './containers/User/LoginPage';
import LogoutPage from './containers/User/LogoutPage';
import NotFoundPage from './containers/NotFoundPage.js';

import App from './containers/App';

import {
  userIsAuthenticatedRedir,
  // userIsAdminRedir,
  userIsNotAuthenticatedRedir,
} from './auth';

export default () => (
  <Router onUpdate={() => window.scrollTo(0, 0)}>
    <Switch>
      <Route path="/login" component={userIsNotAuthenticatedRedir(LoginPage)} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/" component={userIsAuthenticatedRedir(App)} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  </Router>
);
