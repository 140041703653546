import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const DivScroll = styled.div`
  && {
    overflow-y: auto;
    overflow-x: hidden;
    height: ${(props) => props.height || "calc(100vh - 180px)"};

    @media (max-width: 455px) {
      height: 100%;
    }
  }
`;

class ScrollBar extends React.Component {
  render() {
    return (
      <DivScroll height={this.props.height}>{this.props.children}</DivScroll>
    );

    // return !this.props.isMobile ? (
    //   <ScrollArea {...this.props}>{this.props.children}</ScrollArea>
    // ) : (
    //   <DivScroll height={this.props.height}>{this.props.children}</DivScroll>
    // );
  }
}

export default connect((state) => {
  return {
    isMobile: state.app.isMobile,
  };
}, {})(ScrollBar);
