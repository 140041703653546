import { refreshToken } from '../containers/User/actions';

export function jwt({ dispatch, getState }) {
  return next => action => {
    // only worry about expiring token for async actions
    if (typeof action === 'function') {
      if (getState().user.data && getState().user.data.token) {
        // decode jwt so that we know if and when it expires
        const tokenExpiration = getState().user.data.expiresIn;
        if (tokenExpiration && new Date(tokenExpiration) - new Date() < 5000) {
          // make sure we are not already refreshing the token
          if (!getState().user.freshTokenPromise && refreshToken) {
            return refreshToken(dispatch, getState);
          } else {
            return getState().user.freshTokenPromise.then(() => next(action));
          }
        }
      }
    }
    return next(action);
  };
}
