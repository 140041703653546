import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonGroup } from "@material-ui/core";
import { Row, Col } from "react-flexbox-grid";
import FullScreenModal from "../../../components/Layout/FullScreenModal";
import PageTitle from "../../../components/Text/PageTitle";
import MainButton from "../../../components/Buttons/MainButton";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import Table from "../../../components/Table/Table";
import { getRelationsByElementId } from "../actions";
import ViewReport from "../../Reports/ViewReport";
import { setSearch } from "../../App/actions";
import EditElementDetails from "./EditElementDetails";

// import { setActiveStep, getMaintenances, addNewToggle } from './actions';
import {
  resetElement,
  getElementsTypes,
  getVegetationTypes,
  getHistoryByElementId,
} from "../actions";

const Section = styled.div`
  && {
    margin: 2em 0;
    max-width: 1200px;

    h2 {
      margin: 0;
    }
  }
`;
const ButtonWrapper = styled.div`
  && {
    text-align: left;
    display: block;
    font-size: 0.8em;
    margin-top: 0.9em;
    margin-bottom: 2.3em;
    button {
      // margin-right: 0.5em;
      :hover {
        a {
          color: #4da1ff;
        }
      }
    }

    a {
      text-decoration: none;
      color: #fff;
      :hover {
        color: #4da1ff;
      }
    }
  }
`;
const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

class EditElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "info",
      elementsTypeCategories:
        props.elementTypesList && this.createCategoryHirarchy(props),
      activeHistoryId: null,
    };

    this.close = this.close.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.getHistory = this.getHistory.bind(this);
    this.getViewData = this.getViewData.bind(this);
    // if (!props.elementTypesList || props.elementTypesList.length < 1)
    props.getElementsTypes();

    // if (!props.vegetationTypes || props.vegetationTypes.length < 1)
    props.getVegetationTypes();

    if (props.elementOverride) {
      props.getRelationsByElementId(
        props.elementOverride.origin || props.elementOverride.id,
        props.elementOverride.numero
      );
    } else {
      props.getRelationsByElementId(
        props.element.properties.origin || props.element.properties.id,
        props.element.properties.numero
      );
    }
  }

  close() {
    this.props.closeModal();
  }

  handleDataChange(e, id) {
    this.setState({ view: "info" });
    this.setState({ activeHistoryId: id });
  }

  getHistory() {
    this.setState({ view: "history" });
    if (this.props.elementOverride) {
      this.props.getHistoryByElementId(
        this.props?.elementOverride?.origin || this.props?.elementOverride?.id
      );
    } else {
      this.props.getHistoryByElementId(
        this.props.element.properties.origin || this.props.element.properties.id
      );
    }
  }

  componentDidUpdate() {
    if (!this.state.elementsTypeCategories && this.props.elementTypesList) {
      this.setState({
        elementsTypeCategories: this.createCategoryHirarchy(this.props),
      });
    }
    if (this.props.status === "deleted") {
      this.close();
    }
  }

  createCategoryHirarchy(props) {
    // we need to create the hirarchy
    return props.elementTypesList.reduce((prev, curr) => {
      let thisCategory = "generic";
      if (!prev["generic"]) prev["generic"] = [];

      if (curr.category) {
        if (!prev["generic"].find((cu) => cu.label === curr.category)) {
          prev["generic"].push({
            id: curr.id,
            label: curr.category,
            category: curr.category,
          });
        }
        thisCategory = curr.category;
      }

      prev[thisCategory] = [...(prev[thisCategory] || []), curr];
      return prev;
    }, {});
  }
  renderParkName() {
    let parkName = "";
    const selectedParks = this.props.list.find(
      (park) =>
        this.props.selected === park.numero ||
        this.props.elementOverride?.pid === park.número_ant
    );

    parkName = `${selectedParks?.nome || ""}, ${selectedParks?.local || ""}`;

    return parkName;
  }

  getViewData() {
    if (this.state.activeHistoryId) {
      const historyData = this.props.history.find(
        (item) => item.id === this.state.activeHistoryId
      );
      if (historyData) {
        const thisType = this.props.elementTypes.find(
          (type) => type.id === historyData.ElementTypeId
        );

        const thisVegType = this.props.vegetationTypes.find(
          (type) => type.id === historyData.VegetationTypeId
        );

        return {
          type: "Feature",
          properties: {
            typeId: historyData.ElementTypeId,
            implementationDate: historyData.implementationDate,
            local: historyData.local,
            files: historyData.files,
            observations: historyData.observations,
            label: thisType.label,
            category: thisType.category,
            numero: historyData.pid,
            id: historyData.id,
            type: "element",
          },
          vegetation: thisVegType,
          geometry: historyData.geometry,
        };
      }
    }

    return this.props.elementsGeoJson.find((ele) => {
      if (this.props.elementOverride) {
        return ele.properties.id === this.props.elementOverride?.id;
      }
      return ele.properties.id === this.props.element.properties?.id;
    });
  }

  render() {
    const columnHeader = [
      {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
      },
      { id: "date", numeric: false, disablePadding: false, label: "Data" },
      {
        id: "user",
        numeric: false,
        disablePadding: false,
        label: "Utilizador",
      },
      { id: "team", numeric: false, disablePadding: false, label: "Equipa" },
      // { id: 'status', numeric: false, disablePadding: false, label: 'Estado' },
    ];
    let columnData = [];
    if (this.props.history) {
      columnData = this.props.history.reduce((prev, curr) => {
        // curr.UserId
        // curr.createdAt
        const itemData = {
          id: curr.id,
          user: curr.User && curr.User.name,
          team: curr.Team && curr.Team.name,
          date: curr.createdAt,
          status: curr.status,
        };
        return [...prev, itemData];
      }, []);
    }

    return (
      <FullScreenModal>
        <Close onClick={() => this.close()} className={`icon-close icons`} />
        <Section>
          <Row>
            <Col xs={12} md={6}>
              <PageTitle>{this.renderParkName()}</PageTitle>
            </Col>
            <Col xs={12} md={6}>
              <ButtonWrapper>
                <ButtonGroup size="large" aria-label="large button group">
                  <MainButton
                    active={this.state.view === "info" ? "true" : "false"}
                    onClick={() => this.setState({ view: "info" })}
                  >
                    <MenuIcon className={`icon-notebook icons`} /> Informação
                  </MainButton>
                  <MainButton
                    active={this.state.view === "history" ? "true" : "false"}
                    onClick={() => this.getHistory()}
                  >
                    <MenuIcon className={`icon-notebook icons`} /> Alterações
                  </MainButton>
                  <MainButton
                    active={this.state.view === "calendar" ? "true" : "false"}
                    onClick={() => this.setState({ view: "calendar" })}
                  >
                    <MenuIcon className={`icon-calendar icons`} /> Historico
                  </MainButton>
                </ButtonGroup>
              </ButtonWrapper>
            </Col>
          </Row>
          {this.state.view === "info" &&
            this.props.vegetationTypes &&
            this.state.elementsTypeCategories &&
            this.props.elementTypesList.length > 0 && (
              <EditElementDetails
                element={
                  this.getViewData() || {
                    properties: this.props?.elementOverride,
                  }
                }
                view={this.props.view}
                selectedElementID={
                  this.props?.elementOverride?.origin ||
                  this.props?.elementOverride?.id ||
                  this.props?.element?.properties?.id
                }
                elementsTypeCategories={this.state.elementsTypeCategories}
                elementTypesList={this.props.elementTypesList}
                elementTypes={this.props.elementTypes}
                vegetationTypes={this.props.vegetationTypes}
                selectedParcelHorta={this.props.selectedParcelHorta}
              />
            )}
          {this.state.view === "calendar" && this.props.status === "" && (
            <ViewReport
              selectedElementID={
                this.props?.elementOverride?.id ||
                this.props?.element?.properties?.id
              }
            />
          )}
          {this.state.view === "history" && this.props.status === "" && (
            <Table
              orderBy={"id"}
              data={columnData}
              click={this.handleDataChange}
              header={columnHeader}
            />
          )}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      poligonType: state.georeference.type,
      element: state.georeference.element,
      elementsGeoJson: state.app.elementsGeoJson,
      history: state.georeference.history,
      selectedParcelHorta: state.georeference.selectedParcelHorta,
      elementType: state.georeference.newItem.type,
      elementSubType: state.georeference.newItem.subType,
      elementTypes: state.georeference.elementTypes,
      vegetationTypes: state.georeference.vegetationTypes,
      elementTypesList: state.georeference.elementTypes,
      selected: state.app.search.selected,
      geoJson: state.georeference.geoJson,
      status: state.georeference.status,
      list: state.parklist.list,
      role: state.user.data.role,
      pid: state.georeference.pid,
    };
  },
  {
    resetElement,
    getElementsTypes,
    getVegetationTypes,
    getHistoryByElementId,
    getRelationsByElementId,
    setSearch,
  }
)(EditElement);
