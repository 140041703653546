import React, { Component } from "react";
import { connect } from "react-redux";

import { MenuItem } from "@material-ui/core";
import { debounce } from "lodash";

import { apiAddress } from "../../config/general";
import View from "./View";

import Sidebar from "../../components/Layout/Sidebar";
import AddActionButtons from "../../components/Buttons/AddActionButtons";

import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";

import { handleDate } from "../../services/utils";

import { addNewToggle, getInspections, setHistory } from "./actions";
import { setSearch, setActivePark } from "../App/actions";
import {
  InspectionsFilter,
  StyledWrap,
  SectionFilter,
  Card,
  CardList,
  FilterList,
} from "../../components/InspMain/styled";

class Inspections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewImageId: null,
      selectedElement: null,
    };

    this.toggleActiveInspection = this.toggleActiveInspection.bind(this);
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.goToInspectionDetail = this.goToInspectionDetail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.getInspections = debounce(props.getInspections, 600);
    this.setSearch = debounce(props.setSearch, 600);
    this.closeAddModal = this.closeAddModal.bind(this);
  }

  componentDidMount(prevProps, prevState) {
    this.toggleActiveInspection("general");
    // if (!this.props.match.params.pid) this.props.getInspections();
    this.setSearch({
      element: "edit",
      value: null,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //if Edit
    if (
      this.props.match.params.id &&
      this.props.edit !== this.props.match.params.id
    ) {
      this.setSearch({
        element: "edit",
        value: this.props.match.params.id,
      });
    }
    if (
      this.props.appStatus === "searching" ||
      this.props.appStatus === "searchUpdated"
    ) {
      // this.getInspections();
    }
  }

  handleChange(e, value) {
    this.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });
  }

  handleSearchChange(e, value) {
    this.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });
    // this.getInspections();
  }

  setActivePark(park, type) {
    this.props.setActivePark(
      park.properties.numero || this.props.numero,
      park.properties.local || this.props.local,
      park.properties.freguesia || this.props.freguesia,
      type === "element" && !this.props.element
        ? park.properties.origin || park.properties.id
        : this.props.element // custom null for set element clear
        ? -1
        : null,
      true,
      true
    );

    if (type !== "element" && !this.props.selected) {
      this.setState({ selectedElement: null });
      // this.getInspections();
    } else if (type !== "element") {
      this.setState({ selectedElement: null });
      // this.getInspections();
      // this.toggleAddNew();
    } else {
      this.setState({ selectedElement: park.properties });
      // this.toggleAddNew();
    }
  }

  toggleActiveInspection(element) {
    this.setSearch({ element: "active", value: element });
    this.getInspections();
  }

  preprocessInspections(inspections) {
    // lets filter inspections when element is selected
    if (this.props.element) {
      return inspections.filter(
        (insp) => insp.ElementId && insp.ElementId === this.props.element
      );
    } else if (this.props.status !== "all") {
      return inspections.filter((insp) => {
        return insp.status === this.props.status;
      });
    }
    return inspections;
  }

  closeAddModal() {
    this.props.addNewToggle();
  }
  toggleAddNew(local, state) {
    let localId = local; //from url

    if (local && local.properties) {
      localId = local.properties.numero;
    } else if (!local && this.props.selected) {
      localId = this.props.selected;
    }
    if (localId !== this.props.selected) this.props.setHistory();
    this.props.addNewToggle(localId, state);
  }

  renderCard(inspection, i) {
    let selectedPark = [];

    selectedPark = this.props.list.find(
      (park) => park.numero === inspection.pid
    );

    let parkName = "";

    if (selectedPark) {
      parkName = selectedPark.nome;
    }
    const photo =
      inspection.photos && inspection.photos.length > 0
        ? inspection.photos[0]
        : null;

    return (
      <Card
        className={`animated fadeInUp ${inspection.type}`}
        key={i}
        onMouseOver={() => this.setState({ previewImageId: i })}
      >
        <div
          onClick={() =>
            this.goToInspectionDetail(null, inspection.id, inspection.group)
          }
        >
          {photo && this.state.previewImageId === i && (
            <span
              className={"photo"}
              style={{ backgroundImage: `url(${apiAddress}${photo.path})` }}
            />
          )}

          <span className={"parque"}>
            {this.props.role !== "EV" &&
              inspection.Element &&
              inspection.Element.ElementType && (
                <span className={"element"}>
                  {inspection.Element.ElementType.label}
                </span>
              )}

            {parkName}
          </span>
          {inspection.attr && inspection.attr.rating && (
            <span className={"report"}>{inspection.attr.rating}</span>
          )}
          {inspection.Damage && (
            <span className={"damages"}>{inspection.Damage.label}</span>
          )}
          <span className={"details"}>{inspection.details}</span>

          <span className={"name"}>{inspection.User.name}, </span>

          <span className={"date"}> {handleDate(inspection.date)}</span>
        </div>
        {selectedPark && selectedPark.numero && (
          <span
            className={"goToPark"}
            onClick={() => {
              selectedPark && this.setActivePark({ properties: selectedPark });
            }}
          >
            <span className={`icon-location-pin icons`} /> Ver Horta{" "}
            {selectedPark.numero}{" "}
          </span>
        )}
      </Card>
    );
  }

  goToInspectionDetail(event, id, group) {
    //les allways get the history
    return this.props.history.push(`/inspections/${id}`);
    // return this.props.history.push(`/inspections/${!group ? id : group}`);
  }

  render() {
    const {
      startDate,
      endDate,
      edit,
      history,
      inspections,
      selected,
      status,
      freguesia,
      local,
      menuData,
    } = this.props;

    const hasInspection = inspections.length > 0;

    return (
      <StyledWrap>
        {/*<MobileLayoutToggle />*/}
        <Sidebar
          title={menuData.name}
          text={`${freguesia} ${local}<span> ${selected} </span>`}
          icon={menuData.icon}
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="inspection-filter"
              contentClassName="inspection-filter-content"
              horizontal={false}
            >
              <SectionFilter>
                <InspectionsFilter
                  fullWidth
                  name={"status"}
                  value={status}
                  onChange={this.handleSearchChange}
                  inputProps={{
                    name: "status",
                    id: "status",
                  }}
                  label={"status"}
                >
                  <MenuItem value="all">
                    <em>Todas</em>
                  </MenuItem>
                  <MenuItem value={0}>
                    {this.props.role !== "EV" ? "Por Aprovar" : "Aberto"}
                  </MenuItem>
                  <MenuItem value={1}>
                    {this.props.role !== "EV" ? "Aprovado" : "Fechado"}
                  </MenuItem>
                </InspectionsFilter>
              </SectionFilter>

              {hasInspection && (
                <CardList>
                  {inspections &&
                    this.preprocessInspections(
                      inspections
                    ).map((inspection, index) =>
                      this.renderCard(inspection, index)
                    )}
                </CardList>
              )}
            </ScrollArea>
          </FilterList>
          <DateRangeFilter
            handleSearchChange={this.handleSearchChange}
            endDate={endDate}
            startDate={startDate}
          />
        </Sidebar>

        <AddActionButtons />
        {edit && (
          <View
            group={edit}
            history={history}
            callback={this.props.match.params.callback}
          />
        )}
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      element: state.app.search.element,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      status: state.app.search.status,
      appStatus: state.app.status,
      startDate: state.app.search.startDate,
      endDate: state.app.search.endDate,
      edit: state.app.search.edit,
      active: state.app.search.active,
      type: state.app.search.type,
      elementType: state.app.search.elementTypeSelected,
      elmentDisplay: state.app.elmentDisplay,
      addNew: state.inspections.addNew,
      map: state.app.map,
      inspections: state.inspections.list,
      userWrite: state.user.data.write,

      list: state.parklist.list,
      role: state.user.data.role,
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
    };
  },
  {
    addNewToggle,
    getInspections,
    setSearch,
    setHistory,
    setActivePark,
  }
)(Inspections);
