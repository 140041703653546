module.exports = {
  message1: {
    title: "Posição na lista de espera",
    message: `Caríssimo(a) {{name}},<br/><br/>

    No seguimento do seu contacto, gostaria antes de mais de confirmar a sua inscrição ({{createdAt}}) e aproveito também para esclarecer o funcionamento do programa “Terras de Cascais”. <br/><br/>
    
    Atualmente temos 26 Hortas Comunitárias, totalizando 317 parcelas, às quais acrescem 2 Hortas Associativas a ainda 3 Vinhas Comunitárias e 3 Pomares Comunitários.<br/><br/>
    
    O Regulamento Geral do projeto Hortas Comunitárias define como critérios de atribuição a ordem de inscrição e a proximidade de residência. Assim, uma percentagem das parcelas da horta são atribuídas às pessoas em lista de espera residentes na freguesia da horta - cota de freguesia -, outra percentagem a pessoas em lista de espera que vivem nas proximidades da horta (a aproximadamente 1km) – cota de freguesia -, e as restantes parcelas são atribuídas a pessoas da lista de espera que vivem no bairro à volta da horta – cota de bairro. Desta forma, garantimos que todos os inscritos têm a possibilidade de entrar para uma horta perto da sua residência e que cada horta é vigiada pelos horticultores que vivem nas imediações da horta.<br/><br/>
    
    Um projeto desta dimensão obriga a uma monitorização constante e um processo contínuo de acompanhamento dos horticultores de forma a garantir que as parcelas são cultivadas e que damos resposta às mais de 1500 pessoas em lista de espera.<br/><br/>
    
    É importante que saiba que o processo de reatribuição de parcelas e a entrada de novos hortelões está dependente do contacto com os munícipes em lista de espera e da disponibilidade de formação (porque todos os novos horticultores são formados em agricultura biológica antes de lhes ser atribuída uma parcela). Estes dois factos tornam a atribuição das parcelas um processo, por vezes, menos ágil e mais moroso do que seria desejável.<br/><br/>
    
    Assim, e para que perceba a sua situação, neste momento encontra-se em {{ranking1}}º lugar (cota de Bairro), em {{rankingFreguesia}}º lugar na cota de freguesia, e em {{ranking2}}º lugar na cota de proximidade;<br/><br/>
   
    Pelos motivos atrás explicados infelizmente não conseguimos estimar quanto mais tempo terá de aguardar.<br/><br/>
    
    Aceite os N/Melhores cumprimentos,<br/>`,
  },
  message2: {
    title: "Contacto para atribuição",
    message: `Caríssimo(a) {{name}},<br/><br/>
 
    Foi contactada(o) pois encontra-se inscrita(o) na lista de espera para atribuição de uma parcela numa Horta Comunitária da sua freguesia de residência.<br/><br/>
     
    Aguardamos que entre em contacto connosco até ao 12h00 de amanhã afim de confirmar o seu interesse em participar no projecto.<br/><br/>
     
    Aceite os N/Melhores cumprimentos,<br/>`,
  },
  message3: {
    title: "Rescisão de contrato de utilização da parcela voluntariamente",
    message: `Caríssimo(a) {{name}},<br/><br/>

    De acordo com o falado formalizamos então a sua desistência. Espero que tenha gostado da experiência! Queria agradecer ter tido a sensibilidade de abdicar da sua parcela sabendo que existem tantas pessoas à espera e desejosas por receber uma. Já sabe que se algum dia voltar a ter tempo e interesse em voltar a cultivar pode entrar em contacto connosco.<br/><br/>
    
    Em relação à devolução das chaves, por favor, pode deixá-las ao tutor ou tutora da horta ou, se preferir, deixá-la na portaria dos nossos escritórios (Estr. de Manique 1830, 2645-550 Alcabideche) devidamente indicada.<br/><br/>
    
    Caso queira continuar a receber a nossa informação sobre os eventos, workshops, etc. pode sempre inscrever-se na secção “Junte-se à Comunidade” do nosso website:<br/><br/>
    
    https://ambiente.cascais.pt/pt/terrasdecascais/terras-cascais<br/><br/>
     
    Aceite os N/Melhores cumprimentos,<br/>
    
    `,
  },
  message4: {
    title: "Notificação de necessidade de manutenção",
    message: `Caríssimo(a) {{name}},<br/><br/>
 
    Após uma visita à sua Horta Comunitária, verificámos que a sua parcela não está mantida.<br/><br/>
     
    Peço-lhe, por isso, que reconsidere a sua presença na horta e, se for do seu desejo voltar a cultivar com frequência, proceda à manutenção da sua parcela dentro dos próximos 10 dias. Caso contrário, seremos obrigados a reatribuir a sua parcela à próxima pessoa em lista de espera.<br/><br/>
     
    Aceite os N/Melhores cumprimentos,<br/>
    `,
  },
  message5: {
    title: "Comunicação de desatribuição da parcela (involuntária)",
    message: `Caríssimo(a) {{name}},<br/><br/>
 
    Após uma segunda visita à sua Horta Comunitária, verificámos que a sua parcela continua a não estar mantida.<br/><br/>
     
    Assim sendo, e tendo em conta que não procedeu à manutenção da sua parcela dentro do prazo estabelecido (10 dias), somos obrigados a atribuir a sua parcela à próxima pessoa em lista de espera.<br/><br/>
     
    Por favor, retire as suas ferramentas do abrigo  dentro de 15 dias e entregue as chaves da horta ao tutor ou tutora da horta ou, se preferir, entregue-as na portaria dos nossos escritórios (Estr. de Manique 1830, 2645-550 Alcabideche).<br/><br/>
     
    Aceite os N/Melhores cumprimentos,<br/>
    `,
  },
};
