import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import styled from "styled-components";
import GrowerCard from "../../components/Card/GrowerCard";
import { apiAddress } from "../../config/general";

import {
  schoolList,
  schoolMaterial,
  schoolResources,
  schoolState,
} from "../../config/general";

import { Box, MenuItem } from "@material-ui/core";
import { Col, Row } from "react-flexbox-grid";

import LineButton from "../../components/Buttons/LineButton";
import MainButton from "../../components/Buttons/MainButton";
import Atcomplete from "../../components/form-wrappers/AtComplete";
import SelectComponent from "../../components/form-wrappers/Select";
import TextField from "../../components/form-wrappers/TextField";
import PageTitle from "../../components/Text/PageTitle";
import SideSubTitle from "../../components/Text/SideSubTitle";

// import { getMessage } from "../actions";

const Input = styled(TextField)`
  && {
    width: 100%;
    background: #fff;

    margin: 0.5em 0 0 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;

const RenderUsers = ({ responsible, setUsers }) => {
  const [search, setSearch] = useState("");
  const [users, setUsersList] = useState([]);
  const requestUser = async (value) => {
    try {
      const res = await axios.get(
        `${apiAddress}api/v1/contacts/search/${value}`
      );
      //TODO> new to filter by project
      setUsersList(res?.data?.msg.filter((u) => u.type === `2`) || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (search?.length > 2) {
      requestUser(search);
    }
  }, [search]);

  const removeItem = (i) => {
    setUsers(responsible.filter((s, index) => index !== i));
  };

  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <Row>
          <Col>
            <div style={{ margin: "0 1em 0 0" }}>
              <SideSubTitle>Responsáveis</SideSubTitle>
            </div>
          </Col>
          <Col>
            <Atcomplete
              list={users.map((item) => {
                return `${item.name} ${item.email}`;
              })}
              style={{ width: "200px" }}
              label="Adicionar Utilizador"
              onChange={(e, value) => {
                setSearch(e.target.value);
                if (value) {
                  //finde user
                  users.find((item) => {
                    if (`${item.name} ${item.email}` === value) {
                      setUsers([...responsible, item]);
                      setSearch("");
                    }
                  });
                }
              }}
              name="school"
              meta={{ invalid: false }}
            />
          </Col>
        </Row>
        <Row>
          {responsible?.map((item, index) => {
            return (
              <Col key={index}>
                <Box mr={"1em"}>
                  <GrowerCard grower={item} />
                  <div style={{ fontSize: ".7em", marginTop: "-30px" }}>
                    <MainButton onClick={() => removeItem(index)}>
                      Remover Utilizador
                    </MainButton>
                  </div>
                </Box>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};

const RenderStudents = ({ students, setStudents }) => {
  const handleChange = (e, i) => {
    setStudents(
      students.map((student, index) => {
        if (index === i) {
          return {
            ...student,
            [e.target.name]: e.target.value,
          };
        }
        return student;
      })
    );
  };
  const removeItem = (i) => {
    setStudents(students.filter((s, index) => index !== i));
  };
  const addNew = (e) => {
    setStudents([
      ...students,
      {
        name: "",
        surname: "",
        email: "",
        phone: "",
      },
    ]);
  };
  return (
    <>
      <Row>
        <Col md={12}>
          {students.map((item, index) => {
            return (
              <Box key={index} margin={"0 0 1em 0"}>
                <Row>
                  <Col md={6}>
                    <Input
                      value={item.name}
                      label="Nome"
                      onChange={(e) => handleChange(e, index)}
                      name="name"
                      meta={{ invalid: false }}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      value={item.surname}
                      label="Sobrenome"
                      onChange={(e) => handleChange(e, index)}
                      name="surname"
                      meta={{ invalid: false }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      value={item.email}
                      label="Email"
                      onChange={(e) => handleChange(e, index)}
                      name="email"
                      meta={{ invalid: false }}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      value={item.phone}
                      label="Telefone"
                      onChange={(e) => handleChange(e, index)}
                      name="phone"
                      meta={{ invalid: false }}
                    />
                  </Col>
                </Row>
                <Row style={{ justifyContent: "flex-end", fontSize: ".8em" }}>
                  <LineButton onClick={() => removeItem(index)}>
                    Remove
                  </LineButton>
                </Row>
              </Box>
            );
          })}
          <Row>
            <LineButton onClick={() => addNew()}>
              Adicionar novo Formando
            </LineButton>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const EditSchoolSubscription = (props) => {
  const [data, setData] = useState(props.edit || {});

  const handleChange = (e, value) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const atributeEntry = async () => {
    const { school, id } = data;

    try {
      await axios.get(`api/v1/schools/attribute/${id}`);
      props.reloadData();
      toastr.success(
        "Inscrição Atribuida",
        `A inscrição foi atirbuida ${school}`
      );
    } catch (error) {
      console.log(error);
      toastr.error(
        "Atribuição",
        `Ocorreu um erro ao atualizar os dados para ${school}`
      );
    }
  };
  const updateEntry = async () => {
    const { school, id } = data;
    const setData = {
      ...data,
      responsible: data?.responsible?.map((item) => item?.id) || [],
    };

    try {
      await axios.post(`api/v1/schools/${id}`, setData);
      props.reloadData();
      toastr.success(
        "Inscrição",
        `Dados atualizados com sucesso para ${school}`
      );
    } catch (error) {
      console.log(error);
      toastr.error(
        "Inscrição",
        `Ocorreu um erro ao atualizar os dados para ${school}`
      );
    }
  };

  const renderSchool = () => {
    const { school, agrupamento, schooltype } = data;
    return (
      <Row>
        <Col md={12}>
          <Atcomplete
            list={schoolList.map((item) => {
              return item.escola;
            })}
            value={school}
            label="Escola"
            onChange={(e, value) => {
              let schoolData = schoolList.find((item) => item.escola === value);
              setData({
                ...data,
                school: schoolData?.escola || e.target.value,
                agrupamento: schoolData?.agrupamento,
                // schooltype: "public",
              });
            }}
            name="school"
            meta={{ invalid: false }}
          />
          {/* <Atcomplete
            list={schoolAgrupamento}
            value={agrupamento}
            label="Agrupamento"
            onChange={(e, v) =>
              handleChange({
                target: { name: "agrupamento", value: v },
              })
            }
            name="agrupamento"
            meta={{ invalid: false }}
          /> */}

          <div style={{ width: "100%", margin: "1em 0 0 0" }}>
            <SelectComponent
              value={schooltype}
              label="Tipo de Escola"
              onChange={(e) =>
                handleChange({
                  target: { name: "schooltype", value: e.target.value },
                })
              }
              name="schooltype"
              meta={{ invalid: false }}
              style={{ width: "100%" }}
            >
              <MenuItem value={"public"}>Publica</MenuItem>
              <MenuItem value={"private"}>Privada</MenuItem>
            </SelectComponent>
          </div>
        </Col>
      </Row>
    );
  };

  const renderInformation = () => {
    const {
      observations,
      area,
      status,
      slots,
      tutors,
      volunteer,
      hortaattr = [],
      material = [],
      points,
      admin,
      owner,
    } = data;
    return (
      <>
        <Row>
          <Col md={4}>
            <SideSubTitle>Geral</SideSubTitle>
            <Row>
              <Col md={12}>
                <div style={{ width: "100%", margin: "1em 0 0 0" }}>
                  <SelectComponent
                    value={status}
                    label="Estado"
                    onChange={(e) => {
                      handleChange({
                        target: { name: "status", value: e.target.value },
                      });
                    }}
                    name="status"
                    meta={{ invalid: false }}
                    style={{ width: "100%" }}
                  >
                    {schoolState.map((item, i) => {
                      if (item === "Atribuido" && `${status}` !== "2") {
                        return null;
                      }
                      return (
                        <MenuItem value={i} key={i}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </SelectComponent>
                </div>
              </Col>
            </Row>
            {renderSchool()}
            {renderHistory()}
            {renderCurricular()}
          </Col>
          <Col md={4}>
            <SideSubTitle>Horta</SideSubTitle>
            <Row>
              <Col md={12}>
                <div style={{ width: "100%", margin: "1em 0 0 0" }}>
                  <SelectComponent
                    value={material || []}
                    label="Material Escolhido"
                    onChange={(e) =>
                      handleChange({
                        target: { name: "material", value: e.target.value },
                      })
                    }
                    name="material"
                    meta={{ invalid: false }}
                    style={{ width: "100%" }}
                    multiple
                  >
                    {schoolMaterial &&
                      schoolMaterial.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </SelectComponent>
                </div>
              </Col>
            </Row>{" "}
            <Row>
              <Col md={12}>
                <Input
                  value={observations}
                  label="Observações"
                  onChange={(e) => handleChange(e)}
                  type="multiline"
                  name="observations"
                  rows={4}
                  multiline
                  meta={{ invalid: false }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Input
                  value={points || 4}
                  label="Total de Pontos"
                  onChange={(e) => handleChange(e)}
                  name="points"
                  type={"number"}
                  meta={{ invalid: false }}
                />
              </Col>
              <Col xs={3}>
                <Input
                  value={area}
                  label="Area m2"
                  onChange={(e) => handleChange(e)}
                  name="area"
                  meta={{ invalid: false }}
                />
              </Col>
              <Col xs={3}>
                <Input
                  value={slots}
                  label="Alunos"
                  onChange={(e) => handleChange(e)}
                  name="slots"
                  meta={{ invalid: false }}
                />
              </Col>
              <Col xs={3}>
                <Input
                  value={tutors}
                  label="Professores"
                  onChange={(e) => handleChange(e)}
                  name="tutors"
                  meta={{ invalid: false }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ width: "100%", margin: "1em 0 0 0" }}>
                  <SelectComponent
                    value={volunteer}
                    label="Voluntario Sénior"
                    onChange={(e) =>
                      handleChange({
                        target: { name: "volunteer", value: e.target.value },
                      })
                    }
                    name="volunteer"
                    meta={{ invalid: false }}
                    style={{ width: "100%" }}
                  >
                    <MenuItem value={"yes"}>Sim</MenuItem>
                    <MenuItem value={"no"}>Não</MenuItem>
                    <MenuItem value={"maybe"}>Talvez</MenuItem>
                  </SelectComponent>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ width: "100%", margin: "1em 0 0 0" }}>
                  <SelectComponent
                    value={hortaattr || []}
                    label="Material na Escola"
                    onChange={(e) =>
                      handleChange({
                        target: { name: "hortaattr", value: e.target.value },
                      })
                    }
                    name="hortaattr"
                    meta={{ invalid: false }}
                    style={{ width: "100%" }}
                    multiple
                  >
                    {schoolResources &&
                      schoolResources.map((item, index) => {
                        return (
                          <MenuItem value={item.name} key={index}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </SelectComponent>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <SideSubTitle>Formandos</SideSubTitle>
            <Row>
              <Col xs={12}>
                <RenderStudents
                  students={data.students || []}
                  setStudents={(val) => setData({ ...data, students: val })}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const renderApproval = () => {
    const { admin, owner, id } = data;

    const sendEmail = async () => {
      try {
        await axios.get(`api/v1/schools/sendEmail/${id}`);
        toastr.success("Email enviado com sucesso");
      } catch (error) {
        console.log(error);
        toastr.error("Email não enviado");
      }
    };

    return (
      <>
        <Row>
          <Col md={6}>
            <SideSubTitle>Director</SideSubTitle>
            <Row>
              <Col md={12}>
                <Input
                  value={admin?.name}
                  label="Nome"
                  onChange={(e) =>
                    setData({
                      ...data,
                      admin: {
                        ...(admin || {}),
                        [e.target.name]: e.target.value,
                      },
                    })
                  }
                  name="name"
                  meta={{ invalid: false }}
                />

                <Input
                  value={admin?.email}
                  label="Email"
                  onChange={(e) =>
                    setData({
                      ...data,
                      admin: {
                        ...(admin || {}),
                        [e.target.name]: e.target.value,
                      },
                    })
                  }
                  name="email"
                  meta={{ invalid: false }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <SideSubTitle>Responsavel</SideSubTitle>
            <Row>
              <Col md={12}>
                <Input
                  value={owner?.name}
                  label="Nome"
                  onChange={(e) =>
                    setData({
                      ...data,
                      owner: {
                        ...(owner || {}),
                        [e.target.name]: e.target.value,
                      },
                    })
                  }
                  name="name"
                  meta={{ invalid: false }}
                />

                <Input
                  value={owner?.email}
                  label="Email"
                  onChange={(e) =>
                    setData({
                      ...data,
                      owner: {
                        ...(owner || {}),
                        [e.target.name]: e.target.value,
                      },
                    })
                  }
                  name="email"
                  meta={{ invalid: false }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <LineButton onClick={() => sendEmail()}>
              Enviar email de aprovação
            </LineButton>
          </Col>
        </Row>
      </>
    );
  };

  const renderCurricular = () => {
    const { curricular } = data;
    return (
      <>
        <Row>
          <Col md={12}>
            <Input
              value={curricular}
              label="Atividades Curriculares"
              onChange={(e) => handleChange(e)}
              type="multiline"
              name="curricular"
              rows={4}
              multiline
              meta={{ invalid: false }}
            />
          </Col>
        </Row>
      </>
    );
  };
  const renderHistory = () => {
    const { history } = data;
    return (
      <>
        <Row>
          <Col md={12}>
            <Input
              value={history}
              label="Histórico"
              onChange={(e) => handleChange(e)}
              type="multiline"
              name="history"
              rows={4}
              multiline
              meta={{ invalid: false }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const { school, date, status } = data;
  return (
    <div
      style={{
        padding: "2em",
        background: "#fafafa",
        borderBottom: "4px solid #f4f6f8",
      }}
    >
      <Row>
        <Col md={12}>
          <PageTitle noMargin={true}>
            Inscrição de {school}
            <span style={{ fontSize: ".8em!important" }}>
              {moment(date).format("DD/MM/YYYY HH:mm")}{" "}
            </span>
          </PageTitle>{" "}
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          {
            <RenderUsers
              responsible={data.responsible || []}
              setUsers={(val) => setData({ ...data, responsible: val })}
            />
          }
        </Col>
        <Col md={4}>{renderApproval()}</Col>
      </Row>

      {/* <SideSubTitle>Escola</SideSubTitle> */}
      {renderInformation()}
      <br />
      <div>
        <LineButton
          onClick={() =>
            props.setRowsExpanded(
              props.rowsExpanded.filter((i) => i !== props.index)
            )
          }
        >
          Fechar
        </LineButton>
        <MainButton onClick={() => updateEntry()}>
          Guardar Alterações
        </MainButton>{" "}
        {status === 1 && (
          <MainButton
            onClick={() => atributeEntry()}
            style={{ background: "#0a2236" }}
          >
            Atribuir
          </MainButton>
        )}
      </div>
    </div>
  );
};

export default EditSchoolSubscription;
