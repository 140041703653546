import React, { Component } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import Leaflet from "leaflet";

export default class EditElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editedElements: [],
    };
    this.editLayers = [];
    this._editableFG = {};
  }

  onFeatureGroupReady(reactFGref) {
    // populate the leaflet FeatureGroup with the geoJson layers
    if (reactFGref && this.props.elementActionType === "edit") {
      let leafletGeoJSON = new Leaflet.GeoJSON(this.props.elements);
      let leafletFG = reactFGref.leafletElement;

      // duplication happening removing before adding
      if (reactFGref && this.editLayers.length > 0) {
        this.editLayers.map((lay) => {
          return leafletFG.removeLayer(lay);
        });
        this.editLayers = [];
      }

      // lets add now
      leafletGeoJSON.eachLayer((layer) => {
        layer.on({
          click: () => {
            return typeof this.props.onClickEditMarker === "function"
              ? this.props.onClickEditMarker(layer.feature)
              : null;
          },
          mouseover: () => {
            // console.log(feature);
          },
        });
        this.editLayers.push(layer);
        leafletFG.addLayer(layer);
      });

      // store the ref for future access to content

      this._editableFG = reactFGref;
    }
  }

  render() {
    return (
      <FeatureGroup
        ref={(reactFGref) => {
          this.onFeatureGroupReady(reactFGref);
        }}
      >
        <EditControl
          ref="edit"
          key={`${new Date()}-elements`}
          position="topleft"
          onEditStart={() => this.props.stopUserLocation()}
          onEditStop={async () => {
            this.props.startUserLocation();
            await this.state.editedElements.map(async (elementJson) => {
              await this.props.updateGeojsonElement(
                elementJson.id,
                elementJson.geometry
              );
            });
          }}
          onEdited={(e) => {
            // console.log(e.layers._layers);

            let ids = [];
            let editedElements = [];
            e.layers.eachLayer((a) => {
              const elementJson = a.toGeoJSON();
              if (!ids.includes(elementJson.properties.id)) {
                ids.push(elementJson.properties.id);
                editedElements.push({
                  id: elementJson.properties.id,
                  geometry: elementJson.geometry,
                });
              }
            });
            this.setState({ editedElements });

            //we need to update element view
            // this.props.getElements();
          }}
          edit={{ circle: false, marker: false, remove: false }}
          draw={{
            marker: false,
            circle: false,
            rectangle: false,
            polygon: false,
            polyline: false,
          }}
        />
      </FeatureGroup>
    );
  }
}
