import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonGroup, Box } from "@material-ui/core";
import { Row, Col } from "react-flexbox-grid";
import FullScreenModal from "../../../components/Layout/FullScreenModal";
import PageTitle from "../../../components/Text/PageTitle";
import MainButton from "../../../components/Buttons/MainButton";
import EditParkDetails from "./EditParkDetails";
import EditParkShoolSubscription from "./EditParkShoolSubscription";
import Table from "../../../components/Table/Table";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import SideSubTitle from "../../../components/Text/SideSubTitle";
import SchoolResponsibles from "../../Schools/SchoolResponsibles";

// import { setActiveStep, getMaintenances, addNewToggle } from './actions';
import {
  resetElement,
  getElementsTypes,
  getVegetationTypes,
  getParkById,
  getHistoryByParkId,
} from "../actions";

const Section = styled.div`
  && {
    margin: 2em 0;
    max-width: 1200px;
    ${(props) => (props.fullWidth ? "max-width: 100%" : "")}
  }
   {
    h2 {
      margin: 0;
    }
  }
`;

const ButtonWrapper = styled.div`
  && {
    text-align: right;
    font-size: 0.8em;
    margin-top: 0.9em;
    margin-bottom: 2.3em;
    button {
      // margin-right: 0.5em;
      :hover {
        a {
          color: #4da1ff;
        }
      }
    }

    a {
      text-decoration: none;
      color: #fff;
      :hover {
        color: #4da1ff;
      }
    }
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

class EditElement extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.state = {
      view: "info",
      activeHistoryId: null,
      selectedParks: props.list.find((park) => props.selected === park.numero),
    };
    props.getParkById(props.selected);
    this.getHistory = this.getHistory.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
  }

  componentDidUpdate() {
    if (this.props.status === "deleted") {
      this.close();
    }
  }

  close() {
    this.props.closeModal();
  }

  handleDataChange(e, id) {
    this.setState({ view: "info" });
    this.setState({ activeHistoryId: id });
  }

  getViewData() {
    if (this.state.activeHistoryId)
      return this.props.history.find(
        (item) => item.id === this.state.activeHistoryId
      );
    return this.props.park;
  }

  renderParkName() {
    const { selectedParks } = this.state;
    let parkName = `${(selectedParks && selectedParks.nome) ||
      selectedParks ||
      ""} ${this.state.selectedParks ? this.state.selectedParks.local : ""}`;

    return parkName;
  }

  getHistory() {
    this.setState({ view: "history" });
    this.props.getHistoryByParkId(this.props.selected || null);
  }

  render() {
    const columnHeader = [
      {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
      },
      { id: "date", numeric: false, disablePadding: false, label: "Data" },
      {
        id: "user",
        numeric: false,
        disablePadding: false,
        label: "Utilizador",
      },
      { id: "team", numeric: false, disablePadding: false, label: "Equipa" },
      // { id: 'status', numeric: false, disablePadding: false, label: 'Estado' },
    ];
    let columnData = [];
    if (this.props.history) {
      columnData = this.props.history.reduce((prev, curr) => {
        // curr.UserId
        // curr.createdAt
        const itemData = {
          id: curr.id,
          user: curr.User.name,
          team: curr.Team && curr.Team.name,
          date: curr.createdAt,
          status: curr.status,
        };
        return [...prev, itemData];
      }, []);
    }

    const isSchool =
      this.props?.park?.maintenance?.tipo === "Hortas nas Escolas";

    return (
      <FullScreenModal fullWidth={this.state.view === "subscriptions"}>
        <Close onClick={() => this.close()} className={`icon-close icons`} />
        <Section fullWidth={this.state.view === "subscriptions"}>
          <Row>
            <Col xs={12} md={6}>
              <PageTitle>{this.renderParkName()}</PageTitle>
            </Col>
            <Col xs={12} md={6}>
              <ButtonWrapper>
                <ButtonGroup>
                  <MainButton
                    active={this.state.view === "info" ? "true" : "false"}
                    onClick={() => this.setState({ view: "info" })}
                  >
                    <MenuIcon className={`icon-notebook icons`} /> Informação
                  </MainButton>
                  {isSchool && (
                    <MainButton
                      active={
                        this.state.view === "subscriptions" ? "true" : "false"
                      }
                      onClick={() => this.setState({ view: "subscriptions" })}
                    >
                      <MenuIcon className={`icon-check icons`} /> Inscrições
                    </MainButton>
                  )}
                  <MainButton
                    active={this.state.view === "history" ? "true" : "false"}
                    onClick={() => this.getHistory()}
                  >
                    <MenuIcon className={`icon-calendar icons`} /> Histórico
                  </MainButton>
                  <MainButton
                    active={this.state.view === "reports" ? "true" : "false"}
                  >
                    <MenuIcon className={`icon-graph icons`} />{" "}
                    <Link to={"reports"}>Relatórios</Link>
                  </MainButton>
                </ButtonGroup>
              </ButtonWrapper>
            </Col>
          </Row>
          {this.state.view === "info" &&
            this.props.park &&
            this.props.status === "" && (
              <>
                {isSchool && (
                  <SchoolResponsibles
                    schoolName={this.getViewData()?.about?.designacao}
                  />
                )}
                <EditParkDetails
                  data={this.getViewData()}
                  view={this.props.view}
                />
              </>
            )}
          {this.state.view === "subscriptions" && (
            <div style={{ marginTop: "-7%" }}>
              <EditParkShoolSubscription
                data={this.getViewData()}
                view={this.props.view}
              />
            </div>
          )}
          {this.state.view === "history" && this.props.status === "" && (
            <Table
              orderBy={"id"}
              data={columnData}
              click={this.handleDataChange}
              header={columnHeader}
            />
          )}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      park: state.georeference.park,
      history: state.georeference.history,
      role: state.user.data.role,
      selected: state.app.search.selected,
      status: state.georeference.status,
      list: state.parklist.list,
    };
  },
  {
    resetElement,
    getElementsTypes,
    getVegetationTypes,
    getParkById,
    getHistoryByParkId,
  }
)(EditElement);
