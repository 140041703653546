import styled from "styled-components";
import ContentWrap from "../../components/Layout/ContentWrap";
import PageTitle from "../../components/Text/PageTitle";
import MainButton from "../../components/Buttons/MainButton";
import LineButton from "../../components/Buttons/LineButton";
import Select from "../../components/form-wrappers/Select";
import TextField from "../../components/form-wrappers/TextField";

export const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    margin: 1em 0;
    text-align: center;
  }
`;

export const ButtonIcon = styled.div`
  && {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 1.2em;
    padding: 0.3em;
    background: #fafafa;
    border-radius: 6px;
    margin: 0.1em;
    ${(props) => props.active && `background: #8e3810; color: #fff;`}
    ${(props) => props.primary && `background: #28587f; color: #fff;`}
    ${(props) => props.formed && `background: #4da1ff; color: #fff;`}
    &:hover {
      background: #f0f0f0;
      color: #4da1ff;
    }
  }
`;

export const SideBarPaper = styled.div`
  && {
    background: #ffffff;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    height: 100vh;
    overflow: auto;
    border: none;
    z-index: 1;
    /* width: ${(props) =>
      props.expanded ? "calc(100% - 320px)" : "260px"}; */
    /* animation-delay: 1s; */
    @media (max-width: 455px) {
      position: relative;
      width: 100%;
      height: 100%;
      margin-left: 0;
      display: ${(props) => (props.activeview ? "block" : "none")};
    }
    @media print {
      display: none;
    }
  }
`;

export const SideToggle = styled.div`
  && {
    background: #28587f; // #4da1ff;
    padding: 0.6em;
    font-size: 0.8em;
    border-radius: 4px;
    writing-mode: tb-rl;
    /* transform: rotate(-90deg); */
    color: #fff;
    margin: 0.5em 0;
    &:hover {
      background: #4da1ff;
    }
    ${(props) => (props.active ? `background: #4da1ff;` : "")}
    .icons {
      margin-left: 0.5em;
    }
  }
`;

export const EditParkButton = styled.div`
  && {
    position: absolute;
    bottom: 0;
    right: 0.5em;
    font-size: 0.7em;
    padding: 0.3em 0.1em;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;

    border-radius: 6px;
    display: inline-block;
    color: #ffffff;
    letter-spacing: 0;
    text-transform: initial;
    margin: 0.4em 0;

    background: #4da1ff;
    color: #fff;
    .icons {
      color: #fff;
      font-size: 1em;
    }

    &:hover {
      background: #28587f;
      color: #fff;
      .icons {
        color: #fff;
      }
    }
  }
`;
export const DeleteButton = styled.div`
  && {
    font-size: 0.7em;
    padding: 0.2em 1em;

    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;

    border-radius: 6px;
    display: inline-block;
    color: #ffffff;
    letter-spacing: 0;
    text-transform: initial;
    margin: 0.4em 0;

    background: #df5757;
    border-color: #f44336;
    color: #fff;
    .icons {
      color: #fff;
      font-size: 1em;
    }

    &:hover {
      color: #fff;
      .icons {
        color: #fff;
      }
    }
  }
`;

export const Input = styled(TextField)`
  && {
    width: 100%;
    margin: ${(props) => (props.nomargin ? 0 : "0 0 0.5em 0")};
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;

export const ModalInner = styled.div`
  && {
    border: 1px solid #e9eff4;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 6px;
    padding: 0.8em;
    margin: 0.8em 0;
    overflow: auto;
    cursor: default;
    position: relative;
    color: #000;
    min-width: 280px;
    width: 50%;
    padding: 1em 2em;
    max-width: 500px;
    background: white;
    max-height: 95vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .flexwrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .left {
        width: 65%;
      }
      .right {
        width: 25%;
      }
    }
  }
`;

export const Card = styled.div`
  && {
    /* base: */
    background: ${(props) => (props.background ? props.background : "#ffffff")};
    border: 1px solid #e9eff4;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 6px;
    padding: 0.8em;
    margin: 0.8em 0;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: ${(props) => (props.color ? props.color : "#000")};

    &:hover {
      background: #28587f;
      color: #fff;
    }
    strong {
      color: rgba(74, 144, 226, 0.6);
    }
    .parcel {
      font-size: 1.5em;
      text-align: center;
      display: block;
      font-weight: bold;
      background: #28587f;
      color: #fff;
      border-radius: 5px;
      padding: 0.4em 0;
    }
    .rank {
      font-size: 1.5em;
      text-align: center;
      display: block;
      font-weight: bold;
      background: #28587f;
      color: #fff;
      border-radius: 5px;
      padding: 0.4em 0;
    }

    .label {
      position: absolute;
      font-size: 0.75em;
      text-align: center;
      background: #28587f;
      color: #fff;
      padding: 0.2em 0.5em;
      border-radius: 5px;
      top: -0.5em;
      right: 0.5em;
    }

    .name {
      font-size: 0.8em;
      font-weight: bold;
      display: block;
    }
    .nameRank {
      font-size: 1.2em;
      font-weight: bold;
    }
    .ranking {
      font-size: 0.7em;
      font-weight: normal;
      display: block;
    }
    .rankingType {
      margin: 0.3em 0;
      font-size: 0.7em;
      font-weight: normal;
      display: block;
      text-align: center;
    }
    .flex {
      display: flex;
      justify-content: flex-end;
      font-size: 0.8em;
      margin: 0.5em 0 0 0;
    }
    .date {
      margin: 0.5em 0 0 0;
      font-size: 0.8em;
    }
    .state {
      font-weight: bold;
      display: inline-flex;
      border-radius: 5px;
      padding: 0.2em 0.5em;
      font-size: 0.7em;
      color: #fff;
      background: #26a32a;
    }
    .education {
      margin: 0.5em 0 0 0;
      font-size: 0.8em;
    }
    .note {
      font-size: 0.9em;
      line-height: 1.1;
      display: none;
      background: #fff;
      color: #000;
      border-radius: 5px;
      margin: 1em 0.5em 0 0.5em;
    }
    .contacts {
      font-size: 0.8em;
      margin: 0.5em 0 0 0;

      .email {
        text-decoration: none;
        color: #28587f;
      }
      .phone {
      }
    }

    .icons {
    }
    &:hover {
      .note {
        padding: 0.5em;
        display: block;
      }
      .email {
        color: #fff;
      }
    }
  }
`;

export const OptionWrapRank = styled.div`
  padding: 0em 1em 1em 0;
  margin: 0.5em 0;
  font-size: 0.9em;
  height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background: #fff; */
  overflow: auto;
`;

export const OptionWrapElements = styled.div`
  && {
    padding: 1em;
    margin: 0.5em 0;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e9eff4;
    border-radius: 6px;
    cursor: pointer;
    background: ${(props) => {
      if (props.selected) {
        return "#4da1ff";
      }

      if (props.full) {
        return "#6fc44c";
      }
      if (props.fixed) {
        return "#f29729";
      }
      return "#fff";
    }};
    .places {
      text-align: center;
      width: 20%;
      font-weight: bold;
      /* color: #4da1ff; */
      font-size: 1.2em;
      .total {
        font-size: 1.6em;
      }
      .totalUsers {
        background: #28587f;
        display: inline-flex;
        padding: 0.2em 0.5em;
        border-radius: 6px;
        color: #fff;
        align-items: center;
        span {
          font-size: 0.65em;
        }
      }
    }
    .details {
      width: 78%;
      .name {
        font-weight: bold;
      }

      .sidebside {
        display: flex;
        opacity: 0.5;

        .abbr {
          margin-left: 0.5em;
          font-weight: bold;
        }
      }
      .quotas {
        display: flex;
        justify-content: flex-start;
        .r0,
        .r1,
        .r2 {
          background: #4da1ff;
          border-radius: 3px;
          padding: 0.2em 0.5em;
          margin: 0 0.2em;
          color: #fff;
          font-weight: bold;
          span {
            font-size: 0.8em;
          }
          &.colorRed {
            background: #df5757;
          }
        }
      }
    }

    span,
    div {
      color: ${(props) => {
        if (props.fixed) {
          return "#fff";
        }
      }};
    }
    :hover {
      background: ${(props) =>
        props.selected || props.full ? "#4da1ff" : "#4da1ff"};
      color: #fff;
    }

    ${(props) => (props.selected || props.full ? "span,div{color:#fff}" : "")};
  }
`;

export const DateWrap = styled(ContentWrap)`
  && {
    padding: 1em;
    margin: 1em 0 2em 0;
    text-align: center;
  }
`;
export const StyledPageTitleWrap = styled.div`
  && {
    display: inline-block;
  }
`;

export const StyledPageTitle = styled(PageTitle)`
  && {
    margin-left: 120px;
  }
`;
export const Section = styled.div`
  && {
    margin: 2em auto;
    max-width: 1400px;
  }
`;
export const Bar = styled.div`
  && {
    height: 4px;
    width: 52px;
    background: ${(props) => props.color || "#4da1ff"};
    border-radius: 2px;
    margin: 0.4em 0 1em 0;
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  /* height: calc(100vh - 49px); */
  @media (max-width: 768px) {
    display: block;
    height: 100%;
    overflow: auto;
  }
`;

export const TableWrap = styled.div`
  && {
    display: ${(props) => (props.display === "display" ? "block" : "none")};
    overflow-x: auto;
    overflow-y: visible;
    margin: 0;
    > div {
      padding-top: 0;
    }
  }
`;

export const StyledMainButton = styled(MainButton)`
  && {
    width: 100%;
    margin-top: 1em;
    font-size: 0.8em;
    background: ${(props) => (props.color ? props.color : "")};
  }
`;
export const StyledLineButton = styled(LineButton)`
  && {
    font-size: 0.8em;
    padding: 0;
    margin: 0.3em 0;
    background: #fff !important;
    text-align: center;
    color: #4da1ff;
  }
`;

export const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: calc(100vh - 180px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
      @media (max-width: 768px) {
        height: calc(100vh - 230px);
      }
    }
  }
`;
