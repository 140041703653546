import React from 'react';
import styled from 'styled-components';

const Title = styled.h3`
  && {
    font-size: 2em;
    color: #474f58;
    font-weight: bold;
    letter-spacing: 0.13px;
    margin: 0;
    padding: 0.5em 0 0.3em;
  }
`;

const BlueBar = styled.div`
  && {
    height: 4px;
    width: 52px;
    background: #4da1ff;
    border-radius: 2px;
    margin: 0 auto;
  }
`;

class OptionTitle extends React.Component {
  render() {
    return (
      <div {...this.props}>
        <Title>{this.props.children}</Title>
        <BlueBar />
      </div>
    );
  }
}

export default OptionTitle;
