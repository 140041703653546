export const ADD_NEW_TOGGLE = 'ADD_NEW_MAINTENANCE_TOGGLE';
export const SET_PHOTOS = 'SET_MAINTENANCE_PHOTOS';
export const SET_TYPE = 'SET_MAINTENANCE_TYPE';
export const SET_DETAILS = 'SET_MAINTENANCE_DETAILS';
export const SET_ACTIVE_STEP = 'SET_MAINTENANCE_ACTIVE_STEP';

export const UPDATE_SEARCH = 'UPDATE_MAINTENANCE_SEARCH';
export const UPDATE_ACTIVE_PARK = 'UPDATE_MAINTENANCE_PARK';

export const SAVING = 'MAINTENANCE_SAVING_REQUEST';
export const SAVED = 'MAINTENANCE_SAVED_SUCCESS';
export const ERROR = 'MAINTENANCE_ERROR_FAILURE';
export const RESET = 'MAINTENANCE_RESET';
export const DELETE = 'MAINTENANCE_DELETE';
export const DELETED = 'MAINTENANCE_DELETED';

export const HISTORY_ERROR = 'MAINTENANCE_HISTORY_FAILURE';
export const HISTORY_GETTING = 'MAINTENANCE_HISTORY_REQUEST';
export const HISTORY_RECEIVED = 'MAINTENANCE_HISTORY_SUCCESS';

export const ELEMENTS_ERROR = 'MAINTENANCE_ELEMENTS_FAILURE';
export const ELEMENTS_GETTING = 'MAINTENANCE_ELEMENTS_REQUEST';
export const ELEMENTS_RECEIVED = 'MAINTENANCE_ELEMENTS_SUCCESS';

export const GETTING = 'MAINTENANCE_REQUEST';
export const RECEIVED = 'MAINTENANCE_SUCCESS';
export const UPDATED = 'MAINTENANCE_UPDATED';

export const UPDATED_HISTORY = 'MAINTENANCE_UPDATED_HISTORY';
export const APPROVED_MAINTENANCE_GROUP = 'APPROVED_MAINTENANCE_GROUP';

export const INTERVENTIONS_GETTING = 'INTERVENTIONS_REQUEST';
export const INTERVENTIONS_RECEIVED = 'INTERVENTIONS_SUCCESS';
export const INTERVENTIONS_UPDATED = 'INTERVENTIONS_UPDATED';
