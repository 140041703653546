import styled from "styled-components";
import Select from "../../components/form-wrappers/Select";

export const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  /* height: calc(100vh - 49px); */
  @media (max-width: 768px) {
    display: block;
    height: 100%;
    overflow: auto;
  }
`;

export const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: calc(100vh - 180px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
      @media (max-width: 768px) {
        height: calc(100vh - 230px);
      }
    }
  }
`;

export const ContactsDisplay = styled.div`
  && {
    .contact {
      border-bottom: 1px solid rgba(77, 161, 255, 0.25);
      text-align: left;
      color: #4da1ff;
      padding: 0.5em 0;
      font-size: 0.8em;
    }
    max-height: 200px;
    overflow: auto;
  }
`;

export const TableWrap = styled.div`
  && {
    overflow-x: auto;
    overflow-y: visible;
    margin: 0;
    /* margin: 60px 0 0 0; */
    > div {
      padding-top: 0;
    }
  }
`;

export const MaintenanceFilter = styled(Select)`
  && {
    border: 0;
    font-size: 0.9em;
    color: #a3a1a1;
  }
`;
