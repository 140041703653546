import React from "react";
import styled from "styled-components";
import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import DropzoneComponent from "react-dropzone-component";
import { Select, Switch } from "@material-ui/core";

export const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;

export const Toggle = styled(props => <Switch {...props} />)`
  && {
    .MuiSwitch-bar-143,
    .jss143 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-colorPrimary-140.MuiSwitch-checked-139,
    .jss139 {
      color: #4da1ff;
    }
  }
`;

export const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;

export const SelectStyled = styled(props => <Select {...props} />)`
  && {
    font-size: 1.2em;
    text-align: left;
    input:disabled,
    select:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

export const AutoCompleteWrapper = styled.div`
  && {
    margin: 0.5em;
    input {
      font-size: 1.2em;
    }
    input:disabled,
    select:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

export const Input = styled(props => <TextField {...props} />)`
  && {
    width: 100%;
    margin: 1em 0 0 0;
    text-align: left;
    textarea {
      height: auto;
    }
    input:disabled,
    select:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
  }
`;

export const Calendar = styled(Input)`
  && {
    margin: 1em 0 0 0;
  }
`;

export const ImageContainer = styled.div`
  && {
    text-align: left;
  }
`;

export const AlignLeft = styled.div`
  && {
    text-align: left;
    margin: 1em 0;
  }
`;
export const Image = styled.div`
  && {
    width: 200px;
    height: 130px;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    border-radius: 0.5em;
    background-size: cover;
    cursor: pointer;
  }
`;

export const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
  }
`;

export const DisableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #df5757;
    width: 100%;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;
