import React from "react";
import styled from "styled-components";

const StyledButtonGroup = styled.div`
  && {
    border-radius: 6px;
    background: #f4f4f4;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 3px;
    padding: 3px;
    margin-bottom: 1em;
    color: #000;

    ${(props) =>
      props.expanded &&
      `
        width:23%;
        position:absolute;
        right:2em;
      `};
  }
`;

class ButtonGroup extends React.Component {
  render() {
    return (
      <StyledButtonGroup
        {...this.props}
        expanded={this.props.expanded ? "true" : undefined}
      >
        {this.props.children}
      </StyledButtonGroup>
    );
  }
}

export default ButtonGroup;
