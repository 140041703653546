import { createSelector } from "reselect";

const getGrowers = (state) => state.user.growersList;
const getContacts = (state) => state.communications.contacts;
const getGrowerRelations = (state) => state.georeference.growerRelations;
const getEditData = (state) => state.communications.edit;

export const getMessageContactProfile = createSelector(
  [getContacts, getEditData, getGrowers, getGrowerRelations],
  (contact, edit, growers, growerRelations) => {
    let growerData = [];
    let contactArray = [];

    if (contact && contact.length > 0) {
      contactArray = contact;
    } else if (edit.contacts && edit.contacts.length > 0) {
      contactArray = edit.contacts;
    }
    //lets find the contact from growers list
    growerData = contactArray.map((thisCont) => {
      const thisGrower = Number(thisCont);
      let thisGrowerData = [];
      thisGrowerData = growers.find((gr) => {
        return (
          Number(gr.id) === thisGrower || Number(gr.originId) === thisGrower
        );
      });
      if (!thisGrowerData) {
        thisGrowerData = growerRelations.find((gr) => {
          return Number(gr.id) === thisGrower;
        });
      }
      return thisGrowerData;
    });

    //lets return the grower data
    return growerData;
  }
);
