import { FormControlLabel, MenuItem } from "@material-ui/core";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import Select from "../../../components/form-wrappers/Select";

import { SendMessageButton } from "../../../components/Card/styled";
import Freguesias from "../../../components/Filters/Freguesias";
import AutoComplete from "../../../components/form-wrappers/AutoComplete";
import SingleMap from "../../../components/Map/SingleMap";
import SectionTitle from "../../../components/Text/SectionTitle";
import { apiAddress } from "../../../config/general";

import {
  Data,
  Input,
  NextButton,
  OptionWrap,
  Section,
  Toggle,
  Files,
  RelationButton,
} from "./styled.js";

import MenuIcon from "../../../components/Buttons/MenuIcon";

import {
  getGrower,
  getGrowers,
  resetUser,
  searchAddress,
  searchGeoLocation,
  setGrower,
  setGrowerDetails,
  toggleUserStatus,
} from "../actions";
// import { setHistory } from './actions';

class EditGrower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.edit || "",
      admin: false,
      external: false,
      hiddenAutocomplete: true,
    };

    this.callbackPhoto = this.callbackPhoto.bind(this);
    this.handleFreguesiaChange = this.handleFreguesiaChange.bind(this);
    this.handleUpdateLatLon = this.handleUpdateLatLon.bind(this);
    this.handleSaveGrower = this.handleSaveGrower.bind(this);
    this.handleChangeAux = this.handleChangeAux.bind(this);
    this.handleChangeEducationAux = this.handleChangeEducationAux.bind(this);
    this.handleWarningChange = this.handleWarningChange.bind(this);
    this.handlTypeChange = this.handlTypeChange.bind(this);
    this.handleEducationChange = this.handleEducationChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleAttrStateChange = this.handleAttrStateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectedParksChange = this.handleSelectedParksChange.bind(this);
    this.handleChangeDebounce = debounce(this.handleChange.bind(this), 600);

    if (props.edit) {
      props.getGrower(props.edit);
    }
    const t = this;
    setTimeout(() => {
      t.setState({ hiddenAutocomplete: false });
    }, 1000);

    this.djsConfig = {
      acceptedFiles: "image/*",
      addRemoveLinks: false,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },

      params: {
        fid: props.data.id, //should be the park id
      },
    };
    this.config = {
      iconFiletypes: ["images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };
  }

  handleSaveGrower() {
    this.props.setGrower(this.props.edit);
  }

  handleStateChange(value) {
    this.props.setGrowerDetails({
      element: "status",
      value: value.target.value || [],
    });
  }

  handleAttrStateChange(value) {
    this.props.setGrowerDetails({
      element: "attrStatus",
      value: value.target.value || [],
    });
  }

  handlTypeChange(value) {
    this.props.setGrowerDetails({
      element: "type",
      value: value.target.value || [],
    });
  }
  handleEducationChange(value) {
    this.props.setGrowerDetails({
      element: "education",
      value: value.target.value || [],
    });
  }
  handleFreguesiaChange(value) {
    this.props.setGrowerDetails({
      element: "freguesia",
      value: value.target.value || "",
    });
  }

  handleSelectedParksChange(parks) {
    this.props.setGrowerDetails({
      element: ["parks"],
      value: parks,
    });
  }
  handleUpdateLatLon(lat, lon) {
    this.props.setGrowerDetails({
      element: "lat",
      value: lat,
    });
    this.props.setGrowerDetails({
      element: "lon",
      value: lon,
    });
  }

  handleChange(e, name) {
    let nameChanged = "";
    let valueChanged = "";

    if (name === "address" && !e.target) {
      nameChanged = name;
      valueChanged = {
        ...this.props.data.address,
        rua: e,
      };

      // let ruaSearch = valueChanged.rua;
      if (valueChanged.rua && valueChanged.rua.newValue) {
        let ruaSearch = valueChanged.rua.newValue;
        this.props.searchAddress("porta", ruaSearch);
      } else if (valueChanged.rua && valueChanged.rua.suggestion) {
        this.props.searchGeoLocation(valueChanged.rua.suggestion.urn);
        ///lets save id
      }
    } else if (name === "address") {
      // nameChanged = name;
      // valueChanged = e.target.value;
      // valueChanged = {
      //   ...this.props.data.address,
      //   [e.target.name]: valueChanged
      // };
      // if (e.target.name === "porta" && this.props.data.address.rua.suggestion) {
      //   console.log(this.props.data.address.rua.suggestion.label, valueChanged);
      //   this.props.searchAddress(
      //     "porta",
      //     `${this.props.data.address.rua.suggestion.label}, ${valueChanged.porta}`
      //   );
      // }
    } else if (name === "oringinalAddress") {
      nameChanged = name;
      valueChanged = {
        ...this.props.data.oringinalAddress,
        [e.target.name]: e.target.value,
      };
    } else if (e.target.name === "educationDone") {
      nameChanged = e.target.name;
      valueChanged = !this.props.data.educationDone;
    } else {
      nameChanged = e.target.name;
      valueChanged = e.target.value;
    }

    this.props.setGrowerDetails({
      element: [nameChanged],
      value: valueChanged,
    });
  }

  handleChangeEducationAux(e) {
    const nameChanged = e.target.name;
    const valueChanged =
      this.props.data.auxiliar.educationDone &&
      this.props.data.auxiliar.educationDone
        ? !this.props.data.auxiliar.educationDone
        : true;

    this.props.setGrowerDetails({
      element: "auxiliar",
      value: {
        ...this.props.data.auxiliar,
        [nameChanged]: valueChanged,
      },
    });
  }
  handleWarningChange(e) {
    const nameChanged = e.target.name;
    const valueChanged =
      this.props.data.attr && this.props.data.attr.warning
        ? !this.props.data.attr.warning
        : true;

    this.props.setGrowerDetails({
      element: "attr",
      value: {
        ...this.props.data.attr,
        [nameChanged]: valueChanged,
      },
    });
  }
  handleChangeAux(e) {
    const nameChanged = e.target.name;
    const valueChanged = e.target.value;
    this.props.setGrowerDetails({
      element: "auxiliar",
      value: { ...this.props.data.auxiliar, [nameChanged]: valueChanged },
    });
  }

  // Simple callbacks work too, of course
  callbackPhoto(file, target) {
    if (!target || !file.xhr.response) return;
    const fileData = JSON.parse(file.xhr.response);
    const photo = {
      path: fileData.msg.details.path,
      name: fileData.msg.details.originalname,
      size: fileData.msg.details.size,
      id: fileData.msg.id,
    };

    const newPhotoObj = {
      ...this.props.data.photo,
      [target]: [...(this.props.data.photo?.[target] || []), photo],
    };

    this.props.setGrowerDetails({
      element: "photo",
      value: newPhotoObj,
    });
  }

  preloadImages(dropzone) {
    (this.state.photos || []).forEach(function(i) {
      var name = "";
      var file = { url: i.path, name: name };
      var imageUrl = `${apiAddress}${i.path}`;

      dropzone.createThumbnailFromUrl(file, imageUrl);
      dropzone.emit("addedfile", file);
      dropzone.emit("thumbnail", file, imageUrl);
      dropzone.emit("complete", file);
    });
  }

  renderAddress(value = {}, name, label) {
    const porta = value?.porta || "";
    const localidade = value?.localidade || "";
    const rua = value?.rua || "";
    const cp = value?.cp || "";
    const fracao = value?.fracao || "";
    const freguesia = value?.freguesia || "";
    const addresses = this.props.addresses;

    return (
      <div>
        <Row>
          <Col md={12}>
            {name === "address" ? (
              <AutoComplete
                updateState={(e) => this.handleChangeDebounce(e, name)}
                inject={
                  addresses
                    ? addresses.map((address, i) => {
                        return {
                          label: address.porta || address.rua,
                          index: i,
                          urn: address.urn,
                        };
                      })
                    : []
                }
                placeholder={"Pesquise uma morada, porta"}
                defaultValue={rua}
              />
            ) : (
              <Input
                label={"Rua"}
                onChange={(e) => this.handleChange(e, name)}
                margin="dense"
                name={`rua`}
                value={rua}
                input={{}}
                meta={{ invalid: false }}
              />
            )}
          </Col>
        </Row>
        {name !== "address" && (
          <Row>
            <Col md={6}>
              <Input
                label={"Porta"}
                onChange={(e) => this.handleChange(e, name)}
                margin="dense"
                name={`porta`}
                value={porta}
                input={{}}
                meta={{ invalid: false }}
              />
            </Col>
            <Col md={6}>
              <Input
                label={"Fração"}
                onChange={(e) => this.handleChange(e, name)}
                margin="dense"
                name={`fracao`}
                value={fracao}
                input={{}}
                meta={{ invalid: false }}
              />
            </Col>
          </Row>
        )}

        <Row>
          {name !== "address" && (
            <Col md={6}>
              <Input
                label={"CP"}
                onChange={(e) => this.handleChange(e, name)}
                margin="dense"
                name={`cp`}
                value={cp}
                input={{}}
                meta={{ invalid: false }}
              />
            </Col>
          )}
          {name !== "address" && (
            <Col md={6}>
              <Input
                label={"Localidade"}
                onChange={(e) => this.handleChange(e, name)}
                margin="dense"
                name={`localidade`}
                value={localidade}
                input={{}}
                meta={{ invalid: false }}
              />
            </Col>
          )}
          {name !== "address" && (
            <Col md={12}>
              <Input
                label={"Freguesia"}
                onChange={(e) => this.handleChange(e, name)}
                margin="dense"
                name={`freguesia`}
                value={freguesia}
                input={{}}
                meta={{ invalid: false }}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }

  render() {
    const {
      data,
      growerState,
      attributionState,
      growerType,
      modules,
    } = this.props;
    const self = this;

    if (!data.auxiliar) {
      data.auxiliar = {};
    }

    return (
      <Section>
        <Row>
          <Col md={12}>
            <Row>
              <Col xs={12} sm={8} md={8} lg={8} className={"m-b-15"}>
                <SectionTitle>
                  <MenuIcon className={`icon-notebook icons`} /> Dados de
                  Utilizador
                </SectionTitle>
                <OptionWrap>
                  <Input
                    label="Nome de Utilizador"
                    onChange={(e) => this.handleChange(e)}
                    // disabled={!data.edit}
                    name="name"
                    input={{}}
                    value={data.name}
                    meta={{ invalid: false }}
                  />
                  <Input
                    label="NIF"
                    onChange={(e) => this.handleChange(e)}
                    name="vat"
                    input={{}}
                    value={`${data.vat}`}
                    meta={{ invalid: false }}
                  />
                  <Data
                    id="birthday"
                    name="birthday"
                    label="Data de Nascimento"
                    type="date"
                    onChange={(e) => this.handleChange(e)}
                    value={data.birthday ? data.birthday.split("T")[0] : ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Input
                    label="Telefone"
                    onChange={(e) => this.handleChange(e)}
                    name="phone"
                    value={`${data.phone}`}
                    input={{}}
                    meta={{ invalid: false }}
                  />
                  <Input
                    label="Telemovel"
                    onChange={(e) => this.handleChange(e)}
                    name="mobile"
                    value={`${data.mobile}`}
                    input={{}}
                    meta={{ invalid: false }}
                  />
                  <Input
                    label="Email"
                    onChange={(e) => this.handleChange(e)}
                    name="email"
                    autoComplete={"off"}
                    value={data.email}
                    input={{}}
                    meta={{ invalid: false }}
                  />

                  {this.renderAddress(
                    data.oringinalAddress,
                    "oringinalAddress",
                    "Morada Contacto"
                  )}
                </OptionWrap>
                <br />
                <OptionWrap>
                  <Input
                    label="Observações"
                    onChange={(e) => this.handleChange(e)}
                    type="multiline"
                    name="note"
                    margin="dense"
                    multiline
                    value={`${data.note}`}
                    input={{}}
                    meta={{ invalid: false }}
                  />
                </OptionWrap>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                {/* <SectionTitle>
                  <MenuIcon className={`icon-location-pin icons`} />
                  Fotografia
                </SectionTitle>

                {data.photo && data.photo[0] && (
                  <ImagePlaceHolder
                    src={`${apiAddress}${data.photo && data.photo[0].path}`}
                  />
                )}

                <Files
                  config={this.config}
                  eventHandlers={eventHandlers}
                  djsConfig={this.djsConfig}
                  />
                */}{" "}
                <SectionTitle>
                  <MenuIcon className={`icon-map icons`} /> Morada Ranking
                  Georeferencia
                </SectionTitle>
                <OptionWrap>
                  <SingleMap
                    updateLatLon={this.handleUpdateLatLon}
                    center={[parseFloat(data.lat), parseFloat(data.lon)]}
                  />
                </OptionWrap>
                <OptionWrap>
                  {this.renderAddress(
                    data.address,
                    "address",
                    "Morada Georeferencia"
                  )}
                  <Freguesias
                    handleFreguesiaChange={this.handleFreguesiaChange}
                    freguesia={data.freguesia}
                  />
                </OptionWrap>
                <SectionTitle>Definir nova Password de acesso</SectionTitle>
                <OptionWrap>
                  <Input
                    label="password"
                    onChange={(e) => this.handleChange(e)}
                    name="password"
                    input={{}}
                    type={"password"}
                    meta={{ invalid: false }}
                    style={{ margin: 0 }}
                  />
                </OptionWrap>
                <NextButton
                  color="primary"
                  onClick={() => this.handleSaveGrower()}
                >
                  Salvar
                  <MenuIcon className={`icon-check icons`} />
                </NextButton>
                <br />
                <br />
                <SendMessageButton contacts={[data.id]} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className={"m-b-15"}>
                {/* <SectionTitle>
                  <MenuIcon className={`icon-map icons`} /> Morada Ranking
                  Georeferencia
                </SectionTitle>

                <OptionWrap>
                  <SingleMap
                    updateLatLon={this.handleUpdateLatLon}
                    center={[parseFloat(data.lat), parseFloat(data.lon)]}
                  />
                </OptionWrap>
                <OptionWrap>
                  {this.renderAddress(
                    data.address,
                    "address",
                    "Morada Georeferencia"
                  )}
                  <Freguesias
                    handleFreguesiaChange={this.handleFreguesiaChange}
                    freguesia={data.freguesia}
                  />
                </OptionWrap> */}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                <SectionTitle>
                  <MenuIcon className={`icon-list icons`} /> Formação
                </SectionTitle>
                <OptionWrap>
                  <Data
                    id="coursedata"
                    name="coursedata"
                    label="Data da Formação"
                    type="date"
                    onChange={(e) => this.handleChange(e)}
                    value={data.coursedata ? data.coursedata.split("T")[0] : ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Select
                    fullWidth
                    name={"education"}
                    label={"Modulos"}
                    value={data.education || []}
                    multiple={true}
                    onChange={this.handleEducationChange}
                    inputProps={{
                      name: "education",
                      id: "education",
                    }}
                  >
                    {modules.map((type, i) => {
                      return (
                        <MenuItem key={i} value={`${i}`}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  <FormControlLabel
                    control={
                      <Toggle
                        onChange={this.handleChange}
                        checked={data.educationDone ? true : false}
                        label="Formação"
                        // disabled={!data.edit}
                        name="educationDone"
                        color="primary"
                        meta={{ invalid: false }}
                      />
                    }
                    label="Formação"
                  />
                </OptionWrap>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                <SectionTitle>
                  <MenuIcon className={`icon-lock-open icons`} /> Permissões
                </SectionTitle>
                <OptionWrap>
                  <div style={{ margin: "0 0 1em 0" }}>
                    <Select
                      fullWidth
                      name={"type"}
                      label={"Tipo de utilizador"}
                      value={data.type || []}
                      onChange={this.handlTypeChange}
                      inputProps={{
                        name: "type",
                        id: "type",
                      }}
                    >
                      {growerType.map((type, i) => {
                        return (
                          <MenuItem key={i} value={`${i}`}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                  <div style={{ margin: "0 0 1em 0" }}>
                    <Select
                      fullWidth
                      name={"status"}
                      label={"Estado"}
                      value={`${data.status}`}
                      onChange={this.handleStateChange}
                      inputProps={{
                        name: "status",
                        id: "status",
                      }}
                    >
                      {growerState.map((type, i) => {
                        return (
                          <MenuItem key={i} value={`${i}`}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                  <div style={{ margin: "0 0 1em 0" }}>
                    <Select
                      fullWidth
                      name={"attrStatus"}
                      label={"Estado Atribuição"}
                      value={`${data.attrStatus}`}
                      onChange={this.handleAttrStateChange}
                      placeholder={"Estado Atribuição"}
                      inputProps={{
                        name: "attrStatus",
                        id: "attrStatus",
                      }}
                    >
                      {attributionState &&
                        attributionState.map((type, i) => {
                          return (
                            <MenuItem key={i} value={`${i}`}>
                              {type}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </div>
                  <div style={{ margin: "0 0 1em 0" }}>
                    <Input
                      label="ID Externo"
                      onChange={(e) => this.handleChange(e)}
                      name="extId"
                      input={{}}
                      value={`${data.extId}`}
                      meta={{ invalid: false }}
                    />{" "}
                    <FormControlLabel
                      control={
                        <Toggle
                          onChange={this.handleWarningChange}
                          checked={
                            data.attr && data.attr.warning ? true : false
                          }
                          label="Cartão Amarelo"
                          // disabled={!data.edit}
                          name="warning"
                          color="primary"
                          meta={{ invalid: false }}
                        />
                      }
                      label="Cartão Amarelo"
                    />
                  </div>

                  {/* <FormControlLabel
                      control={
                        <Toggle
                          onChange={this.handleChange}
                          value="true"
                          checked={data.admin}
                          label="Admin"
                          // disabled={!data.edit}
                          name="admin"
                          color="primary"
                          meta={{ invalid: false }}
                        />
                      }
                      label="Administrador"
                    /> */}
                </OptionWrap>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                <SectionTitle>
                  <MenuIcon className={`icon-location-pin icons`} />
                  Contacto do Auxiliar
                </SectionTitle>
                <OptionWrap>
                  <Input
                    label="Nome"
                    onChange={(e) => this.handleChangeAux(e)}
                    name="name"
                    autoComplete={"off"}
                    value={`${data.auxiliar.name || ""}`}
                    input={{}}
                    meta={{ invalid: false }}
                  />

                  <Input
                    label="Email"
                    onChange={(e) => this.handleChangeAux(e)}
                    name="email"
                    autoComplete={"off"}
                    value={`${data.auxiliar.email || ""}`}
                    input={{}}
                    meta={{ invalid: false }}
                  />

                  <Input
                    label="Telefone"
                    onChange={(e) => this.handleChangeAux(e)}
                    name="phone"
                    autoComplete={"off"}
                    value={`${data.auxiliar.phone || ""}`}
                    input={{}}
                    meta={{ invalid: false }}
                  />
                  <Input
                    label="Observações"
                    onChange={(e) => this.handleChangeAux(e)}
                    type="multiline"
                    name="note"
                    margin="dense"
                    multiline
                    value={`${data.auxiliar.note || ""}`}
                    input={{}}
                    meta={{ invalid: false }}
                  />
                  <FormControlLabel
                    control={
                      <Toggle
                        onChange={this.handleChangeEducationAux}
                        checked={data.auxiliar.educationDone ? true : false}
                        label="Formação"
                        // disabled={!data.edit}
                        name="educationDone"
                        color="primary"
                        meta={{ invalid: false }}
                      />
                    }
                    label="Formação"
                  />
                </OptionWrap>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                <SectionTitle>
                  <MenuIcon className={`icon-doc icons`} />
                  Contrato
                </SectionTitle>
                <OptionWrap>
                  {data?.photo?.contrato?.map((photo, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          position: "relative",
                        }}
                      >
                        <a href={`${apiAddress}${photo.path}`} target="_blank">
                          <img
                            src={`${apiAddress}${photo.path}`}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "5px",
                            }}
                            onError={(e) => {
                              e.target.src = "/images/document.png";
                              e.target.onError = "";
                            }}
                          />
                        </a>
                        <button
                          style={{
                            cursor: "pointer",
                            color: "white",
                            textAlign: "center",
                            padding: "5px",
                            backgroundColor: "red",
                            fontSize: ".8em",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            border: "none",
                          }}
                          popovertarget={`contrato-popover-${i}`}
                          // onClick={() => {
                          //   alert(
                          //     "This browser does not support desktop notification"
                          //   );
                          // }}
                        >
                          Remover
                        </button>
                        <popover
                          id={`contrato-popover-${i}`}
                          className="popover"
                          popover="manual"
                          style={{
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          <h4>Remover o documento</h4>
                          <img
                            src={`${apiAddress}${photo.path}`}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "5px",
                            }}
                            onError={(e) => {
                              e.target.src = "/images/document.png";
                              e.target.onError = "";
                            }}
                          />
                          <p>
                            Esta acção irá remover o documento do contrato, tem
                            que salvar para que a alteração seja efectiva
                          </p>
                          <br />
                          <RelationButton
                            popovertarget={`contrato-popover-${i}`}
                            popovertargetaction="hide"
                            color="secondary"
                            // onClick={() => this.handleSaveGrower()}
                            onClick={() => {
                              const newPhotoObj = {
                                ...data.photo,
                                contrato: data.photo.contrato.filter(
                                  (photo, a) => a !== i
                                ),
                              };
                              this.props.setGrowerDetails({
                                element: "photo",
                                value: newPhotoObj,
                              });
                            }}
                          >
                            Remover Imagem
                            <MenuIcon className={`icon-check icons`} />
                          </RelationButton>
                          <button
                            popovertarget={`contrato-popover-${i}`}
                            popovertargetaction="hide"
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                              marginTop: "1em",
                              textAlign: "center",
                            }}
                          >
                            <span class="sr-only">Close</span>
                          </button>
                        </popover>
                      </div>
                    );
                  })}
                  <Files
                    config={this.config}
                    eventHandlers={{
                      init: (dropzone) => {
                        dropzone.autoDiscover = false;
                        // self.preloadImages(dropzone);
                      },
                      removedfile: self.removedfile,
                      error: (e) => {
                        console.log(e);
                      },
                      success: (e) => {
                        self.callbackPhoto(e, "contrato");
                      },
                    }}
                    djsConfig={this.djsConfig}
                  />
                </OptionWrap>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                <SectionTitle>
                  <MenuIcon className={`icon-doc icons`} />
                  Comprovativo de Morada
                </SectionTitle>
                <OptionWrap>
                  {data?.photo?.morada?.map((photo, i) => {
                    //get file type
                    return (
                      <div
                        key={i}
                        style={{
                          position: "relative",
                        }}
                      >
                        <a href={`${apiAddress}${photo.path}`} target="_blank">
                          <img
                            src={`${apiAddress}${photo.path}`}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "5px",
                            }}
                            onError={(e) => {
                              e.target.src = "/images/document.png";
                              e.target.onError = "";
                            }}
                          />
                        </a>
                        <button
                          style={{
                            cursor: "pointer",
                            color: "white",
                            textAlign: "center",
                            padding: "5px",
                            backgroundColor: "red",
                            fontSize: ".8em",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            border: "none",
                          }}
                          popovertarget={`morada-popover-${i}`}
                          // onClick={() => {
                          //   alert(
                          //     "This browser does not support desktop notification"
                          //   );
                          // }}
                        >
                          Remover
                        </button>
                        <popover
                          id={`morada-popover-${i}`}
                          className="popover"
                          popover="manual"
                          style={{
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          <h4>Remover o documento</h4>
                          <img
                            src={`${apiAddress}${photo.path}`}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "5px",
                            }}
                            onError={(e) => {
                              e.target.src = "/images/document.png";
                              e.target.onError = "";
                            }}
                          />
                          <p>
                            Esta acção irá remover o documento do morada, tem
                            que salvar para que a alteração seja efectiva
                          </p>
                          <br />
                          <RelationButton
                            popovertarget={`morada-popover-${i}`}
                            popovertargetaction="hide"
                            color="secondary"
                            // onClick={() => this.handleSaveGrower()}
                            onClick={() => {
                              const newPhotoObj = {
                                ...data.photo,
                                morada: data.photo.morada.filter(
                                  (photo, a) => a !== i
                                ),
                              };
                              this.props.setGrowerDetails({
                                element: "photo",
                                value: newPhotoObj,
                              });
                            }}
                          >
                            Remover Imagem
                            <MenuIcon className={`icon-check icons`} />
                          </RelationButton>
                          <button
                            popovertarget={`morada-popover-${i}`}
                            popovertargetaction="hide"
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                              marginTop: "1em",
                              textAlign: "center",
                            }}
                          >
                            <span class="sr-only">Close</span>
                          </button>
                        </popover>
                      </div>
                    );
                  })}
                  <Files
                    config={this.config}
                    eventHandlers={{
                      init: (dropzone) => {
                        dropzone.autoDiscover = false;
                        // self.preloadImages(dropzone);
                      },
                      removedfile: self.removedfile,
                      error: (e) => {
                        console.log(e);
                      },
                      success: (e) => {
                        self.callbackPhoto(e, "morada");
                      },
                    }}
                    djsConfig={this.djsConfig}
                  />
                </OptionWrap>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                <SectionTitle>
                  <MenuIcon className={`icon-doc icons`} />
                  Cartão de Cidadão
                </SectionTitle>
                <OptionWrap>
                  {data?.photo?.cc?.map((photo, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          position: "relative",
                        }}
                      >
                        <a href={`${apiAddress}${photo.path}`} target="_blank">
                          <img
                            src={`${apiAddress}${photo.path}`}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "5px",
                            }}
                            onError={(e) => {
                              e.target.src = "/images/document.png";
                              e.target.onError = "";
                            }}
                          />
                        </a>
                        <button
                          style={{
                            cursor: "pointer",
                            color: "white",
                            textAlign: "center",
                            padding: "5px",
                            backgroundColor: "red",
                            fontSize: ".8em",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            border: "none",
                          }}
                          popovertarget={`cc-popover-${i}`}
                          // onClick={() => {
                          //   alert(
                          //     "This browser does not support desktop notification"
                          //   );
                          // }}
                        >
                          Remover
                        </button>
                        <popover
                          id={`cc-popover-${i}`}
                          className="popover"
                          popover="manual"
                          style={{
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          <h4>Remover o documento</h4>
                          <img
                            src={`${apiAddress}${photo.path}`}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "120px",
                              borderRadius: "5px",
                            }}
                            onError={(e) => {
                              e.target.src = "/images/document.png";
                              e.target.onError = "";
                            }}
                          />
                          <p>
                            Esta acção irá remover o documento do cc, tem que
                            salvar para que a alteração seja efectiva
                          </p>
                          <br />
                          <RelationButton
                            popovertarget={`cc-popover-${i}`}
                            popovertargetaction="hide"
                            color="secondary"
                            // onClick={() => this.handleSaveGrower()}
                            onClick={() => {
                              const newPhotoObj = {
                                ...data.photo,
                                cc: data.photo.cc.filter((doc, a) => {
                                  return photo.path !== doc.path;
                                }),
                              };
                              this.props.setGrowerDetails({
                                element: "photo",
                                value: newPhotoObj,
                              });
                            }}
                          >
                            Remover Imagem
                            <MenuIcon className={`icon-check icons`} />
                          </RelationButton>
                          <button
                            popovertarget={`cc-popover-${i}`}
                            popovertargetaction="hide"
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                              marginTop: "1em",
                              textAlign: "center",
                            }}
                          >
                            <span class="sr-only">Close</span>
                          </button>
                        </popover>
                      </div>
                    );
                  })}
                  <Files
                    config={this.config}
                    eventHandlers={{
                      init: (dropzone) => {
                        dropzone.autoDiscover = false;
                        // self.preloadImages(dropzone);
                      },
                      removedfile: self.removedfile,
                      error: (e) => {
                        console.log(e);
                      },
                      success: (e) => {
                        self.callbackPhoto(e, "cc");
                      },
                    }}
                    djsConfig={this.djsConfig}
                  />
                </OptionWrap>
              </Col>
            </Row>
            <Row />

            <Row />
          </Col>
        </Row>
      </Section>
    );
  }
}

EditGrower.propTypes = {
  data: PropTypes.object.isRequired,
  growerType: PropTypes.array.isRequired,
  modules: PropTypes.array.isRequired,
  growerState: PropTypes.array.isRequired,
};

EditGrower.defaultProps = {
  data: {},
  growerType: [],
  modules: [],
  growerState: [],
};

export default connect(
  (state) => {
    return {
      teams: state.user.teams,
      addresses: state.user.addresses,
      status: state.user.status,
      viewGrower: state.app.viewGrower,
      growerState: state.user.growerState,
      attributionState: state.user.attributionState,
      growerType: state.user.growerTypes,
      modules: state.user.modules,
      token: state.user.data.token,
      data: state.user.editGrowerData,
    };
  },
  {
    getGrowers,
    setGrower,
    setGrowerDetails,
    resetUser,
    searchGeoLocation,
    toggleUserStatus,
    getGrower,
    searchAddress,
  }
)(EditGrower);
