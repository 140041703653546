import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const StyledButton = styled((props) => <Button {...props} />)`
  && {
    font-size: 0.9em;
    font-weight: 100;
    line-height: 1.5em;
    padding: 0.5em 1em;

    background: #4da1ff;
    border: 1px solid rgba(77, 161, 255, 0.7);
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;

    border-radius: 6px;
    display: inline-block;
    color: #ffffff;
    letter-spacing: 0;
    text-transform: initial;
    ${(props) => {
      if (props.active === "true") {
        return ` 
        background: #fff;
        color: #4da1ff;
        .icons, span {
          color: #4da1ff!important;
        }`;
      }
    }};

    :hover {
      background: #fff;
      color: #4da1ff;
      .icons {
        color: #4da1ff;
      }
    }
    .icons {
      line-height: 2.5em;
      color: #fff;
      font-size: 1.2em;
    }
  }
`;

class MainButton extends React.Component {
  render() {
    return <StyledButton {...this.props}>{this.props.children}</StyledButton>;
  }
}

export default MainButton;
