import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Row, Col } from 'react-flexbox-grid';
import MainButton from '../../../components/Buttons/MainButton';
import OptionTitle from '../../../components/Text/OptionTitle';
import MenuIcon from '../../../components/Buttons/MenuIcon';

import { reset, addNewToggle, setMaintenance } from '../actions';

const OptionIcon = styled.div`
  && {
    background: url(${require('../../../images/IconOptionFundo.png')}) no-repeat
      center center;
    background-size: contain;
    padding: 1em;
    margin: 1em 0 0;
    img {
      height: 100%;
    }
  }
`;
const Button = styled(MainButton)`
  && {
    margin: 0.5em;
  }
`;
const CenterWrap = styled.div`
  && {
    text-align: center;
    width: 100%;
  }
`;

const Check = styled.span`
  && {
    font-size: 6em;
    margin: 1em;
    text-align: center;
    color: #989898;
    vertical-align: middle;
  }
`;

const Text = styled.p`
  && {
    font-size: 0.8em;
    color: #919191;
    line-height: 1.6em;
    padding: 0 0 1.2em;
  }
`;

const BackButton = styled(MainButton)`
  && {
    color: #4da1ff;
    text-transform: capitalize;
    background: transparent;
    border: 1px solid #4da1ff;
    margin: 0.5em;
    .icons {
      color: #4da1ff;
    }
  }
`;

class DetailsStart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.finish = this.finish.bind(this);
  }

  finish() {
    this.props.reset();
    this.props.close();
  }

  setMaintenance() {}

  render() {
    const { savedData } = this.props;
    return (
      <div>
        <Row>
          <Col xs={0} md={3} />

          <Col xs={12} md={6}>
            <Row>
              <CenterWrap>
                <OptionIcon>
                  <Check className={`icon-check icons`} />
                </OptionIcon>
                <OptionTitle>
                  {this.props.inspectionsmenuData.name} foi salva
                </OptionTitle>

                <Text>
                  salvou uma {this.props.inspectionsmenuData.name} do tipo{' '}
                  <strong>{savedData.type}</strong>
                  <br />
                  <br />
                  Agora que salvou a sua {
                    this.props.inspectionsmenuData.name
                  }{' '}
                  escolha uma das seguintes opções
                </Text>
              </CenterWrap>
            </Row>
            <Row>
              <CenterWrap>
                <Button onClick={() => this.props.reset()}>
                  <MenuIcon className={`icon-camera icons`} />
                  Criar uma nova
                </Button>
              </CenterWrap>
            </Row>
            {this.props.role !== 'EV' && (
              <Row>
                <CenterWrap>
                  <Button
                    onClick={() => this.props.setMaintenance(savedData.id)}
                  >
                    <MenuIcon className={`icon-wrench icons`} />
                    Agendar uma {this.props.interventionsMenuData.name}
                  </Button>
                </CenterWrap>
              </Row>
            )}
            <Row>
              <CenterWrap>
                <BackButton onClick={() => this.finish()}>
                  <MenuIcon className={`icon-close icons`} />
                  Terminar
                </BackButton>
              </CenterWrap>
            </Row>
          </Col>
          <Col xs={0} md={3} />
        </Row>
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      savedData: state.inspections.savedData,
      role: state.user.data.role,
      interventionsMenuData: state.app.menus[state.user.data.role].find(
        menu => menu.path === `/interventions`
      ),
      inspectionsmenuData: state.app.menus[state.user.data.role].find(
        menu => menu.path === `/inspections`
      ),
    };
  },
  { reset, addNewToggle, setMaintenance }
)(DetailsStart);
