import React from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { MenuItem, TextField, Paper } from "@material-ui/core";
import styled from "styled-components";
import _ from "lodash";

const FormWrapper = styled.div`
  && {
    margin-bottom: 1em;
    > div > div {
      ul {
        margin: 0;
        padding: 0;
      }
      li {
        list-style-type: "-";
        color: #4da1ff;
        margin: 0 0 0 1em;
        padding: 0;
      }
      .list-wrapper {
        display: block;
        margin: 0;
        span {
        }
        &:before {
          display: none;
        }
      }
      textarea {
      }
      label[data-shrink="true"] {
        color: #4da1ff;
      }
      > div {
        &:after {
          border-bottom: 2px solid #4da1ff !important;
        }
        &:hover {
          &:before {
            border-bottom: 2px solid #4da1ff !important;
          }
        }
      }
    }
  }
`;

function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      inputRef={ref}
      variant="outlined"
      margin="dense"
      InputProps={{
        classes: {
          // input: classes.input,
        },
        ...other,
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem
      className={"list-wrapper"}
      selected={isHighlighted}
      component="div"
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

function getSuggestions(value, options) {
  if (!value) return options.slice(0, 9);
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  const optionsOut =
    inputLength === 0 || (options && options.length === 0)
      ? []
      : options.filter((suggestion) => {
          const keep =
            count < 10 &&
            suggestion.label &&
            suggestion.label
              .toLowerCase()
              .trim()
              .slice(0, inputLength) === inputValue;

          if (keep) {
            count += 1;
            return true;
          }
          return false;
        });
  return optionsOut;
}

class IntegrationAutosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.state = {
      value:
        props.defaultValue && props.defaultValue.label
          ? props.defaultValue.label
          : "",
      suggestions: [],
      changed: false,
      selectedSuggestion: props.defaultValue || {},
    };
  }

  componentDidUpdate(nextProps) {
    // we should update only once, so let make sure there is no value
    if (
      nextProps.defaultValue &&
      nextProps.defaultValue.label &&
      !this.state.changed &&
      (this.state.value === "" || this.state.defaultValue === {})
    ) {
      this.setState({
        value:
          nextProps.defaultValue && nextProps.defaultValue.label
            ? nextProps.defaultValue.label
            : "",
        selectedSuggestion: nextProps.defaultValue || {},
      });
    } else if (
      nextProps.inject &&
      nextProps.inject.length > 0 &&
      _.isEqual(nextProps.inject, this.state.suggestions)
    ) {
      this.setState({
        suggestions: nextProps.inject || [],
      });
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { options } = this.props;

    this.setState({
      suggestions: getSuggestions(value, options || []),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue }) => {
    this.props.updateState({ newValue });
    this.setState({
      value: newValue,
      changed: true,
    });
  };
  onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    this.props.updateState({ suggestion });
    this.setState({
      selectedSuggestion: suggestion,
    });
  }

  render() {
    const { classes, placeholder } = this.props;
    return (
      <FormWrapper>
        <Autosuggest
          // theme={{
          //   container: classes.container,
          //   suggestionsContainerOpen: classes.suggestionsContainerOpen,
          //   suggestionsList: classes.suggestionsList,
          //   suggestion: classes.suggestion,
          // }}
          renderInputComponent={renderInput}
          suggestions={
            this.state.suggestions && this.state.suggestions.length > 0
              ? this.state.suggestions || []
              : this.props.inject || []
          }
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            classes,
            placeholder,
            value: this.state.value,
            onChange: this.handleChange,
            disabled: this.props.disabled,
          }}
        />
      </FormWrapper>
    );
  }
}

IntegrationAutosuggest.propTypes = {};

export default IntegrationAutosuggest;
