// import "babel-polyfill";

import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { theme } from "./theme/colors";

import browserUpdate from "browser-update";
import "simple-line-icons/css/simple-line-icons.css";
import PWAUpdate from "./PWAUpdate";
import RouterContainer from "./routes";
import { configAxios } from "./services/axios";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store } from "./store/configure";

import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import "../node_modules/dropzone/dist/min/dropzone.min.css";
import "../node_modules/leaflet-draw/dist/leaflet.draw.css";
import "../node_modules/react-dropzone-component/styles/filepicker.css";

import "animate.css";
import { GlobalStyles } from "./theme/global";

configAxios();

Sentry.init({
  dsn: "https://43526055a95e4279b865a4edb2dd05d5@sentry.io/1213746",
});

browserUpdate({
  required: {
    e: -2,
    f: -3,
    o: -3,
    s: 10.1,
    c: -2,
    samsung: 7.0,
    vivaldi: 1.2,
  },
  test: false,
  no_permanent_hide: true,
  noclose: true,
  shift_page_down: false,
  insecure: true,
});

if (!("process" in window)) {
  console.log("not in window");
  window.process = {};
}
window.onerror = function(message, source, lineno, colno, error) {
  try {
    throw new Error(message, source, lineno, colno, error);
  } catch (error) {
    console.log(error);
    // var reload = window.confirm(
    //   'Aconteceu um erro, é aconselhável recarregar a aplicação. Carregue no Ok continuar ou cancelar caso queira o pretenda fazer mais tarde'
    // );
    // if (reload == true) window.location = '/logout';
  }
};

const render = (Component) => {
  return ReactDOM.render(
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <div style={{ height: "100%" }}>
          <ReduxToastr
            timeOut={3000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
          />
          <PWAUpdate>
            <Component />
          </PWAUpdate>
        </div>
      </Provider>
    </ThemeProvider>,
    document.getElementById("root")
  );
};

render(RouterContainer);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: (registration) => {
    console.info("service worker on success state");
    console.log(registration);
  },
});
