import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";
import { getElementsPure } from "../App/actions";
import {
  getGrowerByParcelPure,
  getPureGrowers,
  getGrowersByRankPure,
} from "../User/actions";
export function addNewToggle(type, geoJson) {
  return {
    type: constants.ADD_NEW_TOGGLE,
    data: { type, geoJson },
  };
}

export function editToggle(element) {
  return {
    type: constants.EDIT_TOGGLE,
    data: { element },
  };
}

export function viewToggle(element) {
  return {
    type: constants.VIEW_TOGGLE,
    data: { element },
  };
}

export function receivedElements() {
  return {
    type: constants.ELEMENTS_RECEIVED,
  };
}

export function setParkByNumber(pid) {
  return (dispatch) => {
    dispatch({
      type: constants.PARK_GETTING,
    });
    const data = store.getState().georeference;
    const updateData = data.newItem.details;
    axios
      .put(`/api/v1/parks/${pid}`, updateData)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.PARK_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.PARK_DONE,
        });
        toastr.success("Parcela", "Actualizado com sucesso");
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as elementos");
        dispatch({
          type: constants.PARK_ERROR,
        });
      });
  };
}
export function getHistoryByElementId(id) {
  return (dispatch) => {
    dispatch({
      type: constants.HISTORY_GETTING,
    });

    axios
      .get(`/api/v1/elements/history/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.HISTORY_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.HISTORY_DONE,
        });
        toastr.success("Historico", "Actualizado com sucesso");
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as historico");
        dispatch({
          type: constants.HISTORY_ERROR,
        });
      });
  };
}
export function getHistoryByParkId(pid) {
  return (dispatch) => {
    dispatch({
      type: constants.HISTORY_GETTING,
    });

    axios
      .get(`/api/v1/parks/history/${pid}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.HISTORY_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.HISTORY_DONE,
        });
        toastr.success("Historico", "Actualizado com sucesso");
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as historico");
        dispatch({
          type: constants.HISTORY_ERROR,
        });
      });
  };
}

export function getParkById(pid) {
  return (dispatch) => {
    dispatch({
      type: constants.PARK_GETTING,
    });
    let dataType = "hortas";

    axios
      .get(`/api/v1/${dataType}/${pid}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.PARK_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .finally(() => {
        dispatch({
          type: constants.PARK_DONE,
        });
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as elementos");
        dispatch({
          type: constants.PARK_ERROR,
        });
      });
  };
}

export function resetElement() {
  return {
    type: constants.RESET,
  };
}

export function setDetails(details) {
  return {
    type: constants.SET_DETAILS,
    data: details,
  };
}

export function setElementsRelation(origin, park, type, parkType) {
  return (dispatch) => {
    dispatch({
      type: constants.ELEMENT_RELATION_SAVING,
    });
    if (type === "all") {
      toastr.error(`Tem de selecionar um tipo de ranking`);
      return null;
    }
    const selected = store.getState().app.search.selected;

    const setData = {
      origin: origin,
      target: park,
      pid: selected,
      rank: parkType,
    };
    console.log(origin, park, type);

    axios
      .post(
        `/api/v1/relations/${type === "element" ? type : `ranking${type}`}/`,
        setData
      )
      .then(function(response) {
        if (response.data.msg && response.data.status !== "NOK") {
          getElementsRelationPure(dispatch);
          getGrowerByParcelPure(dispatch);
          getParkLeftQuotasPure(dispatch);
          dispatch({
            type: constants.ELEMENT_RELATION_RECEIVED,
            data: response.data.msg,
          });

          toastr.success(`Relação editada`);
        }
      })
      .then(function() {
        getGrowersByRankPure(dispatch, "ativo");
      })
      .catch(function(error) {
        toastr.error(`Ocorreu um erro`);
        console.log(error.response);
      });
  };
}

export function getParkLeftQuotasPure(dispatch) {
  dispatch({
    type: constants.GET_PARK_QUOTAS,
  });

  const search = store.getState().app.search;

  axios
    .get(`/api/v1/relations/quotas/${search.selected}`)
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.RECEIVED_PARK_QUOTAS,
          data: response.data.msg,
        });
      }
    })
    .catch(function(response) {
      console.log(response);
    });
}

export function getParkLeftQuotas() {
  return (dispatch) => {
    getParkLeftQuotasPure(dispatch);
  };
}

export function delElementsRelation(id, type) {
  return (dispatch) => {
    dispatch({
      type: constants.ELEMENT_RELATION_SAVING,
    });

    axios
      .get(`/api/v1/relations/delete/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type:
              type === "element"
                ? constants.ELEMENT_RELATION_RECEIVED
                : constants.GROWER_RELATION_RECEIVED,
            data: response.data.msg,
          });

          getElementsRelationPure(dispatch);
          getGrowerByParcelPure(dispatch);
          getParkLeftQuotasPure(dispatch);

          toastr.success(`Relação editada`);
        }
      })
      .catch(function(response) {
        toastr.error(`Ocorreu a editar relação ${response.msg}`);
        console.log(response);
      });
  };
}

export function getElementsRelation() {
  return (dispatch) => {
    getElementsRelationPure(dispatch);
  };
}

export function getGrowersRelation() {
  return (dispatch) => {
    getGrowersRelationPure(dispatch);
  };
}

export function getParcelsByFreguesia(freguesia) {
  return (dispatch) => {
    dispatch({
      type: constants.GET_PARCELS,
    });

    const search = store.getState().app.search;

    if (!search.freguesia && !freguesia) {
      toastr.error("Tem de escolher uma Freguesia");
      return null;
    }

    axios
      .get(`/api/v1/elements/parcels/${freguesia || search.freguesia}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_PARCELS,
            data: response.data.msg[0] || [],
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };
}

export function getRelationsByElementId(eid, pid) {
  return (dispatch) => {
    dispatch({
      type: constants.ELEMENT_RELATION_GETTING,
    });
    if (!eid) {
      return false;
    }
    const search = store.getState().app.search;

    axios
      .get(`/api/v1/relations/element/${search.selected || pid}/${eid}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ELEMENT_INDIVIDUAL_RELATION_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };
}

export function getElementsRelationPure(dispatch) {
  dispatch({
    type: constants.ELEMENT_RELATION_GETTING,
  });
  const pid = store.getState().app.search.selected;
  if (!pid) {
    return false;
  }
  axios
    .get(`/api/v1/relations/${pid}`)
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.ELEMENT_RELATION_RECEIVED,
          data: response.data.msg,
        });
      }
    })
    .catch(function(response) {
      console.log(response);
    });
}

export function getGrowersRelationPure(dispatch) {
  dispatch({
    type: constants.GROWER_RELATION_GETTING,
  });
  const pid = store.getState().app.search.selected;
  axios
    .get(`/api/v1/relations/growers/${pid}`)
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.GROWER_RELATION_RECEIVED,
          data: response.data.msg,
        });
      }
    })
    .catch(function(response) {
      console.log(response);
    });
}

export function getElementsTypes() {
  return (dispatch) => {
    dispatch({
      type: constants.ELEMENTS_TYPE_GETTING,
    });
    axios
      .get(`/api/v1/elementstypes`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ELEMENTS_TYPE_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };
}

export function getVegetationTypes() {
  return (dispatch) => {
    dispatch({
      type: constants.VEGETATION_TYPE_GETTING,
    });
    axios
      .get(`/api/v1/vegetationtype`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.VEGETATION_TYPE_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };
}

export function updateElement() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    const data = store.getState().georeference;
    const search = store.getState().app.search;
    let elementId = "";

    if (!search.selected) {
      toastr.error("Tem de selecionar uma parcela");

      dispatch({
        type: constants.ERROR,
      });
    }
    let elementData = {
      ...data.newItem,
      pid: search.selected || null,
      local: search.local,
    };

    if (data.element) {
      elementId = data.element.properties.id;
      elementData.geoJson = data.element.geometry;
    }

    axios
      .put(`/api/v1/elements/${elementId}`, elementData)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.SAVED,
            data: response.data.msg,
          });
          toastr.success("Elemento", "Actualizado com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      })
      .finally(() => {
        getElementsPure(dispatch, "selected", search.selected);
      });
  };
}

export function updateGeojsonElement(id, geoJson) {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });
    const search = store.getState().app.search;

    if (!search.selected) {
      toastr.error("Tem de selecionar uma parcela");

      dispatch({
        type: constants.ERROR,
      });
    }

    axios
      .put(`/api/v1/elements/geojson/${id}`, { geometry: geoJson })
      .then(function(response) {
        if (response.data.msg) {
          // dispatch({
          //   type: constants.SAVED,
          //   data: response.data.msg,
          // });
          toastr.success("Elemento", "Actualizado com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      })
      .finally(() => {
        getElementsPure(dispatch, "selected", search.selected);
      });
  };
}

export function delPark(pid) {
  return (dispatch) => {
    axios
      .delete(`/api/v1/parks/${pid}`)
      .then(function(response) {
        if (response.data.msg) {
          // dispatch({
          //   type: constants.DELETED,
          //   data: response.data.msg,
          // });
          console.log(response.data.msg, "we need to update the park list");
          toastr.success("Elemento", "Elemento eleminado");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
      })
      .finally(() => {
        console.log("we need to update the park list");
        // getElementsPure(dispatch, "selected", search.selected);
      });
  };
}

export function delElement() {
  return (dispatch) => {
    const data = store.getState().georeference;
    const search = store.getState().app.search;

    if (!search.selected) {
      toastr.error("Tem de selecionar uma parcela");

      dispatch({
        type: constants.ERROR,
      });
    }
    let elementId = "";

    if (data.element) {
      elementId = data.element.properties.id;
    } else {
      return null;
    }

    axios
      .delete(`/api/v1/elements/${elementId}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.DELETED,
            data: response.data.msg,
          });
          toastr.success("Elemento", "Elemento eleminado");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      })
      .finally(() => {
        getElementsPure(dispatch, "selected", search.selected);
      });
  };
}

export function setElement() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });
    const data = store.getState().georeference;
    const search = store.getState().app.search;

    if (!search.selected) {
      toastr.error("Tem de selecionar uma parcela");

      dispatch({
        type: constants.ERROR,
      });
    }
    axios
      .post(`/api/v1/elements/`, {
        ...data.newItem,
        pid: search.selected || null,
        local: search.local,
      })
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.SAVED,
            data: response.data.msg,
          });
          toastr.success("Elemento", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      })
      .finally(() => {
        getElementsPure(dispatch, "selected", search.selected);
      });
  };
}
