import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  InputLabel,
  Select as SelectComponent,
  FormControl,
} from "@material-ui/core";
import styled from "styled-components";
const FormWrapper = styled.div`
  && {
    margin: 0em 0 0 0;

    label {
    }
    > div {
      display: block;
      background: white;
      textarea {
      }
      label[data-shrink="true"] {
        color: #4da1ff;
      }
      > div {
        &:after {
          border-bottom: 2px solid #4da1ff !important;
        }
        &:hover {
          &:before {
            border-bottom: 2px solid #4da1ff !important;
          }
        }
      }
    }
  }
`;
const LableComp = styled((props) => (
  <InputLabel variant="outlined" margin="dense" {...props} />
))`
  && {
    background: #fff;
    padding-left: 0.5em;
    padding-right: 0.5em;
    &[data-shrink="false"] {
      // margin-top: -8px;
    }
  }
`;

export default class Select extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    const { label, className, name, input, ...custom } = this.props;

    return (
      <FormWrapper>
        <FormControl className={className}>
          <LableComp>{label}</LableComp>

          <SelectComponent
            className={className}
            id={label}
            background="white"
            select
            label={label}
            name={name}
            variant="outlined"
            margin="dense"
            inputProps={{
              name: label,
              id: label,
            }}
            {...input}
            {...custom}
          >
            {this.props.children}
          </SelectComponent>
        </FormControl>
      </FormWrapper>
    );
  }
}
