import { createSelector } from "reselect";

const getTeams = (state) => state.user.teams;
const getUsers = (state) => state.user.list;
const getGrowers = (state) => state.user.growersList;
const editGrowerData = (state) => state.user.editGrowerData;
const getParkGeoJson = (state) => state.parklist.geoJson;
const getElementsGeoJson = (state) => state.app.elementsGeoJson;

export const getUniqueTeams = createSelector(
  [getTeams, getUsers],
  (teams, list) => {
    let uniqueTeams = [];
    // lets filter out duplicates
    teams.map((elem) => {
      if (!uniqueTeams.find((newA) => elem.id === newA.id)) {
        const teamUsers = list.filter((user) => user.TeamId === elem.id);
        uniqueTeams.push({ ...elem, users: teamUsers.length });
      }
      return elem;
    });

    return uniqueTeams;
  }
);

export const getPreferedPark = createSelector(
  [getParkGeoJson, editGrowerData],
  (parks, editGrowerData) => {
    try {
      let preferedPark = editGrowerData.attr.preferedPark;
      if (!(preferedPark instanceof Array)) {
        preferedPark = [preferedPark];
      }
      return parks.filter((park) =>
        preferedPark.includes(park.properties.numero)
      );
    } catch (e) {}
  }
);

export const getGrowersRelations = createSelector(
  [getGrowers, getElementsGeoJson],
  (growers, elements) => {
    growers &&
      growers instanceof Array &&
      growers.map((grower) => {
        const thisElement = elements.find(
          (element) =>
            element.properties.id === Number(grower.targetId) ||
            element.properties.origin === Number(grower.targetId)
        );
        if (thisElement) grower.connection = thisElement;
        return grower;
      });

    return growers || [];
  }
);

export const getGrowerRelations = createSelector(
  [editGrowerData, getElementsGeoJson],
  (growerData, elements) => {
    const thisElement = elements.find(
      (element) =>
        element.properties.id === Number(growerData.targetId) ||
        element.properties.origin === Number(growerData.targetId)
    );
    if (thisElement) growerData.connection = thisElement;
    return growerData || [];
  }
);
