export const GETTING = "REPORTS/GETTING_REQUEST";
export const RECEIVED = "REPORTS/RECEIVED_SUCCESS";
export const SAVING = "REPORTS/SAVING_REQUEST";
export const SAVED = "REPORTS/SAVED_SUCCESS";
export const ERROR = "REPORTS/ERROR_FAILURE";
export const RESET = "REPORTS/RESET";
export const SET_TYPE = "REPORTS/SET_TYPE";
export const SET_VIEW_TYPE = "REPORTS/SET_VIEW_TYPE";

export const GET_MONTH_REPORT = "REPORTS/GET_MONTH_REPORT_REQUEST";
export const RECEIVED_MONTH_REPORT = "REPORTS/RECEIVED_MONTH_REPORT_SUCCESS";
export const ERROR_MONTH_REPORT = "REPORTS/ERROR_MONTH_REPORT_FAILURE";

export const SAVING_REPORT = "REPORTS/REPORTS_SAVING_REQUEST";
export const SAVED_REPORT = "REPORTS/REPORTS_SAVED_SUCCESS";
export const ERROR_REPORT = "REPORTS/REPORTS_ERROR_FAILURE";
export const RECEIVED_FREGUESIAS_REPORT =
  "REPORTS/RECEIVED_FREGUESIAS_REPORT_SUCCESS";

export const GETTING_PARK_REPORT =
  "REPORTS/MAINTENANCE_GETTING_PARKREPORT_REQUEST";
export const RECEIVED_PARK_REPORT =
  "REPORTS/MAINTENANCE_RECEIVED_PARKREPORT_SUCCESS";
export const ERROR_PARK_REPORT = "REPORTS/MAINTENANCE_ERROR_PARKREPORT_FAILURE";
