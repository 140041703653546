import {
  ADD_NEW_TOGGLE,
  SET_PHOTOS,
  SET_DETAILS,
  SET_ACTIVE_STEP,
  ERROR,
  SAVING,
  SAVED,
  DELETED,
  RESET,
  GETTING,
  RECEIVED,
  HISTORY_RECEIVED,
  UPDATED,
  HISTORY_GETTING,
  UPDATED_HISTORY,
  INTERVENTIONS_GETTING,
  INTERVENTIONS_RECEIVED,
  ELEMENTS_ERROR,
  APPROVED_MAINTENANCE_GROUP,
} from "./constants";

import { getCurrentDate } from "../../services/utils";

const status = {
  edit: "edit",
  error: "error",
  saving: "saving",
  edited: "edited",
  getting: "getting",
  deleted: "deleted",
};

let initialState = {
  addNew: false,
  details: {},
  status: "edit",
  savedData: {},
  reportHistory: [],
  interventions: [],
  list: [],
  elementsGeoJson: [],
  pid: "",
  search: {
    active: "preventiva",
    edit: null,
    local: "",
    freguesia: "",
    selected: "",
    element: {},
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
    type: "all",
    status: 1,
  },
};

const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.maintenances) {
  initialState = JSON.parse(localStorage.maintenances);
  initialState.search = {
    ...initialState.search,
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
  };
}

function presistData(type, data) {
  try {
    // if (isLocalStorage)
    //   localStorage[type] = JSON.stringify({ ...data, status: '' });
  } catch (error) {
    console.log(error);
  }
  return data;
}
export default (state = initialState, action) => {
  switch (action.type) {
    case APPROVED_MAINTENANCE_GROUP:
      return { ...state, status: "saved" };
    case ADD_NEW_TOGGLE:
      newState = {
        ...state,
        pid: action.data.id,
        addNew: action.data.state || !state.addNew,
      };
      return presistData("maintenances", newState);
    case SET_ACTIVE_STEP:
      newState = {
        ...state,
        activeStep: action.data,
      };
      return presistData("maintenances", newState);
    case SET_PHOTOS:
      newState = {
        ...state,
        photos: action.data,
      };
      return presistData("maintenances", newState);
    case SET_DETAILS:
      newState = {
        ...state,
        details: action.data,
      };
      return presistData("maintenances", newState);

    case ERROR:
      return { ...state, status: status.error };
    case SAVING:
      return { ...state, status: status.saving };
    case UPDATED:
      return { ...state, status: status.edited };
    case UPDATED_HISTORY:
      newState = {
        ...state,
        activeStep: 0,
        photos: [],
        type: "",
        details: {},
        status: "edit",
        savedData: {},
      };
      return presistData("maintenances", newState);
    case SAVED:
      newState = {
        ...state,
        status: "saved",
        savedData: action.data,
      };
      if (action.data && action.data.type === "comentario")
        newState.reportHistory = [...state.reportHistory, action.data];
      return presistData("maintenances", newState);
    case DELETED:
      newState = {
        ...state,
        status: "deleted",
      };
      return presistData("maintenances", newState);
    case GETTING:
      return { ...state, status: status.getting };
    case RECEIVED:
      newState = {
        ...state,
        status: "set",
        list: action.data,
      };
      return presistData("maintenances", newState);
    case HISTORY_GETTING:
      return {
        ...state,
        reportHistory: [],
      };
    case HISTORY_RECEIVED:
      newState = {
        ...state,
        pid: action.data[0].pid,
        reportHistory: action.data,
      };
      return presistData("maintenances", newState);
    case RESET:
      newState = {
        ...state,
        activeStep: 0,
        photos: [],
        type: "",
        details: {},
        status: "edit",
        savedData: {},
      };
      return presistData("maintenances", newState);
    case INTERVENTIONS_GETTING:
      newState = {
        ...state,
        interventions: [],
      };
      return presistData("maintenances", newState);
    case INTERVENTIONS_RECEIVED:
      newState = {
        ...state,
        interventions: action.data,
      };
      return presistData("maintenances", newState);

    case ELEMENTS_ERROR:
      return { ...state, status: status.error };

    default:
      return state;
  }
};
