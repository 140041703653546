import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import OptionTitle from "../../../components/Text/OptionTitle";

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 6%;
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
  }
`;
const MenuIcon = styled.i`
  && {
    width: 100%;
    display: block;
    font-size: 4em;
    text-align: center;
    padding: 0.4em 0;
    /* color: #4192ec; */
  }
`;
const Button = styled(MainButton)`
  && {
    padding: 0.5em 2em;
    ${(props) =>
      props.active &&
      `
        background: #7ED321;
        border:1px solid #7ED321;
      
      `};
  }
`;
const Text = styled.p`
  && {
    font-size: 0.8em;
    color: #919191;
    line-height: 1.6em;
    padding: 0 0 1.2em;
  }
`;

const OptionIcon = styled.div`
  && {
    background: url(${require("../../../images/IconOptionFundo.png")}) no-repeat
      center center;
    background-size: contain;
    padding: 1em;
    height: 80px;
    margin: 1em 0 0;
    img {
      height: 100%;
    }
  }
`;

class Type extends React.PureComponent {
  setType(type) {
    this.props.setType(type);
    this.props.handleNext();
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12} md className={"m-b-15"}>
            <OptionWrap>
              <OptionIcon>
                <MenuIcon className={`icon-screen-smartphone icons`} />
              </OptionIcon>
              <OptionTitle>SMS</OptionTitle>

              <Button onClick={() => this.props.changeType("3")}>
                Escolher
              </Button>
            </OptionWrap>
          </Col>
          <Col xs={12} md className={"m-b-15"}>
            <OptionWrap>
              <OptionIcon>
                <MenuIcon className={`icon-phone icons`} />
              </OptionIcon>
              <OptionTitle>Telefone</OptionTitle>

              <Button onClick={() => this.props.changeType("0")}>
                Escolher
              </Button>
            </OptionWrap>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} md className={"m-b-15"}>
            <OptionWrap>
              <OptionIcon>
                <MenuIcon className={`icon-envelope icons`} />
              </OptionIcon>
              <OptionTitle>Email</OptionTitle>

              <Button onClick={() => this.props.changeType("1")}>
                Escolher
              </Button>
            </OptionWrap>
          </Col>
          <Col xs={12} md className={"m-b-15"}>
            <OptionWrap>
              <OptionIcon>
                <MenuIcon className={`icon-envelope-letter icons`} />
              </OptionIcon>
              <OptionTitle>Carta</OptionTitle>

              <Button onClick={() => this.props.changeType("2")}>
                Escolher
              </Button>
            </OptionWrap>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    type: state.inspections.type,
  };
}, {})(Type);
