import React from 'react';
import Table from './Table';

export default function(props) {
  const processedData = data => {
    return data.map(row => {
      return { ...row, equipa: row.Team ? row.Team.label : '' };
    });
  };
  const columnData = [
    {
      id: 'id',
      name: 'id',
      numeric: false,
      disablePadding: true,
      label: 'id',
    },
    {
      id: 'label',
      name: 'label',
      numeric: false,
      disablePadding: false,
      label: 'Nome',
    },
    {
      id: 'type',
      name: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Tipo',
    },
    {
      id: 'users',
      name: 'users',
      numeric: false,
      disablePadding: false,
      label: 'Utilizadores',
    },

    {
      id: 'status',
      name: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'createdAt',
      name: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Data',
    },
  ];

  return (
    <Table
      orderBy={'id'}
      data={processedData(props.data)}
      click={props.click}
      header={columnData}
    />
  );
}
