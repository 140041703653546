import React from "react";
import styled from "styled-components";

const Icon = styled.span`
  && {
    text-align: center;
    color: #989898;
    vertical-align: middle;
    margin: 0 0.5em;
    &.icons {
      display: inline-block;
      /* color: #4da1ff; */
      font-style: normal;
      font-size: 1em;
    }
  }
`;

class MenuIcon extends React.Component {
  render() {
    return <Icon {...this.props}>{this.props.children}</Icon>;
  }
}

export default MenuIcon;
