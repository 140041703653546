import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";

import {
  toggleAddInspection,
  toggleAddInterventions,
} from "../../containers/App/actions";

const ActionsContent = styled.div`
  && {
    position: fixed;
    right: 1em;
    bottom: 4.5em;
    z-index: 9;

    @media (max-width: 900px) {
      bottom: 9.5em;
      right: 0.5em;
      z-index: 1;
    }
    @media (max-width: 455px) {
      bottom: 33vh;
    }
  }
`;
const ActionWrapper = styled.div`
  && {
    margin: 0.3em 0.2em;
    background: #4da1ff;
    padding: 0.7em;
    display: block;
    border-radius: 5px;
    max-width: 100px;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    color: #fff;
    cursor: pointer;
    > div {
      font-size: 0.8em;
      ::before {
        border-bottom: none;
      }
    }
    &:hover {
      color: #4da1ff;
      background: #fff;
    }
  }
`;

class AddActionButtons extends React.Component {
  render() {
    const { userWrite, userPermissions } = this.props;
    return (
      <ActionsContent>
        {(userPermissions?.write?.includes("all") ||
          userPermissions?.write?.includes("interventions") ||
          userWrite === "all" ||
          userWrite === "interventions") &&
          this.props.selected !== null && (
            <Tooltip title={`Adicionar ${this.props.menuInterventions.name}`}>
              <ActionWrapper
                onClick={() => this.props.toggleAddInterventions()}
                className={`icon-${this.props.menuInterventions.icon} icons`}
              />
            </Tooltip>
          )}

        {(userPermissions?.write?.includes("all") ||
          userPermissions?.write?.includes("inspections") ||
          userWrite === "all" ||
          userWrite === "inspections") && (
          <Tooltip title={`Adicionar ${this.props.menuInspections.name}`}>
            <ActionWrapper
              onClick={() => this.props.toggleAddInspection()}
              className={`icon-${this.props.menuInspections.icon} icons`}
            />
          </Tooltip>
        )}
      </ActionsContent>
    );
  }
}

export default connect(
  (state) => {
    return {
      role: state.user.data.role,

      userWrite: state.user.data.write,
      userPermissions: state.user.data.permissions,
      userType: state.user.data.type,
      local: state.app.search.local,
      selected: state.app.search.selected,

      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  { toggleAddInspection, toggleAddInterventions }
)(AddActionButtons);
