import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingBar from "react-redux-loading-bar";
import { hideLoader } from "./actions";

class Loader extends Component {
  componentDidUpdate() {
    if (this.props.loadingIndicator === 1) {
      this.props.hideLoader();
    }
  }
  render() {
    return (
      <LoadingBar
        style={{ backgroundColor: "#4da1ff", zIndex: 999 }}
        showFastActions
      />
    );
  }
}
export default connect(
  (state) => {
    return {
      loadingIndicator: state.loadingBar.default,
    };
  },
  {
    hideLoader,
  }
)(Loader);
