import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import Lightbox from "react-image-lightbox";
import { apiAddress } from "../../config/general";
import moment from "moment";
import Rating from "react-rating";
import { FormControlLabel } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import GrowerCard from "../../components/Card/GrowerCard";
import SchoolResponsibles from "../Schools/SchoolResponsibles";

import LineButton from "../../components/Buttons/LineButton";
import { Card } from "../../components/Card/styled";

import SectionTitle from "../../components/Text/SectionTitle";
import MenuIcon from "../../components/Buttons/MenuIcon";
import AutoComplete from "../../components/form-wrappers/AutoComplete";
import { getCurrentGrower } from "../Georeference/Selector";
import { getRelationsByElementId } from "../Georeference/actions";
import {
  updateInspection,
  getInspections,
  getDamages,
  setMaintenance,
  deleteInspection,
} from "./actions";

import {
  OptionWrap,
  ActionWrap,
  Toggle,
  RatingWrap,
  Files,
  Input,
  ImageContainer,
  ElementType,
  Image,
  Calendar,
  SaveButton,
  InspectionWrap,
  HalfRate,
  DisableButton,
  ImageWrapper,
  RemoveItem,
} from "../../components/InspMain/styled";

import PageTitle from "../../components/Text/PageTitle";

class Edit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      edit: false,
      selectedHorta: "",
      damages: [],
      photoIndex: 0,
      isOpen: false,
      hiddenPhotos: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChangeAttribute = this.handleChangeAttribute.bind(this);
    this.handleChangeDamages = this.handleChangeDamages.bind(this);
    this.handleAttrChange = this.handleAttrChange.bind(this);
    this.serverURL = apiAddress;
    this.handleAttrRating = this.handleAttrRating.bind(this);
    this.handleAttrCustomFieldsRating = this.handleAttrCustomFieldsRating.bind(
      this
    );
    // const t = this;
    // setTimeout(() => {
    //   t.setState({ hiddenPhotos: false });
    // }, 0);
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedParkId, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };
    props.getRelationsByElementId(
      props.data &&
        props.data.Element &&
        (props.data.Element.origin || props.data.Element.id),
      props.data && props.data.Element && props.data.Element.pid
    );
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.status === "edited") {
      this.props.getInspections();
    }
    if (this.props.status === "saved" || this.props.status === "deleted") {
      this.props.closeModal && this.props.closeModal();
    }
  }

  getDate(date) {
    const now = new Date(date);
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  handleChangeAttribute(e, value) {
    this.setState({
      data: {
        ...this.state.data,
        attr: {
          ...this.state.data.attr,
          [e.target.name]: !this.state.data.attr[e.target.name],
        },
      },
    });
  }

  handleAttrCustomFieldsRating(value, index) {
    this.setState({
      data: {
        ...this.state.data,
        attr: {
          ...this.state.data.attr,
          customFields: {
            ...this.state.data.attr.customFields,
            [index]: value,
          },
        },
      },
    });
  }

  toggleEdit() {
    if (!this.state.edit) this.props.getDamages();
    this.setState({ edit: !this.state.edit });
  }

  removedfile(e) {
    let foundId = null;
    this.state.data.photos.map((files, index) => {
      if (files.name === e.upload.filename) {
        foundId = index;
      }
      return files;
    });
    if (foundId) this.deleteImage(foundId);
  }

  deleteImage(photoIndex) {
    this.setState({
      data: {
        ...this.state.data,
        photos: this.state.data.photos.filter((photo, i) => i !== photoIndex),
      },
    });
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const photos = [
      ...this.state.data.photos,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({
      data: {
        ...this.state.data,
        photos,
      },
    });
  }

  delete() {
    const toastrConfirmOptions = {
      onOk: () => this.props.deleteInspection({ ...this.state.data }),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  handleChange(e, value) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }
  handleAttrChange(e, value) {
    let valueFinal = e.target.value;
    // for toggle
    if (e.target.name === "include") {
      valueFinal = !this.state.data.attr[e.target.name];
    }

    this.setState({
      data: {
        ...this.state.data,
        attr: { ...this.state.data.attr, [e.target.name]: valueFinal },
      },
    });
  }
  handleAttrRating(value) {
    this.setState({
      data: {
        ...this.state.data,
        attr: { ...this.state.data.attr, rating: value },
      },
    });
  }
  handleChangeDamages(value) {
    this.setState({ damages: value });
  }

  handleSaveInspection() {
    this.toggleEdit();
    this.props.updateInspection({
      ...this.state.data,
      damages: this.state.damages,
    });
  }
  closeModal() {
    this.props.history.push(`/inspections`);
  }

  renderGrowerCard() {
    const { growerRelations, individualRelations } = this.props;
    let growers = [];
    if (individualRelations && individualRelations.grower) {
      growers = individualRelations.grower;
    } else if (growerRelations && growerRelations.length > 0) {
      growers = growerRelations;
    }

    return (
      growers &&
      growers.map((grower, i) => {
        return (
          <GrowerCard
            fullDisplay={true}
            readOnly={true}
            grower={grower}
            key={i}
          />
        );
      })
    );
  }
  renderRelationCard() {
    const { individualRelations } = this.props;
    return (
      individualRelations &&
      individualRelations.elements &&
      individualRelations.elements.map((tar, i) => {
        return (
          <Card key={i}>
            <span className={"element"}>{tar.label}</span>
          </Card>
        );
      })
    );
  }
  renderPhotos(photos) {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const self = this;

    /// not bound to this is moved out of render
    const eventHandlers = {
      init: (dropzone) => {
        dropzone.autoDiscover = false;
      },
      removedfile: self.removedfile,
      error: (e) => {
        console.log(e);
      },
      success: self.callback,
    };

    return (
      <div>
        {photos && photos.length > 0 && (
          <div>
            <SectionTitle>Fotos</SectionTitle>
            <ImageContainer>
              {photos &&
                photos.map((photo, index) => {
                  return (
                    <ImageWrapper key={index}>
                      <Image
                        onClick={() =>
                          this.setState({
                            isOpen: true,
                            photoIndex: index,
                          })
                        }
                        style={{
                          backgroundImage: `url('${this.serverURL}${photo.path}')`,
                        }}
                      />

                      {this.state.edit && (
                        <RemoveItem
                          onClick={() => this.deleteImage(index)}
                          className={`icon-close icons`}
                        />
                      )}
                    </ImageWrapper>
                  );
                })}
            </ImageContainer>
          </div>
        )}

        {this.state.edit && (
          <Files
            config={config}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          />
        )}
      </div>
    );
  }

  render() {
    const { photoIndex, isOpen, data } = this.state;
    const images = data.photos;
    let color;
    switch (data.type) {
      case "Danos":
        color = "#eb3d3d";
        break;
      case "Inicial":
        color = "#ebcd3d";
        break;
      default:
        color = "#6fc44c";
        break;
    }
    const customFields = data.attr ? data.attr.customFields : {};
    return (
      <InspectionWrap name={data.id}>
        {data && (
          <div>
            <Row>
              <Col xs={12} md={9}>
                <SectionTitle color={color}>
                  <i className={`icon-camera icons`} />{" "}
                  {data.Damage ? data.Damage.label : data.type}{" "}
                  {data.Element && data.Element.ElementType && (
                    <ElementType>
                      {data.Element.ElementType.category
                        ? `${data.Element.ElementType.category} ,`
                        : ""}

                      {data.Element.ElementType.label
                        ? `${data.Element.ElementType.label} ,`
                        : ""}
                    </ElementType>
                  )}
                  {data.User && (
                    <span>
                      {data.User.name},{" "}
                      {moment(data.date).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                  )}
                </SectionTitle>
                {customFields && Object.keys(customFields).length > 0 && (
                  <div>
                    <OptionWrap>
                      {Object.keys(customFields).map((cf, index) => (
                        <HalfRate key={index}>
                          <RatingWrap>
                            <label>{cf}</label>
                            <Rating
                              onChange={(rate) =>
                                this.handleAttrCustomFieldsRating(rate, cf)
                              }
                              readonly={!this.state.edit}
                              initialRating={customFields[cf] || 0}
                              stop={3}
                              fractions={2}
                            />
                            <label className={"comment"}>
                              {customFields[cf].comment}
                            </label>
                          </RatingWrap>
                        </HalfRate>
                      ))}
                    </OptionWrap>
                  </div>
                )}
                <OptionWrap>
                  {data.attr && !data.attr.rating && (
                    <AutoComplete
                      updateState={this.handleChangeDamages}
                      options={this.props.damagesList}
                      defaultValue={data.Damage}
                      placeholder={
                        "Pesquise ou adicione um novo tipo de manutenção"
                      }
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.attr && data.attr.reading && (
                    <Input
                      label="Total"
                      onChange={(e) => this.handleAttrChange(e)}
                      name="reading"
                      input={{}}
                      defaultValue={data.attr.reading}
                      meta={{ invalid: false }}
                      disabled={!this.state.edit}
                    />
                  )}

                  {(data?.attr?.rating || this.state.edit) && (
                    <RatingWrap>
                      <label>Avaliação Geral</label>
                      <Rating
                        onChange={(rate) => this.handleAttrRating(rate / 2)}
                        initialRating={data?.attr?.rating * 2 || 0}
                        stop={6}
                        fractions={1}
                        readonly={!this.state.edit}
                      />
                    </RatingWrap>
                  )}
                  <br />
                  <Calendar
                    id="date"
                    name="date"
                    input={{}}
                    label="Data"
                    meta={{ invalid: false }}
                    defaultValue={this.getDate(data.date)}
                    type="datetime-local"
                    onChange={(e) => this.handleChange(e)}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // defaultValue={this.state.date}
                    disabled={!this.state.edit}
                  />
                  <Input
                    multiline
                    label="Descrição"
                    onChange={(e) => this.handleChange(e)}
                    name="details"
                    variant="outlined"
                    margin="dense"
                    input={{}}
                    defaultValue={data.details}
                    type="multiline"
                    meta={{ invalid: false }}
                    rows="8"
                    disabled={!this.state.edit}
                  />
                </OptionWrap>
                <ActionWrap>
                  {this.props.status !== "saving" && (
                    <div>
                      {this.state.edit ? (
                        <div>
                          <SaveButton
                            color="primary"
                            onClick={() => this.handleSaveInspection()}
                          >
                            Salvar
                            <MenuIcon className={`icon-check icons`} />
                          </SaveButton>
                          {this.props.isAdmin && data.type !== "comentario" && (
                            <FormControlLabel
                              control={
                                <Toggle
                                  onChange={this.handleAttrChange}
                                  value="true"
                                  checked={
                                    data.attr && data.attr.include
                                      ? true
                                      : false
                                  }
                                  label="Report"
                                  disabled={!this.state.edit}
                                  name="include"
                                  color="primary"
                                  meta={{ invalid: false }}
                                />
                              }
                              label="Report"
                            />
                          )}
                        </div>
                      ) : (
                        <div>
                          {this.props.isAdmin &&
                            (this.props.userWrite === "all" ||
                              this.props.userWrite === "inspections" ||
                              this.props.userPermissions?.write?.includes(
                                "all"
                              ) ||
                              this.props.userPermissions?.write?.includes(
                                "inspections"
                              )) && (
                              <LineButton
                                color="primary"
                                onClick={() => this.toggleEdit()}
                              >
                                Editar
                                <MenuIcon className={`icon-check icons`} />
                              </LineButton>
                            )}

                          {(this.props.userWrite === "all" ||
                            this.props.userWrite === "inspections" ||
                            this.props.userPermissions?.write?.includes(
                              "all"
                            ) ||
                            this.props.userPermissions?.write?.includes(
                              "inspections"
                            )) &&
                            this.props.role !== "EV" && (
                              <SaveButton
                                color="primary"
                                onClick={() =>
                                  this.props.setMaintenance(data.id)
                                }
                              >
                                Criar Manutenção
                                <MenuIcon className={`icon-check icons`} />
                              </SaveButton>
                            )}
                          {(this.props.userWrite === "all" ||
                            this.props.userWrite === "inspections" ||
                            this.props.userPermissions?.write?.includes(
                              "all"
                            ) ||
                            this.props.userPermissions?.write?.includes(
                              "inspections"
                            )) &&
                            this.props.isAdmin && (
                              <DisableButton onClick={() => this.delete()}>
                                <MenuIcon className={`icon-close icons`} />{" "}
                                Apagar
                              </DisableButton>
                            )}
                          {this.props.isAdmin && data.type !== "comentario" && (
                            <FormControlLabel
                              control={
                                <Toggle
                                  onChange={this.handleAttrChange}
                                  value="true"
                                  checked={
                                    data.attr && data.attr.include
                                      ? true
                                      : false
                                  }
                                  label="Report"
                                  disabled={!this.state.edit}
                                  name="include"
                                  color="primary"
                                  meta={{ invalid: false }}
                                />
                              }
                              label="Report"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </ActionWrap>

                {!this.state.hiddenPhotos && this.renderPhotos(data.photos)}
              </Col>
              {this.props.index === 0 && (
                <Col xs={12} md={3}>
                  <SectionTitle>Contactos</SectionTitle>
                  {this.props.isSchool && (
                    <SchoolResponsibles
                      schoolName={this.props.isSchool}
                      noTitle={true}
                    />
                  )}
                  {this.renderGrowerCard()}
                  {this.renderRelationCard()}
                </Col>
              )}
            </Row>
          </div>
        )}
        {!data && (
          <div>
            <PageTitle>Upss...</PageTitle>
            <Row>
              <Col xs={12} md={9}>
                <SectionTitle>Detalhes</SectionTitle>
                <OptionWrap>
                  Não conseguimos encontrar os detalhes para esta inspeção.
                </OptionWrap>
              </Col>
            </Row>
          </div>
        )}
        {isOpen && (
          <Lightbox
            mainSrc={`${this.serverURL}${images[photoIndex].path}`}
            nextSrc={`${this.serverURL}${
              images[(photoIndex + 1) % images.length].path
            }`}
            prevSrc={`${this.serverURL}${
              images[(photoIndex + images.length - 1) % images.length].path
            }`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </InspectionWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.inspections.type,
      growerRelations: getCurrentGrower(state),
      individualRelations: state.georeference.individualRelations,
      activeStep: state.inspections.activeStep,
      status: state.inspections.status,
      damagesList: state.inspections.damages,
      isAdmin: state.user.data.admin,
      role: state.user.data.role,
      userWrite: state.user.data.write,
      userPermissions: state.user.data.permissions,
      token: state.user.data.token,
      selected: state.app.search.selected,
      list: state.parklist.list,
      selectedParcelHorta: state.georeference.selectedParcelHorta,
      elementsGeoJson: state.app.elementsGeoJson,
    };
  },
  {
    updateInspection,
    getInspections,
    getDamages,
    setMaintenance,
    getRelationsByElementId,
    deleteInspection,
  }
)(Edit);
