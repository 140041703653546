import React, { Component } from "react";
import Leaflet from "leaflet";
import "leaflet-extra-markers";
import PropTypes from "prop-types";
import { EditControl } from "react-leaflet-draw";

import { Map, Marker, TileLayer, FeatureGroup } from "react-leaflet";

import "../../../node_modules/leaflet-geosearch/assets/css/leaflet.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import "../../../node_modules/leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import marker from "../../images/marker-icon.png";
import markerShaddow from "../../images/marker-shadow.png";

import { MapContainer, MapSingleContainer } from "./styled";

delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: marker,
  iconUrl: marker,
  shadowUrl: markerShaddow,
});

export default class SingleMap extends Component {
  constructor(props) {
    super(props);
    this.userMarkerParams = {
      radius: 15,
      fillColor: "#5fba7d",
      color: "#5fba7d",
      weight: 3,
      opacity: 1,
      fillOpacity: 0.8,
      riseOnHover: true,
      autoPan: true,
      keepInView: true,
    };
  }

  render() {
    let mapProps = {
      zoom: this.props.zoom,
      center: this.props.center,
    };
    if (!mapProps.center[0] || !mapProps.center[1]) {
      mapProps.center = [38.719637, -9.395666];
    }
    return (
      <MapSingleContainer>
        <MapContainer>
          <Map className={"map-container"} maxZoom={23} {...mapProps}>
            <TileLayer
              maxZoom={22}
              maxNativeZoom={18}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="Cascais Ambiente &copy;"
            />
            {/* <SearchLayer updateLocation={this.props.updateLatLon} /> */}
            {/** We can edit the marker */}
            {this.props.updateLatLon && (
              <FeatureGroup>
                <EditControl
                  ref="edit"
                  key={`${new Date()}-elements`}
                  position="topleft"
                  onEdited={(e) => {
                    const layer = e.layers._layers;
                    Object.keys(layer).map((layerI) => {
                      const center = layer[layerI]._latlng;
                      //we need to update element view
                      this.props.updateLatLon(center.lat, center.lng);
                      return layerI;
                    });
                  }}
                  onCreated={(e) => {
                    // const geoJson = e.layer.toGeoJSON();
                    // const center = geoJson.geometry.coordinates;
                    // console.log(center);
                    // this.props.updateLatLon(center[0], center[1]);
                    // Now we need to remove it
                    // it will be added from the db later
                    // const { edit } = this.refs;
                    // edit.context.layerContainer.removeLayer(e.layer._leaflet_id);
                  }}
                  edit={{
                    marker: true,
                    circle: false,
                    circlemarker: false,
                    rectangle: false,
                    polygon: false,
                    polyline: false,
                  }}
                  draw={{
                    marker: false,
                    circle: false,
                    circlemarker: false,
                    rectangle: false,
                    polygon: false,
                    polyline: false,
                  }}
                />
                <Marker position={mapProps.center} />
              </FeatureGroup>
            )}
            {/** We are just showing the marker */}
            {!this.props.updateLatLon && <Marker position={mapProps.center} />}
          </Map>
        </MapContainer>
      </MapSingleContainer>
    );
  }
}

SingleMap.propTypes = {
  zoom: PropTypes.number.isRequired,
  center: PropTypes.array.isRequired,
  updateLatLon: PropTypes.func,
};

SingleMap.defaultProps = {
  zoom: 14,
  center: [38.719637, -9.395666],
};
