import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import {
  Button,
  MenuItem,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";

// import { Manager, Reference, Popper } from "react-popper";
import { toggleElements, setActivePark } from "../App/actions";

import { userLocation } from "./actions";

const ManagerRight = styled.div`
  && {
    /* text-align: right;
  display: block;
  width: 100%; */
    min-width: auto;
    text-align: right;
    display: block;
    position: fixed;
    z-index: 10;
    right: 0;
    top: 0;
    @media print {
      display: none;
    }
  }
`;

const MenuDrawer = styled.div`
  && {
    background: #fff;
    @media (max-width: 455px) {
      background: transparent;
      button span .icon-menu {
        color: #fff;
        font-size: 1.2em;
      }
    }
  }
`;

const SearchTotal = styled.div`
  && {
    background: rgba(77, 161, 255, 0.8);
    color: #fff;
    display: inline-block;
    padding: 0.5em;
    font-size: 0.8em;
    opacity: 1;
    div {
      color: #fff;
    }
    @media (max-width: 455px) {
      display: none;
    }
  }
`;

const SpanBlock = styled.div`
  && {
    display: inline-block;
    margin: 0 0.5em;
    font-size: 0.9em;
    color: #989898;
    @media (max-width: 455px) {
      min-width: auto;
      &.name {
        display: none;
      }
    }
  }
`;

const StyledMenuItem = styled((props) => <MenuItem {...props} />)`
  && {
    :hover {
      background: rgba(65, 146, 236, 0.1);
      color: #4192ec;
    }
  }
`;

const PopperStyled = styled.div`
  && {
    z-index: 10;
  }
`;

class ProfileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.props.userLocation();
  }

  componentWillUnmount() {
    // clearTimeout(this.timeout);
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    if (!this.state.open) {
      return;
    }

    // setTimeout to ensure a close event comes after a target click event
    // this.timeout = setTimeout(() => {
    setTimeout(this.setState({ open: false }), 10);
    // });
  };

  render() {
    const { user } = this.props;
    const { open } = this.state;
    return (
      <ManagerRight>
        <MenuDrawer>
          <Button
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <SpanBlock className={`icon-user icons name`} />
            <SpanBlock className={`name`}>
              {user.name.includes(" ") ? user.name.split(" ")[0] : user.name}
            </SpanBlock>
            <SpanBlock className={`icon-menu icons`} />
          </Button>
        </MenuDrawer>
        {(this.props.selected || this.props.element) && (
          <SearchTotal>
            {this.props.selected ? "1" : "0"}{" "}
            <SpanBlock className={`icon-location-pin icons`} />{" "}
            {this.props.element ? "1" : "0"}{" "}
            <SpanBlock className={`icon-pin icons`} />
          </SearchTotal>
        )}
        {open && (
          <PopperStyled placement="bottom-end" eventsEnabled={open}>
            <ClickAwayListener onClickAway={this.handleClose}>
              <Grow
                in={open}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper>
                  <MenuList role="menu">
                    {/* <StyledMenuItem onClick={this.handleClose}>
                      Profile
                    </StyledMenuItem>
                    <StyledMenuItem onClick={this.handleClose}>
                      My account
                    </StyledMenuItem> */}
                    <StyledMenuItem onClick={() => this.props.toggleElements()}>
                      <SpanBlock className={`icon-location-pin icons`} />{" "}
                      {this.props.elmentDisplay ? `Desactivar` : `Activar`}{" "}
                      elementos
                    </StyledMenuItem>

                    {/* <StyledMenuItem onClick={this.handleClose}>
                      Profile
                    </StyledMenuItem>
                    <StyledMenuItem onClick={this.handleClose}>
                      My account
                    </StyledMenuItem> */}
                    <StyledMenuItem
                      component={Link}
                      to={"/logout"}
                      onClick={this.handleClose}
                    >
                      <SpanBlock className={`icon-logout icons`} /> Logout
                    </StyledMenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          </PopperStyled>
        )}
      </ManagerRight>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user.data,
      elmentDisplay: state.app.elmentDisplay,
      selected: state.app.search.selected,
      element: state.app.search.element,
      userType: state.user.data.type,
    };
  },
  {
    toggleElements,
    setActivePark,
    userLocation,
  }
)(ProfileMenu);
