import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import Lightbox from "react-image-lightbox";
import { toastr } from "react-redux-toastr";
import GrowerCard from "../../../components/Card/GrowerCard";

import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import AutoComplete from "../../../components/form-wrappers/AutoComplete";
import { apiAddress } from "../../../config/general";
import { Card } from "../../../components/Card/styled";
import { MenuItem } from "@material-ui/core";
import { getElementRelation, getCurrentGrower } from "../Selector";

import { setDetails, setElement, updateElement, delElement } from "../actions";
import {
  NextButton,
  OptionWrap,
  SelectStyled,
  AutoCompleteWrapper,
  Input,
  Calendar,
  ImageContainer,
  Image,
  Files,
  DisableButton,
} from "./styled";

class EditElementDetails extends Component {
  constructor(props) {
    super(props);

    this.state = this.setEditDataInState(props);

    this.serverURL = apiAddress;
    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*, .pdf, .doc, .docx, .xls, .xlsx",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedPark, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.delete = this.delete.bind(this);
    this.removedfile = this.removedfile.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeElementVegCat = this.handleChangeElementVegCat.bind(this);
    this.handleChangeContact = this.handleChangeContact.bind(this);

    this.handleChangeElementFamiCat = this.handleChangeElementFamiCat.bind(
      this
    );

    this.handleChangeElementType = this.handleChangeElementType.bind(this);
    this.handleChangeElementSubType = this.handleChangeElementSubType.bind(
      this
    );

    this.handleSaveElement = this.handleSaveElement.bind(this);
  }

  setEditDataInState(props) {
    // let elementOverRide = null;
    // console.log(this.props?.individualRelations, this.props.selectedElementID);
    // if (this.props?.individualRelations?.elements) {
    //   //lets looks through individualRelations and find this element data
    //   elementOverRide = this.props?.individualRelations?.elements?.find(
    //     (ele) => {
    //       return (
    //         Number(ele.originId) === this.props.selectedElementID ||
    //         ele.id === this.props.selectedElementID
    //       );
    //     }
    //   );
    // }
    // console.log(elementOverRide);

    if (props.element && props.element.properties) {
      const element = props.element;
      const thisType = props.elementTypesList.find((type) => {
        return (
          type.id === props.element.properties.typeId ||
          type.id === props.element.properties.ElementTypeId
        );
      });

      return {
        details: element.properties.observations || "",
        local: element.properties.local || "",
        numero: element.properties.numero || "",
        vegetation: element.vegetation || "",
        attr: element.properties.attr || {},
        elementVegCat: element.vegetation && {
          suggestion: { label: element.vegetation.category },
        },
        elementVegFam: element.vegetation && {
          suggestion: { label: element.vegetation.family },
        },
        elementType: {
          suggestion: thisType,
        },
        showCatChoice: element.vegetation && element.vegetation.category,
        elementSubType: element.vegetation && {
          suggestion: { label: element.vegetation.family },
        },
        elementVegNorm: element.vegetation && element.vegetation.id,
        elementVegCien: element.vegetation && element.vegetation.id,
        isOpen: null,
        elementId: element.properties.id,
        photoIndex: 0,
        elementsTypeCategories:
          props?.element?.properties["ElementTypes.category"] ||
          props.elementsTypeCategories,
        implementationDate: this.getDate(element.properties.implementationDate),
        files: props.files || element.properties.files,
      };
    } else {
      return {
        details: "",
        vegetation: "",
        elementType: "",
        elementSubType: "",
        elementVegNorm: "",
        elementVegCien: "",
        elementVegFam: "",
        elementVegCat: "",
        attr: {
          contact: {
            email: "",
            phone: "",
            nome: "",
            id: "",
          },
        },
        isOpen: null,
        elementId: null,
        photoIndex: 0,
        elementsTypeCategories: props.elementsTypeCategories,
        implementationDate: this.getDate(),
        files: props.files,
      };
    }
  }

  getDate(date) {
    const now = date ? new Date(date) : new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({ files: files });
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    console.log(file);
    // this.setState({ photos: [...this.state.photos, file.upload] });
  }

  handleChange(e, value) {
    this.setState({ elementVegNorm: null, elementVegCien: null });
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeElementType(value) {
    this.setState({
      elementType: value,
      elementSubType: "",
      elementVegNorm: "",
      elementVegCien: "",
      elementVegCat: "",
      elementVegFam: "",
    });
  }

  handleChangeContact(e, value) {
    this.setState({
      attr: {
        ...this.state.attr,
        contact: {
          ...this.state.attr.contact,
          [e.target.name]: e.target.value,
        },
      },
    });
  }

  handleChangeElementSubType(value) {
    this.setState({ elementSubType: value });
  }

  handleChangeElementVegCat(value) {
    this.setState({ elementVegCat: value });
  }
  handleChangeElementFamiCat(value) {
    this.setState({ elementVegFam: value });
  }

  getElementTypesList() {
    return this.props.elementTypesList || [];
  }

  renderSelect(options, name) {
    return (
      <SelectStyled
        fullWidth
        label={name}
        name={name}
        value={this.state[name]}
        disabled={this.props.view}
        onChange={this.handleChange}
        inputProps={{
          name: name,
          id: name,
        }}
      >
        <MenuItem key={-1} value={""} />
        {options.map((option, index) => {
          const optionVal =
            name === "elementVegCien"
              ? option.cientificName
              : option.normalName;
          return (
            <MenuItem key={index} value={option.id}>
              {optionVal}
            </MenuItem>
          );
        })}
      </SelectStyled>
    );
  }
  renderTreeSelect() {
    return (
      <div>
        <AutoCompleteWrapper>
          <AutoComplete
            updateState={this.handleChangeElementVegCat}
            disabled={this.props.view}
            defaultValue={
              this.state.elementVegCat && this.state.elementVegCat.suggestion
            }
            options={
              this.props.vegetationTypes.reduce((prev, cur) => {
                if (!prev.find((ele) => ele.label === cur.category)) {
                  prev.push({ label: cur.category });
                }
                return prev;
              }, []) || []
            }
            placeholder={`Pesquisar por tipo`}
          />
        </AutoCompleteWrapper>

        {this.state.elementVegCat && this.state.elementVegCat.suggestion && (
          <div>
            <br />
            <AutoCompleteWrapper className={"clearfix"}>
              {this.renderSelect(
                this.props.vegetationTypes.filter((veg) => {
                  return (
                    veg.category === this.state.elementVegCat.suggestion.label
                  );
                }) || [],
                "elementVegCien"
              )}

              {this.state.elementVegCien === "" &&
                this.renderSelect(
                  this.props.vegetationTypes.filter((veg) => {
                    return (
                      veg.category === this.state.elementVegCat.suggestion.label
                    );
                  }) || [],
                  "elementVegNorm"
                )}
            </AutoCompleteWrapper>
            <br clear={"both"} />
          </div>
        )}
      </div>
    );
  }

  renderSubcategory() {
    if (
      this.state.elementType.suggestion &&
      this.state.elementType.suggestion.label &&
      this.state.elementType.suggestion.label.trim() === "Vegetação"
    ) {
      return this.renderTreeSelect();
    } else if (this.state.elementType.suggestion) {
      return (
        <AutoCompleteWrapper>
          <AutoComplete
            updateState={this.handleChangeElementSubType}
            options={
              this.props.elementsTypeCategories[
                this.state.elementType.suggestion.label
              ] || []
            }
            placeholder={`Pode pesquisar ou adicionar um sub elemento ${this
              .state.elementType.suggestion.category || ""} `}
          />
        </AutoCompleteWrapper>
      );
    }
  }

  handleSaveElement() {
    const details = {
      pid: this.state.pid,
      local: this.state.local,
      details: this.state.details,
      elementType: this.state.elementType,
      vegType: this.state.elementVegNorm
        ? this.state.elementVegNorm
        : this.state.elementVegCien,
      elementSubType: this.state.elementSubType,
      implementationDate: this.state.implementationDate,
      files: this.state.files,
      attr: this.state.attr,
    };

    this.props.setDetails(details);

    this.props.updateElement();
  }

  renderPhotos(photos) {
    //create a new array with the photos
    let images = [];
    let files = [];

    photos.forEach((photo) => {
      if (photo.path.match(/(jpg|jpeg|png|gif)$/)) {
        images.push(photo);
      } else {
        files.push(photo);
      }
    });
    //create a new array with files

    return (
      <div>
        {files.length > 0 && (
          <>
            <SectionTitle>Ficheiros</SectionTitle>
            <ul style={{ display: "flex", flexWrap: "wrap" }}>
              {//show files
              files?.map((file, index) => {
                console.log(file);
                return (
                  <li key={index} style={{ margin: "1em" }}>
                    <a href={`${this.serverURL}${file.path}`} target="_blank">
                      {file.name}
                    </a>
                    <div
                      onClick={() => {
                        const files = this.state.files.filter(
                          (f) => f.path !== file.path
                        );
                        this.setState({ files });
                      }}
                      style={{
                        fontSize: "0.8em",
                        cursor: "pointer",
                        color: "red",
                      }}
                    >
                      ( Apagar )
                    </div>
                  </li>
                );
              })}{" "}
            </ul>
          </>
        )}
        <ImageContainer>
          {images &&
            images.map((photo, index) => {
              return (
                <>
                  <Image
                    onClick={() =>
                      this.setState({
                        isOpen: true,
                        photoIndex: index,
                      })
                    }
                    key={index}
                    style={{
                      backgroundImage: `url('${this.serverURL}${photo.path}')`,
                    }}
                  />
                  <div
                    onClick={() => {
                      const files = this.state.files.filter(
                        (f) => f.path !== photo.path
                      );
                      this.setState({ files });
                    }}
                    style={{
                      fontSize: "0.8em",
                      cursor: "pointer",
                      color: "red",
                    }}
                  >
                    ( Apagar )
                  </div>
                </>
              );
            })}
        </ImageContainer>
      </div>
    );
  }

  renderRelationCard() {
    const { individualRelations, elementRelations } = this.props;
    let outputElements = [];
    let ids = [];

    if (elementRelations) {
      elementRelations.map((tar, i) => {
        //legacy check
        if (
          !ids.includes(tar.properties.id) &&
          !ids.includes(tar.properties.origin)
        ) {
          ids.push(tar.properties.origin || tar.properties.id);
          outputElements.push(
            <Card key={i}>
              <span className={"element"}>{tar.properties.label}</span>
            </Card>
          );
        }
      });
    }

    if (
      individualRelations &&
      individualRelations.elements &&
      individualRelations.elements.length
    ) {
      individualRelations.elements.map((tar, i) => {
        //legacy check
        if (!ids.includes(tar.id) && !ids.includes(tar.origin)) {
          ids.push(tar.origin || tar.id);

          outputElements.push(
            <Card key={i}>
              <span className={"element"}>{tar.label}</span>
            </Card>
          );
        }
      });
    }

    return outputElements;
  }

  renderGrowerCard() {
    const { growerRelations, individualRelations } = this.props;
    let growers = [];
    if (individualRelations && individualRelations.grower) {
      growers = individualRelations.grower;
    } else if (growerRelations && growerRelations.length > 0) {
      growers = growerRelations;
    }

    return (
      growers &&
      growers.map((grower, i) => {
        return (
          <GrowerCard
            fullDisplay={true}
            readOnly={true}
            grower={grower}
            key={i}
          />
        );
      })
    );
  }

  delete() {
    const toastrConfirmOptions = {
      onOk: () => this.props.delElement(),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;
    const { photoIndex, isOpen } = this.state;
    const images = this.state.files;

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      removedfile: this.removedfile,
      success: this.callback,
    };

    return (
      <div>
        <Row>
          <Col xs={12} md={6}>
            <SectionTitle>Informação sobre elemento</SectionTitle>
            <OptionWrap>
              {this.props.elementsTypeCategories && this.state.elementType && (
                <AutoCompleteWrapper>
                  <AutoComplete
                    updateState={this.handleChangeElementType}
                    defaultValue={this.state.elementType.suggestion}
                    options={this.props.elementsTypeCategories.generic || []}
                    placeholder={"Pesquise ou adicione um novo elemento"}
                    disabled={this.props.view}
                  />
                </AutoCompleteWrapper>
              )}
              <br />
              {this.renderSubcategory()}
            </OptionWrap>
          </Col>

          <Col xs={12} md={3}>
            <SectionTitle>Localização</SectionTitle>
            <OptionWrap>
              <Input
                label="Local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.local}
                name="local"
                input={{}}
                type="text"
                meta={{ invalid: false }}
                rows="8"
                disabled={this.props.view}
              />
              <Input
                label="Horta"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.numero}
                name="pid"
                input={{}}
                type="text"
                meta={{ invalid: false }}
                rows="8"
                disabled={this.props.view}
              />
            </OptionWrap>
          </Col>
          <Col xs={12} md={3}>
            {this.renderGrowerCard()}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>Descrição</SectionTitle>
            <OptionWrap>
              <Input
                multiline
                label="Descrição"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.details}
                name="details"
                input={{}}
                type="multiline"
                meta={{ invalid: false }}
                rows="8"
                disabled={this.props.view}
              />
              <Calendar
                id="implementationDate"
                name="implementationDate"
                input={{}}
                label="Data de implementação"
                meta={{ invalid: false }}
                type="datetime-local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.implementationDate}
                disabled={this.props.view}
              />
            </OptionWrap>
          </Col>
          <Col xs={12} md={3}>
            <SectionTitle>
              <i className={`icon-key icons`} /> Ligações
            </SectionTitle>
            {this.renderRelationCard()}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={9}>
            {!this.props.view && (
              <div>
                <SectionTitle>Opções</SectionTitle>
                <OptionWrap>
                  <Files
                    config={config}
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
                  />
                  {this.props.status !== "getting" &&
                    this.props.status !== "saving" && (
                      <NextButton
                        color="primary"
                        onClick={() => this.handleSaveElement()}
                      >
                        Submeter
                        <MenuIcon className={`icon-check icons`} />
                      </NextButton>
                    )}
                  {this.props.isAdmin && this.state.elementId && (
                    <DisableButton onClick={() => this.delete()}>
                      <MenuIcon className={`icon-close icons`} /> Destivar
                    </DisableButton>
                  )}
                </OptionWrap>
              </div>
            )}
          </Col>

          {images && images.length > 0 && (
            <Col xs={12} md={3}>
              {this.renderPhotos(images)}
            </Col>
          )}
        </Row>
        {isOpen && (
          <Lightbox
            mainSrc={`${this.serverURL}${images[photoIndex].path}`}
            nextSrc={`${this.serverURL}${
              images[(photoIndex + 1) % images.length].path
            }`}
            prevSrc={`${this.serverURL}${
              images[(photoIndex + images.length - 1) % images.length].path
            }`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

EditElementDetails.propTypes = {
  tagetElement: PropTypes.array,
  originElement: PropTypes.array,
};

EditElementDetails.defaultProps = {
  tagetElement: [],
  originElement: [],
};
export default connect(
  (state) => {
    return {
      poligonType: state.georeference.type,
      individualRelations: state.georeference.individualRelations,
      growerRelations: getCurrentGrower(state),
      elementRelations: getElementRelation(state),
      elementType: state.georeference.newItem.type,
      elementSubType: state.georeference.newItem.subType,
      selected: state.app.search.selected,
      token: state.user.data.token,
      isAdmin: state.user.data.admin,
    };
  },
  { setDetails, setElement, updateElement, delElement }
)(EditElementDetails);
