export function handleDate(date) {
  const now = new Date(date);
  const addZero = i => (i < 10 ? "0" + i : i);
  return `${addZero(now.getDate())}-${addZero(
    now.getMonth() + 1
  )}-${now.getFullYear()} ${addZero(now.getHours())}:${addZero(
    now.getMinutes()
  )}`;
}

export function getUniqArrayElements(arrArg) {
  return arrArg.filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos;
  });
}

export function getCurrentDate(startOfMonth) {
  const now = new Date();
  const addZero = i => (i < 10 ? "0" + i : i);
  return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
    startOfMonth ? 1 : now.getDate()
  )}`;
}

// rad calculation
function rad(x) {
  return (x * Math.PI) / 180;
}

//get distance between 2 points
export function getDistance(p1, p2) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(p2.lat - p1.lat);
  var dLong = rad(p2.lng - p1.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
}

export function ParseDMS(latitude, longitude) {
  // eslint-disable-next-line
  var latParts = latitude.trim().split(/[^\d\w\-\.]+/);
  // eslint-disable-next-line
  var lngParts = longitude.trim().split(/[^\d\w\-\.]+/);
  var lat = ConvertDMSToDD(latParts[0], latParts[1], latParts[2], latParts[3]);
  var lng = ConvertDMSToDD(lngParts[0], lngParts[1], lngParts[2], lngParts[3]);

  return {
    Latitude: lat,
    Longitude: lng,
    Position: lat + "," + lng
  };
}

function ConvertDMSToDD(degrees, minutes, seconds, direction) {
  var dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);

  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}
