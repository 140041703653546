import { createTheme } from "@material-ui/core/styles";

export const palette = {
  BLUE: "#0a2236",
  LIGHT_BLUE: "#4192ec",
};

export const PRIMARY_COLOR = palette.BLUE;
export const SECONDARY_COLOR = palette.LIGHT_BLUE;

export const theme = createTheme({
  overrides: {
    MuiChip: {
      root: {
        margin: ".3em",
      },
    },
    MuiInput: {
      root: {
        background: "white",
      },
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: palette.BLUE,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: palette.LIGHT_BLUE,
      main: palette.LIGHT_BLUE,
      // dark: will be calculated from palette.secondary.main,
    },
    // error: will use the default color
  },
});
