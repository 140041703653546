import React, { useState, useEffect } from "react";
import axios from "axios";

import { Row, Col } from "react-flexbox-grid";
import { Box } from "@material-ui/core";

import SideSubTitle from "../../components/Text/SideSubTitle";
import GrowerCard from "../../components/Card/GrowerCard";

const SchoolResponsibles = ({ schoolName, noTitle, readOnly }) => {
  const [responsible, setResponsible] = useState([]);
  // console.log(schoolName);
  useEffect(() => {
    getSubscriptions();
  }, []);

  const getSubscriptions = async () => {
    try {
      const response = await axios.get(
        `api/v1/schools/${schoolName}/all/all/all/2`
      );
      const parsedResponsible = JSON.parse(response?.data?.msg);
      // console.log(response?.data?.msg);
      if (parsedResponsible[0]?.responsible) {
        setResponsible(parsedResponsible[0]?.responsible);
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (!responsible || responsible.length === 0) return null;

  return (
    <>
      <div style={{ padding: "0 8px" }}>
        {!noTitle && (
          <Row>
            <Col>
              <div style={{ margin: "0 1em 0 0" }}>
                <SideSubTitle>Responsáveis</SideSubTitle>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          {responsible?.map((item, index) => {
            return (
              <Col key={index}>
                <Box mr={"1em"}>
                  <GrowerCard grower={item} readOnly={readOnly} />
                </Box>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};
export default SchoolResponsibles;
