import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";

import { toastr } from "react-redux-toastr";

import Sidebar from "../../components/Layout/Sidebar";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import AddActionButtons from "../../components/Buttons/AddActionButtons";

import { getTasks, setVisit } from "./actions";
import { setSearch, setUserTypeSearch, setDateSearch } from "../App/actions";
import { Data } from "../../components/Filters/styled";
import { StyledWrap } from "../../components/InspMain/styled";

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

const CalendarInput = styled(Data)`
  && {
    width: 100%;
  }
`;

const FilterList = styled.div`
  && {
    > div {
      overflow-y: none;
      height: calc(100vh - 180px);
      @media (max-width: 768px) {
        height: calc(100vh - 280px);
      }
      @media (max-width: 455px) {
        height: auto;
      }
    }
    .maintenance-filter {
      height: calc(100vh - 180px);
      padding-right: 18px;
      margin: 1em 0;
      @media (max-width: 768px) {
        height: calc(100vh - 240px);
      }
      @media (max-width: 455px) {
        height: auto;
      }
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;

const OptionWrapElements = styled.div`
  && {
    padding: 1em;
    margin: 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: left;
    border: 1px solid #e9eff4;
    border-radius: 6px;
    cursor: pointer;
    background: ${(props) => {
      if (props.selected) {
        return "#4da1ff";
      }

      if (props.visited) {
        return "#6fc44c";
      }
      if (props.fixed) {
        return "#f29729";
      }
      return "#fff";
    }};
    span,
    div {
      color: ${(props) => {
        if (props.fixed) {
          return "#fff";
        }
      }};
    }
    :hover {
      background: ${(props) =>
        props.selected || props.visited ? "#4da1ff" : "#e9eff4"};
      color: #fff;
    }

    ${(props) =>
      props.selected || props.visited ? "span,div{color:#fff}" : ""};
  }
`;

const SlectPark = styled.span`
  && {
    font-size: 1.6em;
    margin: 0 0.3em 0 0;
    text-align: center;
    color: rgba(74, 144, 226, 0.4);
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    cursor: pointer;
  }
`;

const ParkName = styled.div`
  && {
    font-size: 0.8em;
    color: rgba(74, 144, 226, 0.8);
    font-weight: bold;
    text-align: left;
  }
`;
const ParkLocation = styled.div`
  && {
    font-size: 0.8em;
    color: rgba(74, 144, 226, 0.33);
    text-align: left;
  }
`;

const Inline = styled.div`
  && {
    display: inline-block;
  }
`;
class Tasks extends Component {
  constructor(props) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.setVisitToPark = this.setVisitToPark.bind(this);

    // lets set the correct permissions
    props.setUserTypeSearch(props.userType);
    // const currentDate = getCurrentDate();
    // if (currentDate !== props.startDate) {
    //   props.setDateSearch(currentDate);
    //   toastr.success('Data Alterada', 'A data foi alterada para o dia de hoje');
    // }
  }

  componentDidUpdate() {
    // we need to update after saving elements
    // to present parcel in green

    if (
      (this.props.maintenanceStatus === "saving" ||
        this.props.inpectionsStatus === "saving" ||
        this.props.status === "saving") &&
      this.props.status !== "getting"
    ) {
      this.props.getTasks();
    }
  }

  setVisitToPark(park) {
    const toastrConfirmOptions = {
      onOk: () => this.props.setVisit(park),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      `Quer registar a sua visita à folha ${park}`,
      toastrConfirmOptions
    );
  }

  componentDidMount() {
    if (this.props.status !== "getting") this.props.getTasks();
  }

  handleSearchChange(e, value, nofetch) {
    this.props.setSearch(
      {
        element: e.target ? [e.target.name] : e,
        value: e.target ? e.target.value : value,
      },
      nofetch
    );

    if (e.target && e.target.name === "startDate") this.props.getTasks();
  }

  /**
   * Get all filtered tasks
   */
  getTasks() {
    const { maintenances, inpections, type } = this.props;
    const filteredType =
      type !== "interventions" && type !== "inspections" ? "all" : type;
    switch (filteredType) {
      case "all":
        const i = inpections || [];
        const m = maintenances || [];

        return [
          ...i.filter(
            (inpection) => this.filterTask(inpection) && inpection.status === 0
          ),
          ...m.filter((maintenance) => this.filterTask(maintenance)),
        ].sort(function(a, b) {
          return new Date(b.date) - new Date(a.date);
        });
      case "inspections":
        return inpections
          ? inpections.filter(
              (inpection) =>
                this.filterTask(inpection) && inpection.status === 0
            )
          : [];
      case "interventions":
        return maintenances
          ? maintenances.filter((maintenance) => this.filterTask(maintenance))
          : [];

      default:
        return [];
    }
  }

  renderSelectedPark() {
    const {
      geoJson,
      routesList,
      selected,
      inpections,
      maintenances,
      startDate,
    } = this.props;
    if (!geoJson || !routesList) return null;

    return (
      <div>
        {routesList.map((route, index) => {
          return route.list.map((place, index) => {
            const thisPark = geoJson.find(
              (park) => park.properties.numero === place
            );

            if (!thisPark || routesList.length < 1) return null;

            let wasVisited =
              (inpections &&
                inpections.find(
                  (inpection) =>
                    inpection.pid === place &&
                    moment(startDate).diff(inpection.date, "hours") < 16
                )) ||
              null;

            // lets check if we have maintenances
            let wasFix =
              maintenances &&
              maintenances.find(
                (inpection) =>
                  inpection.pid === place &&
                  moment(startDate).diff(inpection.date, "hours") < 16
              );

            const isSelected = selected === thisPark.properties.numero;

            return (
              <OptionWrapElements
                key={index}
                selected={isSelected}
                visited={wasVisited}
                fixed={wasFix}
                onClick={() => {
                  this.handleSearchChange(
                    "freguesia",
                    thisPark.properties.freguesia,
                    true
                  );
                  this.handleSearchChange(
                    "selected",
                    isSelected ? "" : place,
                    false
                  );
                }}
              >
                <SlectPark
                  // onClick={() => this.togglePark(thisPark)}
                  className={`icon-location-pin icons`}
                />
                <Inline>
                  <ParkName>{thisPark.properties.name}</ParkName>
                  <ParkLocation>
                    {thisPark.properties.freguesia}, {thisPark.properties.local}
                  </ParkLocation>
                  <ParkLocation>{route["Team.label"]}</ParkLocation>
                </Inline>
              </OptionWrapElements>
            );
          });
        })}
      </div>
    );
  }

  render() {
    const { startDate, totalParks, menuData } = this.props;
    return (
      <StyledWrap>
        {/*<MobileLayoutToggle />*/}
        <Sidebar
          title={menuData?.name}
          text={` ${totalParks === 0 ? "0" : totalParks}  agendadas`}
          icon={"layers"}
          // noanimation
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="maintenance-filter"
              contentClassName="maintenance-filter-content"
              horizontal={false}
            >
              {this.renderSelectedPark()}
            </ScrollArea>
          </FilterList>
          <Section>
            <CalendarInput
              id="startDate"
              name="startDate"
              label="Data "
              input={{}}
              meta={{ invalid: false }}
              variant="outlined"
              margin="dense"
              type="date"
              onChange={(e) => this.handleSearchChange(e)}
              defaultValue={startDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Section>
        </Sidebar>
        <AddActionButtons />
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      geoJson: state.parklist.geoJson,
      type: state.app.search.type,

      maintenanceStatus: state.maintenances.status,
      inpectionsStatus: state.inspections.status,

      maintenances: state.tasks.maintenancesList,
      inpections: state.tasks.inspectionList,

      totalParks: state.tasks.totalParks,
      routesList: state.tasks.routesList,
      startDate: state.app.search.startDate,
      status: state.tasks.status,
      role: state.user.data.role,

      userType: state.user.data.type,
      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/tasks`
        ),
    };
  },
  { getTasks, setSearch, setUserTypeSearch, setDateSearch, setVisit }
)(Tasks);
