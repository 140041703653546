import axios from "axios";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import MainButton from "../../components/Buttons/MainButton";
import { default as DiplomaTable } from "../../components/Table/Diploma";
import { apiAddress, growerTypes } from "../../config/general";
import { store } from "../../store/configure";

const Diploma = (props) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const parks = store.getState().parklist.list;

  useEffect(() => {
    getUserSuplentes();
  }, []);

  /** filter users by props pid */
  const processSelectedPark = () => {
    // getUserSuplentes();

    if (props.pid && props.pid !== "all") {
      return selectedUsers.filter((user) => {
        //check if its the corrct user type
        if (growerTypes[user.type] === props.selectedTypePark) {
          return user.pid === props.pid;
        }
        return false;
      });
      // setSelectedUsers(park?.users);
    } else {
      return selectedUsers.filter((user) => {
        return true;
        // return growerTypes[user.type] === props.selectedTypePark;
      });
    }

    return selectedUsers;
  };
  /**
   * get users for a park
   */
  const getUserSuplentes = async () => {
    try {
      const res = await axios.get(`${apiAddress}api/v1/relations/status/6,9`);
      setSelectedUsers(
        res?.data?.msg?.filter((u) => {
          // if (props.selectedTypePark !== growerTypes[u.type]) {
          //   return false;
          // }
          return true;
        }) || []
      );
    } catch (error) {
      // toastr.error("Aconteceu um obter lista");
      console.log(error);
    }
  };

  //update user state
  const updateUserState = async (state, user) => {
    //get user from selectedUsers
    const userObj = selectedUsers.find((u) => u.id === user);
    let updatedNote = userObj.note || "";

    //if state 3, set user to inactive
    const setNewAttrState = async () => {
      try {
        const res = await axios.post(
          `${apiAddress}api/v1/contacts/status/${user}`,
          { status: state, note: updatedNote }
        );
        const res2 = await axios.post(
          `${apiAddress}api/v1/contacts/attrStatus/${user}`,
          { status: 0 }
        );
        const res3 = await axios.post(
          `${apiAddress}api/v1/contacts/education/${user}`,
          { education: [...new Set([...(userObj.education || []), "3"])] }
        );
        if (document.getElementsByClassName(`close-toastr`)[0]) {
          document.getElementsByClassName(`close-toastr`)[0].click();
        }
        // now we need to update userlist
        getUserSuplentes();
      } catch (error) {
        // toastr.error("Aconteceu um obter lista");
        console.log(error);
      }
    };
    const toastrConfirmOptions = {
      // onOk: () => setNewAttrState(),
      // onCancel: () => console.log("CANCEL: clicked"),
      // closeOnShadowClick: false,
      // enableEscapeKey: false,
      // enableEnterKey: false,
      timeOut: 0, // Default value is 0
      removeOnHover: false, // Default value is false
      removeOnHoverTimeOut: 0, // Default value is 1000
      component: (
        <div>
          <div
            style={{
              fontSize: "1.2em",
              lineHeight: "1",
              fontWeight: "bold",
              marginBottom: "1em",
            }}
          ></div>
          <textarea
            multiline
            id={`note-${user}`}
            name="note"
            defaultValue={updatedNote}
            onChange={(e) => {
              updatedNote = e.target.value;
            }}
            type="multiline"
            rows="7"
            onKe
            style={{
              padding: "0.5em",
              fontFamily: "Arial",
              display: "block",
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              margin: ".5em 0",
              justifyContent: "flex-end",
            }}
          >
            <MainButton
              size={"small"}
              color={"primary"}
              onClick={() => setNewAttrState()}
            >
              Salvar Alterações
            </MainButton>
          </div>
        </div>
      ),
    };

    toastr.message(
      "Adicione um comentário antes de proceder com esta alteração",
      toastrConfirmOptions
    );
  };

  /***Set actions hooks */
  // const updateUserEducation = async (user, eduction) => {};
  // //update user state
  const updateUserEducation = async (state, user) => {
    //get user from selectedUsers
    const userObj = selectedUsers.find((u) => u.id === user);
    let updatedNote = userObj.note || "";
    let updateStatus = {};

    //if state 3, set user to inactive
    const setNewAttrState = async () => {
      try {
        const res = await axios.post(
          `${apiAddress}api/v1/contacts/education/${user}`,
          updateStatus
        );
        if (document.getElementsByClassName(`close-toastr`)[0]) {
          document.getElementsByClassName(`close-toastr`)[0].click();
        }
        if (state === "diploma") {
          await axios.post(`${apiAddress}api/v1/contacts/status/${user}`, {
            status: 0,
            note: updatedNote,
          });
        }
        // now we need to update userlist
        getUserSuplentes();
      } catch (error) {
        // toastr.error("Aconteceu um obter lista");
        console.log(error);
      }
    };

    //its a module, let check and combine with user previouse modules
    const newModules = [...new Set([...(userObj.education || []), "3"])];
    updateStatus = { education: newModules };

    const toastrConfirmOptions = {
      // onOk: () => setNewAttrState(),
      // onCancel: () => console.log("CANCEL: clicked"),
      // closeOnShadowClick: false,
      // enableEscapeKey: false,
      // enableEnterKey: false,
      timeOut: 0, // Default value is 0
      removeOnHover: false, // Default value is false
      removeOnHoverTimeOut: 0, // Default value is 1000
      component: (
        <div>
          <div
            style={{
              fontSize: "1.2em",
              lineHeight: "1",
              fontWeight: "bold",
              marginBottom: "1em",
            }}
          ></div>
          <textarea
            multiline
            id={`note-${user}`}
            name="note"
            defaultValue={updatedNote}
            onChange={(e) => {
              updatedNote = e.target.value;
            }}
            type="multiline"
            rows="7"
            onKe
            style={{
              padding: "0.5em",
              fontFamily: "Arial",
              display: "block",
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              margin: ".5em 0",
              justifyContent: "flex-end",
            }}
          >
            <MainButton
              size={"small"}
              color={"primary"}
              onClick={() => setNewAttrState()}
            >
              Salvar Alterações
            </MainButton>
          </div>
        </div>
      ),
    };

    toastr.message(
      "Adicione um comentário antes de proceder com esta alteração",
      toastrConfirmOptions
    );
  };
  return (
    <div>
      <DiplomaTable
        orderBy={"id"}
        data={processSelectedPark(selectedUsers)}
        // updateUserState={updateUserState}
        updateUserEducation={updateUserEducation}
        parks={parks}
        updateUserState={updateUserState}
        quotas={props.quotas}
        // reloadData={() => this.props.getMaterials(this.state.search)}
        // reloadData={this.props.getMaterials(this.state.search)}
        // click={this.goToDetail}
      />
    </div>
  );
};

export default Diploma;
