import {
  ADD_NEW_TOGGLE,
  VIEW_TOGGLE,
  ELEMENTS_TYPE_GETTING,
  ELEMENTS_TYPE_RECEIVED,
  GROWER_RELATION_RECEIVED,
  VEGETATION_TYPE_GETTING,
  VEGETATION_TYPE_RECEIVED,
  ELEMENT_RELATION_GETTING,
  ELEMENT_INDIVIDUAL_RELATION_RECEIVED,
  ELEMENTS_GETTING,
  ELEMENTS_RECEIVED,
  ELEMENTS_ERROR,
  PARK_GETTING,
  PARK_RECEIVED,
  PARK_DONE,
  SET_DETAILS,
  EDIT_TOGGLE,
  SAVED,
  SAVING,
  DELETED,
  RESET,
  HISTORY_GETTING,
  HISTORY_RECEIVED,
  HISTORY_DONE,
  HORTAS_PARCEL_RECEIVED,
  HORTAS_PARCEL_DONE,
  HORTAS_PARCEL_ERROR,
  ELEMENT_RELATION_RECEIVED,
  GET_PARCELS,
  RECEIVED_PARCELS,
  RECEIVED_PARK_QUOTAS,
} from "./constants";
import { status } from "../../config/general";

let initialState = {
  addNew: false,
  editElement: false,
  viewElement: false,
  status: "",
  type: null,
  element: null,
  park: null,
  elements: [],
  geoJson: [],
  individualRelations: {},
  elementRelations: [],
  growerRelations: [],
  elementsGeoJson: [],
  elementTypes: [],
  vegetationTypes: [],
  newItem: {
    geoJson: null,
    details: null,
  },
  history: [],
  parcels: {},
  selectedParcelHorta: [],
  quotas: [],
  search: {
    edit: null,
    local: "",
    freguesia: "",
    selected: "",
    type: "all",
    element: null,
    status: 1,
  },
};

const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.georeference) {
  initialState = JSON.parse(localStorage.georeference);
  initialState.viewElement = false;
  initialState.editElement = false;
  initialState.addNew = false;
  initialState.element = null;
  initialState.park = null;
  initialState.search.edit = null;
}

function presistData(type, data) {
  try {
    if (isLocalStorage)
      localStorage[type] = JSON.stringify({ ...data, status: "" });
  } catch (error) {
    console.log(error);
  }
  return data;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEW_TOGGLE:
      return {
        ...state,
        type: action.data.type,
        viewElement: !state.viewElement,
        element: action.data.element,
      };
    case ADD_NEW_TOGGLE:
      return {
        ...state,
        newItem: {
          ...state.newItem,
          geoJson: action.data.geoJson,
        },
        type: action.data.type,
        addNew: !state.addNew,
      };
    case EDIT_TOGGLE:
      return {
        ...state,
        type: action.data.type,
        editElement: !state.editElement,
        element: action.data.element,
      };

    case SAVING:
      return {
        ...state,
        status: status.saving,
      };
    case SET_DETAILS:
      return {
        ...state,
        status: status.saving,
        newItem: {
          ...state.newItem,
          details: action.data,
        },
      };
    case RESET:
      return {
        ...state,
        search: state.search,
        status: "resetElements",
        addNew: false,
        type: null,
        newItem: {
          type: null,
          subType: null,
          geoJson: null,
          details: null,
        },
      };
    case SAVED:
      return {
        ...state,
        search: state.search,
        status: "getElements",
        addNew: false,
        type: null,
        newItem: {
          type: null,
          subType: null,
          geoJson: null,
          details: null,
        },
      };
    case GET_PARCELS:
      return {
        ...state,
        status: "loading",
      };
    case RECEIVED_PARCELS:
      //lets fill objects will the parcels empty and full
      // for each pid
      let empty = {};
      let full = {};

      action.data.map((parcel) => {
        if (parcel.type && parcel.rstatus === 1) {
          full[parcel.epid] = [...(full[parcel.epid] || []), parcel];
        } else {
          empty[parcel.epid] = [...(empty[parcel.epid] || []), parcel];
        }
      });
      return {
        ...state,
        status: "done",
        parcels: { empty, full },
      };
    case DELETED:
      return {
        ...state,
        status: "deleted",
      };
    case VEGETATION_TYPE_GETTING:
      newState = {
        ...state,
        vegetationTypes: [],
      };
      return presistData("georeference", newState);

    case VEGETATION_TYPE_RECEIVED:
      newState = {
        ...state,
        vegetationTypes: action.data,
      };
      return presistData("georeference", newState);

    case ELEMENTS_TYPE_GETTING:
      newState = {
        ...state,
        elementTypes: [],
      };
      return presistData("georeference", newState);

    case ELEMENTS_TYPE_RECEIVED:
      newState = {
        ...state,
        elementTypes: action.data,
      };
      return presistData("georeference", newState);

    case PARK_GETTING:
      return { ...state, status: status.getting };
    case RECEIVED_PARK_QUOTAS:
      return { ...state, quotas: action.data };
    case PARK_RECEIVED:
      return { ...state, status: "done", park: action.data };
    case HORTAS_PARCEL_RECEIVED:
      const horta = action.data[0] ? action.data[0].Horta : 0;
      const updateParcelHorta = {
        ...state.selectedParcelHorta,
        [horta]: action.data,
      };
      return {
        ...state,
        status: "done",
        selectedParcelHorta: updateParcelHorta,
      };
    case HORTAS_PARCEL_DONE:
      return { ...state, status: "" };
    case ELEMENT_RELATION_GETTING:
      return { ...state, individualRelations: [] };
    case ELEMENT_INDIVIDUAL_RELATION_RECEIVED:
      return { ...state, individualRelations: action.data };
    case HORTAS_PARCEL_ERROR:
      return { ...state, status: "done" };
    case PARK_DONE:
      return { ...state, status: "" };

    case HISTORY_GETTING:
      return { ...state, status: status.getting };
    case HISTORY_RECEIVED:
      return { ...state, status: "done", history: action.data };
    case HISTORY_DONE:
      return { ...state, status: "" };

    case ELEMENTS_GETTING:
      return { ...state, status: status.getting };

    case ELEMENT_RELATION_RECEIVED:
      newState = {
        ...state,
        status: "done", // instead of done
        elementRelations: action.data,
      };
      return presistData("georeference", newState);
    case GROWER_RELATION_RECEIVED:
      newState = {
        ...state,
        status: "done",
        growerRelations: action.data,
      };
      return presistData("georeference", newState);
    case ELEMENTS_RECEIVED:
      newState = {
        ...state,
        status: "",
      };
      return presistData("georeference", newState);

    case ELEMENTS_ERROR:
      return { ...state, status: status.error };

    default:
      return state;
  }
};
