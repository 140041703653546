import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
// import ChipInput from 'material-ui-chip-input';
import { Row, Col } from "react-flexbox-grid";
import DropzoneComponent from "react-dropzone-component";
import SchoolResponsibles from "../../Schools/SchoolResponsibles";

import MainButton from "../../../components/Buttons/MainButton";
import ContentWrap from "../../../components/Layout/ContentWrap";
import TextField from "../../../components/form-wrappers/TextField";
import SectionTitle from "../../../components/Text/SectionTitle";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import AutoComplete from "../../../components/form-wrappers/AutoComplete";
import { apiAddress } from "../../../config/general";
import GrowerCard from "../../../components/Card/GrowerCard";
import {
  getCurrentGrower,
  getElementRelation,
} from "../../../containers/Georeference/Selector";
import { getRelationsByElementId } from "../../Georeference/actions";
import { Card } from "../../../components/Card/styled";

import {
  setDetails,
  setMaintenance,
  getInterventions,
  setPhotos,
} from "../actions";

const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;
const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    margin: 1em 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;

const Calendar = styled(Input)`
  && {
    margin: 1em 0 0 0;
  }
`;

const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
    .dz-image {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
`;

class DetailsSpecific extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.getCurrentDate(),
      endDate: this.getCurrentDate(),
      details: "",
      attr: {},
      interventions: [],
      files: props.photos || [],
    };
    // if (
    //   props.pid !== props.details.pid &&
    //   props.photos &&
    //   props.photos.length > 0
    // )
    //   toastr.error(
    //     'Informação incorrecta',
    //     'Atenção as fotos guardadas não corresponde a este parque, carregue em reset para começar de novo'
    //   );

    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedParkId, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIntervention = this.handleChangeIntervention.bind(this);
    this.handleSaveInspection = this.handleSaveInspection.bind(this);

    props.getInterventions();
    const thisElement =
      props.selectedElement && props.selectedElement.properties
        ? props.selectedElement.properties.origin ||
          props.selectedElement.properties.id
        : "";

    props.getRelationsByElementId(
      thisElement,
      props.selectedElement && props.selectedElement.properties.numero
    );
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.props.setPhotos(files);
    this.setState({ files: files });
    this.props.setDetails(this.state);
  }

  handleAttrChange(e, value) {
    this.setState({
      attr: { ...this.state.attr, [e.target.name]: e.target.value },
    });
    this.props.setDetails(this.state);
  }

  // Simple callbacks work too, of course
  removedfile(file) {
    //need to delete images
    const updatedFiles = this.state.files.filter(
      (photo) => photo.name !== file.upload.filename
    );

    this.setState({
      files: updatedFiles,
    });
    this.props.setPhotos(updatedFiles);
  }

  getCurrentDate() {
    const now = new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  getInterventionsList() {
    return this.props.interventionsList || [];
  }

  handleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
    this.props.setDetails(this.state);
  }

  handleChangeIntervention(value) {
    this.setState({ interventions: value });
  }

  handleSaveInspection() {
    this.props.setDetails(this.state);

    this.props.setMaintenance();
  }

  preloadImages(dropzone) {
    (this.state.files || []).forEach(function (i) {
      var name = "";
      var file = { url: i.path, name: name };
      var imageUrl = `${apiAddress}${i.path}`;

      dropzone.createThumbnailFromUrl(file, imageUrl);
      dropzone.emit("addedfile", file);
      dropzone.emit("thumbnail", file, imageUrl);
      dropzone.emit("complete", file);
    });
  }

  renderGrowerCard() {
    const { growerRelations, individualRelations } = this.props;
    let growers = [];
    if (individualRelations && individualRelations.grower) {
      growers = individualRelations.grower;
    } else if (growerRelations && growerRelations.length > 0) {
      growers = growerRelations;
    }
    return (
      growers &&
      growers.map((grower, i) => {
        return (
          <GrowerCard
            fullDisplay={true}
            readOnly={true}
            grower={grower}
            key={i}
          />
        );
      })
    );
  }

  renderRelationCard() {
    const { individualRelations, elementRelations } = this.props;

    let outputElements = [];
    if (
      individualRelations &&
      individualRelations.elements &&
      individualRelations.elements.length
    ) {
      individualRelations.elements.map((tar, i) => {
        outputElements.push(
          <Card key={i}>
            <span className={"element"}>{tar.label}</span>
          </Card>
        );
      });
    } else if (elementRelations) {
      elementRelations.map((tar, i) => {
        outputElements.push(
          <Card key={i}>
            <span className={"element"}>{tar.properties.label}</span>
          </Card>
        );
      });
    }
    return outputElements;
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const self = this;

    /// not bound to this is moved out of render
    const eventHandlers = {
      init: (dropzone) => {
        dropzone.autoDiscover = false;
        // self.preloadImages(dropzone);
      },
      removedfile: self.removedfile,
      error: (e) => {
        console.log(e);
      },
      success: self.callback,
    };

    return (
      <div>
        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>Manutenção</SectionTitle>
            <OptionWrap>
              <AutoComplete
                updateState={this.handleChangeIntervention}
                options={this.getInterventionsList()}
                placeholder={"Pesquise ou adicione uma nova intervenção"}
              />

              {this.state.interventions.suggestion &&
                this.state.interventions.suggestion.label === "Leitura" && (
                  <Input
                    label="Total"
                    onChange={(e) => this.handleAttrChange(e)}
                    name="reading"
                    input={{}}
                    type="number"
                    meta={{ invalid: false }}
                    defaultValue={this.state.attr.reading || ""}
                  />
                )}
              <Calendar
                id="date"
                name="date"
                input={{}}
                label="Data Inicial"
                meta={{ invalid: false }}
                type="datetime-local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.date}
              />
              <Calendar
                id="endDate"
                name="endDate"
                input={{}}
                label="Data Final"
                meta={{ invalid: false }}
                type="datetime-local"
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.endDate}
              />
              <Input
                multiline
                label="Descrição"
                onChange={(e) => this.handleChange(e)}
                name="details"
                input={{}}
                type="multiline"
                meta={{ invalid: false }}
                rows="8"
              />
            </OptionWrap>
          </Col>

          <Col xs={12} md={3}>
            <SectionTitle>Opções</SectionTitle>
            <OptionWrap>
              <Files
                config={config}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
              />
              {this.props.status !== "saving" && (
                <NextButton
                  color="primary"
                  onClick={() => this.handleSaveInspection()}
                >
                  Salvar
                  <MenuIcon className={`icon-check icons`} />
                </NextButton>
              )}
            </OptionWrap>
            <SectionTitle>Contactos</SectionTitle>
            {this.props.isSchool && (
              <SchoolResponsibles
                schoolName={this.props.isSchool}
                readOnly={true}
                noTitle={true}
              />
            )}

            {this.renderGrowerCard()}
            {this.renderRelationCard()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.maintenances.type,
      individualRelations: state.georeference.individualRelations,
      growerRelations: getCurrentGrower(state),
      elementRelations: getElementRelation(state),
      status: state.maintenances.status,
      photos: state.maintenances.photos,
      selected: state.app.search.selected,
      details: state.maintenances.details,
      interventionsList: state.maintenances.interventions,
      token: state.user.data.token,
      role: state.user.data.role,
    };
  },
  {
    setDetails,
    setMaintenance,
    getInterventions,
    getRelationsByElementId,
    setPhotos,
  }
)(DetailsSpecific);
