import React from "react";
import styled from "styled-components";
import { Button as ButtonMUI } from "@material-ui/core";

const StyledButton = styled(props => <ButtonMUI {...props} />)`
  && {
    line-height: 1em;
    width: ${props => props.width || "50%"};
    min-width: auto;
    display: inline-block;
    vertical-align: middle;
    padding: 0.5em 0;
    span {
      font-size: 1em;
      font-weight: lighter;
      text-transform: capitalize;
      color: #989898;
    }

    :hover {
      background: #fff;
      span {
        color: #000;
      }
      .icons {
        color: #4da1ff;
      }
    }

    ${props =>
      props.active &&
      `
        background: #fff;
        span {
          color: #000;
        }

       .icons {
          color: #4da1ff;
        }
      `};

    ${props =>
      props.special &&
      `
          background: #888;
          span {
            color: #fff;
          }
  
         .icons {
            color: #fff;
          }
        `};
    ${props =>
      props.specialActive &&
      `
          background: #4da1ff;
          span {
            color: #fff;
          }
  
         .icons {
            color: #fff;
          }
        `};
  }
`;

class GroupedButton extends React.Component {
  render() {
    return (
      <StyledButton
        {...this.props}
        active={this.props.active ? "true" : undefined}
      >
        {this.props.children}
      </StyledButton>
    );
  }
}

export default GroupedButton;
