import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html{
    touch-action: manipulation;
    margin:0;
    padding:0;
  }

      /* The emerging W3C standard
   that is currently Firefox-only */
   * {
      scrollbar-width: thin;
      scrollbar-color: #989898 white;
    }

    /* Works on Chrome/Edge/Safari */
    *::-webkit-scrollbar {
      width: 12px;
    }
    *::-webkit-scrollbar-track {
      background: white;
    }
    *::-webkit-scrollbar-thumb {
      background-color: #989898;
      border-radius: 20px;
      border: 3px solid white;
    }


  body {
    /* background: #F4F8F9; */
    /* background: #f4f6f8; */
    background: #f4f6f8;
    overscroll-behavior-y: contain;
    @media print {
      background: #fff;
    }
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

    @media print
    {
        * {-webkit-print-color-adjust:exact;}
    }
    @media screen and (max-width: 770px) {
    .row > div{
      margin-bottom:1em;
    }
  }


  input[type='date']::-webkit-clear-button, 
  input[type='date']::-webkit-inner-spin-button{
    -webkit-appearance: none;
    display: none;
  }

  .redux-toastr{
    .rrr-title{
      
    }
      font-size:.8em;

  }


  [popover] {
    transform: translateY(0);
    opacity: 1;
    box-shadow: 0 0 1rem rgba(0,0,0,0.25);
    border-radius: 0.5rem;
    animation: popover 250ms ease;
    background: white;
    padding: 2em;
    max-width: 320px;
  }

  [popover]::backdrop {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(var(--spacing-smallest));
    box-shadow: inset 0 0 calc(200 / 16 * 1rem) rgba(0,0,0,0.25);
    transition: all 250ms ease;
  }

  @keyframes popover {
    0% {
      transform: translateY(-1rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
