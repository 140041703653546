import React from "react";
import Table from "./Table";

export default function(props) {
  const processedData = data => {
    return data.map(row => {
      return { ...row };
    });
  };
  const columnData = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      name: "id",
      options: {
        display: false
      }
    },
    {
      id: "freguesia",
      numeric: false,
      disablePadding: false,
      label: "Freguesia",
      name: "Freguesia"
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Data",
      name: "Data"
    },
    {
      id: "teams",
      numeric: false,
      disablePadding: false,
      label: "Visualizado por",
      name: "Visualizado por"
    }
  ];

  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      click={props.click}
      header={columnData}
    />
  );
}
