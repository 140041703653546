import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";
import { ParseDMS } from "../../services/utils";

export function setDetails(details) {
  return {
    type: constants.SET_DETAILS,
    payload: details,
  };
}
export function setGrowerDetails(details) {
  return {
    type: constants.SET_GROWER_DETAILS,
    payload: details,
  };
}
export function setSearchDate(name, value) {
  return {
    type: constants.SET_SEARCH_DATE,
    payload: { name, value },
  };
}

export function centereToUser() {
  return {
    type: constants.CENTER,
  };
}

export function centereddOnUser() {
  return {
    type: constants.CENTERED,
  };
}

export function setSelectedRows(rows) {
  return {
    type: constants.SET_SELECTED_ROWS,
    payload: rows,
  };
}

export function userLocation() {
  return (dispatch) => {
    navigator.geolocation.watchPosition(
      (position) => {
        const trackUserPosition = store.getState().app.trackUserPosition;
        if (position && trackUserPosition) {
          dispatch({
            type: constants.USER_POSITION,
            payload: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        }
      },
      (e) => {
        console.log(e);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 27000,
      }
    );
  };
}

export function login(email, password) {
  return (dispatch) => {
    dispatch({
      type: constants.USER_LOGGING_IN,
    });
    axios
      .post(`/api/v1/auth/login`, {
        email,
        password,
      })
      .then(function(response) {
        if (response.data.id) {
          dispatch({
            type: constants.USER_LOGGED_IN,
            payload: response.data,
          });
          // toastr.success('Login', 'Fez login com sucesso');
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os seus dados");

        dispatch({
          type: constants.USER_LOGGED_IN_ERROR,
        });
        console.log(response);
      });
  };
}

export function refreshToken(dispatch, getState) {
  console.log("need to build login Modal for now redirecting");
  window.location = "/logout";
}

export function logout() {
  return {
    type: constants.USER_LOGGED_OUT,
  };
}

export function resetUser() {
  return {
    type: constants.RESETUSER,
  };
}

export function setUserInitData(id) {
  return {
    type: constants.SET_USER_INIT_DATA,
    payload: id,
  };
}
export function setTeamInitData(id) {
  return {
    type: constants.SET_TEAM_INIT_DATA,
    payload: id,
  };
}
export function setGrowerInitData(id) {
  return {
    type: constants.SET_GROWER_INIT_DATA,
    payload: id,
  };
}

export function searchGeoLocation(urn = "") {
  //rua or porta
  return (dispatch) => {
    dispatch({
      type: constants.SEARCH_GEOLOCATION,
    });

    axios
      .get(`/api/v1/contacts/searchGeolocation/${urn}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.GOT_GEOLOCATION,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as equias");
      });
  };
}
export function searchAddress(type = "rua", adress) {
  //rua or porta
  return (dispatch) => {
    dispatch({
      type: constants.SEARCH_ADDRESS,
    });

    axios
      .get(`/api/v1/contacts/searchAddress/${type}/${adress}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.GOT_ADDRESS,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as equias");
      });
  };
}

export function getTeams() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING_TEAMS,
    });

    axios
      .get(`/api/v1/users/teams`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_TEAMS,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as equias");
      });
  };
}

export function getUsers() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    axios
      .get(`/api/v1/users`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // //toastr.error("Ocorreu um erro a obter os utilizadores");
      });
  };
}

export function getGrowers(
  status,
  growerType,
  startDate,
  endDate,
  freguesia,
  selected
) {
  return (dispatch) => {
    getPureGrowers(
      dispatch,
      status,
      growerType,
      startDate,
      endDate,
      freguesia,
      selected
    );
  };
}

export function getSocioEconomics(
  status,
  growerType,
  startDate,
  endDate,
  freguesia,
  selected
) {
  return (dispatch) => {
    getPureGrowers(
      dispatch,
      status,
      growerType,
      startDate,
      endDate,
      freguesia,
      selected,
      true
    );
  };
}

export function getPureGrowers(
  dispatch,
  status,
  growerType,
  startDate,
  endDate,
  freguesia,
  selected,
  socioEconomics
) {
  dispatch({
    type: constants.GETTING_GROWERS,
  });
  const search = store.getState().app.search;
  const user = store.getState().user;
  let statusQuery = "all";
  if (status && Array.isArray(status) && status.length > 0) {
    statusQuery = status.join(",");
  } else if (search.ustatus && search.ustatus.length > 0) {
    statusQuery = search.ustatus.join(",");
  } else if (status === "0") {
    statusQuery = status;
  }

  axios
    .get(
      `/api/v1/contacts/${growerType || search.growerType}/${encodeURI(
        statusQuery
      )}/${encodeURI(
        selected || search.selected
          ? "all"
          : freguesia || search.freguesia || "all"
      )}/${encodeURI(selected || search.selected || "all")}/${encodeURI(
        startDate || user.startDate
      )}/${encodeURI(endDate || user.endDate)}/${
        socioEconomics ? "true" : "false"
      }`
    )
    .then(function(response) {
      if (response.data.msg) {
        const data = JSON.parse(response.data.msg || "[]");
        dispatch({
          type: constants.RECEIVED_GROWERS,
          payload: data,
        });
      }
    })
    .catch(function(response) {
      // //toastr.error("Ocorreu um erro a obter os utilizadores");
    });
}

export function getGrowersByRankPure(dispatch, active, limit) {
  dispatch({
    type: constants.GETTING_GROWERS,
  });
  const search = store.getState().app.search;
  let status = encodeURI(search.ustatus);
  if (active == "ativo") status = [0, 9, 10, 11];
  if (active == "transfers") status = [7];
  if (search.transfers) status = [7];
  if (status.length < 1) status = "all";
  axios
    .get(
      `/api/v1/rankings/${search.rankingType || "0"}/${encodeURI(
        search.selected || "all"
      )}/${Array.isArray(status) ? status.join(",") : status}/${encodeURI(
        limit || "all"
      )}/${encodeURI(search.education)}`
    )
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.RECEIVED_GROWERS_LIST,
          payload: response.data.msg,
        });
      }
    })
    .catch(function(response) {
      // //toastr.error("Ocorreu um erro a obter os utilizadores");
    });
}

export function getGrowersByRank(active, limit) {
  return (dispatch) => {
    getGrowersByRankPure(dispatch, active, limit);
  };
}

export function calcFreguesiaRanks() {
  return (dispatch) => {
    const search = store.getState().app.search;
    const growersList = store.getState().user.growersList;
    toastr.info(`A calcular rankings para ${search.freguesia} ...`, {
      timeOut: (growersList.length || 0) * 10000,
      removeOnHover: false,
    });
    axios
      .get(
        // `/api/v1/rankings/calc/${encodeURI(
        //   search.freguesia
        // )}/${search.growerType || "0"}`

        `/api/v1/rankings/calc/all/${search.growerType || "0"}`
      )
      .then(function(response) {
        if (response.data.msg) {
          if (response.data.msg === "done") {
            getPureGrowers(dispatch);
          }
          toastr.success("Rankings recalculados");
          // dispatch({
          //   type: constants.RECEIVED_GROWERS,
          //   payload: response.data.msg
          // });
        }
      })
      .catch(function(response) {
        // //toastr.error("Ocorreu um erro a obter os utilizadores");
      });
  };
}

export function getGrowerByParcel() {
  return (dispatch) => {
    getGrowerByParcelPure(dispatch);
  };
}

export function getGrowerByParcelPure(dispatch) {
  const pid = store.getState().app.search.selected;
  if (!pid) return null;
  dispatch({
    type: constants.GETTING,
  });
  axios
    .get(`/api/v1/contacts/pid/${pid}`)
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.RECEIVED_GROWERS,
          payload: response.data.msg,
        });
      }
    })
    .catch(function(response) {
      // //toastr.error("Ocorreu um erro a obter os utilizadores");
    })
    .finally(() =>
      dispatch({
        type: constants.DONE,
      })
    );
}

export function getGrowerHistory(id = 0) {
  return (dispatch) => {
    // dispatch({
    //   type: constants.GROWER_HISTORY_GETTING
    // });
    axios
      .get(`/api/v1/relations/history/${id}`)
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.GROWER_HISTORY_RECEIVED,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };
}

export function getAllGrowersRelations(id = 0) {
  return (dispatch) => {
    dispatch({
      type: constants.GROWER_RELATION_GETTING,
    });
    axios
      .get(`/api/v1/relations/all/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.GROWER_RELATION_RECEIVED,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };
}

export function getGrowersRelations(id = 0) {
  return (dispatch) => {
    dispatch({
      type: constants.GROWER_RELATION_GETTING,
    });
    axios
      .get(`/api/v1/relations/grower/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.GROWER_RELATION_RECEIVED,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        console.log(response);
      });
  };
}

export function getGrower(id) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    axios
      .get(`/api/v1/contacts/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_GROWER,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // //toastr.error("Ocorreu um erro a obter os utilizador");
      })
      .finally(() =>
        dispatch({
          type: constants.DONE,
        })
      );
  };
}
export function getUser(id) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    axios
      .get(`/api/v1/users/${id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_USER,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // //toastr.error("Ocorreu um erro a obter os utilizador");
      })
      .finally(() =>
        dispatch({
          type: constants.DONE,
        })
      );
  };
}
export function toggleUserStatus() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });
    const userData = store.getState().user.editData;
    const id = store.getState().user.id;
    axios
      .post(`/api/v1/users/status/${id}`, {
        status: userData.status === 1 ? 0 : 1,
      })
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.SAVED,
            payload: response.data.msg,
          });
          toastr.success("Utilizador", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}
export function toggleTeamStatus() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });
    const userData = store.getState().user.editData;
    const id = store.getState().user.id;
    axios
      .post(`/api/v1/users/teams/status/${id}`, {
        status: userData.status === 1 ? 0 : 1,
      })
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.SAVED,
            payload: response.data.msg,
          });
          toastr.success("Equipa", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}

export function setTeam() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    const editData = store.getState().user.editData;
    const id = store.getState().user.id;

    axios
      .post(`/api/v1/users/teams/${id}`, {
        label: editData.label,
        external: editData.type === "external",
      })
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.SAVED,
            payload: response.data.msg,
          });
          toastr.success("Equipas", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}

export function setUser() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    const editData = store.getState().user.editData;
    const id = store.getState().user.id;

    axios
      .post(`/api/v1/users/${id}`, {
        name: editData.name,
        email: editData.email,
        external: editData.external,
        password: editData.password,
        admin: editData.admin,
        team: editData.Team,
        teams: editData.teams,
        permissions: editData.permissions,
        parks: editData.parks,
      })
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.SAVED,
            payload: response.data.msg,
          });
          toastr.success("Utilizador", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}

export function setGrower(gid) {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    const editData = store.getState().user.editGrowerData;
    const id = store.getState().user.id;

    axios
      .post(`/api/v1/contacts/${gid || id}`, editData)
      .then(function(response) {
        if (
          response.data &&
          response.data.result &&
          response.data.result.status === "NOK"
        ) {
          toastr.error("Ocorreu um erro a salvar o utilizador");
        } else if (response.data) {
          dispatch({
            type: constants.SAVED,
            payload: response.data.result,
          });
          // dispatch({
          //   type: constants.RECEIVED_GROWER,
          //   payload: response.data.result,
          // });
          toastr.success("Utilizador", "Salvo com sucesso");
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a salvar o utilizador");
      });
  };
}

export function getRankginsById(uid) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING_USER_RANKINGS,
    });
    const id = store.getState().user.id;

    axios
      .get(`/api/v1/rankings/${uid || id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_USER_RANKINGS,
            payload: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as equias");
      });
  };
}
