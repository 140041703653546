import React, { useEffect, useState } from "react";

import { MenuItem } from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";

import { Tooltip } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import GroupedButton from "../../components/Buttons/GroupedButton";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import LocationFilter from "../../components/Filters/LocationFilter";
import LocationHistory from "../../components/Filters/LocationHistory";
import FullScreenModal from "../../components/Layout/FullScreenModal";
import EditElement from "../Georeference/modals/EditElement";

import MenuIcon from "../../components/Buttons/MenuIcon";
import Sidebar from "../../components/Layout/Sidebar";
// import SidebarList from '../../components/Layout/SidebarList';
import Content from "../../components/Layout/Content";
import LocalReportTable from "../../components/Table/LocalReportTable";
import ParkReportTable from "../../components/Table/ParkReportTable";
import RelationsTable from "../../components/Table/RelationsTable";
import ReportsTable from "../../components/Table/ReportsTable";
import PageTitle from "../../components/Text/PageTitle";
import {
  toggleViewInspection,
  toggleViewInterventions,
  setSearch as setSearchApp,
} from "../App/actions";
import ViewReport from "./ViewReport";

import { setActiveType, submitTeamReports } from "./actions";

import {
  Close,
  LocationTitle,
  Section,
} from "../../components/InspMain/styled";

import { getCurrentDate } from "../../services/utils";
import {
  FilterList,
  MaintenanceFilter,
  StyledMainButton,
  StyledWrap,
  TableWrap,
} from "./styled";

const Reports = (props) => {
  const [state, setState] = useState({
    type: "inspections",
    elementsGeoJson: props.elementsGeoJson,
    elementSelected: props.elementSelected,
    role: props.role,
    list: props.list,
    menuData: props.menuData,
    menuInspections: props.menuInspections,
    menuInterventions: props.menuInterventions,
    typeOfElements: 1,
  });
  const [parksHistory, setParksHistory] = useState([]);
  const [elementsHistory, setElementsHistory] = useState([]);
  const [elementsWithRelation, setElementsWithRelation] = useState([]);
  const [viewElement, setViewElement] = useState(false);

  const [search, setSearch] = useState({
    element: "",
    selected: props.selected || "",
    freguesia: props.freguesia || "",
    startDate: getCurrentDate(true),
    endDate: props.endDate || getCurrentDate(),
    status: "all",
    elementType: "",
    elementSelected: "",
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (state.type === "elements") {
      getElementsData();
      if (state.typeOfElements === 2) {
        setState({ ...state, typeOfElements: 1 });
      }
    } else {
      getReports(state.type);
    }
    return () => {};
  }, [state.type, search]);

  const getElementsData = async () => {
    try {
      const resultElements = await axios.get(
        `api/v1/elements/relations/${search.freguesia ||
          "all"}/${search.selected || "all"}`
      );
      setElementsWithRelation(resultElements.data.elements);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getParkHistory = (local, date) => {
    axios
      .get(`api/v1/parks/inactive`)
      .then(function(response) {
        if (response.data.msg) {
          setParksHistory(response.data.inactiveParks);
          setElementsHistory(response.data.inactiveElements);
        } else {
          toastr.error("Ocorreu um erro a obter histórico de parcelas");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a obter histórico de parcelas");
      });
  };

  const getReports = (type, team) => {
    let parks = "all";
    if (search?.selected?.length > 0) parks = search?.selected || "all";

    if (state.typeOfElements === 2) {
      if (!search?.selected) {
        parks = parksHistory.map((p) => p.pid).join(",") || "all";
      }
    }

    const url = [
      `api/v1/reports`,
      encodeURI(team || "all"),
      encodeURI(type || "all"),
      encodeURI(search.freguesia || "all"),
      encodeURI("all"),
      encodeURI(parks),
      encodeURI(search.startDate),
      encodeURI(search.endDate),
      encodeURI(search.status),
    ];
    axios
      .get(url.join("/"))
      .then(function(response) {
        if (response.data.msg && response.data.msg.data) {
          setTableData(response.data.msg.data);
        } else {
          toastr.error("Ocorreu um erro a obter a informação");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a obter a informação");
      });
  };

  const goToDetail = (id) => {
    if (state.type === "elements") {
      const selectedElement = elementsWithRelation.find((element) => {
        return element.id === id;
      });
      //lets change serach to include this pidRaw
      handleSearchChange({
        target: { name: "selected", value: selectedElement["pidRaw"] },
      });
      // props.setSearchApp(
      //   {
      //     element: ["selected"],
      //     value: selectedElement["pidRaw"],
      //   },
      //   true
      // );
      setViewElement(selectedElement);
    }
    const elementFound = tableData.find((element) => {
      return element.id === id;
    });

    if (!elementFound) return false;
    if (elementFound.instanceType === "inspections") {
      // setSearch({ ...search, group: id });
      props.toggleViewInspection(id);
    } else if (elementFound.instanceType === "interventions") {
      // setSearch({ ...search, group: id });

      // console.log(id);
      props.toggleViewInterventions(id);
    }
  };

  const handleSearchChange = (e, fetch) => {
    if (e.target.name === "freguesia" && e.target.value === "") {
      setSearch({
        ...search,
        [e.target.name]: e.target.value,
        selected: "",
        elementSelected: "",
      });
    } else if (e.target.name === "selected" && e.target.value === "") {
      setSearch({
        ...search,
        [e.target.name]: e.target.value,
        elementSelected: "",
      });
    } else {
      setSearch({ ...search, [e.target.name]: e.target.value });
    }
    props.setSearchApp(
      {
        element: [e.target.name],
        value: e.target.value,
      },
      !fetch
    );
    getReports(state.type);
  };

  const preProcessData = () => {
    const { elementSelected } = search;

    let data = state.type === "elements" ? elementsWithRelation : tableData;
    let processedData = tableData;

    processedData = data.map((row) => {
      const selectedPark = props.list.find(
        (park) => park.numero === row["pid"]
      );
      if (!selectedPark) return row;
      row["pidRaw"] = row["pid"];
      row["pid"] = selectedPark["número_ant"];
      return row;
    });

    processedData = processedData.filter((row) => {
      let include = true;

      if (state.typeOfElements === 2 && elementSelected) {
        include = false;
        //check if element is in list of inactive elements
        if (
          elementsHistory.length > 0 &&
          (row["Element.id"] || row["Element.origin"])
        ) {
          const elementFound = elementsHistory.find(
            (e) => e.id === row["Element.id"]
          );

          if (elementSelected && elementSelected !== row["Element.id"]) {
            include = false;
          } else if (elementFound) {
            include = true;
          }
        }
      } else {
        // check if element is in selection
        if (
          (elementSelected && !row["Element.id"]) ||
          (row["Element.id"] &&
            elementSelected &&
            elementSelected !== row["Element.id"])
        ) {
          include = false;
        }
      }

      // for external providers
      // if (
      //   state.include !== "all" &&
      //   (state.type !== "readings" || state.type !== "reports")
      // ) {
      //   /// everything is excluded by default so lets check if we have a true value to not diplae
      //   // 0 = internals or flase
      //   // 1 = external or excluded
      //   if (state.include !== 1 && row["include"]) {
      //     include = false;
      //   } else if (row["include"] && state.include === 1) {
      //     include = false;
      //   }
      // }
      return include;
    });

    // custom fields rating transformation
    processedData = processedData.map((row) => {
      let newRowElements = {};
      row.teams =
        row.teams &&
        row.teams.map((t) => {
          const thisTeam = props.teams.find((team) => team.id === t);
          if (thisTeam && thisTeam.length > 0) {
            return thisTeam.label;
          }
          return t;
        });

      if (row.attr && row.attr.rating) {
        // console.log('adding rating', row.attr.rating);
        return { ...row, rating: row.attr.rating };
      }
      if (row.attr && row.attr.customFields) {
        Object.keys(row.attr.customFields).map((cf) => {
          newRowElements[`cf_${cf}`] = row.attr.customFields[cf];
          return cf;
        });
        return { ...row, ...newRowElements };
      }

      return row;
    });

    return processedData;
  };

  const tableSwitch = () => {
    if (state.type === "reports") {
      return (
        <ReportsTable
          orderBy={"id"}
          data={preProcessData()}
          click={goToDetail}
        />
      );
    } else if (state.type === "elements") {
      return (
        <RelationsTable
          orderBy={"id"}
          data={preProcessData()}
          click={goToDetail}
        />
      );
    } else if (props?.selected?.length > 0) {
      return (
        <ParkReportTable
          orderBy={"id"}
          data={preProcessData()}
          type={state.type}
          click={goToDetail}
        />
      );
    } else {
      return (
        <LocalReportTable
          orderBy={"id"}
          data={preProcessData()}
          type={state.type}
          click={goToDetail}
        />
      );
    }
  };

  const {
    list,
    menuData,
    menuInterventions,
    menuInspections,
    elementSelected,
    element,
    elementsGeoJson,
  } = props;

  let elementData = {};
  if (
    elementsHistory.length > 0 &&
    state.showHistory &&
    state.typeOfElements === 2
  ) {
    elementData = elementsHistory.find(
      (e) =>
        e.id === search.elementSelected || e.origin === search.elementSelected
    );
  } else if (elementSelected && elementSelected !== "" && list) {
    elementData = elementsGeoJson.find(
      (e) =>
        e?.properties?.id === elementSelected ||
        e?.properties?.origin === elementSelected
    )?.properties;
    //add park name to elementData
    if (elementData) {
      const selectedPark = list.find(
        (park) => park.numero === elementData.numero
      );
      elementData.parkName = selectedPark?.nome;
    }
  }

  return (
    <>
      <StyledWrap>
        {/*<MobileLayoutToggle />*/}
        <Sidebar
          title={menuData.name}
          text={`gerir ${menuData.name}`}
          icon={"graph"}
          noanimation
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="maintenance-filter"
              contentClassName="maintenance-filter-content"
              horizontal={false}
            >
              <ButtonGroup>
                <GroupedButton
                  width={"33%"}
                  onClick={() =>
                    setState({
                      ...state,
                      type: "inspections",
                    })
                  }
                  active={state.type === "inspections"}
                >
                  <MenuIcon
                    theme={state.type === "inspections"}
                    className={`icon-${menuInspections.icon} icons`}
                  />
                </GroupedButton>
                <GroupedButton
                  width={"33%"}
                  onClick={() =>
                    setState({
                      ...state,
                      type: "interventions",
                    })
                  }
                  active={state.type === "interventions"}
                >
                  <MenuIcon
                    theme={state.type === "interventions"}
                    className={`icon-${menuInterventions.icon} icons`}
                  />
                </GroupedButton>

                <GroupedButton
                  width={"33%"}
                  onClick={() =>
                    setState({
                      ...state,
                      type: "elements",
                    })
                  }
                  active={state.type === "elements"}
                >
                  <MenuIcon
                    theme={state.type === "elements"}
                    className={`icon-share-alt icons`}
                  />{" "}
                </GroupedButton>
              </ButtonGroup>
              <div
                style={{
                  marginBottom: "1em",
                }}
              >
                {state.type !== "elements" && (
                  <MaintenanceFilter
                    fullWidth
                    name={"status"}
                    value={state.typeOfElements}
                    label={"Estado do elemento"}
                    onChange={(e) => {
                      setState({
                        ...state,
                        typeOfElements: e.target.value,
                      });
                      setSearch({
                        ...search,
                        selected: "",
                        elementSelected: "",
                        freguesia: "",
                      });
                      if (e.target.value === 2) {
                        getParkHistory();
                      }
                    }}
                    inputProps={{
                      name: "status",
                      id: "status",
                    }}
                  >
                    <MenuItem value={1}>{"Ativos"}</MenuItem>
                    <MenuItem value={2}>{"Inactivo"}</MenuItem>
                  </MaintenanceFilter>
                )}
              </div>
              {/* <SectionTitle>Parcelas</SectionTitle> */}
              {state.typeOfElements === 1 ? (
                <LocationFilter
                  hideElements
                  handleSearchChange={handleSearchChange}
                  override={search}
                />
              ) : (
                <></>
              )}
              {state.typeOfElements === 2 ? (
                <>
                  <LocationHistory
                    parksHistory={parksHistory}
                    elementsHistory={elementsHistory}
                    handleSearchChange={handleSearchChange}
                    override={search}
                  />
                </>
              ) : (
                <></>
              )}
              {search.elementSelected &&
                search.elementSelected !== "" &&
                state.type !== "elements" && (
                  <StyledMainButton
                    onClick={() =>
                      setState({
                        ...state,
                        showHistory: true,
                      })
                    }
                  >
                    <MenuIcon className={`icon-doc icons`} /> Abrir histórico
                  </StyledMainButton>
                )}
              {state.type !== "elements" && (
                <DateRangeFilter
                  handleSearchChange={handleSearchChange}
                  endDate={search.endDate}
                  startDate={search.startDate}
                />
              )}
            </ScrollArea>
          </FilterList>
        </Sidebar>
        <Content size={2} color={"white"} map={true}>
          <TableWrap display={"display"}>
            {/* <TableWrap display={props.viewType ? "display" : "hide"}> */}
            {list && tableSwitch()}
          </TableWrap>
        </Content>
      </StyledWrap>
      {viewElement && (
        <EditElement
          closeModal={() => {
            setViewElement(false);

            props.setSearchApp(
              {
                element: "element",
                value: "",
              },
              true
            );
            props.setSearchApp(
              {
                element: "selected",
                value: "",
              },
              true
            );
          }}
          // getElements={getElements}
          elementOverride={viewElement}
          view
        />
      )}

      {state.showHistory && (
        <FullScreenModal>
          <Tooltip title="Fechar">
            <Close
              onClick={() => {
                setState({
                  ...state,
                  showHistory: false,
                });
                props.setSearchApp(
                  {
                    element: "element",
                    value: "",
                  },
                  true
                );
                props.setSearchApp(
                  {
                    element: "selected",
                    value: "",
                  },
                  true
                );
              }}
              className={`icon-close icons`}
            />
          </Tooltip>
          <Section>
            <PageTitle>
              {elementData?.nome || elementData?.parkName}
              <LocationTitle>
                {elementData?.local ? `${elementData?.local} ,` : ""}
                {elementData?.label}
              </LocationTitle>
            </PageTitle>
            <ViewReport
              // selectedParkId={selectedPark.pid}
              selectedElementID={search.elementSelected}
            />
          </Section>
        </FullScreenModal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  elementsGeoJson: state.app.elementsGeoJson,
  startDate: state.app.search.startDate,
  selected: state.app.search.selected,
  elementSelected: state.app.search.element,
  freguesia: state.app.search.freguesia,
  endDate: state.app.search.endDate,
  role: state.user.data.role,
  list: state.parklist.list,
  menuData:
    state.app.menus[state.user.data.role] &&
    state.app.menus[state.user.data.role].find(
      (menu) => menu.path === `/reports`
    ),
  menuInspections:
    state.app.menus[state.user.data.role] &&
    state.app.menus[state.user.data.role].find(
      (menu) => menu.path === `/inspections`
    ),
  menuInterventions:
    state.app.menus[state.user.data.role] &&
    state.app.menus[state.user.data.role].find(
      (menu) => menu.path === `/interventions`
    ),
});

const mapDispatchToProps = {
  submitTeamReports,
  setActiveType,
  toggleViewInspection,
  toggleViewInterventions,
  setSearchApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
