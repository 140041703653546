import React, { Component } from "react";
import PropTypes from "prop-types";

import { TextField, FormControl } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import styled from "styled-components";
const FormWrapper = styled.div`
  && {
    label {
    }
    > div {
      textarea {
      }
      label[data-shrink="true"] {
        color: #4da1ff;
      }
      > div {
        &:after {
          border-bottom: 2px solid #4da1ff !important;
        }
        &:hover {
          &:before {
            border-bottom: 2px solid #4da1ff !important;
          }
        }
      }
    }
  }
`;

export default class Atcomplete extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    input: {},
  };

  render() {
    const { label, className, list, input, ...custom } = this.props;
    return (
      <FormWrapper>
        {/* <InputLabel className={className} error={invalid} htmlFor={label}>
            {label}
          </InputLabel> */}
        <Autocomplete
          disablePortal
          id={label}
          options={list}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              list={list}
              className={className}
              id={label}
              style={{ width: "100%" }}
              label={label}
              variant="outlined"
              margin="dense"
              {...input}
              {...custom}
            />
          )}
          {...input}
          {...custom}
          // className={className}
          // id={label}
          // label={label}
          // variant="outlined"
          // margin="dense"
          // {...input}
          // {...custom}
        />
      </FormWrapper>
    );
  }
}
