import { Chip } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "./Table";
import { store } from "../../store/configure";

export default function(props) {
  const [groups, setGroups] = useState({});
  const [data, setData] = useState([]);
  //get geo elements
  const parks = store.getState().parklist.list;

  const processedData = (data) => {
    const messageGroups = {};
    const outputData = data
      .map((row) => {
        if (row.group) {
          messageGroups[row.group] = (messageGroups[row.group] || 0) + 1;
          return { type: "duplicated", group: row.group };
        } else {
          messageGroups[row.id] = (messageGroups[row.id] || 0) + 1;
          return row;
        }
      })
      .filter((row) => row.type !== "duplicated");
    setGroups(messageGroups);
    setData(outputData);
  };

  useEffect(() => {
    processedData(props.data);
  }, [props.data]);

  const columnData = [
    {
      id: "type",
      name: "type",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = data[tableMeta.rowIndex];
          switch (thisData.type) {
            case "0":
              return "Telefone";
            case "1":
              return "Comunicação";
            case "2":
              return "Carta";
            case "3":
              return "SMS";
            case "imported":
              return "Importado";
            default:
              return "";
          }
        },
      },
    },
    {
      id: "id",
      name: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      options: {
        display: false,
      },
    },

    {
      id: "Contact.contacts",
      name: "Contact.contacts",
      numeric: false,
      disablePadding: false,
      label: "Hortelões",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = data[tableMeta.rowIndex];

          if (thisData?.Contact?.name) {
            return (
              <Chip label={`${thisData.Contact.name} `} color={"primary"} />
            );
          }

          return (
            <Chip
              label={`${thisData?.contacts?.length || 0} `}
              color={"primary"}
            />
          );
        },
      },
    },
    {
      id: "group",
      name: "group",
      numeric: false,
      disablePadding: false,
      label: "Mensagens",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = data[tableMeta.rowIndex];
          return <Chip label={`${groups[thisData.id] || 1} `} />;
        },
      },
    },
    {
      id: "element",
      name: "element",
      numeric: false,
      disablePadding: false,
      label: "Elemento",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = data[tableMeta.rowIndex];
          if (!thisData?.Element?.pid) return;

          const thisPark = parks.find(
            (p) => p.numero === thisData?.Element?.pid
          );
          const elementText = `${thisPark?.número_ant} ${thisData?.Element?.ElementType?.label}`;

          return <Chip label={`${elementText} `} />;
        },
      },
    },
    {
      id: "title",
      name: "title",
      numeric: false,
      disablePadding: false,
      label: "Assunto",
    },
    // {
    //   id: "message",
    //   name: "message",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Mensagem",
    //   options: {
    //     filter: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const thisData = data[tableMeta.rowIndex];
    //       if (thisData.message && thisData.message.length > 100) {
    //         return thisData.message.substring(0, 100);
    //       } else {
    //         return thisData.message;
    //       }
    //     },
    //   },
    // },
    {
      id: "sendDate",
      name: "sendDate",
      numeric: false,
      disablePadding: false,
      label: "Data Envio",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = data[tableMeta.rowIndex];
          return moment(thisData.sendDate || thisData.date).format(
            "YYYY-MM-DD HH:mm"
          );
        },
      },
    },
    {
      id: "status",
      name: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = data[tableMeta.rowIndex];
          if (thisData.type === "imported") {
            return (
              <Chip
                label={"processado"}
                // label={thisData.status == 0 ? "aberto" : "fechado"}
                color={"default"}
              />
            );
          }

          switch (thisData.status) {
            case 0:
              return <Chip label={"Por Enviar"} color={"secondary"} />;
            case 1:
              return <Chip label={"Ativo"} color={"secondary"} />;
            case 2:
              return <Chip label={"Enviado"} color={"secondary"} />;
            case 3:
              return <Chip label={"Desativo"} color={"secondary"} />;
            default:
              return "";
          }
        },
      },
    },
    {
      id: "User.name",
      numeric: false,
      disablePadding: false,
      label: "User",
      name: "User",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = data[tableMeta.rowIndex];
          return thisData.User ? thisData.User.name : "";
        },
      },
    },
  ];

  return (
    <Table
      orderBy={"id"}
      data={data}
      clickWithData={props.click}
      header={columnData}
    />
  );
}
