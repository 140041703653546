import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { MenuItem } from "@material-ui/core";
import Select from "../../components/form-wrappers/Select";
import { debounce } from "lodash";

import { apiAddress } from "../../config/general";

import View from "./View";

import Sidebar from "../../components/Layout/Sidebar";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import AddActionButtons from "../../components/Buttons/AddActionButtons";

import DateRangeFilter from "../../components/Filters/DateRangeFilter";

import { handleDate } from "../../services/utils";

import { addNewToggle, getMaintenances } from "./actions";
import { setSearch, setActivePark } from "../App/actions";

import {
  StyledWrap,
  SectionFilter,
  FilterList,
} from "../../components/InspMain/styled";

const Card = styled.div`
  && {
    /* base: */
    background: #ffffff;
    border: 1px solid #e9eff4;
    border-radius: 6px;
    padding: 1em;
    margin: 1em 0;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;

    .photo {
      height: 150px;
      display: none;
      margin: -16px -16px 16px -16px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    &:hover {
      /* base: */
      background: rgba(74, 144, 226, 0.75);
      border: 1px solid #e9eff4;
      color: #fff;
      .date,
      .damages,
      .details {
        color: #fff;
      }
      .photo {
        display: block;
      }
      .parque,
      .element,
      .type {
        color: rgba(255, 255, 255, 0.4);
      }
    }
    &.Danos {
      border-left: 3px solid #eb3d3d;
      &:hover {
        background: #eb3d3d;
      }
    }
    &.Inicial {
      border-left: 3px solid #ebcd3d;
      &:hover {
        background: #ebcd3d;
      }
    }
    &.Final {
      border-left: 3px solid #6fc44c;
      &:hover {
        background: #6fc44c;
      }
    }
    .element {
      text-align: right;
      font-size: 0.8em;
      display: inline-block;
      color: rgba(74, 144, 226, 0.6);
    }
    .parque {
      font-size: 0.8em;
      text-align: right;
      display: block;
      color: rgba(74, 144, 226, 0.3);
      margin-bottom: 0.5em;
    }
    .type {
      display: inline-block;
      padding: 5px;
      position: absolute;
      right: 0.5em;
      img {
        width: 20px;
        opacity: 0.7;
      }
    }
    .details {
      font-size: 0.8em;
      display: block;
      color: #989898;
    }
    .damages {
      font-size: 0.8em;
      margin-bottom: 0.5em;
      display: block;
      font-weight: bold;
      color: rgba(74, 144, 226, 1);
    }
    .date,
    .name {
      /* 11:00 AM - 11:30 AM: */
      margin-top: 1em;
      opacity: 0.7;
      font-size: 0.7em;
      color: rgba(74, 144, 226, 0.8);
      letter-spacing: -0.32px;
      display: inline-block;
      text-align: right;
    }

    .name {
      font-weight: bold;
      text-align: left;
    }
    .goToPark {
      background: #fff;
      display: block;
      border-top: 1px solid #e9eff4;
      color: #cfd1db;
      padding: 0.6em 1em;
      font-size: 0.7em;
      margin: 1em -1.4em -1.5em;
      border-radius: 0 0 5px 5px;
      :hover {
        color: #fff;
        background: rgba(74, 144, 226, 0.8);
      }
    }
  }
`;

const MaintenanceFilter = styled(Select)`
  && {
    border: 0;
    font-size: 0.9em;
    color: #a3a1a1;
  }
`;

class Maintenances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImageId: null,
      selectedElement: null,
    };

    this.toggleActiveInspection = this.toggleActiveInspection.bind(this);
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.goToMaintenanceDetail = this.goToMaintenanceDetail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.getMaintenances = debounce(props.getMaintenances, 600);
    this.setSearch = debounce(props.setSearch, 600);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.setActivePark = this.setActivePark.bind(this);
  }

  componentDidMount(nextProps) {
    // this.toggleActiveInspection('preventiva');
    if (!this.props.match.params.pid) this.props.getMaintenances();
    this.setSearch({
      element: "edit",
      value: null,
    });
  }

  componentDidUpdate(nextProps) {
    //if Edit
    if (
      this.props.match.params.id &&
      this.props.edit !== this.props.match.params.id
    ) {
      this.setSearch({
        element: "edit",
        value: this.props.match.params.id,
      });
    }
    if (
      this.props.appStatus === "searching" ||
      this.props.appStatus === "searchUpdated"
    ) {
      // this.getMaintenances();
    }
  }

  handleChange(e, value) {
    this.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });
  }

  handleSearchChange(e, value) {
    this.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });
    // this.getMaintenances();
  }

  setActivePark(park, type) {
    this.props.setActivePark(
      park.properties.numero || this.props.numero,
      park.properties.local || this.props.local,
      park.properties.freguesia || this.props.freguesia,
      type === "element" && !this.props.element
        ? park.properties.origin || park.properties.id
        : this.props.element // custom null for set element clear
        ? -1
        : null,
      true,
      true
    );

    if (type !== "element" && !this.props.selected) {
      this.setState({ selectedElement: null });
      // this.getMaintenances();
    } else if (type !== "element") {
      this.setState({ selectedElement: null });
      // this.getMaintenances();
      // this.toggleAddNew();
    } else {
      this.setState({ selectedElement: park.properties });
      // this.toggleAddNew();
    }
  }

  toggleActiveInspection(element) {
    this.setSearch({ element: "active", value: element });
    // this.getMaintenances();
  }

  closeAddModal() {
    this.props.addNewToggle();
  }

  toggleAddNew(local, state) {
    let localId = local; //from url

    if (local && local.properties) {
      localId = local.properties.numero;
    } else if (!local && this.props.selected) {
      localId = this.props.selected;
    }

    this.props.addNewToggle(localId, state);
  }

  preprocessMaintenances(maintenances) {
    // lets filter maintenances when element is selected
    if (this.props.element) {
      return maintenances.filter((main) => {
        return main.ElementId && main.ElementId === this.props.element;
      });
    } else if (this.props.status !== "all") {
      return maintenances.filter((insp) => {
        return insp.status === this.props.status;
      });
    }
    return maintenances;
  }

  renderCard(maintenance, i) {
    const thisPID =
      maintenance.Inspection && maintenance.Inspection.pid
        ? maintenance.Inspection.pid
        : maintenance.pid;
    const selectedPark = this.props.list.find(
      (park) => park.numero === thisPID
    );

    let parkName = "";

    if (selectedPark) {
      parkName = selectedPark.nome;
    }

    const photo =
      maintenance.Inspection &&
      maintenance.Inspection.photos &&
      maintenance.Inspection.photos.length > 0
        ? maintenance.Inspection.photos[0]
        : maintenance.files && maintenance.files[0];
    return (
      <Card
        className={`animated fadeInUp ${maintenance.type}`}
        key={i}
        onMouseOver={() => this.setState({ previewImageId: i })}
      >
        <div
          onClick={() =>
            this.goToMaintenanceDetail(null, maintenance.id, maintenance.group)
          }
        >
          {photo && this.state.previewImageId === i && (
            <span
              className={"photo"}
              style={{ backgroundImage: `url(${apiAddress}${photo.path})` }}
            />
          )}

          <span className={"parque"}>
            {maintenance.Element && maintenance.Element.ElementType && (
              <span className={"element"}>
                {maintenance.Element.ElementType.label}
              </span>
            )}

            {parkName}
          </span>
          {maintenance.Intervention && (
            <span className={"damages"}>{maintenance.Intervention.label}</span>
          )}
          {maintenance.Inspection && maintenance.Inspection.Damage && (
            <span className={"damages"}>
              {maintenance.Inspection.Damage.label}
            </span>
          )}
          <span className={"details"}>
            {maintenance.details}
            {maintenance.Inspection && maintenance.Inspection.details}
          </span>
          {/* <span className={'photos'}>{maintenance.photos.lenght}</span> */}

          <span className={"name"}>{maintenance.User.name}, </span>
          <span className={"date"}>{handleDate(maintenance.date)}</span>
        </div>
        {selectedPark && selectedPark.numero && (
          <span
            className={"goToPark"}
            onClick={() => {
              selectedPark && this.setActivePark({ properties: selectedPark });
            }}
          >
            <span className={`icon-location-pin icons`} /> Ver Horta{" "}
            {selectedPark.numero}
          </span>
        )}
      </Card>
    );
  }

  goToMaintenanceDetail(event, id, group) {
    //les allways get the history
    return this.props.history.push(`/interventions/${!group ? id : group}`);
  }

  render() {
    const {
      startDate,
      endDate,
      edit,
      history,
      maintenances,
      selected,
      status,
      menuData,
      role,
      freguesia,
      local,
    } = this.props;

    const hasMaintenances = maintenances.length > 0;

    return (
      <StyledWrap>
        {/*<MobileLayoutToggle />*/}
        <Sidebar
          title={menuData.name}
          text={`${freguesia} ${local}<span> ${selected} </span>`}
          icon={menuData.icon}
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="maintenance-filter"
              contentClassName="maintenance-filter-content"
              horizontal={false}
            >
              {role !== "EV" && (
                <SectionFilter>
                  <MaintenanceFilter
                    fullWidth
                    name={"status"}
                    label={"Estado"}
                    value={status}
                    onChange={this.handleSearchChange}
                    inputProps={{
                      name: "status",
                      id: "status",
                    }}
                  >
                    <MenuItem value="all">
                      <em>Todas</em>
                    </MenuItem>
                    <MenuItem value={0}>
                      {this.props.role !== "EV" ? "Por Aprovar" : "Aberto"}
                    </MenuItem>
                    <MenuItem value={1}>
                      {this.props.role !== "EV" ? "Aprovado" : "Fechado"}
                    </MenuItem>
                  </MaintenanceFilter>
                </SectionFilter>
              )}

              {hasMaintenances && (
                <div>
                  {maintenances &&
                    this.preprocessMaintenances(maintenances).map(
                      (maintenance, index) =>
                        this.renderCard(maintenance, index)
                    )}
                </div>
              )}
            </ScrollArea>
          </FilterList>
          <DateRangeFilter
            handleSearchChange={this.handleSearchChange}
            endDate={endDate}
            startDate={startDate}
          />
        </Sidebar>
        <AddActionButtons />

        {edit && (
          <View
            group={edit}
            history={history}
            callback={this.props.match.params.callback}
          />
        )}
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      element: state.app.search.element,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      status: state.app.search.status,
      startDate: state.app.search.startDate,
      endDate: state.app.search.endDate,
      edit: state.app.search.edit,
      type: state.app.search.type,
      userWrite: state.user.data.write,

      active: state.app.search.active,
      elementType: state.app.search.elementTypeSelected,
      elmentDisplay: state.app.elmentDisplay,
      appStatus: state.app.status,

      addNew: state.maintenances.addNew,
      map: state.app.map,
      maintenances: state.maintenances.list,

      list: state.parklist.list,
      role: state.user.data.role,
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  {
    addNewToggle,
    getMaintenances,
    setSearch,
    setActivePark,
  }
)(Maintenances);
