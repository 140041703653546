import { createSelector } from "reselect";

const getElementsGeoJson = (state) => state.app.elementsGeoJson;
const getElementRelations = (state) => state.georeference.elementRelations;
const getSelectedElement = (state) => state.app.search.element;
const getGrowers = (state) => state.user.growersList;
const getSelected = (state) => state.app.search.selected;
const getParkGeoJson = (state) => state.parklist.geoJson;
const getSearchProps = (state) => state.app.search;
const getElmentDisplay = (state) => state.app.elmentDisplay;
const getInspections = (state) => state.inspections.list;

export const getCurrentElement = createSelector(
  [getElementsGeoJson, getSelectedElement],
  (elementsGeoJson, element) => {
    return elementsGeoJson.find((ele) => ele.properties.id === element);
  }
);

export const getSelectedPark = createSelector(
  [getParkGeoJson, getSelected],
  (geoJson, selected) => {
    return geoJson.find((park) => park.properties.numero === selected);
  }
);

export const getElementRelation = createSelector(
  [getElementsGeoJson, getElementRelations, getSelectedElement],
  (elementsGeoJson, ElementRelations, element) => {
    //we need to get target ids from relations
    const tagetElement = [];
    const originElement = [];
    elementsGeoJson.map((elem) => {
      return ElementRelations.map((rel) => {
        if (
          elem.properties.id === Number(rel.originId) &&
          element !== elem.properties.id
        ) {
          originElement.push(elem);
        } else if (
          elem.properties.id === Number(rel.targetId) &&
          element !== elem.properties.id
        ) {
          tagetElement.push(elem);
        }
        return true;
      });
    });

    return { tagetElement, originElement };
  }
);

export const getGeoJson = createSelector(
  [getParkGeoJson, getSearchProps],
  (geoJson, search) => {
    const { freguesia, selected, local, element } = search;
    // const { freguesia, selected, local, role } = this.props;
    if (!geoJson)
      return {
        geoJson: [],
        freguesia: [],
        local: [],
        selected: [],
      };

    const freguesiaList = [];
    const localList = [];
    const selectedList = [];

    const list = geoJson.reduce((acc, park, index) => {
      const parkInSelection = selected === park.properties.numero;
      if (element && element === park.properties.eid) {
        acc.push({
          ...park,
          properties: { ...park.properties, selected: true, color: "#fff" },
        });
        selectedList.push(park);
      } else if (selected && parkInSelection) {
        acc.push({
          ...park,
          properties: { ...park.properties, selected: true, color: "#01f4c1" },
        });
        selectedList.push(park);
      } else if (local && park.properties.local === local) {
        acc.push({
          ...park,
          properties: { ...park.properties, selected: true, color: "#4da1ff" },
        });
        localList.push(park);
      } else {
        if (
          !parkInSelection &&
          ((local && park.properties.local === local) ||
            (freguesia && park.properties.freguesia === freguesia))
        ) {
          acc.push({
            ...park,
            properties: { ...park.properties, area: true },
          });
        } else if (
          !local &&
          !parkInSelection &&
          freguesia &&
          park.properties.freguesia === freguesia
        ) {
          acc.push({
            ...park,
            properties: { ...park.properties, area: true, color: "#8400ff" },
          });
          freguesiaList.push(park);
        } else {
          acc.push({
            ...park,
            properties: { ...park.properties, color: "#8400ff" },
          });
        }
      }

      return acc;
    }, []);
    return {
      geoJson: list,
      freguesia: freguesiaList,
      local: localList,
      selected: selectedList,
    };
  }
);

export const getGeoElementJson = createSelector(
  [
    getElementsGeoJson,
    getElmentDisplay,
    getElementRelations,
    getSearchProps,
    getInspections,
    getGrowers,
  ],
  (geoJson, elmentDisplay, elementRelations, search, inspections, growers) => {
    if (!elmentDisplay) return [];
    const { freguesia, selected, local, elementTypeSelected, element } = search;
    // const { freguesia, selected, local, role } = this.props;
    if (!geoJson) return [];
    const resultGeoJson = geoJson.reduce((acc, park) => {
      const parkInSelection = selected === park.properties.numero;
      let warningAlert = false;
      const parInRelationTarget = elementRelations.find(
        (rel) =>
          (Number(rel.targetId) === park.properties.id ||
            Number(rel.targetId) === park.properties.origin) &&
          rel.type.match(/ranking/)
      );

      // lets check for last inspections rating
      // if (inspections && inspections.length > 0) {
      //   const insp = inspections[0];
      //   if (insp.attr && insp.attr.rating && insp.attr.rating < 1) {
      //     if (insp.Element && insp.Element.id) {
      //       if (
      //         insp.Element.id === park.properties.id ||
      //         insp.Element.origin === park.properties.id ||
      //         insp.Element.id === park.properties.origin ||
      //         insp.Element.origin === park.properties.origin
      //       ) {
      //         warningAlert = true;
      //       }
      //     }
      //   }
      // }

      // const parkInRelationOrigin = elementRelations.filter(
      //   rel => Number(rel.originId) === park.properties.id
      // );

      let elementSelection = false;

      if (elementTypeSelected.length === 0) {
        elementSelection = true;
      }

      if (
        elementTypeSelected.length > 0 &&
        park.properties.category &&
        elementTypeSelected.includes(park.properties.category)
      ) {
        elementSelection = true;
      }

      if (
        elementTypeSelected.length > 0 &&
        park.properties.label &&
        elementTypeSelected.includes(park.properties.label)
      ) {
        elementSelection = true;
      }

      const thisId = park.properties.origin || park.properties.id;
      //set element properties
      const addElement = {
        ...park,
        properties: { ...park.properties, color: "" },
      };
      if (warningAlert) {
        addElement.properties.color = "#ffb038";
      } else if (thisId === element || thisId === elementTypeSelected) {
        addElement.properties.color = "#ff3c3c";
        addElement.properties.selected = true;
      } else if (
        park.properties.label &&
        park.properties.label.match(/Administrativa/)
      ) {
        addElement.properties.color = "#000000";
      } else if (parInRelationTarget) {
        const grower = growers.find((g) => {
          return (
            g.id === Number(parInRelationTarget.originId) ||
            g.ContactId === Number(parInRelationTarget.originId)
          );
        });
        addElement.properties.color =
          grower && (grower.status === 6 || !grower.educationDone)
            ? "#ecf94b"
            : "#ffffff";
        addElement.properties.relation = true;
      }

      // if (parkInRelationOrigin && parkInRelationOrigin.length > 0) {
      //   addElement.properties.color = '#000000';
      //   addElement.properties.relation = true;
      // }
      // console.log(thisId, parkInRelationTarget);
      // if (
      //   parkInRelationTarget &&
      //   parkInRelationTarget.find(
      //     parkrelt => Number(parkrelt.targetId) === element
      //   )
      // ) {
      //   addElement.properties.color = '#ffffff';
      //   addElement.properties.relation = true;
      // }

      if (selected && parkInSelection && elementSelection) {
        acc.push({
          ...addElement,
        });
      }
      if (
        !parkInSelection &&
        local &&
        park.properties.local === local &&
        elementSelection
      ) {
        acc.push({ ...addElement });
      } else if (
        !local &&
        !parkInSelection &&
        freguesia &&
        elementSelection &&
        park.properties.freguesia === freguesia
      ) {
        acc.push({ ...addElement });
      }

      return acc;
    }, []);
    return resultGeoJson;
  }
);
