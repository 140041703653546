import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ListItem, ListItemIcon, List } from "@material-ui/core";

const ListContainer = styled.div`
  && {
    min-width: 80px;
    border: none;
    @media (max-width: 900px) {
      height: 65px;
      min-width: 100%;
      top: auto;
      display: flex;
    }
  }
`;

const ItemNumb = styled.span`
  && {
    background: #fff;
    right: 10px;
    font-size: 0.5em !important;
    padding: 0.5em;
    border-radius: 50%;
    position: absolute;
    color: #4192ec !important;
    display: block !important;
    min-width: 1.3em;
    @media (max-width: 900px) {
      right: auto;
      top: 7px;
    }
  }
`;

const StyledDrawer = styled.div`
  && {
    min-width: 80px;
    min-height: 100%;
    border: none;
    background: #ffffff;
    position: fixed;
    z-index: 3;

    box-shadow: rgb(0 0 0 / 22%) 6px 0px 6px;
    /* box-shadow: 4px 0 9px 0 rgba(0, 0, 0, 0.06); */
    @media (max-width: 900px) {
      min-width: 100%;
      min-height: auto;
      height: 65px;
    }
    & > div {
      border: none;
      width: 80px;
      height: 100%;
      @media (max-width: 900px) {
        bottom: 0;
        top: auto;
        height: 65px;
        width: calc(80px - 100vw);

        /* width: 100%; */
      }
    }
    @media print {
      display: none;
    }
  }
`;

const StyledListItem = styled(Link)`
  && {
    text-align: center;
    display: block;
    position: relative;
    transition: all 0.2s ease-in;
    padding: 0.5em 0;
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    i {
      color: #fff;
    }

    &:last-child {
      border-bottom: none;
    }
    span {
      display: none;
    }
    > div {
      display: block;
      margin-right: 0;
    }
    @media (min-width: 768px) {
      :hover {
        border-left: 6px solid #4192ec;
        background: rgba(65, 146, 236, 0.1);
        span {
          display: block;
          color: #92bff1;
          font-size: 0.7em;
        }
        @media (max-width: 900px) {
          border-bottom: 6px solid #4192ec;
          border-left: none;
          padding: 0.3em 2em 0.3em 2em;
        }
        @media (max-width: 600px) {
          padding: 0.3em 1em 0.3em 1em;
        }
        i {
          color: #4192ec;
        }
      }
    }

    @media (max-width: 900px) {
      display: inline-block;
      border-left: none;
      width: auto;
      padding: 0.7em 2em 0.3em 2em;
      border-bottom: 0;

      border-right: 1px solid rgba(255, 255, 255, 0.05);
      span {
        display: none;
      }
    }
    @media (max-width: 600px) {
      padding: 0.7em 1em 0.7em 1em;
    }
  }
`;

const StyledActiveListItem = styled(Link)`
  && {
    text-align: center;
    display: block;
    position: relative;
    /* border-left: 6px solid #4192ec; */
    text-decoration: none;
    padding: 0.8em 0;
    background: #4192ec;
    i {
      color: #fff;
    }
    > div {
      display: block;
      margin-right: 0;
    }
    span {
      color: #fff;
      font-size: 0.7em;
    }
    @media (max-width: 900px) {
      display: inline-block;
      width: auto;
      border-left: none;
      /* border-bottom: 6px solid #4192ec; */
      padding: 0.7em 2em 0.7em 2em;
      span {
        display: none;
      }
    }
    @media (max-width: 600px) {
      padding: 0.7em 1em 0.7em 1em;
    }
  }
`;

const MenuIcon = styled.i`
  && {
    width: 100%;
    display: block;
    font-size: 1em;
    text-align: center;
    padding: 0.4em 0;
    /* color: #4192ec; */
  }
`;

const StyledLogo = styled((props) => <ListItem {...props} />)`
  && {
    width: 80px;
    padding: 1em;
    font-size: 1.2em;
    color: #fff;
    font-weight: bold;
    opacity: 0.6;
    display: inline-block;
    text-align: center;
    @media (max-width: 900px) {
      display: inline-block;
      padding: 0 1em;
      margin: 0;
    }
  }
`;

const StyledListWrap = styled.div`
  && {
    display: block;
    width: 100%;
    overflow: auto;

    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 900px) {
      height: 65px;
      width: calc(100vw - 80px);
      overflow-y: hidden;
    }
  }
`;

const StyledList = styled.div`
  && {
    @media (max-width: 900px) {
      display: flex;
      /* width: 100%; */
      padding: 0 8px;
      margin: 0;
      min-width: 430px;
    }
  }
`;

export default class NavigationPanel extends React.PureComponent {
  static propTypes = {
    // isOpened: PropTypes.bool.isRequired,
    // toggle: PropTypes.func.isRequired,
  };
  renderMenuItemTotal(menu) {
    switch (menu.path) {
      case "/tasks":
        return this.props.tasks && this.props.tasks > 0 ? (
          <ItemNumb>{this.props.tasks}</ItemNumb>
        ) : null;
      case "/inspections":
        return this.props.inspections && this.props.inspections > 0 ? (
          <ItemNumb>{this.props.inspections}</ItemNumb>
        ) : null;
      case "/interventions":
        return this.props.maintenances && this.props.maintenances > 0 ? (
          <ItemNumb>{this.props.maintenances}</ItemNumb>
        ) : null;
      default:
        return null;
    }
  }

  renderMenuItem(menu, index) {
    // if (!this.props.admin && menu.permission === "admin") return null;
    // if (this.props.admin && menu.permission === "user") return null;
    if (
      this.props.type &&
      this.props.type !== "all" &&
      menu.path !== `/${this.props.type}` &&
      (menu.path === "/inspections" || menu.path === "/interventions")
    )
      return null;
    return (
      <StyledListItem key={index} to={menu.path}>
        {this.renderMenuItemTotal(menu)}
        <ListItemIcon>
          <MenuIcon className={`icon-${menu.icon} icons`} />
        </ListItemIcon>

        <span>{menu.name}</span>
      </StyledListItem>
    );
  }

  renderActiveMenuItem(menu, index) {
    // if (!this.props.admin && menu.permission === "admin") return null;
    // if (this.props.admin && menu.permission === "user") return null;
    if (
      this.props.type &&
      this.props.type !== "all" &&
      menu.path !== `/${this.props.type}` &&
      (menu.path === "/inspections" || menu.path === "/interventions")
    )
      return null;
    return (
      <StyledActiveListItem key={index} to={"/"}>
        {this.renderMenuItemTotal(menu)}
        <ListItemIcon>
          <MenuIcon className={`icon-${menu.icon} icons`} />
        </ListItemIcon>
        <span>{menu.name}</span>
      </StyledActiveListItem>
    );
  }

  render() {
    const { roleMenu, permissions } = this.props;

    const thisViewPermissions = permissions?.read || ["All"];

    return (
      <StyledDrawer variant="permanent" anchor="left">
        <ListContainer>
          <List>
            <StyledLogo>
              <img width={"80%"} alt="" src={"/images/icons/cascais.svg"} />
            </StyledLogo>
          </List>
          <StyledListWrap>
            <StyledList>
              {roleMenu.map((menu, index) => {
                if (!thisViewPermissions.includes("All")) {
                  if (!thisViewPermissions.includes(menu.name)) {
                    return null;
                  }
                }
                return this.props.location.includes(menu.path)
                  ? this.renderActiveMenuItem(menu, index)
                  : this.renderMenuItem(menu, index);
              })}
            </StyledList>
          </StyledListWrap>
          {/* <StyledFooter>
            <img
              width={'80%'}
              alt=""
              src={require('../../images/icons/cascais.svg')}
            />
          </StyledFooter> */}
        </ListContainer>
      </StyledDrawer>
    );
  }
}
