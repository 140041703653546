import React from "react";
import { connect } from "react-redux";

import { default as AddNewInspection } from "../Inspections/AddNew";
import EditMessage from "../Communications/Modals/EditMessage";
import EditSchoolSubscription from "../Schools/Edit";
import { default as AddNewMaintenance } from "../Maintenances/AddNew";
import Grower from "../User/Modals/Grower";

import { default as ViewInspection } from "../Inspections/View";
import { default as ViewMaintenance } from "../Maintenances/View";
import ViewReport from "../Reports/ViewReport";

import {
  toggleAddInspection,
  toggleViewInspection,
  toggleViewInterventions,
  toggleViewGrowers,
  toggleAddInterventions,
  toggleViewReport,
  toggleViewSchoolSubscription,
  toggleEditCommunication,
} from "./actions";

function Overlays(props) {
  return (
    <React.Fragment>
      {props.addInspection && (
        <AddNewInspection closeModal={props.toggleAddInspection} />
      )}
      {props.addIntervention && (
        <AddNewMaintenance closeModal={props.toggleAddInterventions} />
      )}
      {props.viewInspection && (
        <ViewInspection
          onClose={props.toggleViewInspection}
          groupId={props.viewInspection}
        />
      )}
      {props.viewIntervention && (
        <ViewMaintenance
          onClose={props.toggleViewInterventions}
          groupId={props.viewIntervention}
        />
      )}
      {props.viewDayReport && <ViewReport onClose={props.toggleViewReport} />}
      {props.viewGrower && (
        <Grower edit={props.viewGrower} closeModal={props.toggleViewGrowers} />
      )}
      {props.editCommunication && (
        <EditMessage closeModal={props.toggleEditCommunication} />
      )}
      {props.editSchoolSubscription && (
        <EditSchoolSubscription
          closeModal={props.toggleViewSchoolSubscription}
        />
      )}
    </React.Fragment>
  );
}

export default connect(
  (state) => {
    return {
      viewInspection: state.app.viewInspection,
      viewIntervention: state.app.viewIntervention,
      editCommunication: state.app.editCommunication,
      editSchoolSubscription: state.app.editSchoolSubscription,
      viewGrower: state.app.viewGrower,
      addInspection: state.app.addInspection,
      addIntervention: state.app.addIntervention,
      viewDayReport: state.app.viewDayReport,
    };
  },
  {
    AddNewMaintenance,
    AddNewInspection,
    toggleAddInspection,
    toggleViewSchoolSubscription,
    toggleAddInterventions,
    toggleViewInterventions,
    toggleViewGrowers,
    toggleViewReport,
    toggleEditCommunication,
    toggleViewInspection,
  }
)(Overlays);
