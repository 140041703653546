import React from "react";
import styled from "styled-components";
import Select from "../form-wrappers/Select";
import MainButton from "../Buttons/MainButton";
import DropzoneComponent from "react-dropzone-component";
import ContentWrap from "../Layout/ContentWrap";
import TextField from "../form-wrappers/TextField";
import { Switch } from "@material-ui/core";

export const InspectionsFilter = styled(Select)`
  && {
    border: 0;
    font-size: 0.9em;
    color: #a3a1a1;
  }
`;

export const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
  }
`;
export const StyledWrap = styled.div`
  && {
    display: flex;
    height: 100%;
    @media (max-width: 455px) {
      display: flex;
      flex-direction: column;
    }
    /* height: calc(100vh - 49px); */
  }
`;
export const StyledMainButton = styled(MainButton)`
  && {
    width: 100%;
    margin-top: 1em;
    font-size: 0.8em;
  }
`;

export const Card = styled.div`
  && {
    /* base: */
    background: #ffffff;
    border: 1px solid #e9eff4;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 6px;
    padding: 1em;
    margin: 1em 0;
    cursor: pointer;

    .photo {
      height: 150px;
      display: none;
      margin: -16px -16px 16px -16px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &:hover {
      /* base: */
      background: rgba(74, 144, 226, 0.75);
      border: 1px solid #e9eff4;
      color: #fff;
      .date,
      .damages,
      .details,
      .report,
      .name {
        color: #fff;
      }
      .photo {
        display: block;
      }
      .parque,
      .element,
      .type {
        color: rgba(255, 255, 255, 0.4);
      }
    }
    &.Danos {
      border-left: 3px solid #eb3d3d;
      &:hover {
        background: #eb3d3d;
      }
    }
    &.Inicial {
      border-left: 3px solid #ebcd3d;
      &:hover {
        background: #ebcd3d;
      }
    }
    &.Final {
      border-left: 3px solid #6fc44c;
      &:hover {
        background: #6fc44c;
      }
    }
    .element {
      text-align: right;
      font-size: 1em;
      display: inline-block;
      color: rgba(74, 144, 226, 0.6);
    }
    .parque {
      font-size: 0.8em;
      text-align: right;
      display: block;
      color: rgba(74, 144, 226, 0.3);
      margin-bottom: 0.5em;
    }
    .type {
      display: inline-block;
      padding: 5px;
      position: absolute;
      right: 0.5em;
      img {
        width: 20px;
        opacity: 0.7;
      }
    }

    .details {
      font-size: 0.8em;
      display: block;
      color: #989898;
    }
    .damages {
      font-size: 0.8em;
      margin-bottom: 0.5em;
      display: inline-block;
      font-weight: bold;
      color: rgba(74, 144, 226, 1);
    }
    .date,
    .name {
      /* 11:00 AM - 11:30 AM: */
      margin-top: 1em;
      opacity: 0.7;
      font-size: 0.7em;
      color: rgba(74, 144, 226, 0.8);
      letter-spacing: -0.32px;
      display: inline-block;
      text-align: right;
    }
    .report {
      margin-top: 0;
      font-size: 1.2em;
      text-align: right;
      display: inline-block;
      font-weight: bold;
      margin-right: 0.2em;
      color: rgba(74, 144, 226, 0.8);
    }
    .name {
      font-weight: bold;
      text-align: left;
    }

    .goToPark {
      background: #fff;
      display: block;
      border-top: 1px solid #e9eff4;
      color: #cfd1db;
      padding: 0.6em 1em;
      font-size: 0.7em;
      margin: 1em -1.4em -1.5em;
      border-radius: 0 0 5px 5px;
      :hover {
        color: #fff;
        background: rgba(74, 144, 226, 0.8);
      }
    }
  }
`;

export const CardList = styled.div`
  && {
    .inspection-list {
      height: calc(100vh - 100px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;

export const FilterList = styled.div`
  && {
    > div {
      height: calc(100vh - 180px);
      padding-right: 18px;

      @media (max-width: 900px) {
        height: calc(100vh - 230px);
      }
      @media (max-width: 455px) {
        height: auto;
      }
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;

export const ActionWrap = styled(ContentWrap)`
  && {
    margin-top: 1em;
    padding: 1em;
    display: flex;
    text-align: left;
  }
`;

export const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: center;
  }
`;

export const Toggle = styled((props) => <Switch {...props} />)`
  && {
    .MuiSwitch-bar-192,
    .jss192 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-iconChecked-194,
    .jss194 {
      color: #4da1ff;
    }
  }
`;
export const RatingWrap = styled.div`
  && {
    margin: 2em 0;
    text-align: left;
    label {
      display: block;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8em;
      margin-bottom: 0.5em;
    }
    > span {
      > span > span {
        margin: 0 0.2em 0 0;
        > span {
        }
      }
    }
  }
`;
export const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
    .dz-image {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
`;
export const Input = styled(TextField)`
  && {
    width: 100%;
    margin: 1em 0 0 0;
    text-align: left;
    color: #000;
    label {
      color: #919191;
      margin: 0;
    }

    textarea {
      height: auto;
    }

    input:disabled,
    textarea:disabled {
      color: #4da1ff;
    }
    div {
      margin: 0;
    }
    @media print {
      margin: 0.5em 0;
    }
  }
`;
export const ImageContainer = styled.div`
  && {
    text-align: left;
  }
`;

export const ElementType = styled.span`
  && {
    font-weight: bold;
    font-size: 1.5em;
  }
`;

export const Image = styled.div`
  && {
    width: 32%;
    height: 25vh;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    border-radius: 0.5em;
    background-size: cover;
    cursor: pointer;
  }
`;

export const Calendar = styled(Input)`
  && {
    margin: 1em 0 0 0;
  }
`;

export const SaveButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin: 0.5em 1em;
    display: inline-block;
    text-align: right;
  }
`;

export const InspectionWrap = styled.div`
  && {
    margin: 2em 0;
    .col-md-3 {
      @media print {
        display: none;
      }
    }
  }
`;

export const HalfRate = styled.div`
  && {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
    @media (max-width: 900px) {
      width: 45%;
    }
    @media (max-width: 455px) {
      width: 100%;
    }
  }
`;

export const DisableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin: 0.5em 1em;
    background: #df5757;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;

export const LocationTitle = styled.span`
  && {
    font-size: 0.8em;
    display: block;
  }
`;

export const ImageWrapper = styled.span`
  && {
    position: relative;
  }
`;
export const RemoveItem = styled.span`
  && {
    font-size: 2em;
    margin: 0 0.5em 0 0;
    text-align: center;
    background: #fff;
    position: absolute;
    z-index: 11;
    cursor: pointer;
    right: 6px;
    margin-top: 6px;
    border-radius: 1em;

    color: #f44336;
    vertical-align: middle;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 455px) {
      font-size: 2em;
    }
  }
`;

export const ButtonWrapper = styled.div`
  && {
    text-align: right;
    font-size: 0.8em;
    margin-top: 0.9em;
    margin-bottom: 2.3em;
    button {
      // margin-right: 0.5em;
      :hover {
        a {
          color: #4da1ff;
        }
      }
    }

    a {
      text-decoration: none;
      color: #fff;
      :hover {
        color: #4da1ff;
      }
    }
  }
`;

export const SectionFilter = styled.div`
  && {
    margin: 0.5em auto;
  }
`;
export const Section = styled.div`
  && {
    margin: 2em auto;
    max-width: 1400px;
  }
`;

export const FinishButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    width: 100%;
    margin-top: 1em;
    background: #6fc44c;
    border: 1px solid #6fc44c;
    @media print {
      display: none;
    }
  }
`;

export const Close = styled.span`
  && {
    font-size: 2em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 5px;
    top: 10px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 455px) {
      font-size: 2em;
    }
  }
`;
export const PrintButton = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 77px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 455px) {
      font-size: 1em;
      right: 76px;
      margin: 0;
    }
  }
`;

export const DailyReportButton = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 126px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
    @media (max-width: 455px) {
      font-size: 1em;
      right: 117px;
      margin: 0;
    }
  }
`;

export const SideOptionWrap = styled(OptionWrap)`
  && {
    padding: 1em;
    @media screen and (max-width: 1183px) and (min-width: 766px) {
      font-size: 0.7em;
    }
    text-align: left;
    button {
      margin: 0 0 0.5em 0;
    }
  }
`;

export const MaintenanceWrap = styled.div`
  && {
    margin: 2em 0;
    .col-md-3 {
      @media print {
        display: none;
      }
    }
  }
`;

export const FileContainer = styled.div`
  && {
    display: inline-block;
    margin: 0 1em 1em 0;
    width: 80px;
    text-align: center;
    a {
      text-align: center;
      text-decoration: none;
      color: #4da1ff;
      font-size: 0.8em;
      .filepicker-file-icon {
        color: #4990e2;
        &::after {
          background-color: #4990e2;
        }
        &::before {
          border: solid 2px #4990e2;
        }
      }
    }
  }
`;
