import { createSelector } from "reselect";

const getElementsGeoJson = (state) => state.app.elementsGeoJson;
const getElementRelations = (state) => state.georeference.elementRelations;
const getGrowerRelations = (state) => state.georeference.growerRelations;
const getSelectedElement = (state) => state.app.search.element;
const getGrowers = (state) => state.user.growersList;

export const getParkParcels = createSelector(
  [getElementsGeoJson],
  (elementsGeoJson) => {
    return elementsGeoJson.filter(
      (element) =>
        element.properties && element.properties.category === "Parcela"
    );
  }
);

export const getParkParcelNumber = createSelector(
  [getElementsGeoJson],
  (elementsGeoJson) => {
    const parcels = elementsGeoJson.filter(
      (element) =>
        element.properties &&
        (element.properties.category === "Parcela" ||
          element.properties.category === "Lote") &&
        element.properties.label !== "Parcela Administrativa"
    );

    if (parcels) {
      return parcels.length;
    } else {
      return 0;
    }
  }
);

export const getElementRelation = createSelector(
  [getElementsGeoJson, getElementRelations, getSelectedElement],
  (elementsGeoJson, ElementRelations, element) => {
    //we need to get target ids from relations
    const relations = [];
    //lets find root real element
    const selectedElement = elementsGeoJson.find(
      (eleRoot) =>
        element === eleRoot.properties.origin ||
        element === eleRoot.properties.id
    );
    if (!selectedElement) return null;

    //check if we have origin
    const selectedElementId =
      selectedElement.properties.origin || selectedElement.properties.id;

    //loop though elements
    elementsGeoJson.map((elem) => {
      const thisLoopElementId = elem.properties.origin || elem.properties.id;
      //loop though relations to save element to array
      ElementRelations.map((rel) => {
        if (
          (selectedElementId === Number(rel.originId) ||
            selectedElementId === Number(rel.targetId) ||
            selectedElement.properties.id === Number(rel.originId) ||
            selectedElement.properties.id === Number(rel.targetId)) &&
          (thisLoopElementId === Number(rel.originId) ||
            thisLoopElementId === Number(rel.targetId)) &&
          elem.properties.origin !== selectedElementId &&
          elem.properties.id !== selectedElementId &&
          !rel.type.match(/ranking/)
        ) {
          relations.push({ ...elem, rel });
        }

        return rel;
      });
      return false;
    });
    return relations;
  }
);

export const getCurrentGrower = createSelector(
  [getElementsGeoJson, getGrowers, getElementRelations, getSelectedElement],
  (geoJson, growers, ElementRelations, element) => {
    try {
      //lets find root real element
      const selectedElement = geoJson.find(
        (eleRoot) =>
          element === eleRoot.properties.origin ||
          element === eleRoot.properties.id
      );
      if (!selectedElement || !element) return null;
      //check if we have origin

      // //we need to get target ids from relations
      // const relation =
      //   thisElement &&
      //   ElementRelations.find(
      //     rel =>
      //       Number(rel.targetId) === Number(thisElement.properties.origin) ||
      //       Number(rel.targetId) === Number(thisElement.properties.id)
      //   );

      return growers.filter((grower) => {
        if (selectedElement.properties.origin) {
          return (
            grower.targetId &&
            (Number(grower.targetId) ===
              Number(selectedElement.properties.id) ||
              Number(grower.targetId) ===
                Number(selectedElement.properties.origin))
          );
        } else {
          return (
            grower.targetId &&
            Number(grower.targetId) === Number(selectedElement.properties.id)
          );
        }
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);
export const getCurrentGrowersRelation = createSelector(
  [getGrowerRelations, getElementsGeoJson],
  (growerRelations, elements) => {
    //we need to get target ids from relations
    growerRelations &&
      growerRelations.map((grower) => {
        // add found elements to relation propreity
        grower.connections = elements.filter(
          (ele) =>
            ele.properties.origin === Number(grower.targetId) ||
            ele.properties.id === Number(grower.targetId)
        );
        if (grower.relation) {
          grower.connections.date = grower.createdAt;
        }

        return grower;
      });

    return growerRelations || [];
  }
);
