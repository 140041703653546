import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { connect } from "react-redux";

import { ButtonGroup } from "@material-ui/core";

import MainButton from "../../../components/Buttons/MainButton";
import FullScreenModal from "../../../components/Layout/FullScreenModal";
import PageTitle from "../../../components/Text/PageTitle";

import EditGrower from "./EditGrower";
import RankingsGrower from "./RankingsGrower";
import RelationsGrower from "./RelationsGrower";
// import HistoryGrower from "./HistoryGrower";
import ViewReport from "../../Reports/ViewReport";

import { ButtonWrapper, Close, Section } from "./styled.js";

import MenuIcon from "../../../components/Buttons/MenuIcon";

import { getGrowers, getGrowersByRank } from "../actions";

class Grower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.edit || "",
      admin: false,
      external: false,
      view: "info",
      hiddenAutocomplete: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === "saved") {
      this.props.closeModal();
    }
  }

  render() {
    const { closeModal, data, grower, growerType, geoJson } = this.props;
    const growerPark =
      data &&
      data.pid &&
      geoJson &&
      geoJson.find((park) => park.properties.numero == data.pid);

    return (
      <FullScreenModal>
        <Close
          onClick={() => closeModal(false)}
          className={`icon-close icons`}
        />
        <Section>
          <Row>
            <Col xs={12} md={6}>
              <PageTitle>
                {data.attr && data.attr.warning && (
                  <i className={`icon-fire icons`} />
                )}{" "}
                {data.type && data.type.length >= 1
                  ? growerType[data.type]
                  : "Hortelão"}
                <span>
                  {data.label
                    ? `${growerPark ? growerPark.properties.name : data.pid}, ${
                        data.label
                      }`
                    : ""}
                </span>
              </PageTitle>
            </Col>
            <Col xs={12} md={6}>
              <ButtonWrapper>
                <ButtonGroup size="large" aria-label="large button group">
                  <MainButton
                    active={this.state.view === "info" ? "true" : "false"}
                    onClick={() => this.setState({ view: "info" })}
                  >
                    <MenuIcon className={`icon-notebook icons`} /> Informação
                  </MainButton>
                  <MainButton
                    active={this.state.view === "relations" ? "true" : "false"}
                    onClick={() => this.setState({ view: "relations" })}
                  >
                    <MenuIcon className={`icon-share-alt icons`} />
                    Relações
                  </MainButton>
                  {grower?.type !== "8" &&
                    grower?.type !== "1" &&
                    grower?.type !== "2" &&
                    grower?.type !== "3" &&
                    grower?.status !== 1 && (
                      <MainButton
                        active={
                          this.state.view === "rankings" ? "true" : "false"
                        }
                        onClick={() => this.setState({ view: "rankings" })}
                      >
                        <MenuIcon className={`icon-graph icons`} />
                        Rankings
                      </MainButton>
                    )}
                  <MainButton
                    active={this.state.view === "history" ? "true" : "false"}
                    onClick={() => this.setState({ view: "history" })}
                  >
                    <MenuIcon className={`icon-calendar icons`} /> Histórico
                  </MainButton>
                </ButtonGroup>
              </ButtonWrapper>
            </Col>
          </Row>
          <Col>
            {this.state.view === "rankings" && <RankingsGrower />}
            {this.state.view === "relations" && (
              <RelationsGrower
                closeModal={closeModal}
                updateToInfo={() => this.setState({ view: "info" })}
              />
            )}
            {this.state.view === "info" && (
              <EditGrower edit={this.props.edit} />
            )}
            {this.state.view === "history" && (
              <ViewReport
                growerHistory={data.attr && data.attr.history}
                type="user"
              />
            )}
          </Col>
        </Section>
      </FullScreenModal>
    );
  }
}

Grower.propTypes = {
  data: PropTypes.object,
};

Grower.defaultProps = {
  data: {},
};
export default connect(
  (state) => {
    return {
      status: state.user.status,
      growerState: state.user.growerState,
      growerType: state.user.growerTypes,
      data: state.user.editGrowerData,
      geoJson: state.parklist.geoJson,
      grower: state.user.editGrowerData,
    };
  },
  {
    getGrowers,
    getGrowersByRank,
  }
)(Grower);
