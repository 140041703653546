import React from "react";
import Table from "./Table";

export default function(props) {
  const processedData = (data) => {
    return data.map((row) => {
      return { ...row };
    });
  };
  const columnData = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      name: "id",
      options: {
        display: false,
      },
    },
    {
      id: "pid",
      numeric: false,
      disablePadding: false,
      label: "Horta",
      name: "Horta",
    },
    {
      id: "ElementTypes.category",
      numeric: false,
      disablePadding: false,
      label: "Categoria",
      name: "Categoria",
    },
    {
      id: "ElementTypes.label",
      numeric: false,
      disablePadding: false,
      label: "Elemento",
      name: "Elemento",
    },
    {
      id: "observations",
      numeric: false,
      disablePadding: false,
      label: "Observações",
      name: "Observações",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
      name: "Tipo",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "ranking0") return "Bairro";
          if (value === "ranking1") return "Proximidade";
          if (value === "ranking2") return "Freguesia";
        },
      },
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Hortelão",
      name: "Hortelão",
    },
    {
      id: "mobile",
      numeric: false,
      disablePadding: false,
      label: "Telemovel",
      name: "Telemovel",
      options: {
        display: false,
      },
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      name: "Email",
      options: {
        display: false,
      },
    },

    {
      id: "local",
      numeric: false,
      disablePadding: false,
      label: "local",
      name: "local",
    },
  ];

  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      click={props.click}
      header={columnData}
    />
  );
}
