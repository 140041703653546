import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { FormControlLabel, Switch } from "@material-ui/core";

import SectionTitle from "../../../components/Text/SectionTitle";
import FullScreenModal from "../../../components/Layout/FullScreenModal";
import PageTitle from "../../../components/Text/PageTitle";
import MainButton from "../../../components/Buttons/MainButton";
import TextField from "../../../components/form-wrappers/TextField";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import ContentWrap from "../../../components/Layout/ContentWrap";

// import DetailsSpecific from './.steps/DetailsSpecific';
// import ThankYou from './steps/ThankYou';

import {
  setTeam,
  getTeams,
  setDetails,
  resetUser,
  toggleTeamStatus
} from "../actions";
// import { setHistory } from './actions';

const Section = styled.div`
  && {
    margin: 2em 0;
    max-width: 1200px;
  }
`;

const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: left;
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    margin: 1em 0 0 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;
const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    width: 100%;
  }
`;

const DisableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #df5757;
    width: 100%;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;

const EnableButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    background: #6ab053;
    width: 100%;
    border-color: #6ab053;
    &:hover {
      color: #6ab053;
      .icons {
        color: #6ab053;
      }
    }
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;
const Toggle = styled(props => <Switch {...props} />)`
  && {
    .MuiSwitch-bar-192,
    .jss192 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-iconChecked-194,
    .jss194 {
      color: #4da1ff;
    }
  }
`;

class AddNewTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.edit || "",
      admin: false,
      external: false,
      hiddenAutocomplete: true
    };

    this.handleSaveTeam = this.handleSaveTeam.bind(this);
    this.handleToggleTeam = this.handleToggleTeam.bind(this);
    this.handleChange = this.handleChange.bind(this);

    const t = this;
    setTimeout(() => {
      t.setState({ hiddenAutocomplete: false });
    }, 1000);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.status == 'fetched' && nextProps.data) {
  //     this.setState({
  //       name: nextProps.data.name || '',
  //       emailUser: nextProps.data.email || '',
  //       permissions: nextProps.data.permissions || '',
  //       team: nextProps.data.Team ? nextProps.data.Team : '',
  //       admin: nextProps.data.internal || false,
  //     });
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.props.status === "saved") {
      this.props.getTeams();
      this.props.closeModal();
    }
  }

  handleSaveTeam() {
    this.props.setTeam();
  }

  handleToggleTeam() {
    this.props.toggleTeamStatus();
  }

  handleChange(e) {
    let valueChanged = e.target.value;
    switch (e.target.name) {
      case "type":
        valueChanged =
          this.props.data.type !== "external" ? "external" : "internal";
        break;
      default:
        break;
    }

    this.props.setDetails({
      element: [e.target.name],
      value: valueChanged
    });
  }

  render() {
    const { closeModal, data } = this.props;
    return (
      <FullScreenModal>
        <Close onClick={() => closeModal()} className={`icon-close icons`} />
        <Section>
          <PageTitle>Equipa</PageTitle>

          <Row>
            <Col md={12}>
              <Row>
                <Col xs={12} sm={8} md={8} lg={8} className={"m-b-15"}>
                  <SectionTitle>Dados da Equipa</SectionTitle>
                  <OptionWrap>
                    <Input
                      label="Nome da Equipa"
                      onChange={e => this.handleChange(e)}
                      name="label"
                      input={{}}
                      value={data && data.label}
                      meta={{ invalid: false }}
                    />

                    <FormControlLabel
                      control={
                        <Toggle
                          onChange={this.handleChange}
                          value="true"
                          checked={
                            data && data.type === "external" ? true : false
                          }
                          label="type"
                          // disabled={!data.edit}
                          name="type"
                          color="primary"
                          meta={{ invalid: false }}
                        />
                      }
                      label="Equipa / Empresa Externa"
                    />
                  </OptionWrap>
                </Col>

                <Col xs={12} sm={4} md={4} lg={4} className={"m-b-15"}>
                  <SectionTitle>Opções</SectionTitle>
                  <OptionWrap>
                    <NextButton
                      color="primary"
                      onClick={() => this.handleSaveTeam()}
                    >
                      Salvar
                      <MenuIcon className={`icon-check icons`} />
                    </NextButton>
                    {data.status === 1 && (
                      <DisableButton
                        color="primary"
                        onClick={() => this.handleToggleTeam()}
                      >
                        Desactivar
                        <MenuIcon className={`icon-close icons`} />
                      </DisableButton>
                    )}
                    {data.status === 0 && (
                      <EnableButton
                        color="primary"
                        onClick={() => this.handleToggleTeam()}
                      >
                        Activar
                        <MenuIcon className={`icon-check icons`} />
                      </EnableButton>
                    )}
                  </OptionWrap>
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  state => {
    return {
      teams: state.user.teams,

      status: state.user.status,
      data: state.user.editData,
      role: state.user.data.role,
      menuInspections:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          menu => menu.path === `/inspections`
        ),
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          menu => menu.path === `/interventions`
        )
    };
  },
  {
    setTeam,

    getTeams,
    setDetails,
    resetUser,
    toggleTeamStatus
  }
)(AddNewTeam);
