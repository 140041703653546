import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import moment from "moment";
import { default as InspectionsEdit } from "../Inspections/Edit";
import { default as MaintenanceEdit } from "../Maintenances/Edit";
import { default as CommuncationEdit } from "../Communications/Modals/DetailsStart";
import { default as EmailDetails } from "../Communications/Modals/EmailDetails";
import { getCurrentGrower } from "../Georeference/Selector";

import { getParkDayReport } from "./actions";
import { growerState, attributionState } from "../../config/general";

import {
  OptionWrap,
  DateWrap,
  Section,
  Bar,
  Icon,
  Timeline,
  StyledPageTitle,
  StyledPageTitleWrap,
  DayPageTitle,
  LocationTitle,
} from "./styled";

import { IconWrap, Card } from "../User/Modals/styled.js";

class ViewReport extends React.PureComponent {
  constructor(props) {
    super(props);

    // const startDate = moment("2001-01-01")
    //   // .subtract(30, "days")
    //   .format("YYYY-MM-DD");

    const startDate = moment()
      .subtract(20, "years")
      .format("YYYY-MM-DD");
    this.state = {
      data: props.data,
      endDate: moment().format("YYYY-MM-DD"),
      startDate: startDate,
      args: props.args,
      group: props.group,
      grower: props.grower,
    };
    this.generatePDF = this.generatePDF.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  /**
   * If we have a type than lets use to user data
   */
  componentDidMount() {
    const { growerRelations, individualRelations, grower, type } = this.props;
    let growers = [grower] || [];

    if (growerRelations && growerRelations.length > 0 && !type) {
      growers = growerRelations;
    } else if (individualRelations && individualRelations.grower && !type) {
      growers = individualRelations.grower;
    }
    // console.log(growerRelations, individualRelations);
    // console.log(growers, grower);

    this.props.getParkDayReport(
      this.state.startDate,
      this.state.endDate,
      type === "user" ? growers : null,
      this.props.selectedElementID
    );
  }

  closeModal() {
    if (typeof this.props.onClose === "function") return this.props.onClose();
  }

  generatePDF() {
    window && window.print();
  }

  handleSearchChange(e, value) {
    const { growerRelations, individualRelations, grower, type } = this.props;

    let growers = [grower] || [];

    if (!grower && growerRelations && growerRelations.length > 0) {
      growers = growerRelations;
    } else if (!grower && individualRelations) {
      growers = individualRelations.grower;
    }

    if (e.target.name === "startDate") {
      this.props.getParkDayReport(
        moment(e.target.value).format("YYYY-MM-DD"),
        this.state.endDate,
        type === "user" ? growers : null,
        this.props.selectedElementID
      );
    } else {
      this.props.getParkDayReport(
        this.state.startDate,
        moment(e.target.value).format("YYYY-MM-DD"),
        type === "user" ? growers : null,
        this.props.selectedElementID
      );
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  renderCard(visitDate, title, label, freguesia, pid, user, email) {
    return (
      <Card>
        <IconWrap>
          <Icon>
            <span>{visitDate.getDate()}</span>
            <span>{visitDate.getMonth() + 1}</span>
            <span>{visitDate.getFullYear()}</span>
          </Icon>
        </IconWrap>
        <StyledPageTitleWrap>
          <StyledPageTitle>
            <DayPageTitle>
              <span>{title}</span>
            </DayPageTitle>
            {email && (
              <LocationTitle>
                <span>{email}</span>
              </LocationTitle>
            )}
            <span style={{ color: "#000", fontWeight: "500" }}>{label}</span>

            {freguesia && pid && (
              <LocationTitle>
                {freguesia}, {pid}
              </LocationTitle>
            )}
          </StyledPageTitle>
        </StyledPageTitleWrap>
      </Card>
    );
  }

  render() {
    const data = [
      ...this.props.historyData,
      ...(this.props.growerHistory || []),
    ];

    // let selectedPID = "";
    // // eslint-disable-next-line
    // let isApproved = false;

    // if (data && data.length > 0) {
    //   selectedPID = data[0].Inspection ? data[0].Inspection.pid : data[0].pid;
    //   isApproved = data[0].status === 1;
    // }

    let currentDay = "";
    return (
      <Section>
        <Row>
          <Col xs={12} md={9}>
            <DateWrap>
              <DateRangeFilter
                handleSearchChange={this.handleSearchChange}
                endDate={this.state.endDate}
                startDate={this.state.startDate}
              />
            </DateWrap>
          </Col>
        </Row>
        {data && (
          <div>
            {data
              .sort((a, b) => {
                return (
                  new Date(b.date || b.sendDate) -
                  new Date(a.date || a.sendDate)
                );
              })
              .map((visit, index) => {
                let dateTitle = "";
                let visitDate = new Date(visit.date || visit.sendDate);
                if (!visit.date && !visit.createdAt) return null;
                const newDate = (
                  visit.date ||
                  visit.createdAt ||
                  visit.sendDate
                ).split("T")[0];
                if (currentDay !== newDate) {
                  dateTitle = (
                    <div>
                      <Icon>
                        <span>
                          {visitDate.getDate() < 10
                            ? `0${visitDate.getDate()}`
                            : visitDate.getDate()}
                        </span>
                        <span>
                          {visitDate.getMonth() + 1 < 10
                            ? `0${visitDate.getMonth() + 1}`
                            : visitDate.getMonth() + 1}
                        </span>
                        <span>{visitDate.getFullYear()}</span>
                      </Icon>
                      <DayPageTitle>
                        <span>
                          Relatório <br /> atividade diária
                        </span>
                        <Bar />
                      </DayPageTitle>
                    </div>
                  );

                  currentDay = newDate;
                }

                if (visit.name) {
                  const startDate = new Date(visit.date);
                  const endDate = new Date(visit.reldateupdate);

                  const startSting = moment(startDate).format("YYYY-MM-DD");
                  const endSting = moment(endDate).format("YYYY-MM-DD");
                  const finalDate =
                    startSting === endSting
                      ? moment(startDate).format("YYYY-MM-DD")
                      : `${moment(startDate).format("YYYY-MM-DD")} → ${moment(
                          endDate
                        ).format("YYYY-MM-DD")}`;
                  return (
                    <div>
                      {/* {this.renderCard(
                        endDate,
                        "Retirar parcela",
                        visit.name,
                        visit.freguesia,
                        visit.pid
                      )} */}
                      {this.renderCard(
                        startDate,
                        "Atribuição de parcela",
                        visit.name,
                        visit.freguesia,
                        finalDate,
                        visit?.userID,
                        visit?.userEmail
                      )}
                    </div>
                  );
                }
                if (
                  visit.type === "ranking0" ||
                  visit.type === "ranking1" ||
                  visit.type === "ranking2" ||
                  visit.type === "rankingall"
                ) {
                  if (visit.RelationStatus === 0) {
                    const startDate = new Date(visit.RelationCreatedAt);
                    const endDate = new Date(visit.RelationUpdatedAt);
                    return (
                      <div>
                        {this.renderCard(
                          endDate,
                          "Retirar parcela",
                          visit.label,
                          visit.local,
                          visit.pid,
                          visit?.userID,
                          visit?.userEmail
                        )}
                        {/* {this.renderCard(
                          startDate,
                          "Atribuição de parcela",
                          visit.label,
                          visit.local,
                          visit.pid
                        )} */}
                      </div>
                    );
                  } else {
                    return this.renderCard(
                      visitDate,
                      "Atribuição de parcela",
                      visit.label,
                      visit.local,
                      visit.pid,
                      visit?.userID,
                      visit?.userEmail
                    );
                  }
                } else if (visit.Intervention) {
                  return (
                    <div key={index}>
                      {dateTitle}
                      <Timeline>
                        <MaintenanceEdit data={visit} />
                      </Timeline>
                    </div>
                  );
                } else if (visit.messageId || visit.group) {
                  return (
                    <div key={index}>
                      {dateTitle}
                      <Timeline>
                        <EmailDetails readOnly={true} edit={visit} />
                      </Timeline>
                    </div>
                  );
                } else if (visit.sendDate) {
                  return (
                    <div key={index}>
                      {dateTitle}
                      <Timeline>
                        <CommuncationEdit readOnly={true} edit={visit} />
                      </Timeline>
                    </div>
                  );
                } else if (visit.ElementId) {
                  return (
                    <div key={index}>
                      {dateTitle}
                      <Timeline>
                        <InspectionsEdit data={visit} />
                      </Timeline>
                    </div>
                  );
                } else {
                  let thisStateLAbel = growerState[Number(visit.status)];
                  if (
                    visit.label === "Alteração de estado de atribuição para"
                  ) {
                    thisStateLAbel = attributionState[Number(visit.status)];
                  }

                  return this.renderCard(
                    new Date(visit.date),
                    visit.label || "Alteração de estado",
                    thisStateLAbel,
                    null,
                    null,
                    visit?.userID,
                    visit?.userEmail
                  );
                }
              })}
          </div>
        )}
        {data.length < 1 && (
          <div>
            <Row>
              <Col xs={12} md={9}>
                <OptionWrap>
                  Não conseguimos encontrar dados para esta data
                </OptionWrap>
              </Col>
            </Row>
          </div>
        )}
      </Section>
    );
  }
}

export default connect(
  (state) => {
    return {
      growerRelations: getCurrentGrower(state),
      individualRelations: state.georeference.individualRelations,
      historyData: state.reports.reportData,
      status: state.reports.status,
      startDate: state.app.search.startDate,
      endDate: state.app.search.endDate,
      parks: state.parklist.list,
      role: state.user.data.role,
      grower: state.user.editGrowerData,
      isAdmin: state.user.data.admin,
    };
  },
  {
    getParkDayReport,
  }
)(ViewReport);
