import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { apiAddress } from "../../config/general";
import { materialState, schoolMaterial } from "../../config/general";
import { toastr } from "react-redux-toastr";
import { useContainerQuery } from "react-container-query";

import { Button, MenuItem } from "@material-ui/core";
import { Col, Row } from "react-flexbox-grid";
import MainButton from "../../components/Buttons/MainButton";
import LineButton from "../../components/Buttons/LineButton";

import SelectComponent from "../../components/form-wrappers/Select";
import TextField from "../../components/form-wrappers/TextField";
import SideModal from "../../components/Layout/SideModal";
import PageTitle from "../../components/Text/PageTitle";

import { Close, Section } from "../../components/InspMain/styled";
// import { getMessage } from "../actions";

const query = {
  lcolumn: {
    maxWidth: 599,
  },
  lrow: {
    minWidth: 600,
  },
};

const EditWrapper = styled.div`
  && {
    padding: 5% 0;
    background: #fafafa;
    input,
    select,
    textarea {
      background: #ffffff;
    }
    div {
      &:has(+ textarea) {
        background: #ffffff;
      }
    }
    .lrow {
      display: flex;
      width: 90%;
      margin: 0 auto;
      max-width: 1100px;
      justify-content: space-between;
      .side {
        width: 30%;
      }
    }
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    margin: 0.5em 0 0 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;
const ButtonWrap = styled.div`
  && {
    padding: 0em 0 1em 0;
    margin-top: -1em;
  }
`;

const EditMaterial = (props) => {
  const [data, setData] = useState(props.edit || { status: 1 });
  const [params, containerRef] = useContainerQuery(query);
  const setMaterial = async () => {
    try {
      const res = await axios.post(
        `${apiAddress}api/v1/materials/${data.id || ""}`,
        data
      );
      toastr.success("Material criado com sucesso");
      if (props.noModal) {
        props.reloadData();
      } else {
        props.closeModal();
      }
    } catch (error) {
      toastr.error("Aconteceu um erro a criar o material");
      console.log(error);
    }
  };

  const renderInformation = () => {
    const { name, type, status, observations, price, isDefault } = data;
    return (
      <>
        <div className="side">
          <Row>
            <Col md={12}>
              <Input
                value={name}
                label="Nome"
                type={"text"}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                name="Nome"
                meta={{ invalid: false }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ width: "100%", margin: "1em 0 0 0" }}>
                <SelectComponent
                  value={type || []}
                  label="Tipo de Material"
                  onChange={(e) => setData({ ...data, type: e.target.value })}
                  name="type"
                  meta={{ invalid: false }}
                  style={{ width: "100%" }}
                >
                  {schoolMaterial &&
                    schoolMaterial.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </SelectComponent>
              </div>
              <Input
                value={observations}
                label="Observações"
                onChange={(e) =>
                  setData({ ...data, observations: e.target.value })
                }
                type="multiline"
                name="observations"
                rows={4}
                multiline
                meta={{ invalid: false }}
              />
            </Col>
          </Row>
        </div>
        <div className="side">
          {data?.levels?.map((level, index) => {
            return (
              <>
                <Row>
                  <Col md={6}>
                    <Input
                      value={level.area}
                      label="até m2"
                      type={"nember"}
                      onChange={(e) =>
                        setData({
                          ...data,
                          levels: data?.levels?.map((item, i) => {
                            if (i === index) {
                              return { ...item, area: e.target.value };
                            }
                            return item;
                          }),
                        })
                      }
                      name="area"
                      meta={{ invalid: false }}
                    />
                  </Col>
                  <Col md={5}>
                    <Input
                      value={level.numberOfItems}
                      label="N. de itens"
                      type={"number"}
                      onChange={(e) =>
                        setData({
                          ...data,
                          levels: data?.levels?.map((item, i) => {
                            if (i === index) {
                              return { ...item, numberOfItems: e.target.value };
                            }
                            return item;
                          }),
                        })
                      }
                      name="numberOfItems"
                      meta={{ invalid: false }}
                    />
                  </Col>
                  <Col md={1}>
                    <span
                      className="icon icon-close"
                      style={{
                        display: "block",
                        height: "10px",
                        width: "10px",
                        marginTop: "1.5em",
                        marginLeft: "-.5em",
                        fontSize: "1.1em",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setData({
                          ...data,
                          levels: data?.levels?.filter(
                            (item, i) => i !== index
                          ),
                        });
                      }}
                    />{" "}
                  </Col>
                </Row>
              </>
            );
          })}
          <LineButton
            style={{
              textAlign: "center",
              display: "block",
              margin: "1em 0 0 0",
              width: "100%",
            }}
            target={"_blank"}
            onClick={() => {
              setData({
                ...data,
                levels: [
                  ...(data?.levels || []),
                  {
                    area: "",
                    numberOfItems: "",
                  },
                ],
              });
            }}
          >
            Adicionar Nivel
          </LineButton>
        </div>
        <div className="side">
          <Row>
            <Col md={12}>
              <Input
                value={price}
                label="Preço por unidade"
                type={"number"}
                onChange={(e) => setData({ ...data, price: e.target.value })}
                name="price"
                meta={{ invalid: false }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ width: "100%", margin: "1em 0 0 0" }}>
                <SelectComponent
                  value={isDefault ? 1 : 0}
                  label="Default"
                  onChange={(e) =>
                    setData({
                      ...data,
                      isDefault: e.target.value === 1 ? true : false,
                    })
                  }
                  name="type"
                  meta={{ invalid: false }}
                  style={{ width: "100%" }}
                >
                  <MenuItem value={0}>Sem Definição</MenuItem>
                  <MenuItem value={1}>Por Definição</MenuItem>
                </SelectComponent>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ width: "100%", margin: " 0 0 0" }}>
                <SelectComponent
                  value={status}
                  onChange={(e) => setData({ ...data, status: e.target.value })}
                  label="Estado"
                  name="status"
                  meta={{ invalid: false }}
                  style={{ width: "100%" }}
                >
                  {materialState.map((item, i) => {
                    return (
                      <MenuItem value={i} key={i}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </SelectComponent>
              </div>
            </Col>
            <MainButton
              style={{
                textAlign: "center",
                display: "block",
                margin: "1em 0 0 0",
                width: "100%",
              }}
              target={"_blank"}
              onClick={() => {
                setMaterial();
              }}
            >
              Salvar
            </MainButton>
          </Row>
        </div>
      </>
    );
  };
  //for editing
  if (props.noModal) {
    return (
      <EditWrapper>
        <div
          ref={containerRef}
          className={`${params.lcolumn ? "lcolumn" : ""} ${
            params.lrow ? "lrow" : ""
          }`}
        >
          {renderInformation()}
        </div>
      </EditWrapper>
    );
  }
  return (
    <SideModal>
      <Close
        onClick={() => props.closeModal()}
        className={`icon-close icons`}
      />
      <Section>
        <PageTitle>Adicionar novo Material</PageTitle>

        {renderInformation()}
      </Section>
    </SideModal>
  );
};

export default EditMaterial;
