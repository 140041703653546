import React from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import SectionTitle from '../../components/Text/SectionTitle';

export const Data = styled(props => <TextField {...props} />)`
  && {
    input {
      font-size: 0.8em !important;
      display: flex;
      text-align: left;
    }
  }
`;

export const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

export const SectionTitleStyles = styled(SectionTitle)`
  && {
    @media (max-width: 455px) {
      display: none;
    }
  }
`;

export const Half = styled.div`
  && {
    display: flex;
    > div {
      width: 49%;
      &:first-child {
        margin-right: 1%;
      }
    }
  }
`;
