export const apiAddress = "https://hcapi.sgev.net/"; //add tralling slash
// export const apiAddress = "http://localhost:3013/"; //add tralling slash

export const rankingTypes = ["Bairro", "Proximidade", "Freguesia"];
export const escolasURL = "https://escolas.terrasdecascais.pt/";

export const growerTypes = [
  "Hortas Comunitárias",
  "Tutor",
  "Hortas nas Escolas",
  // "Auxiliar",
  "Hortas em Casa",
  "Vinhas Comunitárias",
  "Pomares Comunitários",
  "Olival Comunitários",
  "Hortas Associativas",
  "Auxiliar",
  "Voluntários",
];
export const growerState = [
  "Ativo", //0
  "Atribuído", //1//attr 4
  "Pendente", //2
  "Inativo", //3
  "Suspenso", //4
  "Duplicado", //5
  "Atribuído Pendente", //6
  "Atribuído Transferência", //7
  "Incontactável", //8
  "Suplente", //attr 3 //9
  "Selecionado", //attr 1 //10
  "Formação", //attr 2 //11
];
export const attributionState = [
  //"formacao" //Altera estado principal para formacao
  "Não selecionado", //Altera estado principal para selecionado
  "Não antende",
  "Sai",
  "Não pode ir",
  "Aguarda Resposta",
  "Escolheu Horta",
  "Chamado",
];

export const modules = ["Modulo 1", "Modulo 2", "Modulo 3", "Diploma"];
export const contactTypes = ["Auxiliar", "Auxiliado"];

export const status = {
  set: "set",
  fetched: "fetched",
  edit: "edit",
  saved: "saved",
  error: "error",
  saving: "saving",
  edited: "edited",
  getting: "getting",
  received: "received",
  searchUpdated: "searchUpdated",
  searchUpdatFinished: "searchUpdatFinished",
};

export const materialState = ["Inativo", "Ativo"];
export const schoolState = [
  "Por Aprovar",
  "Aprovado",
  "Atribuido",
  "Inativo",
  "Pré-Inscrição",
  "Não quer pré-Inscrição",
];
export const schoolAgrupamento = [
  "Agrupamento de Escolas de São João do Estoril",
  "Agrupamento de Escolas Frei Gonçalo de Azevedo",
  "Agrupamento de Escolas Alapraia",
  "Agrupamento de Escolas Alcabideche",
  "Agrupamento de Escolas da Cidadela",
  "Agrupamento de Escolas de Alvide",
  "Agrupamento de Escolas de Carcavelos",
  "Agrupamento de Escolas de Cascais",
  "Agrupamento de Escolas de Parede",
  "Agrupamento de Escolas Ibn Mucana",
  "Agrupamento de Escolas Matilde Rosa Araújo",
  "Agrupamento de Escolas Aquilino Ribeiro",
];
export const schoolMaterial = [
  {
    id: 2,
    name: "Hortícolas Primavera",
    points: 1,
    pointsText: "1 ponto",
  },
  {
    id: 3,
    name: "Aromáticas e Flores Comestíveis Primavera",
    points: 1,
    pointsText: "1 ponto",
  },
  {
    id: 4,
    name: "Fertilizantes",
    points: 1,
    pointsText: "1 ponto",
  },
  {
    id: 5,
    name: "Ferramentas",
    points: 1,
    pointsText: "1 ponto",
  },

  {
    id: 7,
    name: "Apoio à Cava",
    points: 4,
    pointsText: "4 pontos",
  },
  {
    id: 8,
    name: "Sistema de Rega Gota-a-gota",
    points: 3,
    pointsText: "3 pontos",
  },
  {
    id: 9,
    name: "Apoio à Cava Outono (10 m2)",
    points: 4,
    pointsText: "4 pontos",
  },
  {
    id: 10,
    name: "Fertilizantes Outono",
    points: 1,
    pointsText: "1 pontos",
  },
  {
    id: 11,
    name: "Hortícolas Outono",
    points: 1,
    pointsText: "1 ponto",
  },
];
export const schoolResources = [
  {
    id: 1,
    name: "Exposição solar direta	",
  },
  {
    id: 2,
    name: "Ponto de água",
  },
  {
    id: 3,
    name: "Sistema de rega",
  },
  {
    id: 4,
    name: "Ferramentas",
  },
  {
    id: 5,
    name: "Compostor",
  },
];

export const schoolList = [
  {
    agrupamento: "Agrupamento de Escolas Alapraia",
    escola: "Escola Básica Alapraia",
  },

  {
    agrupamento: "Agrupamento de Escolas Alapraia",
    escola: "Escola Básica Professor Doutor Oliveira Marques",
  },

  {
    agrupamento: "Agrupamento de Escolas Alapraia",
    escola: "Escola Básica Almada Negreiros",
  },

  {
    agrupamento: "Agrupamento de Escolas Alapraia",
    escola: "Escola Básica Caparide",
  },

  {
    agrupamento: "Agrupamento de Escolas Alapraia",
    escola: "Escola Básica Manique",
  },

  {
    agrupamento: "Agrupamento de Escolas Alapraia",
    escola: "Escola Básica São Pedro do Estoril",
  },

  {
    agrupamento: "Agrupamento de Escolas Alapraia",
    escola: "Jardim-de-Infância Bicesse",
  },

  {
    agrupamento: "Agrupamento de Escolas Alcabideche",
    escola: "Escola Básica Bruno Nascimento",
  },

  {
    agrupamento: "Agrupamento de Escolas Alcabideche",
    escola: "Escola Básica Gracinda Antunes Valido",
  },

  {
    agrupamento: "Agrupamento de Escolas Alcabideche",
    escola: "Escola Básica Malangatana",
  },

  {
    agrupamento: "Agrupamento de Escolas Alcabideche",
    escola: "Escola Básica Alto da Peça",
  },

  {
    agrupamento: "Agrupamento de Escolas Alcabideche",
    escola: "Escola Básica professora Maria Margarida Rodrigues",
  },

  {
    agrupamento: "Agrupamento de Escolas Alcabideche",
    escola: "Jardim-de-Infância Cesaltina Fialho Gouveia",
  },

  {
    agrupamento: "Agrupamento de Escolas Alcabideche",
    escola: "Jardim-de-Infância Fátima Campino",
  },

  {
    agrupamento: "Agrupamento de Escolas da Cidadela",
    escola: "Escola Básica Cobre",
  },

  {
    agrupamento: "Agrupamento de Escolas da Cidadela",
    escola: "Escola Básica e Secundária Cidadela",
  },

  {
    agrupamento: "Agrupamento de Escolas da Cidadela",
    escola: "Escola Básica José Jorge Letria",
  },

  {
    agrupamento: "Agrupamento de Escolas da Cidadela",
    escola: "Escola Básica Malveira da Serra",
  },

  {
    agrupamento: "Agrupamento de Escolas da Cidadela",
    escola: "Jardim-de-Infância Murches",
  },
  {
    agrupamento: "Agrupamento de Escolas Aquilino Ribeiro",
    escola: "Escola Básica Talaíde",
  },
  {
    agrupamento: "Agrupamento de Escolas de Alvide",
    escola: "Escola Básica Alvide",
  },

  {
    agrupamento: "Agrupamento de Escolas de Alvide",
    escola: "Escola Básica Cascais nº 4",
  },

  {
    agrupamento: "Agrupamento de Escolas de Alvide",
    escola: "Escola Básica e Secundária Alvide",
  },

  {
    agrupamento: "Agrupamento de Escolas de Alvide",
    escola: "Escola Básica Professor Manuel Gaião",
  },

  {
    agrupamento: "Agrupamento de Escolas de Carcavelos",
    escola: "Escola Básica Arneiro",
  },

  {
    agrupamento: "Agrupamento de Escolas de Carcavelos",
    escola: "Escola Básica Carcavelos nº 1",
  },

  {
    agrupamento: "Agrupamento de Escolas de Carcavelos",
    escola: "Escola Básica e Secundária Carcavelos",
  },

  {
    agrupamento: "Agrupamento de Escolas de Carcavelos",
    escola: "Escola Básica Lombos",
  },

  {
    agrupamento: "Agrupamento de Escolas de Carcavelos",
    escola: "Escola Básica Rebelva",
  },

  {
    agrupamento: "Agrupamento de Escolas de Carcavelos",
    escola: "Escola Básica Sassoeiros",
  },

  {
    agrupamento: "Agrupamento de Escolas de Carcavelos",
    escola: "Jardim-de-Infância Carcavelos",
  },

  {
    agrupamento: "Agrupamento de Escolas de Cascais",
    escola: "Escola Básica Aldeia do Juso nº 1",
  },

  {
    agrupamento: "Agrupamento de Escolas de Cascais",
    escola: "Escola Básica Areia Guincho",
  },

  {
    agrupamento: "Agrupamento de Escolas de Cascais",
    escola: "Escola Básica Cascais",
  },

  {
    agrupamento: "Agrupamento de Escolas de Cascais",
    escola: "Escola Básica Branquinho da Fonseca",
  },

  {
    agrupamento: "Agrupamento de Escolas de Cascais",
    escola: "Escola Secundária Cascais",
  },

  {
    agrupamento: "Agrupamento de Escolas de Cascais",
    escola: "Jardim-de-Infância Torre",
  },

  {
    agrupamento: "Agrupamento de Escolas de Parede",
    escola: "Escola Básica Murtal",
  },

  {
    agrupamento: "Agrupamento de Escolas de Parede",
    escola: "Escola Básica Parede nº 2",
  },

  {
    agrupamento: "Agrupamento de Escolas de Parede",
    escola: "Escola Básica Santo António",
  },

  {
    agrupamento: "Agrupamento de Escolas de Parede",
    escola: "Escola Básica São Domingos de Rana nº 2",
  },

  {
    agrupamento: "Agrupamento de Escolas de Parede",
    escola: "Escola Secundária Fernando Lopes Graça",
  },

  {
    agrupamento: "Agrupamento de Escolas de Parede",
    escola: "Jardim-de-Infância Parede",
  },

  {
    agrupamento: "Agrupamento de Escolas de São João do Estoril",
    escola: "Escola Básica Galiza nº 1",
  },

  {
    agrupamento: "Agrupamento de Escolas de São João do Estoril",
    escola: "Escola Básica nº 1 São João do Estoril",
  },

  {
    agrupamento: "Agrupamento de Escolas de São João do Estoril",
    escola: "Escola Básica São João do Estoril",
  },

  {
    agrupamento: "Agrupamento de Escolas de São João do Estoril",
    escola: "Escola Secundária São João do Estoril",
  },

  {
    agrupamento: "Agrupamento de Escolas Frei Gonçalo de Azevedo",
    escola: "Escola Básica  Rómulo de Carvalho",
  },

  {
    agrupamento: "Agrupamento de Escolas Frei Gonçalo de Azevedo",
    escola: "Escola Básica Padre Andrade",
  },

  {
    agrupamento: "Agrupamento de Escolas Frei Gonçalo de Azevedo",
    escola: "Jardim de Infância Padre Andrade",
  },

  {
    agrupamento: "Agrupamento de Escolas Frei Gonçalo de Azevedo",
    escola: "Escola Básica Abóboda nº 2",
  },

  {
    agrupamento: "Agrupamento de Escolas Frei Gonçalo de Azevedo",
    escola: "Escola Básica e Secundária Frei Gonçalo de Azevedo",
  },

  {
    agrupamento: "Agrupamento de Escolas Frei Gonçalo de Azevedo",
    escola: "Escola Básica Tires nº 2",
  },

  {
    agrupamento: "Agrupamento de Escolas Frei Gonçalo de Azevedo",
    escola: "Escola Básica Trajouce",
  },

  {
    agrupamento: "Agrupamento de Escolas Ibn Mucana",
    escola: "Escola Básica Fernando Teixeira Lopes",
  },

  {
    agrupamento: "Agrupamento de Escolas Ibn Mucana",
    escola: "Escola Básica Fausto Cardoso Figueiredo",
  },

  {
    agrupamento: "Agrupamento de Escolas Ibn Mucana",
    escola: "Escola Básica Fernando José dos Santos",
  },

  {
    agrupamento: "Agrupamento de Escolas Ibn Mucana",
    escola: "Escola Básica Raúl Lino",
  },

  {
    agrupamento: "Agrupamento de Escolas Ibn Mucana",
    escola: "Escola Básica Alcabideche",
  },

  {
    agrupamento: "Agrupamento de Escolas Matilde Rosa Araújo",
    escola: "Escola Básica António Torrado",
  },

  {
    agrupamento: "Agrupamento de Escolas Matilde Rosa Araújo",
    escola: "Escola Básica e Secundária Matilde Rosa Araújo",
  },

  {
    agrupamento: "Agrupamento de Escolas Matilde Rosa Araújo",
    escola: "Escola Básica Padre Agostinho da Silva",
  },

  {
    agrupamento: "Agrupamento de Escolas Matilde Rosa Araújo",
    escola: "Escola Básica Parede nº 4",
  },

  {
    agrupamento: "Agrupamento de Escolas Matilde Rosa Araújo",
    escola: "Escola Básica São Domingos de Rana nº 1",
  },

  {
    agrupamento: "Agrupamento de Escolas Matilde Rosa Araújo",
    escola: "Escola Básica Tires",
  },
];
