import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import MenuIcon from "../Buttons/MenuIcon";
import { apiAddress, growerState } from "../../config/general";
import { toggleViewGrowers } from "../../containers/App/actions";
import {
  ActionButton,
  DeleteButton,
} from "../../containers/Georeference/styled";
import { Card, SendMessageButton } from "./styled";

const GrowerCard = (props) => {
  const {
    readOnly,
    key,
    grower,
    type,
    previewGrower,
    viewGrower,
    noCommunication,
    reltype,
  } = props;
  if (!grower || !grower.name) return null;
  return (
    <div>
      <Card
        key={key}
        warning={grower.attr && grower.attr.warning}
        // onMouseOver={() =>
        //   props.setPreviewGrower && props.setPreviewGrower(grower.id)
        // }
        // onMouseLeave={() =>
        //   props.setPreviewGrower && props.setPreviewGrower(null)
        // }
      >
        {!readOnly && type === "add-growers" && (
          <ActionButton
            color="primary"
            onClick={() => {
              props.setParcelToGrower(grower.id);
              toastr.info("Escolha uma parcela para atribuir a este Hortelão");
            }}
          >
            Atribuir
            <MenuIcon className={`icon-check icons`} />
          </ActionButton>
        )}
        {!readOnly && type === "edit-growers" && (
          <DeleteButton
            color="primary"
            onClick={() => {
              props.delElementsRelation(grower.relid, "grower");
            }}
          >
            Remover
            <MenuIcon className={`icon-check icons`} />
          </DeleteButton>
        )}
        <div>
          <div
            onClick={() => {
              viewGrower && viewGrower(grower.id);
              props.toggleViewGrowers && props.toggleViewGrowers(grower.id);
            }}
          >
            {grower.photo &&
              (previewGrower === grower.id || readOnly) &&
              grower.photo.length > 0 && (
                <span
                  className={"file"}
                  style={{
                    backgroundImage: `url(${apiAddress}${grower.photo[0].path})`,
                  }}
                />
              )}
            <div className={"line"}>
              <span className={"grower"}>
                {grower.attr && grower.attr.warning && (
                  <span className={`icon-fire icons`} />
                )}{" "}
                {grower.name}{" "}
                <span className={`statusShow`}>
                  {growerState[grower.status]}
                </span>
              </span>
            </div>
            {grower.distance && (
              <div className={"line"}>
                <MenuIcon className={`icon-direction icons`} />
                <span className={"freguesia"}>{grower.distance}m</span>
              </div>
            )}
            {grower.duration && (
              <div className={"line"}>
                <MenuIcon className={`icon-clock icons`} />
                <span className={"freguesia"}>
                  {(grower.duration / 60).toFixed(2)}m
                </span>
              </div>
            )}
            <div className={"line"}>
              <MenuIcon className={`icon-calendar icons`} />
              <span className={"createdAt"}>
                {moment(grower.createdAt || grower.createdat).format(
                  "DD-MMM-YYYY HH:MM"
                )}
              </span>
            </div>
            {(previewGrower === grower.id || readOnly) && (
              <div>
                <div className={"line"}>
                  <MenuIcon className={`icon-map icons`} />
                  <span className={"freguesia"}>{grower.freguesia}</span>
                </div>
                <div className={"line"}>
                  <MenuIcon className={`icon-phone icons`} />
                  <span className={"phone"}>{grower.phone}</span>
                </div>
                <div className={"line"}>
                  <MenuIcon
                    className={`icon-${
                      grower.educationDone ? "check" : "book-open"
                    } icons`}
                  />
                  <span className={"eduction"}>
                    {grower.educationDone ? "Formado" : "Por Formar"}
                  </span>
                </div>
                {grower.auxiliar &&
                  (grower.auxiliar.name || grower.auxiliar.phone) && (
                    <div>
                      <br />

                      <strong>Auxiliar</strong>
                      <div className={"line"}>
                        <MenuIcon className={`icon-people icons`} />
                        <span className={"phone"}>{grower.auxiliar.name}</span>
                      </div>
                      <div className={"line"}>
                        <MenuIcon className={`icon-phone icons`} />
                        <span className={"phone"}>{grower.auxiliar.phone}</span>
                      </div>
                      <br />
                    </div>
                  )}

                {grower.note && grower.note.length > 1 && !noCommunication && (
                  <div className={"line"}>
                    <span className={"note"}>{grower.note}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <br />
          {(previewGrower === grower.id || readOnly) && !noCommunication && (
            <SendMessageButton contacts={[grower.id]} />
          )}
        </div>

        {grower.connections && (
          <div className={"goToPark"}>
            {/* <MenuIcon className={`icon-map icons`} /> */}
            <span className={"freguesia"}>
              {grower.connections.map((rel) => rel.properties.label).join(",")}
              {" | "}
              {moment(grower.connections.date).format("DD-MM-YYYY")}
              {" | "}
              {reltype === "ranking0" && (
                <span className={"freguesia"}>Bairro</span>
              )}
              {reltype === "ranking1" && (
                <span className={"freguesia"}>Proximidade</span>
              )}
              {reltype === "ranking2" && (
                <span className={"freguesia"}>Freguesia</span>
              )}
            </span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default connect(
  (state) => {
    return {};
  },
  {
    toggleViewGrowers,
  }
)(GrowerCard);
