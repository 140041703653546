import { Tooltip } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { BsFillCalendar2XFill } from "react-icons/bs";
import { FaPhoneSlash } from "react-icons/fa";
import { RxLapTimer } from "react-icons/rx";
import { toastr } from "react-redux-toastr";
import MenuIcon from "../../components/Buttons/MenuIcon";
import SectionTitle from "../../components/Text/SectionTitle";
import { apiAddress, growerState, growerTypes } from "../../config/general";
import { store } from "../../store/configure";
import {
  Card,
  DeleteButton,
  OptionWrapRank,
  SideBarPaper,
  StyledMainButton,
} from "./styled";

const Parks = ({
  pid,
  getAttribution,
  selectedTypePark,
  selectedUsers,
  setSelectedUsers,
  sideListGetUserSuplentes,
  quotas,
}) => {
  const [ranking0, setRanking0] = useState([]);
  const [ranking1, setRanking1] = useState([]);
  const [ranking2, setRanking2] = useState([]);
  // const [quotas, setQuotas] = useState({});

  useEffect(() => {
    sideListGetUserSuplentes();
    return () => {};
  }, []);

  useEffect(() => {
    getUserByRank(0);
    getUserByRank(1);
    getUserByRank(2);
    return () => {};
  }, [pid]);

  /**
   * update inteal user state for 3 ranking
   **/
  const updateLocalUserState = (id, update) => {
    const newState = ranking0.map((user) => {
      if (user.id === id) {
        return { ...user, ...update };
      }
      return user;
    });
    setRanking0(newState);
    const newState1 = ranking1.map((user) => {
      if (user.id === id) {
        return { ...user, ...update };
      }
      return user;
    });
    setRanking1(newState1);
    const newState2 = ranking2.map((user) => {
      if (user.id === id) {
        return { ...user, ...update };
      }
      return user;
    });
    setRanking2(newState2);
    //lets manually update the selected users
    const foundUser = selectedUsers.find((user) => user.id === id);
    if (foundUser) {
      const newSelectedUsers = selectedUsers.filter((user) => {
        return user.id !== id;
      });
      setSelectedUsers(newSelectedUsers);
    } else {
      sideListGetUserSuplentes();
    }
  };

  /**
   * update user state to suplete
   */

  const updateUserState = async (id, state) => {
    try {
      const response = await axios.post(`/api/v1/contacts/status/${id}`, {
        status: state,
      });
      updateLocalUserState(id, { status: state });
      toastr.success(`Utilizador atualizado com sucesso`);
    } catch (error) {
      toastr.error(`Ocorreu a editar relação `);
      console.log(error);
    }
  };

  const setNewAttrState = async (id, state) => {
    try {
      const res = await axios.post(
        `${apiAddress}api/v1/contacts/attrStatus/${id}`,
        { status: state }
      );

      updateLocalUserState(id, { attrStatus: state });
      toastr.success(`Utilizador atualizado com sucesso`);
      // now we need to update userlist
      return true;
    } catch (error) {
      // toastr.error("Aconteceu um obter lista");
      console.log(error);
    }
  };

  /**
   * get users for a park
   */
  const getUserByRank = async (rank) => {
    try {
      const res = await axios.get(
        `${apiAddress}api/v1/rankings/${rank}/${pid}/0,7,9,10,11/50/false`
      );
      switch (rank) {
        case 0:
          setRanking0(res?.data?.msg || []);
          break;
        case 1:
          setRanking1(res?.data?.msg || []);
          break;
        case 2:
          setRanking2(res?.data?.msg || []);
          break;
        default:
          break;
      }
    } catch (error) {
      toastr.error("Aconteceu um erro a criar o material");
      console.log(error);
    }
  };

  /**
   * viewGrower is the grower that is being previewed
   */
  const viewGrower = (grower) => {
    store.dispatch({ type: "TOGLE_VIEW_GROWER", data: grower });
  };

  /**
   * Ranking card render
   */

  const RankingCard = ({ grower, i }) => {
    const isSelected =
      grower.status === 10 ||
      grower.status === 11 ||
      grower.status === 9 ||
      (grower.status === 7 && Number(grower.attrStatus) === 6);

    const userAttrStatus = grower?.attrStatus || 0;

    return (
      <Card key={i} color={"#28587f"}>
        <Row style={{ alignItems: "center" }}>
          <Col xs={3} sm={3} md={3} lg={3}>
            <div onClick={() => viewGrower(grower.id)}>
              <span className={"rank"}>{i + 1}</span>
            </div>
          </Col>
          <Col xs={9} sm={9} md={9} lg={9}>
            <div onClick={() => viewGrower(grower.id)}>
              <span className={"nameRank"}>{grower.name}</span>{" "}
              <div className={"state"}>{growerState[grower.status]} </div>
            </div>
          </Col>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col xs={10} sm={10} md={10} lg={10}>
            <div
              onClick={() => viewGrower(grower.id)}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                lineHeight: "1",
              }}
            >
              <div className={"flex"}>
                <div className={"line"}>
                  <MenuIcon className={`icon-direction icons`} />
                  <span className={"freguesia"}>{grower.distance}m</span> (
                  <span className={"freguesia"}>
                    {(grower.duration / 60).toFixed(2)}min
                  </span>
                  )
                </div>
              </div>

              <div className={"date"}>
                <MenuIcon className={`icon-calendar icons`} />
                <span className={"createdAt"}>
                  {moment(grower.createdAt || grower.createdat).format(
                    "DD/MM/YYYY HH:MM"
                  )}
                </span>
              </div>

              <div className={"education"}>
                <MenuIcon className={`icon-graduation icons`} />
                <span>{grower.educationDone ? "Formado" : "Por Formar"} </span>
              </div>

              {/* <div className={"contacts"}>
                <MenuIcon className={`icon-envelope icons`} />
                <a className={"email"} href={`mailto:${grower.email}`}>
                  e-mail link
                </a>
              </div> */}
            </div>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2}>
            <div style={{ fontSize: "1.4em" }}>
              {Number(userAttrStatus) === 4 && (
                <Tooltip title={`Aguarda Resposta`}>
                  <RxLapTimer />
                </Tooltip>
              )}
              {Number(userAttrStatus) === 1 && (
                <Tooltip title={`Não Atende`}>
                  <FaPhoneSlash />
                </Tooltip>
              )}

              {Number(userAttrStatus) === 3 && (
                <Tooltip title={`Não está disponível`}>
                  <BsFillCalendar2XFill />
                </Tooltip>
              )}
            </div>
          </Col>
          <Col>
            <div className={"note"}>{grower.note}</div>
          </Col>
        </Row>
        {!isSelected ? (
          <StyledMainButton
            onClick={() => {
              //if user stati is 7 then update attrStatus to 7
              if (grower.status === 7) {
                setNewAttrState(grower.id, 6);
                return;
              } else {
                updateUserState(grower.id, 10);
              }
            }}
          >
            Selecionar
          </StyledMainButton>
        ) : (
          <StyledMainButton
            color={"#f44336"}
            onClick={() => {
              //if user status is 7 then update attrStatus to 0
              if (grower.status === 7) {
                setNewAttrState(grower.id, 0);
                return;
              } else {
                updateUserState(grower.id, 0);
              }
            }}
          >
            Remover
          </StyledMainButton>
        )}
        {/* <StyledMainButton>Contactado</StyledMainButton>
        <StyledMainButton>Confirmado</StyledMainButton> */}
      </Card>
    );
  };

  const SelectedGrowers = ({}) => {
    return (
      selectedUsers &&
      selectedUsers.length > 0 && (
        <Col xs={12} sm={3} md={3} lg={3}>
          <SideBarPaper>
            <div style={{ padding: "1em" }}>
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  color: "#28587f",
                  fontSize: ".8em",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    background: "#28587f",
                    color: "#fff",
                    padding: ".3em",
                    fontSize: "1.2em",
                    borderRadius: "6px",
                    marginRight: "4px",
                  }}
                >
                  {
                    selectedUsers?.filter(
                      (u) => growerTypes[u.type] === selectedTypePark
                    )?.length
                  }
                </div>{" "}
                Hortelões selecionados
              </div>
              {selectedUsers
                .filter((u) => growerTypes[u.type] === selectedTypePark)
                .sort(
                  (a, b) =>
                    a.label &&
                    b.label &&
                    a.label.localeCompare(b.label, "en", { numeric: true })
                )
                .map((grower, i) => {
                  const userAttrStatus = grower?.attrStatus || 0;
                  return (
                    <Card key={i} color={"#28587f"}>
                      <Row style={{ alignItems: "center" }}>
                        <Col xs={10} sm={10} md={10} lg={10}>
                          {/* <span className={"label"}>{grower.label}</span> */}
                          <div onClick={() => viewGrower(grower.id)}>
                            <span className={"name"}>{grower.name}</span>

                            <span className={"ranking"}>
                              {grower.reldate
                                ? moment(grower.reldate).format("DD/MM/YYYY")
                                : ""}{" "}
                            </span>
                          </div>
                          <DeleteButton
                            color="primary"
                            onClick={() => updateUserState(grower.id, 0)}
                          >
                            Remover
                            <MenuIcon className={`icon-check icons`} />
                          </DeleteButton>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2}>
                          <div style={{ fontSize: "1.4em" }}>
                            {Number(userAttrStatus) === 4 && (
                              <Tooltip title={`Aguarda Resposta`}>
                                <RxLapTimer />
                              </Tooltip>
                            )}
                            {Number(userAttrStatus) === 1 && (
                              <Tooltip title={`Não Atende`}>
                                <FaPhoneSlash />
                              </Tooltip>
                            )}

                            {Number(userAttrStatus) === 3 && (
                              <Tooltip title={`Não está disponível`}>
                                <BsFillCalendar2XFill />
                              </Tooltip>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
            </div>
          </SideBarPaper>
        </Col>
      )
    );
  };

  const noGrowers = selectedUsers?.length === 0;
  const thisQuotas = quotas[pid] || {};

  return (
    <div>
      <Row>
        <SelectedGrowers />
        <Col
          xs={12}
          sm={noGrowers ? 12 : 9}
          md={noGrowers ? 12 : 9}
          lg={noGrowers ? 12 : 9}
        >
          <div style={{ padding: "2em", maxWidth: "1200px", margin: "0 auto" }}>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <SectionTitle>
                  {/* <i className={`icon-${menuInspections.icon} icons`} />{" "} */}
                  <strong>Ranking Bairro</strong>
                  <div>
                    {
                      ranking0?.filter(
                        (u) =>
                          (u.status !== 0 && u.status !== 7) ||
                          (u.status === 7 && Number(u.attrStatus) === 6)
                      )?.length
                    }{" "}
                    Selecionados / {thisQuotas.ranking0} Lugares
                  </div>
                </SectionTitle>
                <OptionWrapRank>
                  {ranking0?.map((grower, i) => (
                    <RankingCard grower={grower} i={i} />
                  ))}
                </OptionWrapRank>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <SectionTitle>
                  {/* <i className={`icon-${menuInspections.icon} icons`} />{" "} */}
                  <strong>Ranking Proximidade</strong>
                  <div>
                    {
                      ranking1?.filter(
                        (u) =>
                          (u.status !== 0 && u.status !== 7) ||
                          (u.status === 7 && Number(u.attrStatus) === 6)
                      )?.length
                    }{" "}
                    Selecionados / {thisQuotas.ranking1} Lugares
                  </div>
                </SectionTitle>
                <OptionWrapRank>
                  {ranking1?.map((grower, i) => (
                    <RankingCard grower={grower} i={i} />
                  ))}
                </OptionWrapRank>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <SectionTitle>
                  {/* <i className={`icon-${menuInspections.icon} icons`} />{" "} */}
                  <strong>Ranking Freguesia</strong>
                  <div>
                    {
                      ranking2?.filter(
                        (u) =>
                          (u.status !== 0 && u.status !== 7) ||
                          (u.status === 7 && Number(u.attrStatus) === 6)
                      )?.length
                    }{" "}
                    Selecionados / {thisQuotas.ranking2} Lugares
                  </div>
                </SectionTitle>
                <OptionWrapRank>
                  {ranking2?.map((grower, i) => (
                    <RankingCard grower={grower} i={i} />
                  ))}
                </OptionWrapRank>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={12}>
                <StyledMainButton>Confirmar Selecção</StyledMainButton>
              </Col>
            </Row> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Parks;
