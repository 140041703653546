import { MenuItem } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import GroupedButton from "../../components/Buttons/GroupedButton";
import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import Atcomplete from "../../components/form-wrappers/AtComplete";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import SideModal from "../../components/Layout/SideModal";
import { escolasURL, schoolList } from "../../config/general";
import { schoolMaterial } from "../../config/general";
import { toastr } from "react-redux-toastr";

import Content from "../../components/Layout/Content";
import Sidebar from "../../components/Layout/Sidebar";
import Materials from "../../components/Table/Materials";
import Orders from "../../components/Table/Orders";
import SchoolsSubscriptions from "../../components/Table/Schools";
import SelectComponent from "../../components/form-wrappers/Select";
import {
  setSearch,
  toggleViewInspection,
  toggleViewInterventions,
  toggleViewSchoolSubscription,
} from "../App/actions";
import {
  getMaterials,
  getOrders,
  getSubscriptions,
  setActiveType,
  sendGlobalOrders,
  sendAutumnOrders,
  sendPreInvite,
  sendReInvite,
} from "./actions";

import EditMaterial from "./EditMaterial";
import { FilterList, MaintenanceFilter, StyledWrap, TableWrap } from "./styled";

const AddMaterial = ({ closeModal }) => {
  return (
    <SideModal>
      <EditMaterial closeModal={closeModal} />
    </SideModal>
  );
};

class Schools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempData: [],
      exportExcel: false,
      search: {
        status: null,
        school: "",
        agrupamento: "",
        type: [],
        endDate: moment()
          .add(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(12, "months")
          .format("YYYY-MM-DD"),
      },
      editMaintenanceId: false,
      editInspectionId: false,
      addMaterial: false,
      type: "subscriptions",
    };
    this.sendPreInvite = this.sendPreInvite.bind(this);
    this.sendReInvite = this.sendReInvite.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.reloadSubscriptions = this.reloadSubscriptions.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    if (props.appStatus !== "getting")
      props.getSubscriptions(this.state.search);
  }

  // goToDetail(id) {
  //   const elementFound = this.props.data.find((element) => {
  //     return element.id === id;
  //   });
  //   if (!elementFound) return false;
  //   this.props.toggleViewSchoolSubscription(elementFound);
  // }

  handleTypeChange(type) {
    this.setState({ type }, () => {
      switch (this.state.type) {
        case "materials":
          this.props.getMaterials(this.state.search);

          break;
        case "orders":
          this.props.getOrders(this.state.search);

          break;
        default:
          this.props.getSubscriptions(this.state.search);
          break;
      }
    });
  }

  reloadSubscriptions() {
    this.props.getSubscriptions(this.state.search);
  }
  handleSearchChange(e) {
    this.setState(
      {
        search: { ...this.state.search, [e.target.name]: e.target.value },
      },
      () => {
        switch (this.state.type) {
          case "materials":
            this.props.getMaterials(this.state.search);

            break;
          case "orders":
            this.props.getOrders(this.state.search);

            break;
          default:
            this.props.getSubscriptions(this.state.search);
            break;
        }
      }
    );
  }

  preProcessData() {
    const { elementSelected, data } = this.props;
    const { school, status } = this.state.search;
    let processedData = data.filter((element) => {
      if (status) {
        return !status || status === "all"
          ? true
          : `${element.status}` === `${status}`;
      }

      if (school) {
        const reg = new RegExp(school, "i");
        return element.school.match(reg);
      }
      return element;
    });

    return [...this.state.tempData, ...processedData];
  }

  download(content, fileName, mimeType) {
    var a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    if (navigator.msSaveBlob) {
      // IE10
      navigator.msSaveBlob(
        new Blob([content], {
          type: mimeType,
        }),
        fileName
      );
    } else if (URL && "download" in a) {
      //html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    // else if (typeof  !== 'undefined') {
    //   .href =
    //     'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    // }
  }

  sendPreInvite() {
    const toastrConfirmOptions = {
      onOk: () => this.props.sendPreInvite(),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Enviar convite Pré-inscrição? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  sendReInvite() {
    const toastrConfirmOptions = {
      onOk: () => this.props.sendReInvite(),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      " Enviar convite Re-inscrição? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  render() {
    const { menuData } = this.props;
    return (
      <StyledWrap>
        {/*<MobileLayoutToggle />*/}
        <Sidebar
          title={menuData.name}
          text={`gerir ${menuData.name}`}
          icon={"graduation"}
          noanimation
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="maintenance-filter"
              contentClassName="maintenance-filter-content"
              horizontal={false}
            >
              <ButtonGroup>
                <GroupedButton
                  width={"31%"}
                  onClick={() => this.handleTypeChange("subscriptions")}
                  active={this.state.type === "subscriptions"}
                >
                  <MenuIcon
                    theme={this.state.type === "subscriptions"}
                    className={`icon-graduation icons`}
                  />
                </GroupedButton>{" "}
                <GroupedButton
                  width={"31%"}
                  onClick={() => {
                    this.handleTypeChange("materials");
                  }}
                  active={this.state.type === "materials"}
                >
                  <MenuIcon
                    theme={this.state.type === "materials"}
                    className={`icon-wrench icons`}
                  />
                </GroupedButton>{" "}
                <GroupedButton
                  width={"31%"}
                  onClick={() => this.handleTypeChange("orders")}
                  active={this.state.type === "orders"}
                >
                  <MenuIcon
                    theme={this.state.type === "orders"}
                    className={`icon-book-open icons`}
                  />
                </GroupedButton>
              </ButtonGroup>
              {this.state.type === "subscriptions" && (
                <>
                  <MaintenanceFilter
                    fullWidth
                    name={"status"}
                    value={this.state.search.status}
                    label={"Estado"}
                    onChange={(e, value) => {
                      this.setState({
                        search: {
                          ...this.state.search,
                          status: `${e.target.value}`,
                        },
                      });
                    }}
                    inputProps={{
                      name: "status",
                      id: "status",
                    }}
                  >
                    <MenuItem value="all">
                      <em>Todas</em>
                    </MenuItem>
                    <MenuItem value={"0"}>{"Por Aprovar"}</MenuItem>
                    <MenuItem value={1}>{"Aprovado"}</MenuItem>
                    <MenuItem value={2}>{"Atribuido"}</MenuItem>
                    <MenuItem value={3}>{"Inativo"}</MenuItem>
                    <MenuItem value={4}>{"Pré-Inscrito"}</MenuItem>
                    <MenuItem value={5}>{"Não quer"}</MenuItem>
                  </MaintenanceFilter>

                  {/* <SectionTitle>Parcelas</SectionTitle> */}

                  <Atcomplete
                    list={schoolList.map((item) => {
                      return item.escola;
                    })}
                    value={this.state.school}
                    label="Escola"
                    onChange={(e, value) => {
                      let schoolData = schoolList.find(
                        (item) => item.escola === value
                      );
                      this.setState({
                        search: {
                          ...this.state.search,
                          school: schoolData?.escola || e.target.value,
                        },
                      });
                    }}
                    name="school"
                    meta={{ invalid: false }}
                  />
                </>
              )}

              {/* {this.state.type === "materials" && (
                <MaintenanceFilter
                  fullWidth
                  name={"status"}
                  value={this.state.search.status}
                  label={"Estado"}
                  onChange={(e, value) => {
                    this.handleSearchChange(e);
                  }}
                  inputProps={{
                    name: "status",
                    id: "status",
                  }}
                >
                  <MenuItem value="all">
                    <em>Todas</em>
                  </MenuItem>
                  <MenuItem value={"0"}>{"Inativo"}</MenuItem>
                  <MenuItem value={1}>{"Ativo"}</MenuItem>
                </MaintenanceFilter>
              )} */}
              {this.state.type !== "materials" && (
                <DateRangeFilter
                  handleSearchChange={this.handleSearchChange}
                  endDate={this.state.search.endDate}
                  startDate={this.state.search.startDate}
                />
              )}

              {this.state.type === "orders" && (
                <>
                  <div style={{ width: "100%", margin: "0 0 1em 0" }}>
                    <SelectComponent
                      value={this.state.search.type || []}
                      label="Tipo de Material"
                      onChange={
                        (e) => {
                          this.setState({
                            search: {
                              ...this.state.search,
                              type: e.target.value,
                            },
                          });
                        }
                        // this({ ...data, type: e.target.value })
                      }
                      multiple
                      name="type"
                      meta={{ invalid: false }}
                      style={{ width: "100%" }}
                    >
                      {schoolMaterial &&
                        schoolMaterial.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </SelectComponent>
                  </div>
                  <MainButton
                    style={{
                      textAlign: "center",
                      display: "block",
                      width: "100%",
                    }}
                    target={"_blank"}
                    onClick={() =>
                      !this.state.exportExcel &&
                      this.setState({ exportExcel: true })
                    }
                    // onClick={() => {
                    //   this.setState({
                    //     tempData: [{}, ...this.state.tempData],
                    //   });
                    // }}
                  >
                    Exportar Ordens Globais
                  </MainButton>
                  <MainButton
                    active={"true"}
                    style={{
                      textAlign: "center",
                      display: "block",
                      marginTop: "10px",
                      width: "100%",
                    }}
                    target={"_blank"}
                    onClick={() => this.props.sendGlobalOrders()}
                    // onClick={() => {
                    //   this.setState({
                    //     tempData: [{}, ...this.state.tempData],
                    //   });
                    // }}
                  >
                    Enviar email com ordens Primavera
                  </MainButton>
                  <MainButton
                    active={"true"}
                    style={{
                      textAlign: "center",
                      display: "block",
                      marginTop: "10px",
                      width: "100%",
                    }}
                    target={"_blank"}
                    onClick={() => this.props.sendAutumnOrders()}
                    // onClick={() => {
                    //   this.setState({
                    //     tempData: [{}, ...this.state.tempData],
                    //   });
                    // }}
                  >
                    Enviar email com ordens Outono
                  </MainButton>
                  <div
                    style={{
                      opacity: ".7",
                      fontSize: ".7em",
                      margin: "1em 0",
                    }}
                  >
                    <b>Enviar email com ordens Primavera</b>
                    <br />
                    Envia um email com para todos os utilizadores inscritos com
                    os materiais escolhidos durante a Inscrição ou Re-inscrição.
                    <br />
                    Só seram contabilizados os materiais de primavera
                    <br />
                    <br />
                    <b>Enviar email com ordens Outono</b>
                    <br />
                    Envia um email para todos os utilizadores pré-inscritos que
                    escolheram materiais de Outono
                    <br />
                    Só seram contabilizados os materiais de Outono
                    <br />
                    <b>Workflow</b>
                    Antes de enviar cada email deve validar cada ordem e passar
                    para o estado finalizado. Apenas é enviado email para ordens
                    finalizadas.
                    <br />
                    Após o envio do email a ordem é marcada como "Enviado"
                    <br />
                    Quando a escola se pré-inscreve, a ordem volta novamente ao
                    estado Ativa, e deve ser finalizada novamente.
                    <br />
                    Quando o novo ciclo começa, as ordens de inscrinções antigas
                    irão desaparecer após a atribuição de uma nova inscrição.
                  </div>
                </>
              )}
              {this.state.type === "materials" && (
                <>
                  {this.state.addMaterial && (
                    <AddMaterial
                      closeModal={() =>
                        this.setState({ addMaterial: false }, () => {
                          this.props.getMaterials(this.state.search);
                        })
                      }
                    />
                  )}
                  <MainButton
                    style={{
                      textAlign: "center",
                      display: "block",
                      width: "100%",
                    }}
                    target={"_blank"}
                    onClick={() => {
                      this.setState({
                        addMaterial: !this.state.addMaterial,
                      });
                    }}
                  >
                    Adicionar Material
                  </MainButton>
                  <div
                    style={{
                      opacity: ".7",
                      fontSize: ".7em",
                      margin: "1em 0",
                    }}
                  >
                    <b>Importante</b>
                    <br />
                    Deve sempre incluir Niveis a cada material adicionado, para
                    que o sistema consiga fazer a atribuição correta.
                  </div>
                </>
              )}
              {this.state.type === "subscriptions" && (
                <>
                  {/* <MainButton
                    style={{ textAlign: "center", display: "block" }}
                    href={escolasURL}
                    target={"_blank"}
                    // onClick={() => {
                    //   this.setState({
                    //     tempData: [{}, ...this.state.tempData],
                    //   });
                    // }}
                  >
                    Adicionar Inscrição
                  </MainButton> */}
                  <MainButton
                    active={"true"}
                    style={{
                      textAlign: "center",
                      display: "block",
                      marginTop: "10px",
                      width: "100%",
                    }}
                    target={"_blank"}
                    onClick={() => this.sendPreInvite()}
                  >
                    Enviar convite Pré-inscrição
                  </MainButton>
                  <MainButton
                    style={{
                      textAlign: "center",
                      display: "block",
                      marginTop: "10px",
                      width: "100%",
                    }}
                    target={"_blank"}
                    onClick={() => this.sendReInvite()}
                  >
                    Enviar convite Re-inscrição
                  </MainButton>
                  <div
                    style={{
                      opacity: ".7",
                      fontSize: ".7em",
                      margin: "1em 0",
                    }}
                  >
                    <b>Enviar convite Pré-inscrição"</b>
                    <br />O sistema envia uma mensagem a todos os utilizadores
                    com uma inscrição "atribuida". A mensagem encaminha o
                    utilizador para https://escolas.terrasdecascais.pt/pre/id da
                    inscrição. <br />
                    <br />A Escola pode confirmar a pre-inscrição ou cancelar a
                    mesma. Passando assim o estado da inscrição para
                    "Pré-Inscrição" ou "Não quer Pré-Inscricão".
                    <br />
                    <br />
                    <b>Enviar convite Re-inscrição"</b>
                    <br />
                    O sistema envia um email para todos as escolas com uma uma
                    Pré-Inscricão ou conta Atribuida. A mensagem encaminha o
                    utilizador para https://escolas.terrasdecascais.pt/ . <br />
                    <br />
                    Ao concluir a inscrição a escola passa a ter uma nova linha
                    com uma inscrição "Por Aprovar". Após validação do
                    administrador da escola a inscrição passa a "Aprovado".
                    <br />É possivel depois atribuir esta inscrição, o que
                    remove e desativa todas as atribuições anteriores
                  </div>
                </>
              )}
            </ScrollArea>
          </FilterList>
        </Sidebar>
        <Content size={2} color={"white"} map={true}>
          <TableWrap display={"display"}>
            {this.state.type === "subscriptions" && (
              <SchoolsSubscriptions
                orderBy={"id"}
                data={this.preProcessData()}
                reloadData={this.reloadSubscriptions}
                // click={this.goToDetail}
              />
            )}
            {this.state.type === "materials" &&
              this.props.appStatus === "received" && (
                <Materials
                  orderBy={"id"}
                  data={this.preProcessData()}
                  reloadData={() => this.props.getMaterials(this.state.search)}
                  // reloadData={this.props.getMaterials(this.state.search)}
                  // click={this.goToDetail}
                />
              )}
            {this.state.type === "orders" &&
              this.props.appStatus === "received" && (
                <Orders
                  orderBy={"id"}
                  exportExcel={this.state.exportExcel}
                  setExportExcel={(value) =>
                    this.state.exportExcel !== value &&
                    this.setState({ exportExcel: value })
                  }
                  materialFilter={this.state.search.type || []}
                  data={this.preProcessData()}
                  reloadData={() => this.props.getOrders(this.state.search)}
                  // click={this.goToDetail}
                />
              )}
          </TableWrap>
        </Content>
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      appStatus: state.schools.status,
      startDate: state.app.search.startDate,
      selected: state.app.search.selected,
      elementSelected: state.app.search.element,
      freguesia: state.app.search.freguesia,
      endDate: state.app.search.endDate,
      role: state.user.data.role,
      list: state.parklist.list,
      data: state.schools.data,
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/schools`
        ),
    };
  },
  {
    getSubscriptions,
    sendGlobalOrders,
    sendAutumnOrders,
    sendPreInvite,
    sendReInvite,
    setSearch,
    getMaterials,
    getOrders,
    setActiveType,
    toggleViewInspection,
    toggleViewSchoolSubscription,
    toggleViewInterventions,
  }
)(Schools);
