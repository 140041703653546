import {
  GETTING,
  RECEIVED,
  MESSAGE_RECEIVED,
  SAVING,
  SAVED,
  SET_CONTACTS,
  ERROR,
  FINISHED,
  SET_DETAILS,
} from "./constants";

import { getCurrentDate } from "../../services/utils";
import { status } from "../../config/general";

let initialState = {
  status: "",
  contacts: [],
  edit: {},
  data: [],
};

const isLocalStorage = typeof localStorage !== "undefined";
//lets load user data for reload
if (isLocalStorage && localStorage.communications) {
  initialState = JSON.parse(localStorage.communications);
  initialState.search = {
    ...initialState.search,
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DETAILS:
      return {
        ...state,
        edit: action.data,
      };
    case SAVING:
      return {
        ...state,
        status: status.saving,
      };
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.data,
      };
    case FINISHED:
      return {
        ...state,
        status: "",
      };
    case SAVED:
      return {
        ...state,
        status: status.saved,
      };
    case GETTING:
      return {
        ...state,
        status: status.getting,
      };
    case MESSAGE_RECEIVED:
      return {
        ...state,
        status: status.received,
        edit: action.data,
      };
    case RECEIVED:
      return {
        ...state,
        status: status.received,
        data: action.data,
      };

    case ERROR:
      return { ...state, status: status.error };

    default:
      return state;
  }
};
