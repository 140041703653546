import { MenuItem } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Select from "../../components/form-wrappers/Select";
import { recenterMap } from "../../containers/App/actions";
import { getParkHistory } from "../../containers/Reports/actions";

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

const StyledMenuItem = styled((props) => <MenuItem {...props} />)`
  && {
    text-transform: uppercase;
  }
`;

class LocationHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: [],
    };
    this.resetSearch = this.resetSearch.bind(this);
  }

  componentDidMount(prevProps, prevState) {
    if (
      this.props.parksHistory?.length === 0 &&
      this.props.elementsHistory?.length === 0
    ) {
      this.props.getParkHistory();
    }
  }

  resetSearch() {
    this.props.handleSearchChange({
      target: {
        name: "freguesia",
        value: "",
      },
    });
  }

  handleSearchChange(e) {
    this.props.handleSearchChange(e);
    this.props.recenterMap();
  }

  sortAlphaNum(array) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    if (!(array instanceof Array)) return array;
    return array.sort((a, b) => {
      var aA = a.replace(reA, "");
      var bA = b.replace(reA, "");
      if (aA === bA) {
        var aN = parseInt(a.replace(reN, ""), 10);
        var bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    });
  }

  render() {
    const {
      list,
      hideElements,
      handleSearchChange,
      elmentDisplay,
      elementsGeoJson,
      elementsHistory,
      parksHistory,
    } = this.props;

    let { freguesia, selected, elementType, elementSelected } = this.props;

    if (this.props.override) {
      freguesia = this.props.override.freguesia;
      selected = this.props.override.selected;
      elementType = this.props.override.elementType;
      elementSelected = this.props.override.elementSelected;
    }

    let elementTypesList = [];

    const location = [
      ...new Set(parksHistory?.map((park) => park?.about?.freguesia) || []),
    ];

    return (
      <Section>
        {/* {!notitle && <SectionTitle>Local</SectionTitle>} */}
        {/* <Reset
          onClick={() => this.resetSearch()}
          className={'icon-close icons reset'}
        /> */}
        {location?.length > 0 && (
          <Select
            fullWidth
            name={"freguesia"}
            label={"Freguesia"}
            value={freguesia || ""}
            margin="dense"
            onChange={(e) => {
              handleSearchChange(e);
            }}
            inputProps={{
              name: "freguesia",
              id: "freguesia",
            }}
          >
            <StyledMenuItem key={-1} value={""}>
              Todos
            </StyledMenuItem>{" "}
            {location.map((freguesia, index) => (
              <StyledMenuItem key={index} value={freguesia}>
                {freguesia}
              </StyledMenuItem>
            ))}
          </Select>
        )}

        {freguesia !== "" && parksHistory?.length > 0 && (
          <Select
            fullWidth
            name={"selected"}
            label={"Horta"}
            value={selected || ""}
            inputProps={{
              name: "selected",
              id: "selected",
            }}
          >
            <StyledMenuItem
              key={-1}
              value={""}
              // onClick={() => {
              //   handleSearchChange({
              //     target: {
              //       name: "selected",
              //       value: "",
              //     },
              //   });
              // }}
            >
              Todos
            </StyledMenuItem>
            {parksHistory
              ?.filter((park) => {
                return park?.about?.freguesia === freguesia;
              })
              ?.map((selectedPark, index) => {
                return (
                  <StyledMenuItem
                    key={index}
                    onClick={() => {
                      handleSearchChange({
                        target: {
                          name: "selected",
                          value: selectedPark.pid,
                        },
                      });
                    }}
                    selected={selectedPark.pid === selected}
                    value={selectedPark.pid}
                  >
                    {selectedPark.about.nome}
                  </StyledMenuItem>
                );
              })}
          </Select>
        )}

        <Select
          fullWidth
          name={"elementSelected"}
          label={"Elemento"}
          value={this.props.override ? elementSelected : elementSelected || []}
          onChange={(e) => {
            //set pid to the one of the selected element
            const foundElement = elementsHistory.find(
              (ele) =>
                ele.origin === e.target?.value || ele.id === e.target?.value
            );
            handleSearchChange({
              target: {
                name: "selected",
                value: foundElement?.pid,
              },
            });
            //set element
            handleSearchChange(e, true);
          }}
          inputProps={{
            name: "elementSelected",
            id: "elementSelected",
          }}
        >
          <StyledMenuItem key={-1} value={""}>
            Todos
          </StyledMenuItem>
          {elementsHistory
            ?.filter((ele) =>
              selected && selected !== "" ? ele.pid === selected : true
            )
            ?.reduce((acc, ele) => {
              //remove duplicates with the same id or origin
              if (
                !acc.find(
                  (element) =>
                    element.origin === ele.origin || element.id === ele.id
                )
              ) {
                acc.push(ele);
              }
              return acc;
            }, [])

            ?.map((element, index) => {
              //find park if its not in element.parkName
              if (!element.parkName) {
                const foundPark = list.find(
                  (park) => park.numero === element.pid
                );
                element.parkName = foundPark?.nome;
              }

              return (
                <StyledMenuItem
                  key={index}
                  value={element.origin || element.id}
                >
                  {selected
                    ? element.label
                    : `${element.label} - ${element.parkName}`}
                </StyledMenuItem>
              );
            })}
        </Select>
      </Section>
    );
  }
}

export default connect(
  (state) => {
    return {
      list: state.parklist.list,
      elementsGeoJson: state.app.elementsGeoJson,
      elmentDisplay: state.app.elmentDisplay,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      location: state.parklist.location,
      elementType: state.app.search.elementTypeSelected,
      elementSelected: state.app.search.element,
    };
  },
  { recenterMap, getParkHistory }
)(LocationHistory);
