import React from "react";
import styled from "styled-components";

const ContentWrap = styled.div`
  && {
    background: #f4f6f8;

    @media print {
      background: #fff;
    }
    width: calc(100% - 80px);
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 80px;
    z-index: 120;

    @media (max-width: 900px) {
      left: 0;
      width: 100%;
      padding-bottom: 160px;
    }
  }
`;
const Padder = styled.div`
  && {
    padding: ${(props) => (props.nopadding ? "0" : "2% 5% 0")};
    max-width: ${(props) => (props.fullWidth ? "100%" : "1200px")};
  }
`;
class FullScreenModal extends React.Component {
  render() {
    let scrollHeight = "auto";

    if (document && document.body && document.documentElement) {
      scrollHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );
    }
    return (
      <ContentWrap
        {...this.props}
        className={"animated fadeIn"}
        style={{ minHeight: scrollHeight }}
      >
        <Padder
          nopadding={this.props.nopadding}
          fullWidth={this.props.fullWidth}
        >
          {this.props.children}
        </Padder>
      </ContentWrap>
    );
  }
}

export default FullScreenModal;
