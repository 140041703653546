import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { ButtonGroup } from "@material-ui/core";
import { Row, Col } from "react-flexbox-grid";
import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";

import DetailsSpecific from "./steps/DetailsSpecific";
import ThankYou from "./steps/ThankYou";

import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import ViewReport from "../Reports/ViewReport";
import { setActiveStep, getMaintenances, addNewToggle } from "./actions";
import { setHistory } from "./actions";

import {
  Section,
  ButtonWrapper,
  Close,
} from "../../components/InspMain/styled";

class AddNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "info",
    };
    this.close = this.close.bind(this);
  }

  close() {
    this.props.closeModal();
    if (this.props.status === "saved") {
      this.props.getMaintenances();
    }
  }

  render() {
    const {
      closeModal,
      status,
      list,
      selected,
      element,
      elementsGeoJson,
    } = this.props;
    const selectedParks = list.filter(
      (park) => Number(selected) === Number(park.numero)
    );

    //override for tasks
    // if (selectedPid) this.props.addNewToggle(selectedPid, false);
    let isSchool = false;
    let parkName = "";
    let locationData = "";

    if (selectedParks.length === 0) {
      toastr.error("nenhuma parcela selecionada");

      return null;
    }

    if (selectedParks.length > 1) {
      parkName = `${selectedParks.length} Parcelas Seleccionados`;
    } else if (selectedParks.length > 0) {
      parkName = `${`${selectedParks[0].nome} ,` || ""}`;
      isSchool =
        selectedParks[0].tipo === "Hortas nas Escolas"
          ? selectedParks[0].designacao
          : false;
      locationData = `${`${selectedParks[0].freguesia} ,` ||
        ""} ${`${selectedParks[0].local} ,` || ""} ${selectedParks[0].numero ||
        ""}`;
    }

    const thisElementType = elementsGeoJson.find((eleGJ) => {
      return (
        eleGJ.properties.id === element ||
        (eleGJ.properties.origin && eleGJ.properties.origin === element)
      );
    });

    return (
      <FullScreenModal>
        <Close onClick={() => closeModal()} className={`icon-close icons`} />
        <Section>
          <Row>
            <Col xs={12} md={6}>
              <PageTitle>
                <i
                  className={`icon-${this.props.menuInterventions.icon} icons`}
                />{" "}
                {parkName}
                <span>
                  {locationData},
                  {thisElementType &&
                    thisElementType.properties.label &&
                    `${
                      thisElementType.properties.category
                        ? thisElementType.properties.category + ","
                        : ""
                    } ${thisElementType.properties.label}`}
                </span>
              </PageTitle>
            </Col>
            <Col xs={12} md={6}>
              <ButtonWrapper>
                <ButtonGroup>
                  <MainButton
                    active={this.state.view === "info" ? "true" : "false"}
                    onClick={() => this.setState({ view: "info" })}
                  >
                    <MenuIcon className={`icon-notebook icons`} /> Informação
                  </MainButton>
                  <MainButton
                    active={this.state.view === "history" ? "true" : "false"}
                    onClick={() => this.setState({ view: "history" })}
                  >
                    <MenuIcon className={`icon-calendar icons`} /> Histórico
                  </MainButton>
                </ButtonGroup>
              </ButtonWrapper>
            </Col>
          </Row>
          {status !== "saved" && this.state.view === "info" && (
            <DetailsSpecific
              isSchool={isSchool}
              // selectedParkId={selectedPark.pid}
              selectedElement={thisElementType}
            />
          )}
          {status !== "saved" && this.state.view === "history" && (
            <ViewReport
            // selectedParkId={selectedPark.pid}
            />
          )}
          {status === "saved" && <ThankYou close={this.close} />}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.maintenances.type,
      activeStep: state.maintenances.activeStep,
      status: state.maintenances.status,
      list: state.parklist.list,
      role: state.user.data.role,
      selected: state.app.search.selected,
      local: state.app.search.local,
      element: state.app.search.element,
      elementsGeoJson: state.app.elementsGeoJson,
      menuInterventions:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/interventions`
        ),
    };
  },
  { setActiveStep, addNewToggle, getMaintenances, setHistory }
)(AddNew);
