export const SAVING = 'PARKLIST_SAVING';
export const SAVED = 'PARKLIST_SAVED';
export const ERROR = 'PARKLIST_ERROR';
export const RESET = 'PARKLIST_RESET';
export const USER_POSITION = 'USER_POSITION';
export const CENTER = 'map/CENTER';

export const GETTING = 'PARKLIST_REQUEST';
export const RECEIVED = 'PARKLIST_SUCCESS';
export const UPDATED = 'PARKLIST_UPDATED';

export const SET_GEOJSON = 'GEOREFERENCE/SET_GEOJSON';
export const SET_GEOJSON_ELEMENTS = 'GEOREFERENCE/SET_GEOJSON_ELEMENTS';
