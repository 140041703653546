import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import FullScreenModal from '../../../components/Layout/FullScreenModal';
import PageTitle from '../../../components/Text/PageTitle';

import AddElementDetails from './AddElementDetails';

// import { setActiveStep, getMaintenances, addNewToggle } from './actions';
import { resetElement, getElementsTypes, getVegetationTypes } from '../actions';

const Section = styled.div`
  && {
    margin: 2em 0;
    max-width: 1200px;
  }
`;

const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;

class AddNew extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    if (!props.elementType || props.elementType.length < 1)
      props.getElementsTypes();
    if (!props.vegetationTypes || props.vegetationTypes.length < 1)
      props.getVegetationTypes();
  }

  close() {
    this.props.resetElement();
    // this.props.getElements();
  }

  renderTemplate() {
    // const { poligonType, elementType, elementSubType } = this.props;
    // switch (poligonType) {
    //   case 'circlemarker':
    //     if (elementSubType && elementSubType !== '') return <AddElementDetails />;
    //     if (elementType === 'Mobiliario') return <TypeEquipment />;
    //     if (elementType === 'Contador' || elementType === 'Rega')
    //       return <AddElementDetails />;
    //     return <TypePoint />;
    //   case 'polygon':
    //     break;
    //   default:
    //     break;
    // }
  }

  render() {
    const { list, selected } = this.props;

    const selectedParks = list.find(park => selected === park.numero);
    let parkName = `${selectedParks.nome || ''}, ${selectedParks.local || ''}`;

    return (
      <FullScreenModal>
        <Close onClick={() => this.close()} className={`icon-close icons`} />
        <Section>
          <PageTitle>
            <i className={`icon-location-pin icons`} /> {parkName}
          </PageTitle>
          {/* {this.renderTemplate()} */}
          {this.props.elementTypes && <AddElementDetails />}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  state => {
    return {
      poligonType: state.georeference.type,
      elementType: state.georeference.newItem.type,
      elementSubType: state.georeference.newItem.subType,
      elementTypes: state.georeference.elementTypes,
      vegetationTypes: state.georeference.vegetationTypes,
      selected: state.app.search.selected,
      geoJson: state.georeference.geoJson,
      status: state.georeference.status,
      list: state.parklist.list,
      pid: state.georeference.pid,
    };
  },
  { resetElement, getElementsTypes, getVegetationTypes }
)(AddNew);
