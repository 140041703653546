import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Content from "../../components/Layout/Content";
import Sidebar from "../../components/Layout/Sidebar";
import PageTitle from "../../components/Text/PageTitle";
import { debounce } from "lodash";

import { default as ViewInspection } from "../Inspections/View";
import Table from "../../components/Table/Table";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import { getFreguesiasReports, getMonthReport } from "./actions";

const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  /* height: calc(100vh - 49px); */
`;

const Card = styled.div`
  && {
    /* base: */
    background: #ffffff;
    border: 1px solid #e9eff4;
    border-radius: 6px;
    padding: 1em;
    margin: 0 0 1em 0;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    display: flex;

    .element {
      text-align: left;
      font-size: 0.8em;
      display: inline-block;
      color: rgba(74, 144, 226, 0.6);
    }
    .freguesia {
      font-size: 1em;
      text-align: left;
      display: block;
      color: rgba(74, 144, 226, 1);
      margin-bottom: 0;
    }

    .details {
      font-size: 0.8em;
      display: block;
      color: #989898;
    }

    .date {
      /* 11:00 AM - 11:30 AM: */
      margin-top: 9;
      opacity: 1;
      font-size: 0.9em;
      color: rgba(74, 144, 226, 0.8);
      letter-spacing: -0.32px;
      display: block;
      text-align: left;
    }
  }
`;

const TableWrap = styled.div`
  && {
    padding: 2% 5% 5%;
    display: ${(props) => (props.display === "display" ? "block" : "none")};
    > div {
      margin: 0;
      padding-top: 0;
    }
  }
`;

const PrintButton = styled.span`
  && {
    font-size: 1em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    display: inline-block !important;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
  }
`;

const SlectReport = styled.span`
  && {
    font-size: 1.6em;
    margin: 0 0.3em 0 0;
    text-align: center;
    color: rgba(74, 144, 226, 0.4);
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    cursor: pointer;
  }
`;
const Inline = styled.div`
  && {
    display: inline-block;
  }
`;

class ViewReportManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      args: props.args,
      group: props.group,
      reportId: null,
      editInspectionId: false,
    };
    this.generatePDF = this.generatePDF.bind(this);
    this.goToDetail = this.goToDetail.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getMonthReport = debounce(this.getMonthReport.bind(this), 400);
  }

  componentDidMount() {
    // first we need to get the freguisias list
    if (this.props.freguesiasReport === null && this.props.parks.length > 0) {
      this.props.getFreguesiasReports();
    }

    // then we can get the first report
    if (
      this.props.freguesiasReport !== null &&
      this.props.freguesiasReport.length > 0 &&
      this.props.parks.length > 0 &&
      this.props.monthStatus === null
    ) {
      const firstReport = this.props.freguesiasReport[0];
      this.getMonthReport(firstReport.freguesia, firstReport.date, 0);
    }
  }

  goToDetail(id) {
    const elementFound = this.props.monthReportData.find((element) => {
      return element.id === id;
    });

    if (!elementFound) return false;
    this.setState({
      editInspectionId: id,
    });
  }

  closeModal() {
    this.setState({
      editInspectionId: null,
    });
  }

  generatePDF() {
    window && window.print();
  }

  getHeader() {
    return [
      {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
        options: {
          display: false,
        },
      },
      {
        id: "location",
        numeric: false,
        disablePadding: false,
        label: "Folha",
      },
      {
        id: "pid",
        numeric: false,
        disablePadding: false,
        label: "Parcela",
      },
      {
        id: "Damage.label",
        numeric: false,
        disablePadding: false,
        label: "Fizcalização",
      },
      {
        id: "details",
        numeric: false,
        disablePadding: false,
        label: "Observações",
      },
      {
        id: "rating",
        numeric: false,
        disablePadding: false,
        label: "Avaliação",
      },
      {
        id: "Element.id",
        numeric: false,
        disablePadding: false,
        label: "eId",
      },
      {
        id: "Element.ElementType.label",
        numeric: false,
        disablePadding: false,
        label: "Elemento",
      },
    ];
  }

  getMonthReport(local, date, i) {
    if (this.props.monthStatus !== "getting") {
      this.setState({ reportId: i });
      return this.props.getMonthReport(local, date);
    }
  }

  preProcessData() {
    return this.props.monthReportData;
  }

  render() {
    const { freguesiasReport, menuDataReports } = this.props;
    const columnData = this.getHeader();
    const processedData = this.preProcessData();
    let selectedReport;
    if (freguesiasReport && freguesiasReport.length > 0) {
      selectedReport = freguesiasReport[this.state.reportId || 0];
      selectedReport.date = new Date(selectedReport.date);
    }
    return (
      <StyledWrap>
        {/*<MobileLayoutToggle />*/}
        <Sidebar
          title={`${menuDataReports.name} Mensais`}
          text={`Relatorios de ${this.props.menuData.name}`}
          icon={"graph"}
        >
          <ScrollArea
            speed={0.8}
            className="cardHolder-filter"
            contentClassName="cardHolder-filter-content"
            horizontal={false}
          >
            {freguesiasReport &&
              freguesiasReport.map((report, index) => {
                const thisDate = new Date(report.date);
                return (
                  <Card
                    key={index}
                    onClick={() =>
                      this.getMonthReport(report.freguesia, report.date, index)
                    }
                  >
                    <SlectReport className={`icon-graph icons`} />
                    <Inline>
                      <span className={"freguesia"}>{report.freguesia}</span>
                      <span className={"date"}>
                        {thisDate.getFullYear()}/{thisDate.getMonth() + 1}
                      </span>
                    </Inline>

                    {/* <StyledMainButton onClick={() => this.toggleAddNew()}>
                    <MenuIcon className={`icon-check icons`} />Validar
                  </StyledMainButton> */}
                  </Card>
                );
              })}
          </ScrollArea>
          {/* <TeamFilter handleSearchChange={this.handleSearchChange} /> */}
        </Sidebar>
        <Content size={2} color={"white"} map={true}>
          <TableWrap
            display={
              !this.state.editInspectionId && !this.state.editMaintenanceId
                ? "display"
                : "hide"
            }
          >
            <div className={"title-header-block"}>
              {selectedReport && (
                <PageTitle>
                  {selectedReport.freguesia}{" "}
                  <PrintButton
                    onClick={() => this.generatePDF()}
                    className={`icon-printer icons`}
                  />
                  <span>
                    {selectedReport.date.getFullYear()}/
                    {selectedReport.date.getMonth() + 1}
                  </span>
                </PageTitle>
              )}
            </div>
            {freguesiasReport && (
              <Table
                orderBy={"id"}
                data={processedData}
                click={this.goToDetail}
                header={columnData}
              />
            )}
          </TableWrap>{" "}
        </Content>

        {this.state.editInspectionId && (
          <ViewInspection
            onClose={this.closeModal}
            group={this.state.editInspectionId}
          />
        )}
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      historyData: state.reports.reportData,
      monthReportData: state.reports.monthReportData,
      monthStatus: state.reports.monthStatus,
      freguesiasReport: state.reports.freguesiasReport,
      status: state.reports.status,
      parks: state.parklist.list,
      role: state.user.data.role,
      isAdmin: state.user.data.admin,
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/inspections`
        ),
      menuDataReports:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/reports`
        ),
    };
  },
  {
    getFreguesiasReports,
    getMonthReport,
  }
)(ViewReportManager);
