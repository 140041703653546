import React, { Component } from "react";
import styled from "styled-components";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import Select from "../../components/form-wrappers/Select";

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

class ParcelsFilter extends Component {
  render() {
    const { location, multiple } = this.props;
    let freguesia = this.props.freguesia || "";
    if (multiple) {
      freguesia = this.props.freguesia || [];
    }
    return (
      <Section>
        <Select
          fullWidth
          multiple={this.props.multiple}
          name={"freguesia"}
          label={"Freguesia"}
          value={freguesia}
          disabled={this.props.view}
          onChange={this.props.handleFreguesiaChange}
          inputProps={{
            name: "freguesia",
            id: "freguesia",
          }}
        >
          {/* <MenuItem key={-1} value={''} /> */}
          {Object.keys(location)
            .sort()
            .map((freguesia, index) => (
              <MenuItem key={index} value={freguesia}>
                {freguesia}
              </MenuItem>
            ))}
        </Select>
      </Section>
    );
  }
}

export default connect((state) => {
  return {
    list: state.parklist.list,
    location: state.parklist.location,
  };
}, {})(ParcelsFilter);
