export const USER_LOGGING_IN = "USERS/USER_LOGGING_IN";
export const USER_LOGGED_IN = "USERS/USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USERS/USER_LOGGED_OUT";
export const USER_LOGGED_IN_ERROR = "USERS/USER_LOGGED_IN_ERROR";
export const USER_POSITION = "USERS/USER_POSITION";
export const DONE_REFRESHING_TOKEN_SUCCESS =
  "USERS/DONE_REFRESHING_TOKEN_SUCCESS";
export const REFRESHING_TOKEN_REQUEST = "USERS/REFRESHING_TOKEN_REQUEST";
export const TOKEN_UPDATED = "USERS/TOKEN_UPDATED";
export const FORM_NAME = "USERS/login";
export const CENTERED = "USERS/map/CENTERED";
export const CENTER = "USERS/map/CENTER";
export const REQUIRED_FIELDS = ["email", "password"];

export const UPDATE_SEARCH = "USERS/UPDATE_USERS_SEARCH";
export const GETTING = "USERS/USERS_GETTING";
export const GETTING_TEAMS = "USERS/TEAMS_GETTING";
export const GETTING_GROWERS = "USERS/GROWERS_GETTING";
export const RECEIVED = "USERS/USERS_RECEIVED";
export const RECEIVED_GROWERS = "USERS/RECEIVED_GROWERS";
export const RECEIVED_GROWERS_LIST = "USERS/RECEIVED_GROWERS_LIST";
export const RECEIVED_TEAMS = "USERS/TEAMS_RECEIVED";
export const RECEIVED_USER = "USERS/RECEIVED_USER";
export const RECEIVED_GROWER = "USERS/RECEIVED_GROWER";
export const DONE = "USERS/DONE";
export const SET_SEARCH_DATE = "USERS/SET_SEARCH_DATE";

export const GROWER_HISTORY_GETTING = "USERS/GROWER_HISTORY_REQUEST";
export const GROWER_HISTORY_RECEIVED = "USERS/GROWER_HISTORY_SUCCESS";

export const SET_USER_INIT_DATA = "USERS/SET_USER_INIT_DATA";
export const SET_TEAM_INIT_DATA = "USERS/SET_TEAM_INIT_DATA";
export const SET_GROWER_INIT_DATA = "USERS/SET_GROWER_INIT_DATA";

export const GETTING_USER_RANKINGS = "USERS/GETTING_USER_RANKINGS";
export const RECEIVED_USER_RANKINGS = "USERS/RECEIVED_USER_RANKINGS";

export const GROWER_RELATION_GETTING = "USERS/GROWER_RELATION_GETTING";
export const GROWER_RELATION_RECEIVED = "USERS/GROWER_RELATION_RECEIVED";

export const SEARCH_ADDRESS = "USERS/SEARCH_ADDRESS";
export const GOT_ADDRESS = "USERS/GOT_ADDRESS";

export const SET_SELECTED_ROWS = "USERS/SET_SELECTED_ROWS";

export const SEARCH_GEOLOCATION = "USERS/SEARCH_GEOLOCATION";
export const GOT_GEOLOCATION = "USERS/GOT_GEOLOCATION";

export const ERROR = "USERS/USERS_ERROR";
export const ERROR_TEAMS = "USERS/TEAMS_ERROR";
export const UPDATED = "USERS/USERS_UPDATED";
export const SAVING = "USERS/USERS_SAVING";
export const SAVED = "USERS/USERS_SAVED";
export const RESETUSER = "USERS/RESETUSER";
export const SET_DETAILS = "USERS/USER_SET_DETAILS";
export const SET_GROWER_DETAILS = "USERS/SET_GROWER_DETAILS";
