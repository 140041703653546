import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";
import moment from "moment";

export function setSearch(seachElement) {
  return {
    type: constants.UPDATE_SEARCH,
    data: seachElement,
  };
}

export function setParkInRoute(park) {
  return {
    type: constants.TOGGLE_PARK,
    data: park,
  };
}

export function setRotasDetails(setElement) {
  return {
    type: constants.ROUTE_UPDATE,
    data: setElement,
  };
}

export function editRouteById(id) {
  return {
    type: constants.ROUTE_EDIT,
    data: id,
  };
}

export function getEvents() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });
    const search = store.getState().app.search;
    let parks = "all";

    const date = new Date(search.startDate),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    if (search.selected.length > 0) parks = search.selected || "all";

    axios
      .get(
        `/api/v1/events/${encodeURI(search.freguesia || "all")}/${encodeURI(
          search.local || "all"
        )}/${encodeURI(parks)}/${encodeURI(
          moment(firstDay).format("YYYY-MM-DD")
        )}/${encodeURI(moment(lastDay).format("YYYY-MM-DD"))}/${encodeURI(
          search.team || "all"
        )}`
      )
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter os eventos');

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      });
  };
}

export function delRoute() {
  return (dispatch) => {
    dispatch({
      type: constants.ROUTE_DELETE,
    });
    const data = store.getState().calendar.route;
    axios
      .delete(`/api/v1/routes/${data.id}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ROUTE_SAVED,
            data: response.data.msg,
          });
          toastr.success("Rota", "Rota eleminada com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      });
  };
}

export function setRoute() {
  return (dispatch) => {
    dispatch({
      type: constants.ROUTE_SAVING,
    });
    const data = store.getState().calendar.route;

    axios
      .post(`/api/v1/routes/${data.id || ""}`, {
        list: data.list,
        endDate: data.endDate,
        startDate: data.startDate,
        frequency: data.frequency,
        team: data.team,
      })
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ROUTE_SAVED,
            data: response.data.msg,
          });
          toastr.success("Rota", "Rota salva com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      });
  };
}
export function duplicateRoute() {
  return (dispatch) => {
    dispatch({
      type: constants.ROUTE_SAVING,
    });
    const data = store.getState().calendar.route;

    axios
      .post(`/api/v1/routes/`, {
        list: data.list,
        endDate: data.endDate,
        startDate: data.startDate,
        frequency: data.frequency,
        team: data.team,
      })
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ROUTE_SAVED,
            data: response.data.msg,
          });
          toastr.success("Rota", "Rota duplicada com sucesso");
        }
      })
      .catch(function(response) {
        toastr.error("Verifique os dados introduzidos");

        dispatch({
          type: constants.ERROR,
        });
        console.log(response);
      });
  };
}
