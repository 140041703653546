import { Button, Chip, Tooltip } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { BsFillCalendar2XFill } from "react-icons/bs";
import { FaMapMarkerAlt, FaPhoneSlash } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import { IoIosSchool } from "react-icons/io";
import { RxLapTimer } from "react-icons/rx";
import { toastr } from "react-redux-toastr";
import { store } from "../../store/configure";
import { ActionsWrap, ButtonIcon } from "./styled";
import Table from "./Table";

export default function(props) {
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [rowSort, setRowSort] = useState({ name: "", direction: "asc" });
  const [selectedUserParks, setSelectedUserParks] = useState({});
  const processedData = (data) => {
    if (!data) return [];
    return data.map((row) => {
      return { ...row, equipa: row.Team ? row.Team.label : "" };
    });
  };

  /**
   * viewGrower is the grower that is being previewed
   */
  const viewGrower = (grower) => {
    store.dispatch({ type: "TOGLE_VIEW_GROWER", data: grower });
  };

  //set unique list of parks selectd per user id
  const setParkToUser = (user, park) => {
    // get user parks from selectedParks
    const userParks = selectedUserParks[user] || [];
    // if park is not in userParks, add it, else remove it
    if (!userParks.includes(park)) {
      userParks.push(park);
    } else {
      userParks.splice(userParks.indexOf(park), 1);
    }
    // set selectedParks
    setSelectedUserParks({ ...selectedUserParks, [user]: userParks });
    toastr.info(
      "Horta selecionada",
      "Após selecionar todas as hortas preferidas, clique em 'Atualizar Hortas' para guardar as alterações"
    );
  };

  const processedDynamicCollumns = (data) => {
    const columnData = [
      {
        id: "id",
        name: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
        options: {
          display: false,
        },
      },

      {
        id: "educationDone",
        name: "educationDone",
        numeric: false,
        disablePadding: true,
        label: "educationDone",
        options: {
          display: false,
        },
      },

      {
        id: "status",
        name: "status",
        numeric: false,
        disablePadding: true,
        label: "status",
        options: {
          display: false,
        },
      },

      {
        id: "attrStatus",
        name: "attrStatus",
        numeric: false,
        disablePadding: true,
        label: "attrStatus",
        options: {
          display: false,
        },
      },
      {
        id: "status",
        name: "status",
        numeric: false,
        disablePadding: false,
        label: "Estado",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === 10) {
              return <Chip color={"default"} label={"Selecionado"} />;
            } else if (value === 7) {
              return (
                <Chip color={"secondary"} label={"Atribuido Transferencia"} />
              );
            } else if (value === 11) {
              return <Chip color={"primary"} label={"Em Formação"} />;
            } else if (value === 9) {
              return <Chip color={"default"} label={"Suplente"} />;
            } else if (value === 6) {
              return <Chip color={"default"} label={"Atribuido Pendente"} />;
            }
          },
        },
      },

      {
        id: "name",
        name: "name",
        numeric: false,
        disablePadding: false,
        label: "Nome",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <div
                  onClick={() => viewGrower(tableMeta.rowData[0])}
                  style={{
                    fontWeight: "bold",
                    width: "200px",
                    cursor: "pointer",
                  }}
                >
                  {value}
                </div>
              </>
            );
          },
        },
      },
      {
        id: "freguesia",
        name: "freguesia",
        numeric: false,
        disablePadding: false,
        label: "Freguesia",
        options: {
          display: false,
        },
      },
      {
        id: "phone",
        name: "phone",
        numeric: false,
        disablePadding: false,
        label: "Telefone",
      },
      {
        id: "email",
        name: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
      },
      {
        id: "note",
        name: "note",
        numeric: false,
        disablePadding: false,
        label: "Observações",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                onClick={() => viewGrower(tableMeta.rowData[0])}
                style={{ fontSize: ".8em", width: "200px" }}
              >
                {/* <textarea
                  multiline
                  name="note"
                  value={value}
                  type="multiline"
                  rows="3"
                  style={{
                    padding: "0.3em",
                    fontFamily: "Arial",
                    display: "block",
                    width: "100%",
                  }}
                /> */}
                {value.substring(0, 100)}
                {value.length > 100 && "..."}
              </div>
            );
          },
        },
      },
      {
        id: "actions",
        name: "Actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const userParks = selectedUserParks[tableMeta.rowData[0]];
            const userEducated = tableMeta.rowData[1];
            const userStatus = tableMeta.rowData[2] || 0;
            const userAttrStatus = tableMeta.rowData[3] || 0;

            return (
              <div>
                <ActionsWrap>
                  <Tooltip title={`Aguarda Resposta`}>
                    <ButtonIcon
                      onClick={() =>
                        props.updateUserAttrState(
                          Number(userAttrStatus) === 4 ? 0 : 4,
                          tableMeta.rowData[0]
                        )
                      }
                      active={Number(userAttrStatus) === 4}
                    >
                      <RxLapTimer />
                    </ButtonIcon>
                  </Tooltip>
                  <Tooltip title={`Não Atende`}>
                    <ButtonIcon
                      active={Number(userAttrStatus) === 1}
                      onClick={() =>
                        props.updateUserAttrState(
                          Number(userAttrStatus) === 1 ? 0 : 1,
                          tableMeta.rowData[0]
                        )
                      }
                    >
                      <FaPhoneSlash />
                    </ButtonIcon>
                  </Tooltip>

                  <Tooltip title={`Não está disponível`}>
                    <ButtonIcon
                      active={Number(userAttrStatus) === 3}
                      onClick={() =>
                        props.updateUserAttrState(
                          Number(userAttrStatus) === 3 ? 0 : 3,
                          tableMeta.rowData[0]
                        )
                      }
                    >
                      <BsFillCalendar2XFill />
                    </ButtonIcon>
                  </Tooltip>
                  <Tooltip title={`Hortelão quer sair do projeto`}>
                    <ButtonIcon
                      onClick={() =>
                        props.updateUserState(3, tableMeta.rowData[0])
                      }
                    >
                      <ImExit />
                    </ButtonIcon>
                  </Tooltip>
                  {!userParks && !userEducated && (
                    <Tooltip title={`Formar Hortelão`}>
                      <ButtonIcon
                        primary={Number(userStatus) !== 11}
                        active={Number(userStatus) === 11}
                        onClick={() => {
                          if (userStatus === 11) return;
                          props.updateUserState(11, tableMeta.rowData[0]);
                        }}
                      >
                        <IoIosSchool />
                      </ButtonIcon>
                    </Tooltip>
                  )}
                  {!userParks && userEducated && (
                    <Tooltip title={`Atribuir Hortelão`}>
                      <ButtonIcon
                        primary={Number(userStatus) !== 9}
                        active={Number(userStatus) === 9}
                      >
                        <FaMapMarkerAlt
                          onClick={() => {
                            if (userStatus === 9) return;
                            props.updateUserState(9, tableMeta.rowData[0]);
                          }}
                        />
                      </ButtonIcon>
                    </Tooltip>
                  )}
                </ActionsWrap>
                {userParks && (
                  <Tooltip title={`Guardar Hortas Preferenciais`}>
                    <Button
                      color="secondary"
                      variant="contained"
                      size={"small"}
                      style={{
                        fontSize: ".8em",
                        display: "block",
                        width: "100%",
                        marginTop: "0.5em",
                      }}
                      active={Number(userAttrStatus) === 5}
                      onClick={async () => {
                        await props.updateUserAttrState(
                          5,
                          tableMeta.rowData[0],
                          userParks
                        );
                        //clear selected parks for this user
                        delete selectedUserParks[tableMeta.rowData[0]];
                        setSelectedUserParks(selectedUserParks);
                      }}
                    >
                      <FaMapMarkerAlt /> Atualizar Hortas
                    </Button>
                  </Tooltip>
                )}
              </div>
            );
          },
        },
      },
      {
        id: "ranks",
        name: "ranks",
        numeric: false,
        disablePadding: false,
        sortable: false,
        label: "Data",
        options: {
          filter: false,
          sort: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return "";
          },
        },
      },
      {
        id: "attr",
        name: "attr",
        numeric: false,
        disablePadding: false,
        sortable: false,
        label: "attr",
        options: {
          filter: false,
          sort: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return "";
          },
        },
      },
      {
        id: "createdAt",
        name: "createdAt",
        numeric: false,
        disablePadding: false,
        label: "Data",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return moment(value).format("DD/MM/YYYY HH:mm");
          },
        },
      },
    ];

    /**
     * dynamic park collumns
     */
    let costumParks = [];

    data?.map((row) => {
      if (row?.ranks) {
        costumParks = [...costumParks, ...Object.keys(row?.ranks)];
      }
    });

    const parks = props.parks;

    const uniqueParks = [...new Set(costumParks)]
      .sort()
      .reverse()
      .map((park) => {
        const thisPark = parks.find((p) => p.numero === park) || {};
        const ParkQuotas = props.quotas[park] || {};

        columnData.push({
          id: `Bairro ${thisPark?.número_ant}`,
          name: `Bairro ${thisPark?.número_ant}`,
          numeric: true,
          disablePadding: false,
          label: `${ParkQuotas.ranking0 ? `${ParkQuotas.ranking0}|` : ""}Bair ${
            thisPark.número_ant
          }`,
          options: {
            filter: true,
            sortCompare: (order) => {
              return (obj1, obj2) => {
                const ranks1Val = obj1.rowData[11];
                const ranks2Val = obj2.rowData[11];
                const val1 = ranks1Val[park]?.ranking1?.row_number;
                const val2 = ranks2Val[park]?.ranking1?.row_number;
                return (
                  (Number(val1) - Number(val2) || 10000) *
                  (order === "asc" ? 1 : -1)
                );
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              const ranksVal = tableMeta.rowData[11];
              //check if park is in user selectedParks
              const isSelected = selectedUserParks[
                tableMeta.rowData[0]
              ]?.includes(park);

              return ranksVal[park]?.ranking1?.row_number ? (
                <Chip
                  color={isSelected ? "primary" : "default"}
                  onClick={() => setParkToUser(tableMeta.rowData[0], park)}
                  label={ranksVal[park]?.ranking1?.row_number}
                />
              ) : (
                "-"
              );
            },
          },
        });
        columnData.push({
          id: `Proximidade ${thisPark.número_ant}`,
          name: `Proximidade ${thisPark.número_ant}`,
          numeric: false,
          disablePadding: false,
          label: `${ParkQuotas.ranking1 ? `${ParkQuotas.ranking1}|` : ""}Prox ${
            thisPark.número_ant
          }`,
          options: {
            sort: true,
            sortCompare: (order) => {
              return (obj1, obj2) => {
                const ranks1Val = obj1.rowData[11];
                const ranks2Val = obj2.rowData[11];
                const val1 = ranks1Val[park]?.ranking2?.row_number;
                const val2 = ranks2Val[park]?.ranking2?.row_number;
                return (
                  (Number(val1) - Number(val2) || 10000) *
                  (order === "asc" ? 1 : -1)
                );
              };
            },
            // hint: "Sort by amount of hobbies",
            customBodyRender: (value, tableMeta, updateValue) => {
              const ranksVal = tableMeta.rowData[11];
              const isSelected = selectedUserParks[
                tableMeta.rowData[0]
              ]?.includes(park);
              return ranksVal[park]?.ranking2?.row_number ? (
                <Chip
                  color={isSelected ? "primary" : "default"}
                  label={ranksVal[park]?.ranking2?.row_number}
                  onClick={() => setParkToUser(tableMeta.rowData[0], park)}
                />
              ) : (
                "-"
              );
            },
          },
        });
        columnData.push({
          id: `Freguesia ${thisPark.número_ant}`,
          name: `Freguesia ${thisPark.número_ant}`,
          numeric: false,
          disablePadding: false,
          label: `${ParkQuotas.ranking2 ? `${ParkQuotas.ranking2}|` : ""}Freg ${
            thisPark.número_ant
          }`,
          options: {
            sort: true,
            sortCompare: (order) => {
              return (obj1, obj2) => {
                const ranks1Val = obj1.rowData[11];
                const ranks2Val = obj2.rowData[11];
                const val1 = ranks1Val[park]?.rankingFreguesia?.row_number;
                const val2 = ranks2Val[park]?.rankingFreguesia?.row_number;
                //if no val1 or val2 return to last order
                return (
                  (Number(val1) - Number(val2) || 10000) *
                  (order === "asc" ? 1 : -1)
                );
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              const ranksVal = tableMeta.rowData[11];
              const isSelected = selectedUserParks[
                tableMeta.rowData[0]
              ]?.includes(park);
              return ranksVal[park]?.rankingFreguesia?.row_number ? (
                <Chip
                  color={isSelected ? "primary" : "default"}
                  label={ranksVal[park]?.rankingFreguesia?.row_number}
                  onClick={() => setParkToUser(tableMeta.rowData[0], park)}
                />
              ) : (
                "-"
              );
            },
          },
        });
        return park;
      });

    return columnData;
  };

  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      rowsExpanded={rowsExpanded}
      setRowsExpanded={setRowsExpanded}
      // onColumnSortChange={(changedColumn, direction) => {
      //   setRowSort({ column: changedColumn, direction: direction });
      // }}
      // click={props.click}
      // renderExpandableRow={(rowData, rowMeta) => {
      //   const thisData = dataReady.find((d) => d.id === rowData[0]);
      //   return (
      //     <tr style={{ width: "100%" }}>
      //       <td style={{ width: "100%" }} colSpan={"20"}>
      //         <Edit
      //           noModal={true}
      //           edit={thisData}
      //           index={rowMeta.rowIndex}
      //           rowsExpanded={rowsExpanded}
      //           reloadData={props.reloadData}
      //           setRowsExpanded={setRowsExpanded}
      //         />
      //       </td>
      //     </tr>
      //   );
      // }}
      header={processedDynamicCollumns(props.data)}
    />
  );
}
