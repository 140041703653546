import styled from "styled-components";
import SendMessage from "../Buttons/SendMessage";

export const SendMessageButton = styled(SendMessage)`
  && {
    width: 100%;
    margin: 0;
  }
`;

export const Card = styled.div`
  && {
    /* base: */
    background: ${(props) =>
      props.warning ? "rgba(255,239,101,.41)" : "#ffffff"};
    border: 1px solid #e9eff4;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 6px;
    padding: 1em;
    margin: 1em 0;
    cursor: pointer;
    position: relative;

    strong {
      color: rgba(74, 144, 226, 0.6);
    }

    .photo {
      height: 150px;
      display: none;
      margin: -16px -16px 16px -16px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    .file {
      height: 150px;
      display: block;
      margin: -16px -16px 16px -16px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    .statusShow {
      display: inline-block;
      padding: 0.5em;
      background: rgba(74, 144, 226, 0.7);
      color: #fff;
      border-radius: 6px;
      font-size: 0.55em;
      font-weight: 400;
    }
    &:hover {
      /* base: */
      /* background: rgba(74, 144, 226, 0.75);
      border: 1px solid #e9eff4;
      color: #fff;
      .date,
      .damages,
      .details,
      .element,
      .report,
      strong,
      .name {
        color: #fff;
      }
      .line {
        .grower,
        .phone,
        .icons,
        .freguesia,
        .parcel,
        .note,
        .createdAt,
        .eduction {
          color: #fff;
        }
      }
      .photo {
        display: block;
      }
      .parque,
      .element,
      .type {
        color: rgba(255, 255, 255, 0.4);
      } */
    }
    &.Danos {
      border-left: 3px solid #eb3d3d;
      &:hover {
        background: #eb3d3d;
      }
    }
    &.Inicial {
      border-left: 3px solid #ebcd3d;
      &:hover {
        background: #ebcd3d;
      }
    }
    &.Final {
      border-left: 3px solid #6fc44c;
      &:hover {
        background: #6fc44c;
      }
    }
    .element {
      text-align: right;
      font-size: 1em;
      display: inline-block;
      color: rgba(74, 144, 226, 0.6);
    }
    .parque {
      font-size: 0.8em;
      text-align: right;
      display: block;
      color: rgba(74, 144, 226, 0.3);
      margin-bottom: 0.5em;
    }
    .type {
      display: inline-block;
      padding: 5px;
      position: absolute;
      right: 0.5em;
      img {
        width: 20px;
        opacity: 0.7;
      }
    }

    .details {
      font-size: 0.8em;
      display: block;
      color: #989898;
    }
    .damages {
      font-size: 0.8em;
      margin-bottom: 0.5em;
      display: inline-block;
      font-weight: bold;
      color: rgba(74, 144, 226, 1);
    }
    .date,
    .name {
      /* 11:00 AM - 11:30 AM: */
      margin-top: 1em;
      opacity: 0.7;
      font-size: 0.7em;
      color: rgba(74, 144, 226, 0.8);
      letter-spacing: -0.32px;
      display: inline-block;
      text-align: right;
    }
    .report {
      margin-top: 0;
      font-size: 1.2em;
      text-align: right;
      display: inline-block;
      font-weight: bold;
      margin-right: 0.2em;
      color: rgba(74, 144, 226, 0.8);
    }
    .element {
      margin-top: 0;
      font-size: 1em;
      text-align: right;
      display: inline-block;
      font-weight: bold;
      margin-right: 0.2em;
      color: rgba(74, 144, 226, 0.8);
    }
    .name {
      font-weight: bold;
      text-align: left;
    }

    .line {
      .icons {
        font-size: 0.8em;
        color: rgba(74, 144, 226, 0.6);
      }
      .grower,
      .parcel,
      .phone,
      .freguesia,
      .note,
      .createdAt,
      .eduction {
        font-size: 0.8em;
        display: inline-block;
        margin: 0.3em;
        color: rgba(74, 144, 226, 0.6);
      }
      .parcel {
        margin-top: 0;
        font-size: 0.9em;
      }
      .grower {
        font-weight: bold;
        color: rgba(74, 144, 226, 0.8);
        font-size: 1.1em;
      }
    }

    .attribute {
      background: #fff;
      display: block;
      border-top: 1px solid #e9eff4;
      color: #cfd1db;
      font-size: 1.2em;
      padding: 0.6em 1em;
      font-size: 0.7em;
      margin: 1em -1.4em -1.5em;
      border-radius: 0 0 5px 5px;
      :hover {
        color: #fff;
        background: rgba(74, 144, 226, 0.8);
      }
    }

    .goToPark {
      background: #fff;
      display: block;
      border-top: 1px solid #e9eff4;
      color: rgba(0, 0, 0, 0.5);
      padding: 0.6em 1em;
      font-size: 0.8em;
      margin: 1em -1.25em -1.3em -1.25em;
      border-radius: 0 0 5px 5px;
      :hover {
        color: #fff;
        background: rgba(74, 144, 226, 0.8);
      }
    }
  }
`;
