import * as constants from "./constants";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { store } from "../../store/configure";
import leafletPip from "@mapbox/leaflet-pip";
import Leaflet from "leaflet";
import moment from "moment";

export function findParkFromPosition() {
  return dispatch => {
    const geoJson = store.getState().parklist.geoJson;
    const position = store.getState().user.position;

    if (position) {
      const gjLayer = Leaflet.geoJson(geoJson);
      const results = leafletPip.pointInLayer(
        [position.lng, position.lat],
        gjLayer,
        true
      );

      dispatch({
        type: constants.CENTER
      });

      if (results.length > 0) {
        toastr.success("Foi encontrado uma parcela na sua localização");
        return results[0].feature;
      }
    }
    toastr.error(
      "Não conseguimos encontrar nenhum elemento na sua localização"
    );

    return null;
  };
}

/**
 * This renders the park for normal teams
 */
export function getTaskGeoJson(geoJson) {
  const inpections = store.getState().tasks.inspectionList;
  const maintenances = store.getState().tasks.maintenancesList;
  const routesList = store.getState().tasks.routesList;
  const { selected, startDate } = store.getState().app.search;

  if (!geoJson || !routesList) return [];

  const list = geoJson.reduce((acc, park, index) => {
    const parkInSelection = selected.includes(park.properties.numero);
    if (selected.length > 0 && parkInSelection) {
      acc.push({
        ...park,
        properties: { ...park.properties, color: "#01f4c1" }
      });
    } else if (
      selected.length < 1 &&
      routesList.find(rout => rout.list.includes(park.properties.numero))
    ) {
      let wasVisited =
        inpections.find(
          inpection =>
            inpection.pid === park.properties.numero &&
            moment(startDate).diff(inpection.date, "hours") < 16
        ) || [];
      // lets check if we have maintenances
      if (wasVisited && maintenances && wasVisited.length === 0) {
        wasVisited = maintenances.filter(
          maintenance =>
            maintenance.pid === park.properties.numero &&
            moment(startDate).diff(maintenance.date, "hours") < 16
        );
      }
      acc.push({
        ...park,
        properties: wasVisited
          ? { ...park.properties, color: "#01f4c1" }
          : park.properties
      });
    }
    return acc;
  }, []);

  return {
    geoJson: list,
    freguesia: list,
    local: list,
    selected: list
  };
}

export function getAllHortas() {
  return dispatch => {
    dispatch({
      type: constants.GETTING
    });
    toastr.info("A receber lista hortas");

    axios
      .get(`/api/v1/hortas/`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED,
            data: response.data.msg[0]
          });
          toastr.success("Lista de hortas recebida");
        }
      })
      .catch(function(response) {
        dispatch({
          type: constants.ERROR
        });
        console.log(response);
      });
  };
}
export function getAllParcelasHortas() {
  return dispatch => {
    dispatch({
      type: constants.GETTING
    });
    toastr.info("A receber lista parcelas");

    axios
      .get(`/uploads/hortas.json`)
      .then(function(response) {
        console.log(response);
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED,
            data: response.data.msg[0]
          });
          toastr.success("Lista de parcelas recebida");
        }
      })
      .catch(function(response) {
        dispatch({
          type: constants.ERROR
        });
        console.log(response);
      });
  };
}
