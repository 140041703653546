import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonGroup } from "@material-ui/core";

import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";
import { Row, Col } from "react-flexbox-grid";
import DetailsSpecific from "./steps/DetailsSpecific";
import ThankYou from "./steps/ThankYou";

import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import ViewReport from "../Reports/ViewReport";

import { setActiveStep, getInspections, addNewToggle } from "./actions";
import { setHistory } from "./actions";

import {
  Section,
  ButtonWrapper,
  Close,
} from "../../components/InspMain/styled";

class AddNew extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.state = {
      view: "info",
    };
  }

  close() {
    this.props.closeModal();
    if (this.props.status === "saved") {
      this.props.getInspections();
    }
  }

  render() {
    const {
      list,
      closeModal,
      status,
      element,
      selected,
      elementsGeoJson,
    } = this.props;

    // const pidFormHistory =
    //   !pid && history && history.length > 0 ? history[0].pid : pid;

    // if (selectedPid) this.props.addNewToggle(selectedPid, false);
    const selectedParks = list.filter((park) => selected === park.numero);

    //override for tasks
    // if (selectedPid) this.props.addNewToggle(selectedPid, false);
    let parkName = "";
    let isSchool = false;
    let locationData = "";
    if (selectedParks.length > 1) {
      parkName = `${selectedParks.length} Parcelas Selecionadas`;
    } else if (selectedParks.length > 0) {
      parkName = `${`${selectedParks[0].nome} ,` || ""}`;
      isSchool =
        selectedParks[0].tipo === "Hortas nas Escolas"
          ? selectedParks[0].designacao
          : false;
      locationData = `${`${selectedParks[0].freguesia} ,` ||
        ""} ${`${selectedParks[0].local} ,` || ""} ${selectedParks[0].numero ||
        ""}`;
    }
    // const thisElementType = elementsGeoJson.find(eleGJ => {
    //   if (eleGJ.properties.id) {
    //     return eleGJ.properties.id === element;
    //   } else if (eleGJ.properties.origin) {
    //     return eleGJ.properties.origin === element;
    //   }
    // });

    const thisElementType = elementsGeoJson.find((eleGJ) => {
      return (
        eleGJ.properties.id === element ||
        (eleGJ.properties.origin && eleGJ.properties.origin === element)
      );
    });

    return (
      <FullScreenModal>
        <Close onClick={() => closeModal()} className={`icon-close icons`} />
        <Section>
          <Row>
            <Col xs={12} md={6}>
              <PageTitle>
                <i className={`icon-camera icons`} /> {parkName}
                <span>
                  {locationData},
                  {thisElementType &&
                    thisElementType.properties.label &&
                    `${
                      thisElementType.properties.category
                        ? thisElementType.properties.category + ","
                        : ""
                    } ${thisElementType.properties.label}`}
                </span>
              </PageTitle>
            </Col>
            <Col xs={12} md={6}>
              <ButtonWrapper>
                <ButtonGroup>
                  <MainButton
                    active={this.state.view === "info" ? "true" : "false"}
                    onClick={() => this.setState({ view: "info" })}
                  >
                    <MenuIcon className={`icon-notebook icons`} /> Informação
                  </MainButton>
                  <MainButton
                    active={this.state.view === "history" ? "true" : "false"}
                    onClick={() => this.setState({ view: "history" })}
                  >
                    <MenuIcon className={`icon-calendar icons`} /> Histórico
                  </MainButton>
                </ButtonGroup>
              </ButtonWrapper>
            </Col>
          </Row>
          {status !== "saved" && this.state.view === "info" && (
            <DetailsSpecific
              isSchool={isSchool}
              // selectedParkId={selectedPark.pid}
              selectedElement={thisElementType}
            />
          )}

          {status !== "saved" && this.state.view === "history" && (
            <ViewReport
            // selectedParkId={selectedPark.pid}
            />
          )}

          {status === "saved" && <ThankYou close={this.close} />}

          {/* <Steps
            title={`Inspeção ${type}`}
            active={this.props.activeStep}
            thankyou={this.getThankyou}
            selectedParkId={pidFormHistory}
            getSteps={this.getSteps}
            getStepContent={this.getStepContent}
          /> */}
        </Section>
      </FullScreenModal>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.inspections.type,
      activeStep: state.inspections.activeStep,
      history: state.inspections.reportHistory,
      status: state.inspections.status,
      list: state.parklist.list,
      local: state.app.search.local,
      role: state.user.data.role,
      selected: state.app.search.selected,
      element: state.app.search.element,
      elementsGeoJson: state.app.elementsGeoJson,
    };
  },
  { setActiveStep, getInspections, setHistory, addNewToggle }
)(AddNew);
