import React, { useEffect, useState } from "react";
import { store } from "../../store/configure";
import MenuIcon from "../Buttons/MenuIcon";
import LocationFilter from "../Filters/LocationFilter";
import { NextButton, OptionWrap } from "./styled.js";

const PreferedPark = ({ user, setGrowerHortas, sortedParks, userRanking }) => {
  const [selected, setSelected] = useState([]);
  const [preferedPark, setPreferedPark] = useState([]);
  useEffect(() => {
    getPreferedPark(sortedParks);
  }, [sortedParks]);

  useEffect(() => {
    if (userRanking) {
      setSelected(
        Object.keys(userRanking).filter(
          (park) =>
            userRanking[park].ranking1 ||
            userRanking[park].ranking2 ||
            userRanking[park].rankingFreguesia
        )
      );
    } else if (user?.attr?.preferedPark) {
      setSelected(user?.attr?.preferedPark);
      // getPreferedPark(user?.attr?.preferedPark);
    }
  }, [userRanking, user]);

  /**
   * get prefed parkData
   */
  const getPreferedPark = (preferedPark) => {
    //validation check
    const newState = store.getState();
    const parks = newState.parklist.geoJson;
    try {
      if (!(preferedPark instanceof Array)) {
        preferedPark = [preferedPark];
      }
      if (!preferedPark || preferedPark.length === 0) {
        // setPreferedPark(parks);
      } else {
        const tempPreferedPark = parks.filter((park) =>
          preferedPark.includes(park.properties.numero)
        );
        setPreferedPark(tempPreferedPark);
      }
    } catch (e) {}
  };

  /**
   * Handle horta prefered save to user attr
   */
  const handleSaveGrowerPreference = () => {
    setGrowerHortas(selected || []);
  };

  const handleAddPark = (parkId) => {
    setSelected([...selected, parkId]);
  };
  /**
   * Handle removePark
   */
  const handleRemovePark = (parkId) => {
    setSelected(selected.filter((park) => park !== parkId));
  };

  /**
   * Update freguesia state
   */
  // const handleSearchChange = (e) => {
  //   if (e.target.name === "selected") {
  //     setSelected(e.target.value);
  //     //let toggle parks
  //     const foundPark = preferedPark.find(
  //       (park) => park.properties.numero === e.target.value
  //     );
  //     if (foundPark) {
  //       setPreferedPark(
  //         preferedPark.filter(
  //           (park) => park.properties.numero !== e.target.value
  //         )
  //       );
  //     } else {
  //       const newState = store.getState();
  //       const parks = newState.parklist.geoJson;
  //       const thisPark = parks.find(
  //         (park) => park.properties.numero === e.target.value
  //       );
  //       setPreferedPark([...preferedPark, thisPark || []]);
  //     }
  //     //   setPreferedPark(e.target.value);
  //   } else {
  //     setFreguesia(e.target.value);
  //   }
  // };

  /**
   * Render park ranks
   */
  return (
    <div>
      {/* <Row>
        <Col xs={12} sm={12} md={8} lg={8} className={"m-b-15"}>
          <OptionWrap>
            <MapContainer>
              <MapComponent
                geoJson={{ geoJson: getGeoJson() }}
                onClickMarker={togglePark}
              />
            </MapContainer>
          </OptionWrap>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className={"m-b-15"}> */}
      {preferedPark.map((park) => {
        const parkName = park.properties.name;
        const parkFreguesia = park.properties.freguesia;
        const isPrefered = selected.find((p) => p === park.properties.numero);

        return (
          <div style={{ opacity: isPrefered ? 1 : 0.5 }}>
            <OptionWrap>
              <div className="flex">
                <div>
                  <strong>{parkName}</strong>
                  <br />
                  {parkFreguesia}
                </div>
                {isPrefered && (
                  <div>
                    <MenuIcon
                      className={`icon-check icons`}
                      style={{ color: "green" }}
                      onClick={() => {
                        handleRemovePark(park.properties.numero);
                      }}
                    />
                  </div>
                )}
                {!isPrefered && (
                  <div>
                    <MenuIcon
                      className={`icon-close icons`}
                      onClick={() => {
                        handleAddPark(park.properties.numero);
                      }}
                    />
                  </div>
                )}
              </div>
            </OptionWrap>
          </div>
        );
      })}

      {/* <OptionWrap>
        <LocationFilter
          hideElements
          override={{
            freguesia: freguesia,
            selected: selected,
          }}
          handleSearchChange={handleSearchChange}
        />
      </OptionWrap> */}

      <NextButton color="primary" onClick={() => handleSaveGrowerPreference()}>
        Recalcular Preferencias
        <MenuIcon className={`icon-check icons`} />
      </NextButton>
      {/* </Col>
      </Row> */}
    </div>
  );
};

export default PreferedPark;
