import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";

export function setActiveType(type) {
  return {
    type: constants.SET_TYPE,
    data: type,
  };
}

export function getParkDayReport(
  startDate,
  endDate,
  thisGrowers,
  selectedElementID
) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING_PARK_REPORT,
    });
    let uid =
      thisGrowers && thisGrowers.length > 0
        ? thisGrowers.map((g) => g.id).join(",")
        : "all";

    const search = store.getState().app.search;

    let parks = "none";
    let eid = "none";

    if (!thisGrowers) {
      if (search.element) {
        eid = search.element;
      } else if (thisGrowers && thisGrowers[0] && thisGrowers[0].originId) {
        eid = thisGrowers[0].originId;
      }
    }

    if (search.selected.length > 0 && !uid) {
      parks = search.selected || "all";
    }

    const url = [
      `api/v1/reports/dayreport`,
      encodeURI("all"),
      encodeURI(uid || "all"),
      encodeURI(parks),
      encodeURI(selectedElementID || eid),
      encodeURI(startDate || search.startDate),
      encodeURI(endDate || search.endDate),
    ];
    axios
      .get(url.join("/"))
      .then(function(response) {
        if (response.data.msg && response.data.msg.data) {
          dispatch({
            type: constants.RECEIVED_PARK_REPORT,
            data: response.data.msg.data,
          });
        } else {
          //toastr.error("Ocorreu um erro a obter as inspecções");

          dispatch({
            type: constants.ERROR_PARK_REPORT,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as inspecções");

        dispatch({
          type: constants.ERROR_PARK_REPORT,
        });
      });
  };
}

export function getParkHistory(local, date) {
  return (dispatch) => {
    dispatch({
      type: constants.GET_PARK_HISTORY,
    });

    axios
      .get(`api/v1/parks/inactive`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_PARK_HISTORY,
            data: response.data,
          });
        } else {
          //toastr.error("Ocorreu um erro a obter as inspecções");

          dispatch({
            type: constants.ERROR_PARK_HISTORY,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as inspecções");

        dispatch({
          type: constants.ERROR_PARK_HISTORY,
        });
      });
  };
}

export function getMonthReport(local, date) {
  return (dispatch) => {
    dispatch({
      type: constants.GET_MONTH_REPORT,
    });

    axios
      .get(`api/v1/reports/freguesia/${encodeURI(local)}/${encodeURI(date)}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED_MONTH_REPORT,
            data: response.data.msg,
          });
        } else {
          //toastr.error("Ocorreu um erro a obter as inspecções");

          dispatch({
            type: constants.ERROR_MONTH_REPORT,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as inspecções");

        dispatch({
          type: constants.ERROR_MONTH_REPORT,
        });
      });
  };
}

export function submitTeamReports() {
  return (dispatch) => {
    dispatch({
      type: constants.SAVING_REPORT,
    });

    const search = store.getState().app.search;
    // const month = new Date(search.startDate).getMonth() + 1;
    const payload = {
      date: encodeURI(search.endDate),
      freguesia: encodeURI(search.freguesia),
    };

    axios
      .post(`api/v1/reports`, payload)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.SAVED_REPORT,
            data: response.data.msg,
          });
          toastr.success(
            `Relatorio para ${search.freguesia} enviado com sucesso`
          );
        } else {
          toastr.error(
            "Ocorreu um erro a salvar o relatorio: " + response.data.nok
          );

          dispatch({
            type: constants.ERROR_REPORT,
          });
        }
      })
      .catch(function(err) {
        console.log(err);
        //toastr.error("Ocorreu um erro a salvar o relatorio");

        dispatch({
          type: constants.ERROR_REPORT,
        });
      });
  };
}
export function getFreguesiasReports() {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    const parks = store.getState().parklist.location;
    const parkFreguesias = Object.keys(parks);

    axios
      .get(`api/v1/reports`)
      .then(function(response) {
        if (response.data.msg) {
          const payload = response.data.msg || [];
          dispatch({
            type: constants.RECEIVED_FREGUESIAS_REPORT,
            data: payload.filter((data) =>
              parkFreguesias.includes(data.freguesia)
            ),
          });
        } else {
          //toastr.error("Ocorreu um erro a obter os relatorios");

          dispatch({
            type: constants.ERROR,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter os relatorios");

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}
export function getReports(type, team) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    const search = store.getState().app.search;

    let parks = "all";
    if (search?.selected?.length > 0) parks = search?.selected || "all";

    const url = [
      `api/v1/reports`,
      encodeURI(team || "all"),
      encodeURI(type || "all"),
      encodeURI(search.freguesia || "all"),
      encodeURI("all"),
      encodeURI(parks),
      encodeURI(search.startDate),
      encodeURI(search.endDate),
      encodeURI(search.status),
    ];

    axios
      .get(url.join("/"))
      .then(function(response) {
        if (response.data.msg && response.data.msg.data) {
          dispatch({
            type: constants.RECEIVED,
            data: response.data.msg.data,
          });
        } else {
          //toastr.error("Ocorreu um erro a obter as inspecções");

          dispatch({
            type: constants.ERROR,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as inspecções");

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}
