import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Content from "../../components/Layout/Content";
import { toggleEditCommunication } from "../App/actions";
import { useSelector } from "react-redux";

import { MenuItem } from "@material-ui/core";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import Sidebar from "../../components/Layout/Sidebar";
import CommunicationsTable from "../../components/Table/Communications";

import { store } from "../../store/configure";
import { FilterList, MaintenanceFilter, StyledWrap, TableWrap } from "./styled";

const Communications = (props) => {
  const statusC = useSelector((state) => state.communications.status);

  const [view, setView] = useState("messages");
  const [data, setData] = useState([]);
  const [parklist, setParklist] = useState(false);
  const [type, setType] = useState("all");
  const [status, setStatus] = useState("all");
  const [group, setGroup] = useState(props?.match?.params?.id);
  const [startDate, setStartDate] = useState(
    moment().subtract(2, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  let title = "Mensagens";

  //load group messages
  useEffect(() => {
    if (group) store.dispatch(toggleEditCommunication(group));
  }, [group]);

  useEffect(() => {
    console.log(statusC);
    if (statusC === "saved") {
      getMessages();
    }
  }, [statusC]);

  //get data on load
  useEffect(() => {
    getMessages();
  }, []);

  //get data on filter change
  useEffect(() => {
    getMessages();
  }, [type, status, startDate, endDate]);

  //api call to get data
  const getMessages = async () => {
    try {
      const result = await axios.get(
        `/api/v1/communications/${type}/${status}/${startDate}/${endDate}`
      );
      setData(result?.data?.msg || []);
    } catch (error) {}
  };

  //import store and dispatch
  const goToDetails = (id) => {
    store.dispatch(toggleEditCommunication(id));
  };

  //update date
  const handelDateChange = (e) => {
    if (e.target.name === "startDate") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  return (
    <StyledWrap>
      <Sidebar
        title={title}
        text={`gerir ${title}`}
        icon={"speech"}
        noanimation
      >
        <FilterList>
          <ScrollArea
            speed={0.8}
            className="maintenance-filter"
            contentClassName="maintenance-filter-content"
            horizontal={false}
          >
            {/* <ButtonGroup>
              <Tooltip title={`Mensagens`}>
                <GroupedButton
                  active={this.state.view === "messages"}
                  width={"33%"}
                  onClick={() => {
                    this.setState({ view: "messages" });
                    // this.props.getUsers();
                  }}
                >
                  <MenuIcon className={`icon-envelope icons`} />
                </GroupedButton>
              </Tooltip>
              <Tooltip title={`Templates`}>
                <GroupedButton
                  active={this.state.view === "growers"}
                  width={"33%"}
                  onClick={() => {
                    this.setState({ view: "growers" });
                    // this.props.getGrowers();
                  }}
                >
                  <MenuIcon className={`icon-notebook icons`} />
                </GroupedButton>
              </Tooltip>
            </ButtonGroup> */}

            {view === "messages" && (
              <div>
                <MaintenanceFilter
                  fullWidth
                  label={"Estado"}
                  name={"status"}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  inputProps={{
                    name: "status",
                    id: "status",
                  }}
                >
                  <MenuItem value="all">
                    <em>Todas</em>
                  </MenuItem>
                  <MenuItem value={`0`}>Por Enviar</MenuItem>
                  <MenuItem value={`1`}>Ativo</MenuItem>
                  <MenuItem value={`2`}>Enviado</MenuItem>
                  <MenuItem value={`3`}>Desativo</MenuItem>
                </MaintenanceFilter>
                <br />
                <MaintenanceFilter
                  fullWidth
                  name={"type"}
                  label={"Tipo"}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}
                >
                  <MenuItem value="all">
                    <em>Todas</em>
                  </MenuItem>
                  <MenuItem value={`0`}>Telefone</MenuItem>
                  <MenuItem value={`1`}>Email</MenuItem>
                  <MenuItem value={`2`}>Carta</MenuItem>
                  <MenuItem value={`3`}>Sms</MenuItem>
                </MaintenanceFilter>
              </div>
            )}

            <DateRangeFilter
              handleSearchChange={handelDateChange}
              endDate={endDate}
              startDate={startDate}
            />
          </ScrollArea>
        </FilterList>
      </Sidebar>
      <Content size={2} color={"white"} map={true}>
        <TableWrap>
          <CommunicationsTable
            orderBy={"id"}
            data={data}
            // growers={this.props.growers}
            click={goToDetails}
          />
        </TableWrap>
      </Content>
    </StyledWrap>
  );
};

export default Communications;
