import * as constants from "./constants";
import axios from "axios";
import moment from "moment";
import { getCurrentDate } from "../../services/utils";
import {
  growerTypes,
  rankingTypes,
  growerState,
  attributionState,
  modules,
  status,
} from "../../config/general";

let initialState = {
  data: null,
  login: {
    username: "",
    password: "",
  },
  growerTypes,
  rankingTypes,
  growerState,
  attributionState,
  modules,
  adresses: [],
  editGrowerData: {
    extId: "",
    name: "",
    vat: "",
    birthday: "",
    email: "",
    phone: "",
    mobile: "",
    oringinalAddress: "",
    address: "",
    coursedata: "",
    education: "",
    educationDone: "",
    preferedPark: "",
    freguesia: "",
    type: "",
    note: "",
    status: "",
    attrStatus: 0,
    lat: "",
    lon: "",
  },
  editData: {
    name: "",
    email: "",
    Team: {},
    teams: [],
    admin: false,
    type: "all",
    write: "all",
    external: false,
    parks: [],
    permissions: {
      write: ["All"],
      read: ["All"],
      projects: ["All"],
    },
    password: "",
  },
  relations: [],
  selectedRows: [],
  growhistory: [],
  id: "",
  position: { lat: "", lng: "" },
  centerToUser: false,
  list: [],
  growers: [],
  growersList: [],
  teams: [],
  status: "",
  attrStatus: 0,
  search: {},
  startDate: "2000-01-01",
  endDate: moment()
    .add(1, "day")
    .format("YYYY-MM-DD"),
  isLoading: false,
  error: null,
};

const isLocalStorage = typeof localStorage !== "undefined";

//lets load user data for reload
if (isLocalStorage && localStorage.userData) {
  initialState = JSON.parse(localStorage.userData);
}

let newState = {};
export default function user(state = initialState, { type, payload }) {
  switch (type) {
    case constants.USER_LOGGING_IN:
      return { ...initialState };
    case constants.CENTER:
      newState = {
        ...state,
        centerToUser: true,
      };
      // if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;

    case constants.SET_SEARCH_DATE:
      console.log(payload);
      newState = {
        ...state,
        [payload.name]: payload.value,
      };
      // if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;

    case constants.SET_SELECTED_ROWS:
      newState = {
        ...state,
        selectedRows: payload,
      };
      // if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;

    case constants.CENTERED:
      newState = {
        ...state,
        centerToUser: false,
      };
      // if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;

    case constants.USER_POSITION:
      newState = {
        ...state,
        position: {
          lat: payload.lat || "",
          lng: payload.lng || "",
        },
      };
      // if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;

    case constants.USER_LOGGED_IN:
      if (isLocalStorage) {
        localStorage.userData = JSON.stringify({ ...state, data: payload });
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + payload.token;
      }

      return { ...state, data: payload, isLoading: false };
    case constants.TOKEN_UPDATED:
      if (isLocalStorage) {
        localStorage.userData = JSON.stringify({
          ...state,
          data: {
            ...state.data,
            token: payload,
          },
        });
        axios.defaults.headers.common["Authorization"] = "Bearer " + payload;
      }
      return {
        ...state,
        data: {
          ...state.data,
          token: payload,
        },
        isLoading: false,
      };

    case constants.GOT_ADDRESS:
      return { ...state, addresses: payload.response.docs };
    case constants.GOT_GEOLOCATION:
      let cords = [];
      if (payload.success && payload.geometries) {
        cords = payload.geometries.anotacao.coordinates;
        newState = {
          ...state,
          editGrowerData: {
            ...state.editGrowerData,
            lat: cords[1],
            lon: cords[0],
          },
        };
      } else {
        newState = { ...state };
      }

      return newState;
    case constants.GETTING_GROWERS:
      return { ...state, status: status.getting };
    case constants.GETTING:
      return { ...state, status: status.getting };
    case constants.RECEIVED_GROWERS_LIST:
      newState = {
        ...state,
        status: status.fetched,
        growersList: payload || [],
      };
      if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;
    case constants.RECEIVED_GROWERS:
      newState = {
        ...state,
        status: status.fetched,
        growersList: payload || [],
      };
      if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;
    case constants.RECEIVED:
      newState = {
        ...state,
        status: status.fetched,
        list: payload,
      };
      // if (isLocalStorage) localStorage.userData = JSON.stringify(newState);
      return newState;

    case constants.GROWER_RELATION_RECEIVED:
      newState = {
        ...state,
        relations: payload,
      };
      return newState;

    case constants.RECEIVED_TEAMS:
      newState = {
        ...state,
        teams: payload,
      };
      return newState;
    case constants.SET_GROWER_INIT_DATA:
      const growerFound = state.growers.find((user) => payload === user.id);
      newState = {
        ...state,
        status: status.fetched,
        id: payload || "",
        editGrowerData: growerFound || {
          permissions: {
            write: ["all"],
            read: ["all"],
            projects: ["all"],
          },
          attrStatus: 0,

          name: "",
          email: "",
          type: "all",
          preferedPark: "",
          freguesia: "",
          write: "all",
          Team: {},
          parks: [],
          teams: [],
          admin: false,
          external: false,
          password: "",
          lat: "",
          lon: "",
        },
      };
      return newState;
    case constants.SET_USER_INIT_DATA:
      const userFound = state.list.find((user) => payload === user.id);
      newState = {
        ...state,
        status: status.fetched,
        id: payload || "",
        editData: userFound || {
          name: "",
          email: "",
          type: "all",
          write: "all",
          permissions: {
            write: ["all"],
            read: ["all"],
            projects: ["all"],
          },
          Team: {},
          parks: [],
          teams: [],
          admin: false,
          external: false,
          password: "",
        },
      };
      return newState;
    case constants.SET_TEAM_INIT_DATA:
      const teamFound = state.teams.find((team) => payload === team.id);
      newState = {
        ...state,
        status: status.fetched,
        id: payload || "",
        editData: teamFound || {
          label: "",
          type: false,
        },
      };
      return newState;
    case constants.RESETUSER:
      newState = {
        ...state,
        status: status.fetched,
        id: "",
        editData: {
          permissions: {
            write: ["all"],
            read: ["all"],
            projects: ["all"],
          },
          name: "",
          email: "",
          type: "all",
          write: "all",
          Team: {},
          parks: [],
          teams: [],
          admin: false,
          external: false,
          password: "",
        },
        editGrowerData: {
          extId: "",
          attrStatus: 0,

          name: "",
          vat: "",
          birthday: "",
          email: "",
          phone: "",
          mobile: "",
          oringinalAddress: "",
          address: "",
          coursedata: "",
          education: "",
          preferedPark: "",
          freguesia: "",
          educationDone: "",
          type: "",
          note: "",
          status: "",
          lat: "",
          lon: "",
        },
      };
      return newState;

    case constants.SET_GROWER_DETAILS:
      newState = {
        ...state,
        editGrowerData: {
          ...state.editGrowerData,
          [payload.element]: payload.value,
        },
      };
      return newState;
    case constants.SET_DETAILS:
      newState = {
        ...state,
        editData: {
          ...state.editData,
          [payload.element]: payload.value,
        },
      };
      return newState;
    case constants.RECEIVED_GROWER:
      newState = {
        ...state,
        status: status.fetched,
        editGrowerData: payload,
      };
      return newState;
    case constants.RECEIVED_USER:
      newState = {
        ...state,
        status: status.fetched,
        editData: {
          permissions: payload.permissions,
          name: payload.name,
          email: payload.email,
          Team: payload.Team || {},
          teams: payload.attr ? payload.attr.teams : [],
          type: payload.type || "all",
          external: payload.Team ? payload.Team.type === "external" : false, //for external types
          admin: payload.admin,
          write: payload.write,
          status: payload.status,
          parks: payload.access || [],
          password: "",
          // external: payload.external,
        },
      };
      return newState;
    case constants.GROWER_HISTORY_RECEIVED:
      return {
        ...state,
        growhistory: payload,
      };
    case constants.RECEIVED_USER_RANKINGS:
      return { ...state, userRanking: payload };
    case constants.SAVED:
      return { ...state, status: status.saved };
    case constants.USER_LOGGED_IN_ERROR:
      return { ...state, error: true };
    case constants.DONE:
      return { ...state, status: status.fetched };
    case constants.USER_LOGGED_OUT:
      // localStorage.removeItem('userData');
      // localStorage.removeItem('parklist');
      // localStorage.removeItem('calendar');
      localStorage.clear();
      window.location = "/";
      return {
        data: null,
        isLoading: false,
        error: null,
        editData: null,
      };
    default:
      return state;
  }
}
