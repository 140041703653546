import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { store } from "../../../store/configure";

import FullScreenModal from "../../../components/Layout/FullScreenModal";
import PageTitle from "../../../components/Text/PageTitle";

import DetailsStart from "./DetailsStart";
import EmailDetails from "./EmailDetails";
import Type from "./Type";

import { Close, Section } from "../../../components/InspMain/styled";
import { getMessage } from "../actions";
// import { getMessage } from "../actions";

const EditMessage = (props) => {
  const status = store.getState().communications.status;
  // const edit = store.getState().communications.edit;
  const editCommunication = store.getState().app.editCommunication;
  const [type, setType] = useState(0);
  const [edit, setEdit] = useState([]);

  useEffect(() => {
    if (!editCommunication || editCommunication === "new") return;
    if (editCommunication.type !== "imported") {
      getMessage(editCommunication);
      getCommunication(editCommunication);
    } else {
      getMessage(editCommunication);
    }
  }, []);

  useEffect(() => {
    if (status === "saved") {
      props.closeModal();
    }
  }, [status]);

  const getMessage = async (data) => {
    try {
      if (!data || data === "new") return;
      setEdit([]);

      const result = await axios.get(`/api/v1/messages/${data.id || data}`);
      const msgData = result.data.msg || [];
      if (msgData?.length < 1) return;
      if (Array.isArray(msgData)) {
        setEdit(msgData);
        setType(msgData[0].type);
      } else {
        setEdit([msgData]);
        setType(msgData.type);
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const getCommunication = async (data) => {
    try {
      if (!data || data === "new") return;
      setEdit({});

      const result = await axios.get(
        `/api/v1/communications/${data.id || data}`
      );
      const msgData = result.data.msg || {};
      if (!msgData.id) return;
      setEdit(msgData);
      setType(msgData.type);
    } catch (err) {
      console.log(err);
      return;
    }
  };
  let mensagem = !edit ? "Adicionar " : "Ver ";
  let showDetails = false;
  if (type === "0") {
    mensagem += "um telefonema";
  } else if (type === "1") {
    mensagem += "um email";
  } else if (type === "2") {
    mensagem += "uma carta";
  } else if (type === "3") {
    mensagem += "um sms";
  } else {
    mensagem += "uma comunicação";
  }

  if (
    (type && editCommunication === "new") ||
    (editCommunication !== "new" && edit?.id)
    //wait dont show begore loading message
  ) {
    showDetails = true;
  }

  //get contact id from messages
  let editContactId = "";
  let group = "";
  if (edit?.length > 0) {
    edit.map((item) => {
      if (item.ContactId) {
        editContactId = item.ContactId;
      }
      if (item.group) {
        group = item.group;
      }
    });
    if (group === "") {
      group = edit[0]?.group || edit[0].id;
    }
  }
  return (
    <FullScreenModal>
      <Close
        onClick={() => props.closeModal()}
        className={`icon-close icons`}
      />
      <Section>
        <PageTitle>{mensagem}</PageTitle>

        <Row>
          <Col md={12}>
            {!type && (!editCommunication || editCommunication === "new") && (
              <Type changeType={(ctype) => setType(ctype)} />
            )}
            {showDetails && editCommunication.type !== "imported" && (
              <DetailsStart
                communicationType={type}
                edit={edit}
                close={props.closeModal}
              />
            )}

            {editCommunication.type === "imported" && edit.length > 0 && (
              <>
                <EmailDetails
                  communicationType={"new"}
                  edit={{ ContactId: editContactId, group: group }}
                  close={props.closeModal}
                />
                {edit?.map((item) => {
                  return (
                    <EmailDetails
                      communicationType={type}
                      edit={item}
                      close={props.closeModal}
                      readOnly={true}
                      noButtons={true}
                    />
                  );
                })}{" "}
              </>
            )}
          </Col>
        </Row>
      </Section>
    </FullScreenModal>
  );
};

export default EditMessage;
