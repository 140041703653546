import React, { Component } from "react";
import { connect } from "react-redux";

import { Route, Switch } from "react-router-dom";
import MapComponent from "../../components/Map/Map";
import { debounce } from "lodash";

import Dashboard from "../Dashboard/DashboardPage";
import Attributions from "../Attributions";
import Inspections from "../Inspections";
import Georeference from "../Georeference";
import Schools from "../Schools";
import Loader from "./Loader";
import Reports from "../Reports";
import ViewReportManager from "../Reports/ViewReportManager";
import Calendar from "../Calendar";
import Users from "../User";
import Tasks from "../Tasks";
import Communications from "../Communications";

import LocationFilterMap from "../../components/Filters/LocationFilterMap";
import { setSearch, setDateSearch } from "./actions";
import { getCurrentDate } from "../../services/utils";

import NavigationPanel from "../../components/Layout/NavigationPanel";
import Header from "../../components/Layout/Header";
import Maintenances from "../Maintenances";
import Overlays from "./Overlays";

import { getAllHortas } from "../ParkList/actions";
import { userIsAdmin } from "../../auth";

import {
  FullContainer,
  FiltersContent,
  MapContent,
  StyledNavigationPanel,
  Styledmain,
} from "./styled";

class App extends Component {
  static propTypes = {
    // children: PropTypes.oneOfType([
    //   PropTypes.arrayOf(PropTypes.node),
    //   PropTypes.node,
    // ]).isRequired,
  };

  constructor(props) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchChange = debounce(this.handleSearchChange, 600);

    if (
      !props.list ||
      props.list.length === 0 ||
      !props.list.length ||
      props.role !== props.listRole
    ) {
      props.getAllHortas();
    }
    this.roleData = props.menus[props.role];
    props.setDateSearch(getCurrentDate(), true);
  }

  handleSearchChange(e, value) {
    let element = [e.target.name];

    this.props.setSearch({
      element: element,
      value: e.target.value,
    });
  }

  render() {
    const {
      admin,
      inspections,
      maintenances,
      totalParks,
      permissions,
    } = this.props;

    let hideMap = false;
    if (admin) {
      hideMap =
        this.props.location.pathname !== "/dashboard" &&
        this.props.location.pathname !== "/reports" &&
        this.props.location.pathname !== "/calendar" &&
        this.props.location.pathname !== "/georeference" &&
        this.props.location.pathname !== "/users" &&
        this.props.location.pathname !== "/schools" &&
        this.props.location.pathname !== "/attributions" &&
        this.props.location.pathname !== "/communications" &&
        this.props.location.pathname !== "/" &&
        this.props.location.pathname.match(/attributions\//g) === null &&
        this.props.location.pathname.match(/communications\//g) === null;
    } else {
      hideMap =
        this.props.location.pathname !== "/" &&
        this.props.location.pathname !== "/reports" &&
        this.props.location.pathname !== "/reports/month" &&
        this.props.location.pathname !== "/calendar" &&
        this.props.location.pathname !== "/georeference" &&
        this.props.location.pathname !== "/attributions" &&
        this.props.location.pathname !== "/schools" &&
        this.props.location.pathname !== "/users" &&
        this.props.location.pathname !== "/communications" &&
        this.props.location.pathname.match(/attributions\//g) === null &&
        this.props.location.pathname.match(/communications\//g) === null;
    }

    return (
      <FullContainer>
        <Loader />
        <StyledNavigationPanel>
          <NavigationPanel
            roleMenu={this.roleData}
            inspections={inspections}
            maintenances={maintenances}
            permissions={permissions}
            tasks={totalParks}
            admin={this.props.admin}
            type={this.props.type}
            location={this.props.history.location.pathname}
          />
        </StyledNavigationPanel>
        <Styledmain>
          <Header />
          {this.props.children}
          <Switch>
            {admin && <Route path="/tasks" component={Tasks} />}
            <Route
              path="/inspections/:id?/:callback?"
              component={Inspections}
            />
            <Route
              path="/interventions/:id?/:callback?"
              component={Maintenances}
            />
            <Route path="/georeference/:id?" component={Georeference} />
            <Route path="/schools/:id?" component={Schools} />
            <Route path="/attributions/:path?" component={Attributions} />
            {/* <Route
              path="/georeference/:id?"
              component={userIsAdmin(Georeference)}
            /> */}
            <Route path="/reports/month" component={ViewReportManager} />
            <Route path="/reports/:id?" component={Reports} />
            <Route path="/calendar/:id?" component={Calendar} />
            <Route path="/users/:id?" component={Users} />
            <Route path="/communications/:id?" component={Communications} />

            <Route
              path="/dashboard"
              component={this.props.admin ? Dashboard : Tasks}
            />
            {<Route path="/" component={Dashboard} />}
            {/* <Route path="/" component={() => {}} /> */}
          </Switch>

          {hideMap && (
            <FiltersContent>
              <LocationFilterMap handleSearchChange={this.handleSearchChange} />
            </FiltersContent>
          )}
          {hideMap && (
            <MapContent>
              <MapComponent />
            </MapContent>
          )}
        </Styledmain>
        <Overlays />
      </FullContainer>
    );
  }
}
export default connect(
  (state) => {
    return {
      maintenances: state.maintenances.list && state.maintenances.list.length,
      inspections: state.inspections.list && state.inspections.list.length,
      totalParks: state.tasks.totalParks,
      permissions: state.user.data.permissions,
      role: state.user.data.role,
      admin: state.user.data.admin,
      type: state.user.data.type,
      listRole: state.parklist.role,
      menus: state.app.menus,
    };
  },
  {
    getAllHortas,
    setSearch,
    setDateSearch,
  }
)(App);
