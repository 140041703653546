import moment from "moment";
import React, { useState } from "react";
import Table from "./Table";
import { Chip } from "@material-ui/core";
import Edit from "../../containers/Schools/EditMaterial";

export default function(props) {
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const processedData = (data) => {
    if (!data) return [];
    return data.map((row) => {
      return { ...row, equipa: row.Team ? row.Team.label : "" };
    });
  };
  const columnData = [
    {
      id: "id",
      name: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      options: {
        display: false,
      },
    },

    {
      id: "status",
      name: "status",
      numeric: false,
      disablePadding: false,
      label: "Estado",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1) {
            return <Chip color={"primary"} label={"Ativo"} />;
          } else {
            return <Chip color={"default"} label={"Inativo"} />;
          }
        },
      },
    },
    {
      id: "name",
      name: "name",
      numeric: false,
      disablePadding: false,
      label: "Nome",
    },
    {
      id: "type",
      name: "type",
      numeric: false,
      disablePadding: false,
      label: "Tipo de Material",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Chip label={value} />;
        },
      },
    },

    {
      id: "levels",
      name: "levels",
      numeric: false,
      disablePadding: false,
      label: "Niveis",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            value &&
            value.map((u, i) => (
              <Chip key={i} label={`${u.numberOfItems}u/${u.area}m2 `} />
            ))
          );
        },
      },
    },

    {
      id: "price",
      name: "price",
      numeric: false,
      disablePadding: false,
      label: "Preço/u",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value} €`;
        },
      },
    },
    {
      id: "observations",
      name: "observations",
      numeric: false,
      disablePadding: false,
      label: "Observações",
    },

    {
      id: "isDefault",
      name: "isDefault",
      numeric: false,
      disablePadding: false,
      label: "Pré Definido",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return "Pré Definido";
          } else {
            return "Não Definido";
          }
        },
      },
    },
    {
      id: "createdAt",
      name: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Data",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD/MM/YYYY HH:mm");
        },
      },
    },
  ];

  const dataReady = processedData(props.data);
  return (
    <Table
      orderBy={"id"}
      data={dataReady}
      rowsExpanded={rowsExpanded}
      setRowsExpanded={setRowsExpanded}
      // click={props.click}
      renderExpandableRow={(rowData, rowMeta) => {
        const thisData = dataReady.find((d) => d.id === rowData[0]);
        return (
          <tr style={{ width: "100%" }}>
            <td style={{ width: "100%" }} colSpan={"20"}>
              <Edit
                noModal={true}
                edit={thisData}
                index={rowMeta.rowIndex}
                rowsExpanded={rowsExpanded}
                reloadData={props.reloadData}
                setRowsExpanded={setRowsExpanded}
              />
            </td>
          </tr>
        );
      }}
      header={columnData}
    />
  );
}
