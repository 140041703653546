import moment from "moment";
import React, { useState, useRef } from "react";
import Table from "./Table";
import { Chip } from "@material-ui/core";
import Edit from "../../containers/Schools/Edit";

export default function (props) {
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const processedData = (data) => {
    if (!data) return [];
    return data?.map((row) => {
      return {
        ...row,
        equipa: row.Team ? row.Team.label : "",
      };
    });
  };

  const preprocessExport = (tdata) => {
    return tdata.map((row, i) => {
      return {
        ...row,
        data: row?.data?.map((col, j) => {
          if (j === 4) {
            return col?.map((e) => `${e.name} - ${e.email}`).join(" | ");
          } else if (j === 5) {
            return col
              ?.map((e) => `${e.name} ${e.surname} - ${e.email}`)
              .join(" | ");
          }
          return col;
        }),
      };
    });
  };

  const columnData = [
    {
      id: "id",
      name: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      options: {
        display: false,
      },
    },
    {
      id: "status",
      name: "status",
      numeric: false,
      disablePadding: false,
      label: "Estado",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === 1) {
            return <Chip color={"primary"} label={"Aprovado"} />;
          } else if (value === 2) {
            return <Chip color={"secondary"} label={"Atribuido"} />;
          } else if (value === 3) {
            return <Chip color={"default"} label={"Inativo"} />;
          } else if (value === 4) {
            return <Chip color={"primary"} label={"Pré-Inscrição"} />;
          } else if (value === 5) {
            return (
              <Chip color={"secondary"} label={"Não quer pré-Inscrição"} />
            );
          } else {
            return <Chip color={"default"} label={"Por Aprovar"} />;
          }
        },
      },
    },
    {
      id: "school",
      name: "school",
      numeric: false,
      disablePadding: false,
      label: "Escola",
    },
    {
      id: "schooltype",
      name: "schooltype",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === "public" ? "Publica" : "Privada";
        },
      },
    },
    {
      id: "agrupamento",
      name: "agrupamento",
      numeric: false,
      disablePadding: false,
      label: "Agrupamento",
    },

    {
      id: "responsible",
      name: "responsible",
      numeric: false,
      disablePadding: false,
      label: "Responsáveis",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value.map((u, i) => <Chip key={i} label={u?.name} />);
        },
      },
    },

    {
      id: "students",
      name: "students",
      numeric: false,
      disablePadding: false,
      label: "Formandos",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value.map((u, i) => <Chip key={i} label={u?.name} />);
        },
      },
    },

    {
      id: "material",
      name: "material",
      numeric: false,
      disablePadding: false,
      label: "Material",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value.map((u, i) => <Chip key={i} label={u} />);
        },
      },
    },
    {
      id: "observations",
      name: "observations",
      numeric: false,
      disablePadding: false,
      label: "Observações",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div
                style={{
                  width: "300px",
                  fontSize: ".9em",
                }}
              >
                {value}
              </div>
            </>
          );
        },
      },
    },
    {
      id: "area",
      name: "area",
      numeric: false,
      disablePadding: false,
      label: "Area",
    },
    {
      id: "hortaattr",
      name: "hortaattr",
      numeric: false,
      disablePadding: false,
      label: "Recursos",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value.map((u, i) => <Chip key={i} label={u} />);
        },
      },
    },
    {
      id: "slots",
      name: "slots",
      numeric: false,
      disablePadding: false,
      label: "Alunos",
    },
    {
      id: "tutors",
      name: "tutors",
      numeric: false,
      disablePadding: false,
      label: "Tutores",
    },
    {
      id: "history",
      name: "history",
      numeric: false,
      disablePadding: false,
      label: "Histórico",
      options: {
        display: false,
      },
    },
    {
      id: "volunteer",
      name: "volunteer",
      numeric: false,
      disablePadding: false,
      label: "Voluntário",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "yes") {
            return "Sim";
          } else if (value === "no") {
            return "Não";
          } else {
            return "Não sabe";
          }
        },
      },
    },
    {
      id: "date",
      name: "date",
      numeric: false,
      disablePadding: false,
      label: "Data",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD/MM/YYYY HH:mm");
        },
      },
    },
    {
      id: "responsible",
      name: "responsibleEmail",
      numeric: false,
      disablePadding: false,
      label: "EmailResponsáveis",
      options: {
        display: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value.map((u, i) => u?.email).join(", ");
        },
      },
    },
    {
      id: "students",
      name: "studentsEmail",
      numeric: false,
      disablePadding: false,
      label: "EmailFormandos",
      options: {
        display: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value.map((u, i) => u?.email).join(", ");
        },
      },
    },
  ];

  const dataReady = processedData(props.data);
  return (
    <Table
      orderBy={"id"}
      data={dataReady}
      rowsExpanded={rowsExpanded}
      setRowsExpanded={setRowsExpanded}
      preprocessExport={preprocessExport}
      // click={props.click}
      renderExpandableRow={(rowData, rowMeta) => {
        const thisData = dataReady.find((d) => d.id === rowData[0]);
        return (
          <tr style={{ width: "100%" }}>
            <td style={{ width: "100%" }} colSpan={"20"}>
              <Edit
                edit={thisData}
                index={rowMeta.rowIndex}
                rowsExpanded={rowsExpanded}
                reloadData={props.reloadData}
                setRowsExpanded={setRowsExpanded}
              />
            </td>
          </tr>
        );
      }}
      header={columnData}
    />
  );
}
