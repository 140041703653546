import React from "react";
import { GeoJSON } from "react-leaflet";

export default function Parks(props) {
  const onEachFeature = (feature, layer) => {
    layer.on({
      click: () => {
        // this.openPopup(feature, layer);

        return typeof props.onClickMarker === "function"
          ? props.onClickMarker(feature)
          : null;
      },
      mouseover: () => {
        layer.openPopup();
        // console.log(feature);
      },
    });
    layer.setStyle({
      weight: props.zoom?.zoom > 14 ? 3 : 10,
      fillColor:
        feature.properties && feature.properties.color
          ? feature.properties.color
          : "#a92222",
      color:
        feature.properties && feature.properties.color
          ? feature.properties.color
          : "#a92222",
    });

    return layer;
  };

  return (
    <GeoJSON
      key={`${new Date()}-parks`}
      data={props.data}
      style={{
        fillColor: "#a92222",
        color: "#a92222",
      }}
      ref={props.reference}
      onEachFeature={props.onEachFeature || onEachFeature}
    />
  );
}
