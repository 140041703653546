import React from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

export default function EditElements(props) {
  return (
    !props.creatingElement && (
      <FeatureGroup>
        <EditControl
          position="topleft"
          //this is the necessary function. It goes through each layer
          //and runs my save function on the layer, converted to GeoJSON
          //which is an organic function of leaflet layers.
          onCreated={e => {
            const geoJson = e.layer.toGeoJSON();
            const type = e.layerType;
            props.onCreated(type, geoJson);
            // Now we need to remove it
            // it will be added from the db later
            // const { edit } = this.refs;
            // edit.context.layerContainer.removeLayer(e.layer._leaflet_id);
          }}
          onEdited={e => {
            e.layers.eachLayer(a => {
              // props.updatePlot({
              //   id: '',
              //   feature: a.toGeoJSON(),
              // });
            });
          }}
          edit={{ circle: false, marker: false, remove: false }}
          draw={{
            marker: false,
            circle: false,
            rectangle: false,
            polygon: true,
            polyline: false,
          }}
        />
      </FeatureGroup>
    )
  );
}
