import React from "react";
import styled from "styled-components";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { recenterMap } from "../../containers/App/actions";
import Select from "../../components/form-wrappers/Select";

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;

const StyledMenuItem = styled((props) => <MenuItem {...props} />)`
  && {
    text-transform: uppercase;
  }
`;

class LocationFilter extends React.Component {
  constructor(props) {
    super(props);
    this.resetSearch = this.resetSearch.bind(this);
  }

  resetSearch() {
    this.props.handleSearchChange({
      target: {
        name: "freguesia",
        value: "",
      },
    });
  }

  handleSearchChange(e) {
    this.props.handleSearchChange(e);
    this.props.recenterMap();
  }

  sortAlphaNum(array) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    if (!(array instanceof Array)) return array;
    return array.sort((a, b) => {
      var aA = a.replace(reA, "");
      var bA = b.replace(reA, "");
      if (aA === bA) {
        var aN = parseInt(a.replace(reN, ""), 10);
        var bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    });
  }

  render() {
    const {
      location,
      list,
      hideElements,
      handleSearchChange,
      elmentDisplay,
      elementsGeoJson,
      elementSelected,
    } = this.props;

    let { freguesia, selected, elementType } = this.props;

    if (this.props.override) {
      freguesia = this.props.override.freguesia;
      selected = this.props.override.selected;
      elementType = this.props.override.elementType;
    }

    let elementTypesList = [];
    return (
      <Section>
        {/* {!notitle && <SectionTitle>Local</SectionTitle>} */}
        {/* <Reset
          onClick={() => this.resetSearch()}
          className={'icon-close icons reset'}
        /> */}

        <Select
          fullWidth
          name={"freguesia"}
          label={"Freguesia"}
          value={freguesia}
          margin="dense"
          onChange={handleSearchChange}
          inputProps={{
            name: "freguesia",
            id: "freguesia",
          }}
        >
          <StyledMenuItem key={-1} value={""}>
            Todos
          </StyledMenuItem>{" "}
          {Object.keys(location)
            .sort()
            .map((freguesia, index) => (
              <StyledMenuItem key={index} value={freguesia}>
                {freguesia}
              </StyledMenuItem>
            ))}
        </Select>
        {/* {freguesia !== '' && (
          <Select
            fullWidth
            name={'local'}
            value={local}
            onChange={handleSearchChange}
            inputProps={{
              name: 'local',
              id: 'local',
            }}
          >
            <StyledMenuItem key={-1} value={''} />
            {Object.keys(location[freguesia]).map((local, index) => (
              <StyledMenuItem
                key={index}
                selected={local === freguesia}
                value={local}
              >
                {local}
              </StyledMenuItem>
            ))}
          </Select>
        )} */}
        {freguesia !== "" && (
          <Select
            fullWidth
            name={"selected"}
            label={"Horta"}
            value={
              list.find((selectedPark) => selectedPark.numero === selected)
                ? selected
                : ""
            }
            onChange={handleSearchChange}
            inputProps={{
              name: "selected",
              id: "selected",
            }}
          >
            <StyledMenuItem key={-1} value={""}>
              Todos
            </StyledMenuItem>
            {list &&
              list.sort().map((selectedPark, index) =>
                selectedPark.freguesia === freguesia ? (
                  <StyledMenuItem
                    key={index}
                    selected={selectedPark.numero === selected}
                    value={selectedPark.numero}
                  >
                    {selectedPark.nome}
                  </StyledMenuItem>
                ) : null
              )}
          </Select>
        )}
        {selected &&
          !hideElements &&
          elmentDisplay &&
          selected !== "" &&
          selected.length > 0 &&
          elementsGeoJson &&
          elementsGeoJson.length > 0 && (
            <Select
              fullWidth
              name={"elementType"}
              label={"Elemento"}
              value={this.props.override ? elementType : elementSelected || []}
              onChange={handleSearchChange}
              inputProps={{
                name: "elementType",
                id: "elementType",
              }}
            >
              <StyledMenuItem key={-1} value={""}>
                Todos
              </StyledMenuItem>
              {elementsGeoJson.reduce((acc, element, index) => {
                const elementTypeSelected =
                  element.properties.label || element.properties.category;

                if (
                  elementTypeSelected &&
                  !elementTypesList.includes(elementTypeSelected) // lets make sure their unique
                ) {
                  elementTypesList.push(elementTypeSelected);
                  acc.push(
                    <StyledMenuItem
                      key={index}
                      value={element.properties.origin || element.properties.id}
                    >
                      {elementTypeSelected}
                    </StyledMenuItem>
                  );
                }
                return acc;
              }, [])}
            </Select>
          )}
      </Section>
    );
  }
}

export default connect(
  (state) => {
    return {
      list: state.parklist.list,
      elementsGeoJson: state.app.elementsGeoJson,
      elmentDisplay: state.app.elmentDisplay,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      location: state.parklist.location,
      elementType: state.app.search.elementTypeSelected,
      elementSelected: state.app.search.element,
    };
  },
  { recenterMap }
)(LocationFilter);
