import React from "react";
import Table from "./Table";
import { ImageContainer } from "./styled";
import {
  apiAddress,
  growerState,
  growerTypes,
  modules,
} from "../../config/general";

export default function(props) {
  const processedData = (data) => {
    return (
      data &&
      data instanceof Array &&
      data.map((row) => {
        //process objects as strings
        Object.keys(row).map((d) => {
          if (row[d] && row[d] instanceof Object) {
            row[d] = JSON.stringify(row[d]);
          }
        });

        if (row.oringinalAddress) {
          const addressData = JSON.parse(row.oringinalAddress);
          row = { ...row, freguesiaSGEV: row.freguesia, ...addressData };
        }
        let attr = row?.attr;
        if (row?.attr) {
          attr = JSON.parse(row.attr);

          if (attr?.socioEconomics) {
            return {
              ...row,
              ...attr.socioEconomics,
              type: row.type ? growerTypes[row.type] : "",
              status: row.status ? growerState[row.status] : "",
              educationDone: row.educationDone ? "Completa" : "Por Completar",
            };
          }
        }

        return {
          ...row,
          type: row.type ? growerTypes[row.type] : "",
          status: row.status ? growerState[row.status] : "",
          educationDone: row.educationDone ? "Completa" : "Por Completar",
        };
      })
    );
  };

  let columnData = [
    {
      id: "gender",
      name: "gender",
      numeric: false,
      disablePadding: false,
      label: "Gênero",
    },
    {
      id: "job",
      name: "job",
      numeric: false,
      disablePadding: false,
      label: "Situação profissional",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && Array.isArray(value) ? value.join(", ") : value;
        },
      },
    },
    {
      id: "familySize",
      name: "familySize",
      numeric: false,
      disablePadding: false,
      label: "Agregado familiar",
    },
    {
      id: "familyIncome",
      name: "familyIncome",
      numeric: false,
      disablePadding: false,
      label: "Rendimento",
    },
    {
      id: "horticultureExperience",
      name: "horticultureExperience",
      numeric: false,
      disablePadding: false,
      label: "Experiência",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && Array.isArray(value) ? value.join(", ") : value;
        },
      },
    },
    {
      id: "howDidYouKnow",
      name: "howDidYouKnow",
      numeric: false,
      disablePadding: false,
      label: "Como soube do projeto",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && Array.isArray(value) ? value.join(", ") : value;
        },
      },
    },
    {
      id: "organicConsumption",
      name: "organicConsumption",
      numeric: false,
      disablePadding: false,
      label: "Consumo",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && Array.isArray(value) ? value.join(", ") : value;
        },
      },
    },
    {
      id: "whyAreYouJoining",
      name: "whyAreYouJoining",
      numeric: false,
      disablePadding: false,
      label: "Porque",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && Array.isArray(value) ? value.join(", ") : value;
        },
      },
    },

    {
      id: "houseType",
      name: "houseType",
      numeric: false,
      disablePadding: false,
      label: "Habitação",
    },

    {
      id: "type",
      name: "type",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
    },
    {
      id: "educationDone",
      name: "educationDone",
      numeric: false,
      disablePadding: false,
      label: "Formação",
    },

    {
      id: "status",
      name: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },

    {
      id: "pid",
      name: "idHorta",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = props.data[tableMeta.rowIndex];
          const thisPark =
            props.geoJson &&
            props.geoJson.geoJson &&
            props.geoJson.geoJson.find(
              (park) => park.properties.numero === thisData.pid
            );

          return (
            thisPark && thisPark.properties && thisPark.properties.numero_ant
          );
        },
      },
      label: "Horta",
    },

    {
      id: "createdat",
      name: "createdat",
      numeric: false,
      disablePadding: false,
      label: "Data Registo",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = props.data[tableMeta.rowIndex];
          return value || thisData.createdAt;
        },
      },
    },

    {
      id: "rua",
      name: "rua",
      numeric: false,
      disablePadding: false,
      label: "Rua",
    },
    {
      id: "porta",
      name: "porta",
      numeric: false,
      disablePadding: false,
      label: "Porta",
    },
    {
      id: "fracao",
      name: "fracao",
      numeric: false,
      disablePadding: false,
      label: "Fração",
    },
    {
      id: "cp",
      name: "cp",
      numeric: false,
      disablePadding: false,
      label: "Codigo Postal",
    },
    {
      id: "freguesia",
      name: "freguesia",
      numeric: false,
      disablePadding: false,
      label: "Freguesia",
    },
    {
      id: "freguesiaSGEV",
      name: "freguesiaSGEV",
      numeric: false,
      disablePadding: false,
      label: "Freguesia SGEV",
    },
  ];

  /// lets add the rest of the data to download
  columnData = columnData;

  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      rowsSelected={props.rowsSelected}
      setSelectedRows={props.setSelectedRows}
      selectCustomAction={props.selectCustomAction}
      selectable={true}
      click={props.click}
      header={columnData}
    />
  );
}
