export const TOGLE_MAP = "TOGLE_MAP";
export const TOGGLE_FILTER = "TOGGLE_FILTER";
export const TOGLE_VIEW_SCHOOL_SUBSCRIPTION = "TOGLE_VIEW_SCHOOL_SUBSCRIPTION";
export const TOGGLE_TRACK_USER = "APP/TOGGLE_TRACK_USER";
export const INITIAL_LOADING_SUCCESS = "INITIAL_LOADING_SUCCESS";
export const TOGLE_MOBILE_VIEW = "TOGLE_MOBILE_VIEW";
export const TOGLE_COMMUNICATION = "TOGLE_COMMUNICATION";
export const TOGLE_ELEMENTS = "TOGLE_ELEMENTS";
export const TOGLE_ADD_INTERVENTIONS = "TOGLE_ADD_INTERVENTIONS";
export const TOGLE_VIEW_GROWER = "TOGLE_VIEW_GROWER";
export const TOGLE_VIEW_INSPECTIONS = "TOGLE_VIEW_INSPECTIONS";
export const TOGLE_VIEW_INTERVENTIONS = "TOGLE_VIEW_INTERVENTIONS";
export const TOGLE_VIEW_REPORT = "TOGLE_VIEW_REPORT";
export const TOGLE_VIEW_REPORT_MANAGER = "TOGLE_VIEW_REPORT_MANAGER";
export const TOGLE_ADD_INSPECTIONS = "TOGLE_ADD_INSPECTIONS";
export const SET_USER_TYPE = "APP/SET_USER_TYPE";
export const SET_SEARCH_DATE = "APP/SET_SEARCH_DATE";
export const UPDATE_SEARCH_END = "APP/UPDATE_SEARCH_END";

export const SET_MAP_INTERACTION = "SET_MAP_INTERACTION";
export const SEARCHING = "SEARCHING";
export const RECENTER = "RECENTER";
export const RECENTEREND = "RECENTEREND";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const UPDATE_ACTIVE_PARK = "UPDATE_PARK";
export const UPDATE_UNIQUE_ACTIVE_PARK = "UPDATE_UNIQUE_ACTIVE_PARK";

export const ELEMENTS_ERROR = "ELEMENTS_FAILURE";
export const ELEMENTS_GETTING = "ELEMENTS_REQUEST";
export const ELEMENTS_RECEIVED = "ELEMENTS_SUCCESS";
export const ELEMENTS_RECEIVED_END = "ELEMENTS_RECEIVED_END";

export const SET_ZOOM = "SET_ZOOM";
