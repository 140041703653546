import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";

import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import GroupedButton from "../../components/Buttons/GroupedButton";
import { MenuItem, Tooltip } from "@material-ui/core";
import Select from "../../components/form-wrappers/Select";
import { getUniqueTeams, getGrowersRelations } from "./Selector";

import LocationFilter from "../../components/Filters/LocationFilter";
import { setSearch } from "../App/actions";

import Sidebar from "../../components/Layout/Sidebar";
import MenuIcon from "../../components/Buttons/MenuIcon";
import SendMessage from "../../components/Buttons/SendMessage";
import MainButton from "../../components/Buttons/MainButton";
import LineButton from "../../components/Buttons/LineButton";
import Content from "../../components/Layout/Content";
import AddUser from "./Modals/AddUser";
import AddTeam from "./Modals/AddTeam";
import Grower from "./Modals/Grower";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import { getGeoJson } from "../ParkList/Selector";

//tables componenet
import UserTable from "../../components/Table/Users";
import GrowersTable from "../../components/Table/Growers";
import SocioEconomics from "../../components/Table/SocioEconomics";
import TeamTable from "../../components/Table/Teams";
import { StyledWrap } from "../../components/InspMain/styled";

import {
  getUsers,
  getTeams,
  getGrowers,
  getSocioEconomics,
  getGrowersByRank,
  resetUser,
  setUserInitData,
  setTeamInitData,
  setGrowerInitData,
  setSelectedRows,
  calcFreguesiaRanks,
  setSearchDate,
} from "./actions";

const AddNew = styled(MainButton)`
  && {
    padding: 0 1em 0;
    line-height: 1;
    font-size: 0.8em;
    min-width: 0;
    width: 100%;
    span {
      display: inline-block;
      color: #fff;
      :hover {
        color: #4da1ff;
      }
    }
  }
`;
const CalcRankings = styled(LineButton)`
  && {
    padding: 0 1em 0;
    line-height: 1;
    font-size: 0.8em;
    min-width: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    color: #4da1ff;
    span {
      display: inline-block;
      color: #4da1ff;
      :hover {
        color: #fff;
      }
    }
    :hover {
      background: #4da1ff;
      color: #fff;
      .icons {
        color: #fff;
      }
    }
  }
`;

const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: calc(100vh - 180px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
      @media (max-width: 768px) {
        height: calc(100vh - 230px);
      }
    }
  }
`;

const TableWrap = styled.div`
  && {
    overflow-x: auto;
    overflow-y: visible;
    margin: 0;
    /* margin: 60px 0 0 0; */
    > div {
      padding-top: 0;
    }
  }
`;

const MaintenanceFilter = styled(Select)`
  && {
    border: 0;
    margin-bottom: 0.5em;
    font-size: 0.9em;
    color: #a3a1a1;
  }
`;

class Users extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      view: "growers",
      edit: props.id || "",
      addNewUser: false,
      addNewTeam: false,
      addNewGrower: false,
    };
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.goToDetails = this.goToDetails.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.selectCustomAction = this.selectCustomAction.bind(this);
    this.handleSearchDate = this.handleSearchDate.bind(this);
    // props.getUsers();
    // props.getTeams();

    if (Number(props.growerType) !== 0 && props.selected) {
      props.getGrowersByRank();
    } else {
      props.getGrowers();
    }
  }

  componentDidUpdate() {
    if (this.props.userStatus === "saved") {
      if (Number(this.props.growerType) !== 0 && this.props.selected) {
        this.props.getGrowersByRank();
      } else {
        this.props.getGrowers();
      }
    }
  }

  setInitialSearch() {
    const availableTypes = this.props.usergrowerType?.map((type, i) => {
      if (
        !this.props.permissions?.projects?.includes("All") &&
        !this.props.permissions?.projects?.includes(type)
      )
        return null;
      return i;
    });

    this.props.setSearch(
      {
        element: "availableTypes",
        value: availableTypes[0],
      },
      "growers"
    );
  }

  closeModal() {
    this.setState({
      addNewUser: null,
      addNewTeam: null,
      addNewGrower: null,
    });
    this.props.resetUser();
  }

  calculateRanks() {
    const toastrConfirmOptions = {
      onOk: () => this.props.calcFreguesiaRanks(),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza que quer recalcular todos os rankings para a Freguesia. ",
      toastrConfirmOptions
    );
  }

  toggleAddNew() {
    if (this.state.view === "users") {
      this.setState({
        edit: null,
        addNewUser: !this.state.addNewUser,
        addNewTeam: false,
        addNewGrower: false,
      });
      this.props.setUserInitData();
    } else if (this.state.view === "growers") {
      this.setState({
        edit: null,
        addNewUser: false,
        addNewTeam: false,
        addNewGrower: !this.state.addNewGrower,
      });
      this.props.setUserInitData();
    } else {
      this.setState({
        edit: null,
        addNewTeam: !this.state.addNewTeam,
        addNewUser: false,
        addNewGrower: false,
      });
      this.props.setTeamInitData();
    }
  }

  goToDetails(id) {
    if (this.state.view === "users") {
      this.setState({
        edit: id,
        addNewUser: true,
        addNewTeam: false,
        addNewGrower: false,
      });

      this.props.setUserInitData(id);
    } else if (this.state.view === "growers") {
      this.setState({
        edit: id,
        addNewUser: false,
        addNewTeam: false,
        addNewGrower: true,
      });

      this.props.setGrowerInitData(id);
    } else {
      this.setState({
        edit: id,
        addNewTeam: true,
        addNewUser: false,
        addNewGrower: false,
      });

      this.props.setTeamInitData(id);
    }

    // return this.props.history.push(`/teams/${id}`);
  }

  handleSearchChange(e) {
    if (e.target.name === "growerType") {
      this.props.setSearch(
        {
          element: [e.target.name],
          value: e.target.value,
        },
        "growers"
      );
      this.props.getGrowers();
    } else if (e.target.name === "rankingType") {
      this.props.setSearch(
        {
          element: [e.target.name],
          value: e.target.value,
        },
        "growers"
      );
      if (e.target.value === "all") {
        this.props.getGrowers();
      } else {
        this.props.getGrowersByRank();
      }
    } else {
      this.props.setSearch(
        {
          element: [e.target.name],
          value: e.target.value,
        },
        "growers"
      );

      if (e.target.name === "selected" && e.target.value === "") {
        this.props.setSearch(
          {
            element: ["rankingType"],
            value: "all",
          },
          "growers"
        );
      }

      if (
        Number(this.props.growerType) === 0 &&
        this.props.selected !== "" &&
        this.props.rankingType !== "all"
      ) {
        if (e.target.name === "selected" && e.target.value === "") {
          this.props.getGrowers();
        } else {
          this.props.getGrowersByRank();
        }
      } else {
        if (e.target.name === "selected") {
          this.props.getGrowers(
            undefined,
            undefined,
            undefined,
            undefined,
            "all",
            undefined
          );
        } else {
          this.props.getGrowers();
        }
      }
    }
  }

  selectCustomAction(selectedRows, displayData, setSelectedRows) {
    let contacts = [];
    if (selectedRows && selectedRows.data.length > 0) {
      contacts = selectedRows.data.map(
        (row) => this.props.growers[row.dataIndex].id
      );
    }
    return <SendMessage contacts={contacts} />;
  }

  handleSearchDate(e) {
    this.props.setSearchDate(e.target.name, e.target.value);
    if (this.props.growerType === "0" && this.props.selected) {
      this.props.getGrowersByRank();
    } else {
      this.props.getGrowers();
    }
  }

  render() {
    const {
      list,
      endDate,
      startDate,
      growers,
      menuData,
      status,
      growerType,
      selected,
      usergrowerType,
      rankingTypes,
      rankingType,
      growerState,
      uniqueTeams,
      permissions,
    } = this.props;
    const { addNewUser, addNewGrower, addNewTeam } = this.state;

    let title = menuData.name;

    switch (this.state.view) {
      case "growers":
        title = "Hortelões";
        break;
      case "teams":
        title = "Equipas";

        break;
      default:
        title = menuData.name;
    }
    return (
      <StyledWrap>
        {/*<MobileLayoutToggle />*/}
        <Sidebar
          title={title}
          text={`gerir ${title}`}
          icon={"user"}
          noanimation
        >
          <FilterList>
            <ScrollArea
              speed={0.8}
              className="maintenance-filter"
              contentClassName="maintenance-filter-content"
              horizontal={false}
            >
              <ButtonGroup>
                <Tooltip title={`Hortelões`}>
                  <GroupedButton
                    active={this.state.view === "growers"}
                    width={"24%"}
                    onClick={() => {
                      this.setState({ view: "growers" });
                      this.props.getGrowers();
                    }}
                  >
                    <MenuIcon className={`icon-puzzle icons`} />
                  </GroupedButton>
                </Tooltip>{" "}
                <Tooltip title={`socioeconómico`}>
                  <GroupedButton
                    active={this.state.view === "socioeconómico"}
                    width={"24%"}
                    onClick={() => {
                      this.setState({ view: "socioeconómico" });
                      this.props.getSocioEconomics();
                    }}
                  >
                    <MenuIcon className={`icon-home icons`} />
                  </GroupedButton>
                </Tooltip>
                <Tooltip title={`Utilizadores`}>
                  <GroupedButton
                    active={this.state.view === "users"}
                    width={"24%"}
                    onClick={() => {
                      this.setState({ view: "users" });
                      this.props.getUsers();
                    }}
                  >
                    <MenuIcon className={`icon-user icons`} />
                  </GroupedButton>
                </Tooltip>
                <Tooltip title={`Equipas`}>
                  <GroupedButton
                    active={this.state.view === "teams"}
                    width={"24%"}
                    onClick={() => {
                      this.setState({ view: "teams" });
                      this.props.getTeams();
                    }}
                  >
                    <MenuIcon className={`icon-people icons`} />
                  </GroupedButton>
                </Tooltip>
              </ButtonGroup>
              {(this.state.view === "growers" ||
                this.state.view === "socioeconómico") && (
                <div>
                  <MaintenanceFilter
                    fullWidth
                    label={"Estado"}
                    name={"ustatus"}
                    multiple={true}
                    value={status}
                    onChange={this.handleSearchChange}
                    inputProps={{
                      name: "ustatus",
                      id: "ustatus",
                    }}
                  >
                    {/* <MenuItem value="all">
                      <em>Todas</em>
                    </MenuItem> */}
                    {growerState.map((type, i) => {
                      return (
                        <MenuItem key={i} value={`${i}`}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </MaintenanceFilter>
                  <MaintenanceFilter
                    fullWidth
                    name={"growerType"}
                    label={"Tipo"}
                    value={growerType}
                    onChange={this.handleSearchChange}
                    inputProps={{
                      name: "growerType",
                      id: "growerType",
                    }}
                  >
                    {/* <MenuItem value="all">
                      <em>Todos</em>
                    </MenuItem> */}
                    {usergrowerType?.map((type, i) => {
                      if (
                        !permissions?.projects?.includes("All") &&
                        !permissions?.projects?.includes(type)
                      )
                        return null;

                      return (
                        <MenuItem key={i} value={`${i}`}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </MaintenanceFilter>
                  {selected && Number(this.props.growerType) === 0 && (
                    <MaintenanceFilter
                      fullWidth
                      name={"rankingType"}
                      label={"Ranking"}
                      value={rankingType}
                      onChange={this.handleSearchChange}
                      inputProps={{
                        name: "rankingType",
                        id: "rankingType",
                      }}
                    >
                      <MenuItem value="all">
                        <em>Sem Ranking</em>
                      </MenuItem>
                      {rankingTypes.map((type, i) => {
                        return (
                          <MenuItem key={i} value={`${i}`}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </MaintenanceFilter>
                  )}
                </div>
              )}

              <LocationFilter
                handleSearchChange={this.handleSearchChange}
                hideElements={true}
              />
              {!this.props.freguesia && (
                <CalcRankings onClick={() => this.calculateRanks()}>
                  <MenuIcon className={`icon-vector icons`} /> Recalcular
                  Rankings
                </CalcRankings>
              )}
              <DateRangeFilter
                handleSearchChange={this.handleSearchDate}
                endDate={endDate}
                startDate={startDate}
              />
            </ScrollArea>
          </FilterList>

          <AddNew onClick={() => this.toggleAddNew()}>
            <MenuIcon className={`icon-plus icons`} /> Adicionar
          </AddNew>
        </Sidebar>

        <Content size={2} color={"white"} map={true}>
          <TableWrap>
            {this.state.view === "users" && (
              <UserTable orderBy={"id"} data={list} click={this.goToDetails} />
            )}
            {this.state.view === "teams" && (
              <TeamTable
                orderBy={"id"}
                data={uniqueTeams}
                click={this.goToDetails}
              />
            )}

            {this.state.view === "socioeconómico" && (
              <SocioEconomics
                orderBy={"id"}
                geoJson={this.props.geoJson}
                rankingType={rankingType}
                selected={selected}
                rowsSelected={this.props.rowsSelected}
                setSelectedRows={this.props.setSelectedRows}
                selectCustomAction={this.selectCustomAction}
                data={growers}
                click={this.goToDetails}
              />
            )}
            {this.state.view === "growers" && (
              <GrowersTable
                orderBy={"id"}
                geoJson={this.props.geoJson}
                rankingType={rankingType}
                selected={selected}
                rowsSelected={this.props.rowsSelected}
                setSelectedRows={this.props.setSelectedRows}
                selectCustomAction={this.selectCustomAction}
                data={growers}
                click={this.goToDetails}
              />
            )}
          </TableWrap>
        </Content>

        {addNewUser && (
          <AddUser edit={this.state.edit} closeModal={this.closeModal} />
        )}
        {addNewTeam && (
          <AddTeam edit={this.state.edit} closeModal={this.closeModal} />
        )}
        {addNewGrower && (
          <Grower edit={this.state.edit} closeModal={this.closeModal} />
        )}
      </StyledWrap>
    );
  }
}

Users.propTypes = {
  list: PropTypes.array.isRequired,
  growers: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  growerState: PropTypes.array.isRequired,
  usergrowerType: PropTypes.array.isRequired,
};

Users.defaultProps = {
  list: [],
  growers: [],
  rowsSelected: [],
  teams: [],
  growerState: [],
  usergrowerType: [],
  // growerType: "all",
  rankingType: [],
};

export default connect(
  (state) => {
    return {
      list: state.user.list,
      growers: state.user.growersList,
      permissions: state.user.data.permissions,
      userStatus: state.user.status,
      teams: state.user.teams,
      uniqueTeams: getUniqueTeams(state),
      id: state.user.id,
      role: state.user.data.role,
      rowsSelected: state.user.selectedRows,
      selected: state.app.search.selected,
      elementSelected: state.app.search.element,
      freguesia: state.app.search.freguesia,
      status: state.app.search.ustatus,
      growerType: state.app.search.growerType,
      rankingTypes: state.user.rankingTypes,
      rankingType: state.app.search.rankingType,
      geoJson: getGeoJson(state),
      usergrowerType: state.user.growerTypes,
      growerState: state.user.growerState,
      startDate: state.user.startDate,
      endDate: state.user.endDate,
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/users`
        ),
    };
  },
  {
    getUsers,
    getTeams,
    getGrowers,
    getSocioEconomics,
    getGrowersByRank,
    setSearch,
    resetUser,
    setUserInitData,
    setGrowerInitData,
    calcFreguesiaRanks,
    setSelectedRows,
    setTeamInitData,
    setSearchDate,
  }
)(Users);
