import * as constants from "./constants";
import axios from "axios";
import { store } from "../../store/configure";
import { toastr } from "react-redux-toastr";
import messages from "./messages";
export function setDetails(details) {
  return {
    type: constants.SET_DETAILS,
    data: details,
  };
}
export function setContacts(contacts) {
  return {
    type: constants.SET_CONTACTS,
    data: contacts,
  };
}

export function getMessage(id) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });
    if (id === "new") {
      dispatch({
        type: constants.ERROR,
      });
      return null;
    }
    axios
      .get(`/api/v1/communications/${id}`)
      .then(function (response) {
        if (response.data.msg) {
          dispatch({
            type: constants.MESSAGE_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function (response) {
        dispatch({
          type: constants.ERROR,
        });
      });
  };
}

export function getMessages({ type, status, startDate, endDate }) {
  ////:type/:status/:start/:end
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    axios
      .get(`/api/v1/communications/${type}/${status}/${startDate}/${endDate}`)
      .then(function (response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function (response) {
        dispatch({
          type: constants.ERROR,
        });
      });
  };
}

/**
 * We need to send a message to the users according to the correct template
 * @param {*} messageId
 * @param {*} contacts
 */
export function setMessageToUser(messageId, contacts) {
  return async (dispatch) => {
    const messageContent = messages[`message${messageId}`];

    const now = new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    const date = `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;

    try {
      const response = await axios.post(`/api/v1/communications`, {
        contacts: contacts && contacts.map((c) => c.id),
        date,
        messageTemplate: messageId,
        type: 0,
        subject: messageContent.title,
        message: messageContent.message,
        status: "0",
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function setMessage(data) {
  return async (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    try {
      const communications = store.getState().communications;
      let response = null;

      if (data) {
        response = await axios.post(`/api/v1/messages/send`, {
          ...communications.edit,
          subject: data?.subject || communications.subject,
        });
      } else {
        response = await axios.post(`/api/v1/communications`, {
          ...communications.edit,
          ContactId: communications.contacts,
        });
      }

      if (response.data.msg) {
        dispatch({
          type: constants.SAVED,
          data: response.data.msg,
        });
        toastr.success("Concluido", "Mensagem salva com sucesso");
      }
      dispatch({
        type: constants.FINISHED,
        data: {},
      });
    } catch (error) {
      toastr.error("Verifique os dados introduzidos");
      console.log(error);

      dispatch({
        type: constants.ERROR,
      });
    }
  };
}

export function updateMessage() {
  return async (dispatch) => {
    dispatch({
      type: constants.SAVING,
    });

    try {
      const communications = store.getState().communications;
      console.log(communications);
      const response = await axios.put(
        `/api/v1/communications/${communications.edit.id}`,
        {
          ...communications.edit,
        }
      );

      if (response.data.msg) {
        dispatch({
          type: constants.UPDATED,
        });
        toastr.success("Concluido", "Comentario Salvo com sucesso");
      }
    } catch (error) {
      toastr.error("Verifique os dados introduzidos");
      console.log(error);

      dispatch({
        type: constants.ERROR,
      });
    }
  };
}

export function deleteMessage(state) {
  // const data = state;
  return (dispatch) => {
    // axios
    //   .delete(`/api/v1/inspections/${id}`)
    //   .then(function(response) {
    //     toastr.success("Eleminar", "foi Eleminada com sucesso");
    //     dispatch({
    //       type: constants.SAVED
    //     });
    //   })
    //   .catch(function(response) {
    //     toastr.error("Verifique os dados introduzidos");
    //     dispatch({
    //       type: constants.ERROR
    //     });
    //   });
  };
}
