import * as constants from "./constants";
import * as communicationConstants from "../Communications/constants";
import axios from "axios";
// import { hideLoading } from "react-redux-loading-bar";
import { store } from "../../store/configure";
import { getInspectionsList } from "../Inspections/actions";
import { getMaintenancesList } from "../Maintenances/actions";
import { getElementsRelationPure } from "../Georeference/actions";
import { getGrowerByParcelPure } from "../User/actions";
import { getTasksList } from "../Tasks/actions";
export function setUserTypeSearch(type) {
  return {
    type: constants.SET_USER_TYPE,
    data: type,
  };
}

export function setMapInteraction(type) {
  return {
    type: constants.SET_MAP_INTERACTION,
    data: type,
  };
}

export function recenterMap() {
  return {
    type: constants.RECENTER,
  };
}

export function recenterEnd() {
  return {
    type: constants.RECENTEREND,
  };
}
export function toggleEditCommunication(open, isNew) {
  return (dispatch) => {
    if (isNew) {
      dispatch({
        type: communicationConstants.MESSAGE_RECEIVED,
        data: {},
      });
    }
    dispatch({
      type: constants.TOGLE_COMMUNICATION,
      data: isNew ? "new" : open,
    });
  };
}
export function toggleMap(open) {
  return {
    type: constants.TOGLE_MAP,
    data: open,
  };
}
export function toggleTrackUser() {
  return {
    type: constants.TOGGLE_TRACK_USER,
  };
}
export function toggleFilter() {
  return {
    type: constants.TOGGLE_FILTER,
  };
}

export function toggleElements(open) {
  return {
    type: constants.TOGLE_ELEMENTS,
    data: open,
  };
}

export function toggleAddInterventions(open) {
  return {
    type: constants.TOGLE_ADD_INTERVENTIONS,
    data: open,
  };
}

export function toggleViewInterventions(open) {
  return {
    type: constants.TOGLE_VIEW_INTERVENTIONS,
    data: open,
  };
}

export function toggleViewReport(open) {
  return {
    type: constants.TOGLE_VIEW_REPORT,
    data: open,
  };
}

export function toggleViewSchoolSubscription(open) {
  return {
    type: constants.TOGLE_VIEW_SCHOOL_SUBSCRIPTION,
    data: open,
  };
}
export function toggleViewGrowers(open) {
  return {
    type: constants.TOGLE_VIEW_GROWER,
    data: open,
  };
}

export function toggleViewInspection(open) {
  return {
    type: constants.TOGLE_VIEW_INSPECTIONS,
    data: open,
  };
}

export function toggleAddInspection(open) {
  return {
    type: constants.TOGLE_ADD_INSPECTIONS,
    data: open,
  };
}
export function toggleMobileView(open) {
  return {
    type: constants.TOGLE_MOBILE_VIEW,
    data: open,
  };
}

export function updateZoom(zoom) {
  return {
    type: constants.SET_ZOOM,
    data: zoom,
  };
}

// const ActionGetElements = async () => {
//   const search = store.getState().app.search;
//   if (search.selected) {
//     return axios.get(`/api/v1/elements/selected/${search.selected}`);
//   } else if (search.local) {
//     return axios.get(`/api/v1/elements/local/${search.local}`);
//   }
// };

export function getElements(type, pid) {
  return (dispatch) => {
    getElementsPure(dispatch, type, pid);
  };
}

export function getElementsPure(dispatch, type, pid) {
  if (!type || !pid) {
    const search = store.getState().app.search;
    if (search.selected) {
      type = "selected";
      pid = search.selected;
    } else if (search.local) {
      type = "local";
      pid = search.local;
    } else {
      return null;
    }
  }
  dispatch({
    type: constants.ELEMENTS_GETTING,
  });
  axios
    .get(`/api/v1/elements/${type}/${pid}`)
    .then(function(response) {
      if (response.data.msg) {
        dispatch({
          type: constants.ELEMENTS_RECEIVED,
          data: response.data.msg,
        });
      }
    })
    .finally(() => {
      dispatch({
        type: constants.ELEMENTS_RECEIVED_END,
      });
    })

    .catch(function(response) {});
}

export function hideLoader() {
  return (dispatch) => {
    // setTimeout(() => dispatch(hideLoading()), 10000);
  };
}

export function setDateSearch(type, initial) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_SEARCH_DATE,
      data: type,
    });

    getInspectionsList(dispatch);
    getMaintenancesList(dispatch);
    if (initial) getTasksList(dispatch);
  };
}

export function setSearch(seachElement, nofetch) {
  return (dispatch) => {
    if (
      seachElement.element[0] === "selected" ||
      seachElement.element === "selected"
    ) {
      const geoJson = store.getState().parklist.geoJson;
      const thisPark = geoJson.find(
        (park) =>
          seachElement.data &&
          seachElement.data.value === park.properties.numero
      );
      if (thisPark) seachElement.data.parkType = thisPark.properties.typeType;
    }
    dispatch({
      type: constants.UPDATE_SEARCH,
      data: seachElement,
    });
    if (nofetch === "growers") {
      searchElements(dispatch, seachElement);
    } else if (!nofetch && seachElement.element !== "edit") {
      getInspectionsList(dispatch, seachElement);
      getMaintenancesList(dispatch, seachElement);
      searchElements(dispatch, seachElement);
      getElementsRelationPure(dispatch);
      getGrowerByParcelPure(dispatch);
    }

    dispatch({
      type: constants.UPDATE_SEARCH_END,
    });
  };
}

export function searchElements(dispatch, seachElement) {
  dispatch({
    type: constants.ELEMENTS_GETTING,
  });

  if (
    typeof seachElement.element === "object" &&
    seachElement.element.find(
      (e) => (e === "local" || e === "selected") && e !== "elementType"
    )
  ) {
    axios
      .get(`/api/v1/elements/${seachElement.element[0]}/${seachElement.value}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ELEMENTS_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as elementos');
      })
      .finally(() => {
        dispatch({
          type: constants.UPDATE_SEARCH_END,
        });
      });
  } else if (seachElement.element === "local") {
    axios
      .get(`/api/v1/elements/${seachElement.element}/${seachElement.value}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ELEMENTS_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as elementos');
      })
      .finally(() => {
        dispatch({
          type: constants.UPDATE_SEARCH_END,
        });
      });
  } else if (seachElement.element === "selected") {
    axios
      .get(`/api/v1/elements/selected/${seachElement.value}`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.ELEMENTS_RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        // toastr.error('Ocorreu um erro a obter as elementos');
      })
      .finally(() => {
        dispatch({
          type: constants.UPDATE_SEARCH_END,
        });
      });
  }
}

export function setActivePark(
  parkId,
  local,
  freguesia,
  element,
  unic,
  center,
  elementSelect
) {
  return async (dispatch) => {
    const geoJson = store.getState().parklist.geoJson;
    const pid = store.getState().app.search.selected;
    const thisPark = geoJson.find((park) => parkId === park.properties.numero);
    dispatch({
      type: constants.UPDATE_ACTIVE_PARK,
      data: {
        parkId,
        local,
        freguesia,
        element,
        unic,
        center,
        elementSelect,
        parkType: thisPark.properties.typeType,
      },
    });

    try {
      const fetchAssets = [
        getInspectionsList(dispatch),
        getMaintenancesList(dispatch),
        // getElementsPure(dispatch),
      ];

      if (pid !== parkId) {
        getElementsRelationPure(dispatch);
        getGrowerByParcelPure(dispatch);
      }

      if (!element) {
        dispatch({
          type: constants.ELEMENTS_GETTING,
        });
        fetchAssets.push(
          getElementsPure(dispatch, { element: "selected", value: [parkId] })
        );
      }

      await Promise.all(fetchAssets);

      dispatch({
        type: constants.UPDATE_SEARCH_END,
      });
    } catch (e) {}
  };
}

export function setUniqueActivePark(
  parkId,
  local,
  freguesia,
  element,
  unic,
  parcelSelect
) {
  return async (dispatch) => {
    const geoJson = store.getState().parklist.geoJson;
    const pid = store.getState().app.search.selected;
    const thisPark = geoJson.find((park) => parkId === park.properties.numero);
    dispatch({
      type: constants.UPDATE_UNIQUE_ACTIVE_PARK,
      data: {
        parkId,
        local,
        freguesia,
        element,
        unic,
        parkType: thisPark.properties.typeType,
      },
    });
    try {
      if (pid !== parkId) {
        getElementsRelationPure(dispatch);
        getGrowerByParcelPure(dispatch);
      }
      if (!element || parcelSelect) {
        getElementsPure(dispatch);
      }
      // hackaroo where are you? we need to pop it to the end of the stack to render the change
      setTimeout(
        () =>
          dispatch({
            type: constants.UPDATE_SEARCH_END,
          }),
        0
      );
    } catch (e) {}
  };
}
