import React from "react";
import { GeoJSON } from "react-leaflet";
import freguesiasJson from "./freguesias.json";

export default function Freguesias(props) {
  const onEachFeature = (feature, layer) => {
    layer.on({
      click: () => {
        // this.openPopup(feature, layer);
        // console.log(feature.properties.Freguesia);
        return typeof props.onClickMarker === "function"
          ? props.onClickMarker(feature.properties.Freguesia || "")
          : null;
      },
      mouseover: () => {
        layer.setStyle({
          // fillColor: "#4da1ff",
          color: "#4da1ff"
        });

        // layer.openPopup();
        // console.log(feature);
      },
      mouseout: () => {
        layer.setStyle({
          fillColor: "#000",
          color: "#000"
        });
      }
    });
    // layer.setStyle({
    //   fillColor:
    //     feature.properties && feature.properties.color
    //       ? feature.properties.color
    //       : "#a92222",
    //   color:
    //     feature.properties && feature.properties.color
    //       ? feature.properties.color
    //       : "#a92222"
    // });

    return layer;
  };
  return freguesiasJson ? (
    <GeoJSON
      key={`${new Date()}-freguesias`}
      data={freguesiasJson}
      style={{
        fillColor: "#000",
        color: "#000"
      }}
      onEachFeature={props.onEachFeature || onEachFeature}
    />
  ) : null;
}
