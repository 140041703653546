import styled from "styled-components";
import Content from "../Content";

export const FullContainer = styled(Content)`
  && {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    height: 100vh;
    background-size: cover;
  }
`;

export const FiltersContent = styled.div`
  && {
    position: fixed;
    right: 1em;
    bottom: 1em;
    z-index: 9;
    @media (max-width: 900px) {
      bottom: 4.5em;
      right: 0.5em;
      z-index: 1;
    }
    @media (max-width: 455px) {
      bottom: auto;
      right: auto;
      top: 0.5em;
      left: 3.5em;
      z-index: 1;
      width: 70%;
    }
  }
`;

export const MapContent = styled.div`
  && {
    width: calc(100% - 80px);
    margin: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    @media (max-width: 900px) {
      width: 100%;
      height: calc(100vh - 65px);
    }
  }
`;

export const StyledNavigationPanel = styled.div`
  && {
    width: 80px;
    height: 100vh;
    border: none;
    z-index: 3;
    > div {
      background: #0a2236;
    }

    @media (max-width: 900px) {
      height: 65px;
      width: 100%;
      position: fixed;
      bottom: 0;
    }
  }
`;

export const Styledmain = styled.main`
  width: calc(100% - 80px);
  z-index: 2;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
