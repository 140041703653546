import React from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components";

const StyledPaper = styled((props) => <Paper {...props} />)`
  && {
    background: #ffffff;
    /* box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.06); */
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 15px;
  }
`;

class ContentWrap extends React.Component {
  render() {
    return <StyledPaper {...this.props}>{this.props.children}</StyledPaper>;
  }
}

export default ContentWrap;
