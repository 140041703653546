import React from "react";
import Table from "./Table";
import moment from "moment";
import { Flag } from "../../containers/Reports/styled";

export default function(props) {
  const processedData = data => {
    return data.map(row => {
      return { ...row };
    });
  };
  const today = moment(new Date());
  let outputHeader = [
    {
      label: "Aviso",
      name: "Aviso",
      id: "id",
      options: {
        filter: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = props.data.find(row => row.id === value);

          if (thisData["status"] === "activo") {
            const date = moment(thisData["date"]);
            const passeDays = today.diff(date, "days");

            return <Flag warning={passeDays > 89 ? "red" : "yellow"} />;
          }
        }
      }
    }
  ];

  if (props.type === "all" && props.role !== "EV") {
    outputHeader.push(
      {
        id: "instanceType",
        numeric: false,
        disablePadding: false,
        label: "Genero",
        name: "Genero"
      },
      {
        id: "pid",
        numeric: false,
        disablePadding: false,
        label: "Parcela",
        name: "Parcela"
      }
    );
  }

  outputHeader = [
    ...outputHeader,
    {
      id: "Intervention.label",
      numeric: false,
      disablePadding: false,
      label: "Intervenção",
      name: "Intervenção"
    }
  ];
  outputHeader = [
    ...outputHeader,

    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Data",
      name: "Data"
    },
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      name: "id",
      options: {
        display: false
      }
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
      name: "Tipo"
    },
    {
      id: "pid",
      numeric: false,
      disablePadding: false,
      label: "Horta",
      name: "Horta"
    },
    {
      id: "Element.ElementType.label",
      numeric: false,
      disablePadding: false,
      label: "Elemento",
      name: "Elemento"
    },
    {
      id: "Damage.label",
      numeric: false,
      disablePadding: false,
      label: "Fiscalização",
      name: "Fiscalização"
    },

    {
      id: "details",
      numeric: false,
      disablePadding: false,
      label: "Observações",
      name: "Observações"
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: false,
      label: "Avaliação",
      name: "Avaliação"
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Estado",
      name: "Estado"
    },
    {
      id: "User.name",
      numeric: false,
      disablePadding: false,
      label: "User",
      name: "User"
    },
    {
      id: "User.Team.label",
      numeric: false,
      disablePadding: false,
      label: "Equipa",
      name: "Equipa"
    }
  ];

  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      click={props.click}
      header={outputHeader}
    />
  );
}
