import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import SchoolsSubscriptions from "../../../components/Table/Schools";

const EditParkShoolSubscription = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getSubscriptions();
  }, []);

  const getSubscriptions = async () => {
    try {
      const schoolName = props.data.about.designacao;
      const response = await axios.get(
        `api/v1/schools/${schoolName}/all/all/all/all`
      );
      if (response.data && response.data.msg) {
        const handledData = JSON.parse(response.data.msg);
        setData(handledData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        margin: "100px -5%",
      }}
    >
      <SchoolsSubscriptions
        orderBy={"id"}
        data={data}
        reloadData={getSubscriptions}
      />
    </div>
  );
};

export default EditParkShoolSubscription;
