import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import { apiAddress, growerTypes } from "../../config/general";
import { store } from "../../store/configure";
import axios from "axios";
import Contacts from "../../components/Table/Contacts";
import MainButton from "../../components/Buttons/MainButton";
import { toastr } from "react-redux-toastr";
import { Switch } from "@material-ui/core";
import user from "../User/Reducer";

const Growers = (props) => {
  const [selectedUsers, setSelectedUsers] = useState(props.selectedUsers || []);
  const parks = store.getState().parklist.list;

  useEffect(() => {
    props.getUserSuplentes();
    return () => {};
  }, []);

  useEffect(() => {
    setSelectedUsers(props.selectedUsers);
    return () => {};
  }, [props.selectedUsers]);

  /** filter users by props pid */
  const processSelectedPark = () => {
    // 1. If the user has selected a park, then filter the users by the park
    if (props.pid && props.pid !== "all") {
      // 2. Filter the users by the park
      return selectedUsers.filter((user) => {
        // 3. If the user is a grower, then filter by the park
        if (growerTypes[user.type] === props.selectedTypePark) {
          console.log(user.ranks);
          // 4. Get the parks for the user
          const userParks = Object.keys(user.ranks);
          // 5. Check if the user's parks include the selected park
          if (userParks.includes(props.pid)) {
            return (
              user.ranks[props.pid]?.ranking1?.status === 1 ||
              user.ranks[props.pid]?.ranking2?.status === 1 ||
              user.ranks[props.pid]?.rankingFreguesia?.status === 1
            );
          }

          return false;
        }
      });
    } else {
      // 6. If the user has not selected a park, then filter the users by the type of park
      return selectedUsers.filter((user) => {
        return growerTypes[user.type] === props.selectedTypePark;
      });
    }

    return selectedUsers;
  };

  /**
   * set users prefered park
   */
  const UpdateUserHortas = async (user, hortas) => {
    try {
      const res = await axios.post(
        `${apiAddress}api/v1/contacts/preferedPark/${user}`,
        {
          preferedPark: hortas,
        }
      );
    } catch (error) {
      // toastr.error("Aconteceu um obter lista");
      console.log(error);
    }
  };

  /***Set actions hooks */
  //update user state
  const updateUserState = async (state, user) => {
    //get user from selectedUsers
    const userObj = selectedUsers.find((u) => u.id === user);
    let updatedNote = userObj.note || "";

    //if state 3, set user to inactive
    const setNewAttrState = async () => {
      try {
        const res = await axios.post(
          `${apiAddress}api/v1/contacts/status/${user}`,
          { status: state, note: updatedNote }
        );
        if (document.getElementsByClassName(`close-toastr`)[0]) {
          document.getElementsByClassName(`close-toastr`)[0].click();
        }

        // props.getUserSuplentes();
        //let update selectedUsers
        setSelectedUsers(
          selectedUsers.map((u) => {
            if (u.id === user) {
              u.status = state;
              u.note = updatedNote;
            }
            return u;
          })
        );

        if (userObj?.status === 7) {
          await props.getParkQuotas();
        }

        // now we need to update userlist

        // if (state === 3) props.getUserSuplentes(); //userLeft
      } catch (error) {
        // toastr.error("Aconteceu um obter lista");
        console.log(error);
      }
    };
    const toastrConfirmOptions = {
      // onOk: () => setNewAttrState(),
      // onCancel: () => console.log("CANCEL: clicked"),
      // closeOnShadowClick: false,
      // enableEscapeKey: false,
      // enableEnterKey: false,
      timeOut: 0, // Default value is 0
      removeOnHover: false, // Default value is false
      removeOnHoverTimeOut: 0, // Default value is 1000
      component: (
        <div>
          <div
            style={{
              fontSize: "1.2em",
              lineHeight: "1",
              fontWeight: "bold",
              marginBottom: "1em",
            }}
          >
            {userObj?.status === 7 && (
              <div>
                Atenção este hortelão têm uma atribuição ativa, se avançar o
                hortlão será removido da parcela atual e passa para o estado
                suplente. <br />
                <br />
                Têm a certesa que quer avançar?
              </div>
            )}
          </div>
          <textarea
            multiline
            id={`note-${user}`}
            name="note"
            defaultValue={updatedNote}
            onChange={(e) => {
              updatedNote = e.target.value;
            }}
            type="multiline"
            rows="7"
            onKe
            style={{
              padding: "0.5em",
              fontFamily: "Arial",
              display: "block",
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              margin: ".5em 0",
              justifyContent: "flex-end",
            }}
          >
            <MainButton
              size={"small"}
              color={"primary"}
              onClick={() => setNewAttrState()}
            >
              Salvar Alterações
            </MainButton>
          </div>
        </div>
      ),
    };

    toastr.message(
      "Adicione um comentário antes de proceder com esta alteração",
      toastrConfirmOptions
    );
  };
  //update user attribution state
  const updateUserAttrState = async (state, user, extra) => {
    //get user from selectedUsers
    const userObj = selectedUsers.find((u) => u.id === user);
    let updatedNote = userObj.note || "";
    //if state 3, set user to inactive
    const setNewAttrState = async () => {
      try {
        const res = await axios.post(
          `${apiAddress}api/v1/contacts/attrStatus/${user}`,
          { status: state, note: updatedNote }
        );
        if (document.getElementsByClassName(`close-toastr`)[0]) {
          document.getElementsByClassName(`close-toastr`)[0].click();
        }
        //lets handle the prefered park update
        if (state === 5) {
          await UpdateUserHortas(user, extra);
          props.getUserSuplentes();
        }
        // now we need to update userlist
        // await props.getUserSuplentes();

        //let update selectedUsers
        setSelectedUsers(
          selectedUsers.map((u) => {
            if (u.id === user) {
              u.attrStatus = state;
              u.note = updatedNote;
            }
            return u;
          })
        );

        return true;
      } catch (error) {
        // toastr.error("Aconteceu um obter lista");
        console.log(error);
      }
    };

    const toastrConfirmOptions = {
      // onOk: () => setNewAttrState(),
      // onCancel: () => console.log("CANCEL: clicked"),
      // closeOnShadowClick: false,
      // enableEscapeKey: false,
      // enableEnterKey: false,
      timeOut: 0, // Default value is 0
      removeOnHover: false, // Default value is false
      removeOnHoverTimeOut: 0, // Default value is 1000
      component: () => {
        return (
          <div>
            <textarea
              multiline
              id={`note-${user}`}
              name="note"
              defaultValue={updatedNote}
              onChange={(e) => {
                updatedNote = e.target.value;
              }}
              type="multiline"
              rows="7"
              onKe
              style={{
                padding: "0.5em",
                fontFamily: "Arial",
                display: "block",
                width: "100%",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: ".5em 0",
                justifyContent: "flex-end",
              }}
            >
              Já enviei SMS <Switch className="checkSMS" />
              <MainButton
                size={"small"}
                color={"primary"}
                onClick={() => {
                  const checkBox = document.querySelector(".checkSMS input");
                  if (checkBox?.checked) setNewAttrState();
                }}
              >
                Salvar Alterações
              </MainButton>
            </div>
          </div>
        );
      },
    };

    toastr.message(
      "Adicione um comentário antes de proceder com esta alteração",
      toastrConfirmOptions
    );
  };

  return (
    <div>
      <Contacts
        orderBy={"id"}
        data={processSelectedPark(selectedUsers)}
        updateUserState={updateUserState}
        updateUserAttrState={updateUserAttrState}
        UpdateUserHortas={UpdateUserHortas}
        quotas={props.quotas}
        parks={parks}
        // reloadData={() => this.props.getMaterials(this.state.search)}
        // reloadData={this.props.getMaterials(this.state.search)}
        // click={this.goToDetail}
      />
    </div>
  );
};

export default Growers;
