import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { getElementRelation, getCurrentGrowersRelation } from "./Selector";
import { getCurrentElement, getSelectedPark } from "../ParkList/Selector";
import { apiAddress, rankingTypes } from "../../config/general";
import { default as ScrollArea } from "../../components/Layout/ScrollBar";
import ButtonGroup from "../../components/Buttons/ButtonGroup";
import GroupedButton from "../../components/Buttons/GroupedButton";
import AddNew from "./modals/AddNew";
import EditElement from "./modals/EditElement";
import EditPark from "./modals/EditPark";
import { debounce } from "lodash";
import MapComponent from "../../components/Map/Map";
import Sidebar from "../../components/Layout/Sidebar";
import Content from "../../components/Layout/Content";
import SectionTitle from "../../components/Text/SectionTitle";
import MenuIcon from "../../components/Buttons/MenuIcon";
import Grower from "../User/Modals/Grower";
import { toastr } from "react-redux-toastr";
import {
  setGrowerInitData,
  getGrowers,
  getGrowersByRank,
} from "../User/actions";
import LocationFilterMap from "../../components/Filters/LocationFilterMap";
import GrowerCard from "../../components/Card/GrowerCard";
import { growerTypes } from "../../config/general";
import {
  addNewToggle,
  editToggle,
  updateGeojsonElement,
  viewToggle,
  receivedElements,
  getElementsRelation,
  getGrowersRelation,
  setElementsRelation,
  delElementsRelation,
  getParcelsByFreguesia,
  getParkLeftQuotas,
} from "./actions";

import {
  ActionsContent,
  ActionWrapper,
  ActionGroupWrapper,
  FilterList,
  SidebarGeo,
  StyledWrap,
  DeleteButton,
} from "./styled";

// import TextField from "../../components/form-wrappers/TextField";
import { TextField } from "@material-ui/core";

import { FiltersContent, MapContent } from "../App/styled";

import { Card } from "../../components/Card/styled";

import { setSearch, setUniqueActivePark, getElements } from "../App/actions";

class Georeference extends Component {
  constructor(props) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.setActivePark = this.setActivePark.bind(this);
    this.addElement = this.addElement.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.viewGrower = this.viewGrower.bind(this);
    this.closeViewGrower = this.closeViewGrower.bind(this);
    this.closeViewModal = this.closeViewModal.bind(this);
    this.handleEditMarker = this.handleEditMarker.bind(this);
    this.getElements = this.getElements.bind(this);
    this.getElementsDelay = debounce(this.getElements, 2000);
    this.state = {
      type: "view",
      group: "view",
      view: "ranking1",
      updateGeoElementId: null,
      viewGrower: null,
      searchGrower: null,
      parcelToGrower: null,
      reRender: "",
      activeData: {},
      previewGrower: null,
      creatingElement: false,
    };
    this.updateGeojsonElement = this.updateGeojsonElement.bind(this);
    props.getElementsRelation();
  }

  componentWillUpdate(nextProps) {
    if (this.props.userStatus === "saved") {
      this.props.getGrowersByRank("ativo");
    }
    if (
      nextProps.selected !== this.props.selected &&
      this.state.type !== "view" &&
      this.state.type !== "view-parcels"
    ) {
      this.setState({ type: "", group: "add" });
    }
    if (
      nextProps.freguesia !== this.props.freguesia &&
      this.state.type === "view-parcels"
    ) {
      this.props.getParcelsByFreguesia();
    }

    const parkType = nextProps?.selectedPark?.properties?.parkType;
    if (parkType === "Horas Associativas") {
      this.props.setSearch(
        {
          element: "rankingType",
          value: 1,
        },
        true
      );
      this.props.getGrowersByRank("ativo");
      console.log("getGrowersByRank");
    }
  }

  componentDidUpdate() {
    if (this.props.status === "getElements") {
      this.getElementsDelay();
    }

    if (this.props.status === "resetElements" && this.state.creatingElement) {
      this.setState({ creatingElement: false });
    }
  }

  addElement(type, geoJson) {
    this.setState({ creatingElement: true });
    this.props.addNewToggle(type, geoJson);
  }

  closeAddModal() {
    this.props.addNewToggle();
  }

  handleSearchChange(e, value) {
    this.props.setSearch({
      element: [e.target.name],
      value: e.target.value,
    });
  }

  async updateGeojsonElement(id, geometry) {
    if (
      this.props.status !== "saving" ||
      id !== this.state.updateGeoElementId
    ) {
      this.setState({ updateGeoElementId: id });
      await this.props.updateGeojsonElement(id, geometry);
      this.setState({ updateGeoElementId: null });
    }
  }

  setActivePark(park) {
    let numero = park.properties.numero;
    let local = park.properties.local;
    let freguesia = park.properties.freguesia;
    let parkType = park.properties.parkType;
    let id = null;
    // set relations
    const realElement = this.props.geoJsonElements.find(
      (ele) =>
        ele.properties.id === this.props.element ||
        ele.properties.origin === this.props.element
    );

    if (
      this.state.type === "edit-relations" &&
      this.props.element &&
      (park?.properties?.origin || park?.properties?.id) &&
      park?.properties?.id !== realElement?.properties?.id
    ) {
      if (
        park.properties.origin &&
        realElement.properties.origin &&
        park.properties.origin === realElement.properties.origin
      ) {
        return false;
      }
      this.props.setElementsRelation(
        realElement.properties.origin || realElement.properties.id,
        park.properties.origin || park.properties.id,
        "element",
        parkType
      );
      this.setState({ type: "relations" });
      return true;
    }

    if (park.properties.typeId) {
      freguesia = this.props.freguesia;
      numero = park.properties.numero || this.props.selected;
      local = park.properties.local || this.props.local;
      id = park.properties.id;
    }

    this.props.setUniqueActivePark(numero, local, freguesia, id);
    this.setState({ activeData: park });

    if (
      this.state.parcelToGrower &&
      park.properties.label &&
      /parcela|lote/.test(park.properties.label.toLowerCase()) &&
      !park.properties.label.match(/Administrativa/)
    ) {
      // let rankingType = "noRank";
      // if (
      //   this.props.selectedPark &&
      //   this.props.selectedPark.properties &&
      //   this.props.selectedPark.properties.parkType == "Hortas Comunitárias"
      // ) {
      let rankingType = this.props.rankingType;
      // }

      this.props.setElementsRelation(
        this.state.parcelToGrower,
        park.properties.origin || park.properties.id,
        rankingType
      );
      this.setState({ parcelToGrower: null });
    } else if (this.state.type === "view") {
      this.props.viewToggle(park);
    } else if (this.state.type === "editinfo") {
      this.props.editToggle(park);
    }

    // lets get growers
    // if (
    //   oldFreguesia &&
    //   park.properties.freguesia &&
    //   oldFreguesia !== park.properties.freguesia &&
    //   (this.state.type === "view-growers" ||
    //     this.state.type === "edit-growers" ||
    //     this.state.type === "add-growers")
    // ) {
    //   this.props.getGrowers(
    //     park.properties.typeType,
    //     "all",
    //     park.properties.freguesia
    //   );
    // }
  }

  getElements() {
    if (this.props.selected) {
      this.props.getElements("selected", this.props.selected);
    } else if (this.props.local) {
      this.props.getElements("local", this.props.local);
    }
  }
  viewGrower(id) {
    this.setState({ viewGrower: id });
    this.props.setGrowerInitData(id);
  }

  searchGrower(text) {
    this.setState({ searchGrower: text });
  }
  getGrowersBySearch(growers) {
    if (this.state.searchGrower) {
      return growers.filter((gr) => {
        if (
          (gr.name &&
            gr.name
              .toLowerCase()
              .match(this.state.searchGrower.toLowerCase())) ||
          (gr.birthday &&
            gr.birthday
              .toLowerCase()
              .match(this.state.searchGrower.toLowerCase())) ||
          (gr.phone &&
            gr.phone
              .toLowerCase()
              .match(this.state.searchGrower.toLowerCase())) ||
          (gr.mobile &&
            gr.mobile
              .toLowerCase()
              .match(this.state.searchGrower.toLowerCase())) ||
          (gr.note &&
            gr.note.toLowerCase().match(this.state.searchGrower.toLowerCase()))
        ) {
          return true;
        }

        return false;
      });
    }
    return growers;
  }

  closeViewGrower() {
    this.setState({ viewGrower: null });
  }

  closeViewModal() {
    if (this.state.type === "editinfo") {
      this.props.editToggle();
    } else {
      this.props.viewToggle();
    }
  }

  handleEditMarker(park) {
    this.setActivePark(park);
    if (this.state.type === "editinfo") {
      this.props.editToggle(park);
    }
  }

  closeEditModal() {
    this.props.editToggle();
  }

  renderEditElement() {
    if (
      this.state.activeData &&
      this.state.activeData.properties &&
      this.state.activeData.properties.type !== "park"
    ) {
      return (
        <EditElement
          closeModal={this.closeEditModal}
          getElements={this.getElements}
        />
      );
    } else {
      return (
        <EditPark
          closeModal={this.closeEditModal}
          getElements={this.getElements}
        />
      );
    }
  }
  renderViewElement() {
    if (
      this.state.activeData &&
      this.state.activeData.properties &&
      this.state.activeData.properties.type !== "park"
    ) {
      return (
        <EditElement
          closeModal={this.closeViewModal}
          getElements={this.getElements}
          view
        />
      );
    } else {
      return (
        <EditPark
          closeModal={this.closeViewModal}
          getElements={this.getElements}
          view
        />
      );
    }
  }

  renderParcelsCard(parcels, thisParkData) {
    let local = "";
    let freguesia = "";
    if (thisParkData) {
      local = thisParkData.properties.local;
      freguesia = thisParkData.properties.freguesia;
    }
    return parcels.map((par, i) => {
      if (par.rstatus) return null;
      return (
        <Card
          key={i}
          onClick={() => {
            this.props.setSearch(
              {
                element: ["freguesia"],
                value: freguesia,
              },
              false
            );

            this.props.setSearch(
              {
                element: ["selected"],
                value: par.epid,
              },
              false
            );
          }}
        >
          <div>
            <span className={"element"}>{par.label}</span>
          </div>
        </Card>
      );
    });
  }

  renderRelationCard(renderCard) {
    return renderCard.map((tar, i) => {
      return (
        <Card key={i}>
          {this.state.type === "remove-relations" && (
            <DeleteButton
              color="primary"
              onClick={() => {
                this.props.delElementsRelation(tar.rel.id, "element");
              }}
            >
              Remover
              <MenuIcon className={`icon-check icons`} />
            </DeleteButton>
          )}
          <div onClick={() => this.setActivePark(tar)}>
            {tar.properties.files && tar.properties.files.length > 0 && (
              <span
                className={"file"}
                style={{
                  backgroundImage: `url(${apiAddress}${tar.properties.files[0].path})`,
                }}
              />
            )}
            <span className={"element"}>{tar.properties.label}</span>
          </div>
        </Card>
      );
    });
  }

  renderCards() {
    const { elementRelations, growers, growerRelations } = this.props;

    if (
      this.state.type === "view-growers" ||
      this.state.type === "edit-growers" ||
      this.state.type === "add-growers"
    ) {
      let receivedGrowers = growerRelations;
      if (this.state.type === "add-growers") {
        receivedGrowers = growers;
      }
      return (
        receivedGrowers instanceof Array &&
        this.getGrowersBySearch(receivedGrowers)
          .sort((a, b) => {
            if (
              a.connections &&
              a.connections[0] &&
              b.connections &&
              b.connections[0] &&
              a.connections[0].properties &&
              b.connections[0].properties &&
              a.connections[0].properties.label &&
              b.connections[0].properties.label
            ) {
              const first = a.connections[0].properties.label.split(" ");
              const second = b.connections[0].properties.label.split(" ");
              if (first[1] && second[1]) return first[1] - second[1];
            } else {
              return true;
            }
          })
          .map((grower, i) => {
            return (
              <GrowerCard
                key={i}
                grower={grower}
                type={this.state.type}
                viewGrower={this.viewGrower}
                setParcelToGrower={(growerId) => {
                  this.setState({ parcelToGrower: growerId });
                }}
                setPreviewGrower={(growerId) =>
                  this.setState({ previewGrower: growerId })
                }
                reltype={grower.reltype}
                noCommunication={true}
                // previewGrower={this.state.previewGrower}
                previewGrower={grower.id}
                delElementsRelation={this.props.delElementsRelation}
              />
            );
          })
      );
    } else if (this.state.type === "view-parcels") {
      const parcelData = this.props.parcels;
      return (
        parcelData && (
          <div>
            {Object.keys(parcelData).map((key, i) => {
              const thisParkData = this.props.geoJson.find(
                (p) => p.properties.numero === key
              );
              return (
                <div key={i}>
                  <SectionTitle>
                    <strong>
                      <i className={`icon-direction icons`} />{" "}
                      {thisParkData &&
                        thisParkData.properties &&
                        thisParkData.properties.name}
                    </strong>
                  </SectionTitle>
                  {this.renderParcelsCard(parcelData[key], thisParkData)}
                </div>
              );
            })}
          </div>
        )
      );
    } else {
      return (
        elementRelations &&
        elementRelations.length > 0 && (
          <div>
            <SectionTitle>
              <i className={`icon-key icons`} /> Relações
            </SectionTitle>

            {this.renderRelationCard(elementRelations)}
          </div>
        )
      );
    }
  }

  render() {
    const { selected, addNew, editElement, viewElement } = this.props;

    let activeSideBar = false;
    let title = "";
    let icon = "globe-alt";

    switch (this.state.type) {
      case "edit-growers":
        activeSideBar = true;
        title = "Hortelões";
        icon = "puzzle";
        break;
      case "view-parcels":
        activeSideBar = true;
        title = "Parcelas Disponíveis";
        icon = "pie-chart";
        break;
      case "view-growers":
        activeSideBar = true;
        title = "Hortelões";
        icon = "puzzle";
        break;
      case "add-growers":
        activeSideBar = true;
        title = "Hortelões";
        icon = "puzzle";
        break;
      case "relations":
        activeSideBar = true;
        title = "Conexões";
        icon = "share";
        break;
      case "edit-relations":
        activeSideBar = true;
        title = "Conexões";
        icon = "share";
        break;
      case "remove-relations":
        activeSideBar = true;
        title = "Conexões";
        icon = "share";
        break;
      default:
        break;
    }

    const parkType = this?.props?.selectedPark?.properties?.parkType;

    return (
      <StyledWrap className={`${activeSideBar ? "withsidebar" : "nosidebar"}`}>
        {/*<MobileLayoutToggle />*/}
        {activeSideBar && (
          <SidebarGeo className={"sidebar"}>
            <Sidebar
              title={
                this.props.currentElement
                  ? this.props.currentElement.properties.label
                  : title
              }
              text={`Gerir ${title}`}
              icon={icon}
              noanimation
            >
              <FilterList>
                {this.state.type === "add-growers" &&
                  parkType !== "Hortas Associativas" && (
                    <div>
                      <ButtonGroup>
                        <Tooltip title={rankingTypes[0]}>
                          <GroupedButton
                            active={this.props.rankingType === 0}
                            width={"20%"}
                            onClick={() => {
                              this.props.setSearch(
                                {
                                  element: "rankingType",
                                  value: 0,
                                },
                                true
                              );
                              this.props.getGrowersByRank("ativo");
                              // this.props.getGrowers();
                            }}
                          >
                            <MenuIcon className={`icon-speedometer icons `} />
                            {this.props.ranking0 && this.props.ranking0}
                          </GroupedButton>
                        </Tooltip>
                        <Tooltip title={rankingTypes[1]}>
                          <GroupedButton
                            active={this.props.rankingType === 1}
                            width={"20%"}
                            onClick={() => {
                              this.props.setSearch(
                                {
                                  element: "rankingType",
                                  value: 1,
                                },
                                true
                              );
                              this.props.getGrowersByRank("ativo");
                              // this.props.getUsers();
                            }}
                          >
                            <MenuIcon className={`icon-clock icons `} />
                            {this.props.ranking1 && this.props.ranking1}
                          </GroupedButton>
                        </Tooltip>
                        <Tooltip title={rankingTypes[2]}>
                          <GroupedButton
                            active={this.props.rankingType === 2}
                            width={"20%"}
                            onClick={() => {
                              this.props.setSearch(
                                {
                                  element: "rankingType",
                                  value: 2,
                                },
                                true
                              );
                              this.props.getGrowersByRank("ativo");
                              // this.props.getTeams();
                            }}
                          >
                            <MenuIcon className={`icon-map icons`} />
                            {this.props.ranking2 && this.props.ranking2}
                          </GroupedButton>
                        </Tooltip>
                        <Tooltip title={"Formação"}>
                          <GroupedButton
                            specialActive={this.props.education}
                            width={"20%"}
                            special
                            onClick={() => {
                              this.props.setSearch(
                                {
                                  element: "education",
                                  value: !this.props.education,
                                },
                                true
                              );
                              this.props.getGrowersByRank("ativo");
                              // this.props.getTeams();
                            }}
                          >
                            <MenuIcon className={`icon-graduation icons`} />
                          </GroupedButton>
                        </Tooltip>
                        <Tooltip title={"Transferencias"}>
                          <GroupedButton
                            specialActive={this.props.transfers}
                            width={"20%"}
                            special
                            onClick={() => {
                              this.props.setSearch(
                                {
                                  element: "transfers",
                                  value: !this.props.transfers,
                                },
                                true
                              );
                              this.props.getGrowersByRank("transfers");
                              // this.props.getTeams();
                            }}
                          >
                            <MenuIcon className={`icon-refresh icons`} />
                          </GroupedButton>
                        </Tooltip>
                      </ButtonGroup>
                    </div>
                  )}

                {parkType === "Hortas Associativas" && (
                  <ButtonGroup>
                    <Tooltip title={rankingTypes[1]}>
                      <GroupedButton
                        active={this.props.rankingType === 1}
                        width={"20%"}
                        onClick={() => {
                          this.props.setSearch(
                            {
                              element: "rankingType",
                              value: 1,
                            },
                            true
                          );
                          this.props.getGrowersByRank("ativo");
                          // this.props.getUsers();
                        }}
                      >
                        <MenuIcon className={`icon-clock icons `} />
                        {this.props.ranking1 && this.props.ranking1}
                      </GroupedButton>
                    </Tooltip>
                    <Tooltip title={"Formação"}>
                      <GroupedButton
                        specialActive={this.props.education}
                        width={"20%"}
                        special
                        onClick={() => {
                          this.props.setSearch(
                            {
                              element: "education",
                              value: !this.props.education,
                            },
                            true
                          );
                          this.props.getGrowersByRank("ativo");
                          // this.props.getTeams();
                        }}
                      >
                        <MenuIcon className={`icon-graduation icons`} />
                      </GroupedButton>
                    </Tooltip>
                    <Tooltip title={"Transferencias"}>
                      <GroupedButton
                        specialActive={this.props.transfers}
                        width={"20%"}
                        special
                        onClick={() => {
                          this.props.setSearch(
                            {
                              element: "transfers",
                              value: !this.props.transfers,
                            },
                            true
                          );
                          this.props.getGrowersByRank("transfers");
                          // this.props.getTeams();
                        }}
                      >
                        <MenuIcon className={`icon-refresh icons`} />
                      </GroupedButton>
                    </Tooltip>
                  </ButtonGroup>
                )}

                {(this.state.type === "view-growers" ||
                  this.state.type === "add-growers" ||
                  this.state.type === "edit-growers") && (
                  <TextField
                    label={"Pesquisa"}
                    onChange={(e) => this.searchGrower(e.target.value)}
                    margin="dense"
                    name={`Pesquisa`}
                    value={this.state.searchGrower}
                    input={{}}
                    style={{ width: "100%", margin: 0, padding: 0 }}
                    meta={{ invalid: false }}
                  />
                )}
                <ScrollArea
                  speed={0.8}
                  className="maintenance-filter"
                  contentClassName="maintenance-filter-content"
                  horizontal={false}
                >
                  {this.renderCards()}
                </ScrollArea>
              </FilterList>
            </Sidebar>
          </SidebarGeo>
        )}
        <Content size={activeSideBar ? 2 : 1} map={true}>
          <ActionsContent className={"actionsBut"}>
            <ActionGroupWrapper>
              {this.state.group === "view" && (
                <div>
                  <Tooltip title={` Parcelas`}>
                    <ActionWrapper
                      onClick={() => {
                        this.setState({ type: "view-parcels" });
                        this.props.getParcelsByFreguesia();
                      }}
                      className={`icon-pie-chart icons ${this.state.type ===
                        "view-parcels" && "active"}`}
                    />
                  </Tooltip>
                  <Tooltip title={` Hortelões`}>
                    <ActionWrapper
                      onClick={() => {
                        if (this.props.selectedPark) {
                          this.setState({ type: "view-growers" });
                          this.props.getGrowersRelation();
                        } else {
                          toastr.error("Tem de selecionar uma horta");
                        }
                      }}
                      className={`icon-puzzle icons ${this.state.type ===
                        "view-growers" && "active"}`}
                    />
                  </Tooltip>
                  <Tooltip title={` Conexões`}>
                    <ActionWrapper
                      onClick={() => {
                        if (this.props.selectedPark) {
                          this.setState({ type: "relations" });
                        } else {
                          toastr.error("Tem de selecionar uma horta");
                        }
                      }}
                      className={`icon-share-alt icons ${this.state.type ===
                        "relations" && "active"}`}
                    />
                  </Tooltip>
                </div>
              )}
              <Tooltip title={`View`}>
                <ActionWrapper
                  onClick={() => {
                    this.setState({ group: "view" });
                    this.setState({ type: "view" });
                  }}
                  className={`icon-eye icons ${this.state.group === "view" &&
                    "active"}`}
                />
              </Tooltip>
            </ActionGroupWrapper>
            <ActionGroupWrapper>
              {this.state.group === "add" && (
                <div>
                  <Tooltip title={`Adicionas Conexões`}>
                    <ActionWrapper
                      onClick={() => {
                        if (!this.props.element || this.props.element === "") {
                          toastr.error("Tem de selecionar uma elemento");
                        } else if (this.props.selectedPark) {
                          this.setState({ type: "edit-relations" });
                        } else {
                          toastr.error("Tem de selecionar uma horta");
                        }
                      }}
                      className={`icon-share-alt icons ${this.state.type ===
                        "edit-relations" && "active"}`}
                    />
                  </Tooltip>

                  <Tooltip title={`Adicionar Elementos`}>
                    <ActionWrapper
                      onClick={() => this.setState({ type: "add" })}
                      className={`icon-plus icons ${this.state.type === "add" &&
                        "active"}`}
                    />
                  </Tooltip>
                  <Tooltip title={`Adicionar Hortelões`}>
                    <ActionWrapper
                      onClick={() => {
                        if (this.props.selectedPark) {
                          this.setState({ type: "add-growers" });

                          this.props.getGrowersByRank("ativo");
                          this.props.getParkLeftQuotas();

                          // this.props.getGrowers();
                        } else {
                          toastr.error("Tem de selecionar uma horta");
                        }
                      }}
                      className={`icon-puzzle icons ${this.state.type ===
                        "add-growers" && "active"}`}
                    />
                  </Tooltip>
                </div>
              )}
              <Tooltip title={`Adicionar`}>
                <ActionWrapper
                  onClick={() => this.setState({ group: "add" })}
                  className={`icon-plus icons ${this.state.group === "add" &&
                    "active"}`}
                />
              </Tooltip>
            </ActionGroupWrapper>
            <ActionGroupWrapper>
              {this.state.group === "edit" && (
                <div>
                  <Tooltip title={`Editar Conexões`}>
                    <ActionWrapper
                      onClick={() => {
                        if (this.props.selectedPark) {
                          this.setState({ type: "remove-relations" });
                        } else {
                          toastr.error("Tem de selecionar uma horta");
                        }
                      }}
                      className={`icon-share-alt icons ${this.state.type ===
                        "remove-relations" && "active"}`}
                    />
                  </Tooltip>
                  <Tooltip title={`Editar Informação`}>
                    <ActionWrapper
                      onClick={() => {
                        this.setState({ type: "editinfo" });
                      }}
                      className={`icon-note icons ${this.state.type ===
                        "editinfo" && "active"}`}
                    />
                  </Tooltip>
                  <Tooltip title={`Editar Georeferencia`}>
                    <ActionWrapper
                      onClick={() => {
                        this.setState({ type: "edit" });
                      }}
                      className={`icon-pin icons ${this.state.type === "edit" &&
                        "active"}`}
                    />
                  </Tooltip>
                  <Tooltip title={`Editar Hortelões`}>
                    <ActionWrapper
                      onClick={() => {
                        if (this.props.selectedPark) {
                          this.setState({ type: "edit-growers" });
                          this.props.getGrowersRelation();
                        } else {
                          toastr.error("Tem de selecionar uma horta");
                        }
                      }}
                      className={`icon-puzzle icons ${this.state.type ===
                        "edit-growers" && "active"}`}
                    />
                  </Tooltip>
                </div>
              )}

              <Tooltip title={`Editar`}>
                <ActionWrapper
                  onClick={() => {
                    this.setState({ group: "edit" });
                  }}
                  className={`icon-note icons ${this.state.group === "edit" &&
                    "active"}`}
                />
              </Tooltip>
            </ActionGroupWrapper>
          </ActionsContent>

          <FiltersContent>
            <LocationFilterMap handleSearchChange={this.handleSearchChange} />
          </FiltersContent>
          <MapContent className={"mapgeo"}>
            <MapComponent
              onClickMarker={this.setActivePark}
              elementActionType={this.state.type}
              editElements={this.state.type === "edit"}
              getElements={this.getElements}
              onClickEditMarker={this.handleEditMarker}
              updateGeojsonElement={this.updateGeojsonElement}
              onCreated={this.addElement}
              creatingElement={this.state.creatingElement}
            />
          </MapContent>
        </Content>
        {addNew && (
          <AddNew
            closeModal={this.closeAddModal}
            selectedParkId={selected}
            getElements={this.getElements}
          />
        )}
        {this.state.viewGrower && <Grower closeModal={this.closeViewGrower} />}
        {editElement && this.renderEditElement()}
        {viewElement && this.renderViewElement()}
      </StyledWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      element: state.app.search.element,
      education: state.app.search.education,
      transfers: state.app.search.transfers,
      growers: state.user.growersList,
      userStatus: state.user.status,
      local: state.app.search.local,
      freguesia: state.app.search.freguesia,
      selected: state.app.search.selected,
      elementType: state.app.search.elementTypeSelected,
      parcels: state.georeference.parcels.empty,
      addNew: state.georeference.addNew,
      ranking0: state.georeference.quotas.ranking0,
      ranking1: state.georeference.quotas.ranking1,
      ranking2: state.georeference.quotas.ranking2,
      rankingType: state.app.search.rankingType,
      editElement: state.georeference.editElement,
      viewElement: state.georeference.viewElement,
      map: state.app.map,
      startDate: state.app.search.startDate,
      status: state.georeference.status,
      currentElement: getCurrentElement(state),
      selectedPark: getSelectedPark(state),
      growerRelations: getCurrentGrowersRelation(state),
      appStatus: state.app.status,
      geoJson: state.parklist.geoJson,
      geoJsonElements: state.app.elementsGeoJson,
      isAdmin: state.user.data.admin,
      elementRelations: getElementRelation(state),
      menuData:
        state.app.menus[state.user.data.role] &&
        state.app.menus[state.user.data.role].find(
          (menu) => menu.path === `/georeference`
        ),
    };
  },
  {
    setSearch,
    setUniqueActivePark,
    addNewToggle,
    editToggle,
    viewToggle,
    updateGeojsonElement,
    getElements,
    receivedElements,
    getElementsRelation,
    getGrowersRelation,
    setElementsRelation,
    delElementsRelation,
    setGrowerInitData,
    getGrowers,
    getParcelsByFreguesia,
    getGrowersByRank,
    getParkLeftQuotas,
  }
)(Georeference);
