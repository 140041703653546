import styled from "styled-components";
import Content from "../../components/Layout/Content";
import ContentWrap from "../../components/Layout/ContentWrap";

export const StyledWrap = styled(Content)`
  && {
    .row {
      margin: 1em;
    }
  }
`;
export const ContactList = styled.ul`
  && {
    li {
      padding: 1em;
      display: flex !important;
      align-items: center;
      justify-content: left;
      .icon {
        padding: 0.8em;
        font-size: 1.4em;
      }
      .content {
        padding: 0 0.5em;
        a {
          color: #474f58;
          text-decoration: none;
        }
        h4 {
          padding: 0;
          font-size: 1.2em;
          margin: 0;
        }
        p {
          margin: 0;
        }
      }
    }
  }
`;
export const StyledContentWrap = styled(ContentWrap)`
  && {
    max-height: 300px;
    overflow: auto;
    /* The emerging W3C standard
   that is currently Firefox-only */

    /* Works on Chrome/Edge/Safari */
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: white;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9e0ea;
      border-radius: 20px;
      border: 0 solid white;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: block;
        list-style-type: none;
        margin: 0;
        color: #474f58;
        font-size: 0.8em;
        border-bottom: 1px dotted #d9e0ea;
        span {
          display: inline-block;
          border-bottom: 1px dotted #fff;
          border-right: 1px solid #d9e0ea;
          margin-right: 1em;
          padding: 1em;
          min-width: 3em;
          text-align: center;
          background: #ecf1f8;
          color: #1e5ca1;
          font-weight: bold;
        }
        &.total {
          font-size: 1em;
          font-weight: bold;
          background: #ecf1f8;
        }
      }
    }
  }
`;
export const StyledContentWrapFullHeight = styled(ContentWrap)`
  && {
    padding: 2em;
    height: 40vh;
  }
`;

export const TotalParcelPerWeekWrap = styled.div`
  && {
    display: flex;
  }
`;
export const TotalParcelPerWeek = styled.div`
  && {
    text-align: center;
    font-size: 0.9em;
    font-weight: bold;
    border-right: 1px solid rgba(30, 92, 161, 0.1);
    border-bottom: 1px solid rgba(30, 92, 161, 0.1);
    background: rgba(30, 92, 161, 0.02);
    span {
      display: block;
      color: #4da1ff;
    }
    .week {
      background: rgba(30, 92, 161, 0.1);
      padding: 0.5em 1em;
      font-size: 0.8em;
      display: block;
      border-bottom: 1px solid rgba(30, 92, 161, 0.1);
    }
    .val {
      font-size: 1em;
      padding: 1em;
      color: rgba(30, 92, 161, 1);
    }
  }
`;
export const Hortas = styled.ul`
  && {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    overflow: auto;
    height: 40vh;
    li {
      padding: 0em 5% !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      color: rgba(30, 92, 161, 1);
      font-weight: bold;
      border-bottom: 1px solid rgba(30, 92, 161, 0.2);

      .content {
        padding: 0.5em;
        a {
          color: #474f58;
          text-decoration: none;
        }
        h4 {
          padding: 0;
          font-size: 1.2em;
          margin: 0;
        }
        p {
          margin: 0;
        }
      }
      .icon {
        font-weight: bold;
        font-size: 1em;
        color: #4da1ff;
      }
    }
  }
`;
export const Interventions = styled.ul`
  && {
    li {
      padding: 0.5em !important;
      display: flex !important;
      align-items: center;
      justify-content: left;
      .content {
        padding: 0.5em;
        a {
          color: #474f58;
          text-decoration: none;
        }
        h4 {
          padding: 0;
          font-size: 1.2em;
          margin: 0;
        }
        p {
          margin: 0;
        }
      }
      .icon {
        padding: 1.1em;
        font-size: 1.3em;
      }
    }
  }
`;
export const HortasList = styled.ul`
  && {
    padding: 0.5em;
    font-size: 1.1em;

    li {
      padding: 1em;

      padding: 1em;
      display: flex !important;
      align-items: top;
      justify-content: left;
      .icon {
        padding: 0.8em;
        font-size: 1.4em;
      }
      .content {
        padding: 0 0.5em;
      }

      strong {
        display: block;
        cursor: pointer;
      }
      ul {
        display: none;
        padding: 1.2em 0;
        li {
          font-size: 0.9em;
          padding: 1.2em;
          display: inline-block;
        }
      }
    }
    .active {
      ul {
        display: block;
      }
    }
  }
`;
