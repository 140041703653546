import React from "react";
import styled from "styled-components";
import ContentWrap from "../../../components/Layout/ContentWrap";
import MainButton from "../../../components/Buttons/MainButton";
import TextField from "../../../components/form-wrappers/TextField";
import { Switch } from "@material-ui/core";
import DropzoneComponent from "react-dropzone-component";
import SectionTitle from "../../../components/Text/SectionTitle";

export const Card = styled.div`
  && {
    margin: 2em 0;
    float: none;
    display: flex;
    clear: both;
  }
`;
export const IconWrap = styled.div`
  && {
    display: table-cell;
    vertical-align: middle;
    margin-right: 1.5%;
  }
`;
export const Section = styled.div`
  && {
    margin: 2em 0;
    max-width: 1200px;
  }
`;
export const RankStyles = styled.div`
  && {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    strong {
      color: #4da1ff;
      font-size: 0.8em;
      margin-bottom: 0em;
      display: block;
    }
    .right {
      text-align: right;
      .place {
        font-size: 3em;
        font-weight: bold;
      }
    }
    .left {
      font-size: 0.8em;
    }
    @media (max-width: 1024px) {
      font-size: 0.6em;
    }
    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
`;
export const SectionWrapTitle = SectionTitle;
export const HortaTitle = styled(SectionTitle)`
  && {
    font-size: 1.2em;
    margin: 1em 0;
    h2 {
      font-weight: bold;
    }
  }
`;
export const ParkRelation = styled.div`
  && {
    .MuiPaper-root {
      background: #4da1ff;
      color: #fff;
      border-radius: 0 0 8px 8px;
      margin-top: -1em;
      padding: 1em 2em;
    }
  }
`;
export const ButtonWrapper = styled.div`
  && {
    text-align: right;
    font-size: 0.8em;
    margin-top: 0.9em;
    button {
      // margin-right: 0.5em;
      :hover {
        a {
          color: #4da1ff;
        }
      }
    }

    a {
      text-decoration: none;
      color: #fff;
      :hover {
        color: #4da1ff;
      }
    }
  }
`;

export const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: left;
    .sideIcon {
      &:before {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 2em;
      }
    }
  }
`;

export const RelationWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    text-align: left;
    .sideIcon {
      &:before {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 2em;
      }
    }
  }
`;

export const ImagePlaceHolder = styled.div`
  && {
    height: 250px;
    background: url('${(props) => props.src}');
    background-position: center;
    background-size: cover;
  }
`;

export const SubTitle = styled.div`
  && {
    margin: 1em 0 0.5em 0;
    font-size: 0.8em;
  }
`;
export const Data = styled(TextField)`
  && {
    width: 100%;
    margin: 0 0 0.5em 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;
export const InputPure = styled.input`
  && {
    width: calc(100% - 1em);
    margin: 0 0 0.5em 0;
    text-align: left;
    line-height: 1.5em;
    padding: 1em 0.5em;
    display: block;
  }
`;
export const Input = styled(TextField)`
  && {
    width: 100%;
    /* padding: 10px 0; */
    margin: 0 0 0.5em 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;
export const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    width: 100%;
  }
`;
export const RelationButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: -1em;
    width: 100%;
    font-size: 0.8em;
    line-height: 1;
    padding: 0;
  }
`;

export const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
  }
`;
export const Toggle = styled((props) => <Switch {...props} />)`
  && {
    .MuiSwitch-bar-192,
    .jss192 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-iconChecked-194,
    .jss194 {
      color: #4da1ff;
    }
  }
`;

export const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
    .dz-image {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
`;
