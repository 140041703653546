import React from "react";
import Table from "./Table";

export default function(props) {
  const processedData = data => {
    return data.map(row => {
      return { ...row };
    });
  };
  let outputHeader = [
    {
      name: "Stats",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(value, tableMeta, updateValue);
        }
      }
    }
  ];
  if (props.type === "all") {
    outputHeader.push({
      id: "instanceType",
      numeric: false,
      disablePadding: false,
      label: "Genero",
      name: "Genero"
    });
  }
  if (props.type === "readings") {
    outputHeader.push(
      {
        id: "monthReading",
        numeric: true,
        disablePadding: false,
        label: "Consumo",
        name: "Consumo"
      },
      {
        id: "reading",
        numeric: true,
        disablePadding: false,
        label: "Leitura",
        name: "Leitura"
      }
    );
  } else {
    outputHeader = [
      ...outputHeader,
      {
        id: "Intervention.label",
        numeric: false,
        disablePadding: false,
        label: "Intervenção",
        name: "Intervenção"
      }
    ];
  }

  outputHeader = [
    ...outputHeader,
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Data",
      name: "Data"
    },
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      name: "id",
      options: {
        display: false
      }
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
      name: "Tipo"
    },
    {
      id: "pid",
      numeric: false,
      disablePadding: false,
      label: "Horta",
      name: "Horta"
    },
    {
      id: "Element.ElementType.label",
      numeric: false,
      disablePadding: false,
      label: "Elemento",
      name: "Elemento"
    },
    {
      id: "Damage.label",
      numeric: false,
      disablePadding: false,
      label: "Fiscalização",
      name: "Fiscalização"
    },

    {
      id: "details",
      numeric: false,
      disablePadding: false,
      label: "Observações",
      name: "Observações"
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: false,
      label: "Avaliação",
      name: "Avaliação"
    },
    // {
    //   id: 'attr',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Contagem',
    //   name: 'Contagem',
    // },

    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Estado",
      name: "Estado"
    },
    {
      id: "User.name",
      numeric: false,
      disablePadding: false,
      label: "User",
      name: "User"
    },
    {
      id: "User.Team.label",
      numeric: false,
      disablePadding: false,
      label: "Equipa",
      name: "Equipa"
    }
  ];
  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      click={props.click}
      header={outputHeader}
    />
  );
}
