import * as constants from './constants';
import axios from 'axios';

export function getStats() {
  return dispatch => {
    dispatch({
      type: constants.GETTING,
    });
    axios
      .get(`/api/v1/stats/`)
      .then(function(response) {
        if (response.data.msg) {
          dispatch({
            type: constants.RECEIVED,
            data: response.data.msg,
          });
        }
      })
      .catch(function(response) {
        dispatch({
          type: constants.ERROR,
        });
      });
  };
}
