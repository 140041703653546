import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import MenuIcon from "./MenuIcon";
import { toggleEditCommunication } from "../../containers/App/actions";
import { setContacts } from "../../containers/Communications/actions";
const StyledButton = styled.a`
  && {
    font-size: 0.8em;
    cursor: pointer;
    font-weight: 100;
    line-height: 1.2em;
    text-align: center;
    padding: 0 1em;
    margin: 0;
    background: #0a2236;
    border: 1px solid #0a2236;
    border-radius: 6px;
    display: block;
    color: #ffffff;
    letter-spacing: 0;
    text-transform: initial;
    ${(props) => {
      if (props.active === "true") {
        return ` 
        background: #fff;
        color: #0a2236;
        .icons, span {
          color: #0a2236!important;
        }`;
      }
    }};

    :hover {
      background: #fff;
      color: #0a2236;
      .icons {
        color: #0a2236;
      }
    }
    .icons {
      line-height: 2.5em;
      color: #fff;
      font-size: 1.2em;
    }
  }
`;

class SendMessage extends React.Component {
  constructor(props) {
    super(props);
    this.setAction = this.setAction.bind(this);
  }
  setAction() {
    this.props.setContacts(this.props.contacts);
    this.props.toggleEditCommunication(true, true);
  }
  render() {
    return (
      this.props.contacts &&
      this.props.contacts.length > 0 && (
        <StyledButton onClick={() => this.setAction()}>
          <MenuIcon className={`icon-bubbles icons`} /> Mensagem
        </StyledButton>
      )
    );
  }
}

export default connect(
  (state) => {
    return {};
  },
  { toggleEditCommunication, setContacts }
)(SendMessage);
