import { createStore, applyMiddleware } from "redux";
import createReducer from "./index";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import { jwt } from "./jwt";
import thunk from "redux-thunk";

const middlewares = applyMiddleware(
  jwt,
  thunk,
  loadingBarMiddleware({
    // promiseTypeSuffixes: ["REQUEST", "SUCCESS", "FAILURE"],
  })
);

export const store = createStore(
  createReducer,
  composeWithDevTools(middlewares)
);
