import styled from "styled-components";
import ContentWrap from "../Layout/ContentWrap";
import MainButton from "../Buttons/MainButton";

export const OptionWrap = styled(ContentWrap)`
  && {
    padding: 1em 1.5em;
    text-align: left;
    margin-bottom: 0.5em;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        cursor: pointer;
      }
    }
    .sideIcon {
      &:before {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 2em;
      }
    }
  }
`;
export const NextButton = styled(MainButton)`
  && {
    text-transform: capitalize;
    margin-left: 0;
    margin-top: 1em;
    width: 100%;
  }
`;
export const MapContainer = styled.div`
  && {
    height: 70vh;
    width: 100%;
    @media (max-width: 768px) {
      height: 64vh;
    }
    /* height: calc(100vh - 300px); */
  }
`;
