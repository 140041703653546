import axios from "axios";
import { toastr } from "react-redux-toastr";
import * as constants from "./constants";

export function setActiveType(type) {
  return {
    type: constants.SET_TYPE,
    data: type,
  };
}
export function sendPreInvite() {
  return (dispatch) => {
    axios
      .get(`api/v1/schools/globalPreEmail`)
      .then(function(response) {
        if (response.data.success) {
          toastr.success(
            `total de ${response?.data?.totalEmails} emails enviados`
          );
        } else {
          toastr.error("Ocorreu um erro a enviar emails");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a enviar emails");
        //toastr.error("Ocorreu um erro a obter as inspecções");
      });
  };
}

export function sendReInvite() {
  return (dispatch) => {
    axios
      .get(`api/v1/schools/globalReEmail`)
      .then(function(response) {
        if (response.data.success) {
          toastr.success(
            `total de ${response?.data?.totalEmails} emails enviados`
          );
        } else {
          toastr.error("Ocorreu um erro a enviar emails");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a enviar emails");
        //toastr.error("Ocorreu um erro a obter as inspecções");
      });
  };
}

export function sendGlobalOrders() {
  return (dispatch) => {
    axios
      .get(`api/v1/schools/globalEmail`)
      .then(function(response) {
        if (response.data.success) {
          toastr.success(
            `total de ${response?.data?.totalEmails} emails enviados`
          );
        } else {
          toastr.error("Ocorreu um erro a enviar emails");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a enviar emails");
        //toastr.error("Ocorreu um erro a obter as inspecções");
      });
  };
}

export function sendAutumnOrders() {
  return (dispatch) => {
    axios
      .get(`api/v1/schools/autumnEmail`)
      .then(function(response) {
        if (response.data.success) {
          toastr.success(
            `total de ${response?.data?.totalEmails} emails enviados`
          );
        } else {
          toastr.error("Ocorreu um erro a enviar emails");
        }
      })
      .catch(function(response) {
        toastr.error("Ocorreu um erro a enviar emails");
        //toastr.error("Ocorreu um erro a obter as inspecções");
      });
  };
}

export function getOrders({ startDate, endDate }) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    const url = [
      `api/v1/schools`,
      encodeURI("all"),
      encodeURI("all"),
      encodeURI(startDate),
      encodeURI(endDate),
      encodeURI("order"),
    ];

    axios
      .get(url.join("/"))
      .then(function(response) {
        if (response.data.msg && response.data.msg) {
          const data = JSON.parse(response.data.msg || "[]");
          dispatch({
            type: constants.RECEIVED,
            data: data,
          });
        } else {
          //toastr.error("Ocorreu um erro a obter as inspecções");

          dispatch({
            type: constants.ERROR,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as inspecções");

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}

export function getMaterials({ startDate, endDate, status }) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });
    const url = [
      `api/v1/materials`,
      encodeURI(startDate),
      encodeURI(endDate),
      encodeURI(status || "all"),
    ];

    axios
      .get(url.join("/"))
      .then(function(response) {
        if (response.data) {
          dispatch({
            type: constants.RECEIVED,
            data: response.data,
          });
        } else {
          //toastr.error("Ocorreu um erro a obter as inspecções");

          dispatch({
            type: constants.RECEIVED,
            data: [],
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as inspecções");

        dispatch({
          type: constants.RECEIVED,
          data: [],
        });
      });
  };
}

export function getSubscriptions({
  agrupamento,
  school,
  startDate,
  endDate,
  status,
}) {
  return (dispatch) => {
    dispatch({
      type: constants.GETTING,
    });

    const url = [
      `api/v1/schools`,
      encodeURI(school || "all"),
      encodeURI(agrupamento || "all"),
      encodeURI(startDate),
      encodeURI(endDate),
      encodeURI(status || "all"),
    ];

    axios
      .get(url.join("/"))
      .then(function(response) {
        if (response.data.msg && response.data.msg) {
          const data = JSON.parse(response.data.msg || "[]");
          dispatch({
            type: constants.RECEIVED,
            data: data,
          });
        } else {
          //toastr.error("Ocorreu um erro a obter as inspecções");

          dispatch({
            type: constants.ERROR,
          });
        }
      })
      .catch(function(response) {
        //toastr.error("Ocorreu um erro a obter as inspecções");

        dispatch({
          type: constants.ERROR,
        });
      });
  };
}
