import { GETTING, RECEIVED, SAVED, SAVING } from "./constants";
import { USER_LOGGED_OUT } from "../User/constants";
import { status } from "../../config/general";

import { getCurrentDate } from "../../services/utils";

let initialState = {
  list: [],
  pid: null,
  elementsGeoJson: [],
  status: "",
  search: {
    local: "",
    freguesia: "",
    totalParks: 0,
    selected: "",
    startDate: getCurrentDate()
  }
};

const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};
//lets load user data for reload
if (isLocalStorage && localStorage.tasks) {
  initialState = JSON.parse(localStorage.tasks);
  initialState.search = {
    ...initialState.search,
    endDate: getCurrentDate(),
    startDate: getCurrentDate()
  };
}
function presistData(type, data) {
  try {
    if (isLocalStorage)
      localStorage[type] = JSON.stringify({ ...data, status: "" });
  } catch (error) {
    console.log(error);
  }
  return data;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED:
      newState = {
        ...state,
        status: status.set,
        maintenancesList: action.data.maintenancesList,
        inspectionList: action.data.inspectionList,
        routesList: action.data.routesList,
        totalParks: action.data.routesList.length
        // totalParks: selectedTotalParks(action.data.routesList)
      };
      return presistData("tasks", newState);
    case GETTING:
      return {
        ...state,
        status: status.getting
      };

    case SAVING:
      newState = {
        ...state,
        status: status.saving
      };
      return presistData("tasks", newState);
    case SAVED:
      newState = {
        ...state,
        status: status.saved
      };
      return presistData("tasks", newState);
    case USER_LOGGED_OUT:
      return initialState;
    default:
      return state;
  }
};
