export const GETTING = 'TASKS_GETTING';
export const SAVING = 'TASKS_SAVING';
export const SAVED = 'TASKS_SAVED';
export const RECEIVED = 'TASKS_RECEIVED';
export const UPDATED = 'TASKS_UPDATED';
export const ERROR = 'TASKS_ERROR';
export const UPDATE_SEARCH = 'UPDATE_TASK_SEARCH';
export const ELEMENTS_ERROR = 'TASK_ELEMENTS_FAILURE';
export const ELEMENTS_GETTING = 'TASK_ELEMENTS_REQUEST';
export const ELEMENTS_RECEIVED = 'TASK_ELEMENTS_SUCCESS';
