import React from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components";
import { toggleFilter } from "../../containers/App/actions";
import { connect } from "react-redux";

const StyledPaper = styled((props) => <Paper {...props} />)`
  && {
    background: #ffffff;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    height: 100%;
    border: none;
    z-index: 2;
    // width: 320px;
    position: fixed;
    border-radius: 0;
    width: ${(props) => (props.hide === "true" ? "20px" : "320px")};
    @media (max-width: 768px) {
      position: relative;
      width: 100%;
      display: none;
      display: ${(props) => (props.activeview ? "block" : "none")};
    }
    @media (max-width: 900px) {
      height: calc(100vh - 65px);
    }
    // @media (max-width: 455px) {
    //   height: auto;
    //   min-height: 100vh;
    // }
    @media (max-width: 455px) {
      margin-top: 70vh;
      display: block;
      height: auto;
      min-height: 100vh;
      order: 2;
      position: absolute;
    }
    @media print {
      display: none;
    }
  }
`;

const ContentWrap = styled.div`
  && {
    display: ${(props) => (props.hidden ? "none" : "block")};
    padding: 2em 2em 0 2em;
    @media (max-width: 455px) {
      padding: 1em 2em 0 2em;
      height: auto;
      min-height: 100%;
    }
  }
`;

const TitleWrap = styled.div`
  && {
    display: flex;
    margin-bottom: 1em;
    @media (max-width: 455px) {
      margin-bottom: 0;
    }
  }
`;

const Icon = styled.div`
  && {
    width: 69px;
    height: 59px;
    margin-right: 8%;
    background: #4da1ff;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 4px;
    @media (max-width: 455px) {
      display: none;
    }
  }
`;

const Label = styled.div`
  && {
    width: 70%;
  }
`;

const Title = styled.h3`
  && {
    font-size: 1.2em;
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: #4a4a4a;
    letter-spacing: 0;
    line-height: 1;
  }
`;

const SubTitle = styled.div`
  && {
    font-size: 1em;
    margin-top: 0em;
    color: #989898;
    font-weight: bold;
    line-height: 1;
    span {
      display: block;
      opacity: 0.8;
      font-size: 0.8em;
      margin-top: 0.5em;
    }
  }
`;

const MenuIcon = styled.i`
  && {
    width: 100%;
    display: block;
    font-size: 1.3em;
    text-align: center;
    color: #fff;
    padding: 0.8em 0;
  }
`;

const ActionSidebar = styled.div`
  && {
    position: absolute;
    display: block;
    top: 16px;
    right: -10px;
  }
`;

const Minimize = styled.div`
  && {
    background: #4da1ff;
    padding: 0.7em;
    font-size: 0.7em;
    border-radius: 4px;
    writing-mode: tb-rl;
    /* transform: rotate(-90deg); */
    color: #fff;
    .icons {
      margin-left: 0.5em;
    }
  }
`;

class Sidebar extends React.Component {
  render() {
    const { title, text, icon, actions } = this.props;
    return (
      <StyledPaper
        className={this.props.noanimation ? "" : "animated fadeInLeft"}
        activeview={
          this.props.activeMobileView === "filter" ? "true" : undefined
        }
        hide={this.props.showFilter.toString()}
      >
        <div style={{ position: "relative", width: "100%", cursor: "pointer" }}>
          <ActionSidebar>
            <Minimize onClick={() => this.props.toggleFilter()}>
              FILTER <span className="icons icon-arrow-left" />
            </Minimize>
            {actions}
          </ActionSidebar>
        </div>
        <ContentWrap hidden={this.props.showFilter}>
          <TitleWrap>
            {icon && (
              <Icon>
                <MenuIcon className={`icon-${icon} icons`} />
              </Icon>
            )}
            <Label>
              <Title>{title}</Title>
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </Label>
          </TitleWrap>

          {this.props.children}
        </ContentWrap>
      </StyledPaper>
    );
  }
}

export default connect(
  (state) => {
    return {
      activeMobileView: state.app.activeMobileView,
      showFilter: state.app.toggleFilter,
    };
  },
  { toggleFilter }
)(Sidebar);
