import styled from "styled-components";

export const MapContainer = styled.div`
  && {
    position: relative;
    height: 100%;
    @media (max-width: 455px) {
      display: block;
      height: 70vh;
    }
    .map-container {
      height: 100%;
      .extra-marker i {
        font-size: 17px;
      }

      .detailsWraper {
        padding: 1em;
        min-width: 200px;
        .element-image {
          height: 180px;
          background-position: center;
          background-size: cover;
          margin: -1.6em -1.6em 1em;
        }
        span {
          font-weight: bold;
        }
        h3 {
          padding: 0;
          margin: 0;
          font-size: 1.3em;
          color: #4da1ff;
          font-weight: normal;
        }
        p {
          padding: 0;
          margin: 0 0 0.8em 0;
          font-size: 1em;
          color: #4a4a4a;
          font-weight: normal;
        }
        ul {
          padding: 0;
          margin: 0;
          font-size: 1em;
          color: #555;
          li {
            list-style-type: none;
          }
        }
      }
    }
  }
`;

export const MapSingleContainer = styled.div`
  && {
    margin: -2em;
    height: 300px;
  }
`;
