import {
  GETTING,
  RECEIVED,
  ERROR,
  GETTING_PARK_REPORT,
  RECEIVED_PARK_REPORT,
  ERROR_PARK_REPORT,
  SAVING_REPORT,
  RECEIVED_FREGUESIAS_REPORT,
  SAVED_REPORT,
  ERROR_REPORT,
  GET_MONTH_REPORT,
  RECEIVED_MONTH_REPORT,
  ERROR_MONTH_REPORT,
  RECEIVED_PARK_HISTORY,
  ERROR_PARK_HISTORY,
  GET_PARK_HISTORY,
} from "./constants";

import { getCurrentDate } from "../../services/utils";

const status = {
  edit: "edit",
  error: "error",
  saving: "saving",
  saved: "saved",
  edited: "edited",
  getting: "getting",
  received: "received",
};

let initialState = {
  status: "",
  data: [],
  type: "all",
  parksHistory: [],
  freguesiasReport: null,
  monthReportData: null,
  monthStatus: null,
  reportData: [],
  elementsHistory: [],
};

const isLocalStorage = typeof localStorage !== "undefined";
//lets load user data for reload
if (isLocalStorage && localStorage.reports) {
  initialState = JSON.parse(localStorage.reports);
  initialState.search = {
    ...initialState.search,
    startDate: getCurrentDate(true),
    endDate: getCurrentDate(),
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING:
      return {
        ...state,
        status: status.getting,
      };
    case RECEIVED:
      return {
        ...state,
        status: status.received,
        data: action.data,
      };

    case GET_PARK_HISTORY:
      return {
        ...state,
        status: status.getting,
      };
    case RECEIVED_PARK_HISTORY:
      return {
        ...state,
        status: status.received,
        parksHistory: action?.data?.inactiveParks || [],
        elementsHistory: action?.data?.inactiveElements || [],
      };
    case ERROR_PARK_HISTORY:
      return {
        ...state,
        status: status.error,
      };

    case GET_MONTH_REPORT:
      return {
        ...state,
        monthStatus: status.getting,
      };

    case RECEIVED_MONTH_REPORT:
      return {
        ...state,
        monthStatus: status.received,
        monthReportData: action.data,
      };
    case ERROR_MONTH_REPORT:
      return {
        ...state,
        monthStatus: status.error,
      };
    case SAVING_REPORT:
      return {
        ...state,
        status: status.saving,
      };
    case SAVED_REPORT:
      return {
        ...state,
        status: status.saved,
      };
    case ERROR_REPORT:
      return {
        ...state,
        status: status.error,
      };
    case GETTING_PARK_REPORT:
      return {
        ...state,
        status: status.getting,
      };
    case ERROR:
      return { ...state, status: status.error };

    case RECEIVED_PARK_REPORT:
      return {
        ...state,
        status: status.received,
        reportData: action.data,
      };
    case ERROR_PARK_REPORT:
      return { ...state, status: status.error };
    case RECEIVED_FREGUESIAS_REPORT:
      return { ...state, freguesiasReport: action.data };

    default:
      return state;
  }
};
