import { GETTING, RECEIVED } from "./constants";
import { USER_LOGGED_OUT } from "../User/constants";
import { growerTypes, status } from "../../config/general";

let initialState = {
  status: "",
  list: [],
  geoJson: [],
  geoJsonLocal: {},
  geoJsonFiltered: [],
  geojsonFilteredElement: [],
  location: {},
  local: [],
};

const isLocalStorage = typeof localStorage !== "undefined";
let newState = {};

function presistData(type, data) {
  try {
    if (isLocalStorage)
      localStorage[type] = JSON.stringify({ ...data, status: "" });
  } catch (error) {
    console.log(error);
  }
  return data;
}

//lets load user data for reload
if (isLocalStorage && localStorage.parklist) {
  initialState = JSON.parse(localStorage.parklist);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GETTING:
      return { ...state, status: status.getting };
    case RECEIVED:
      let locationList = {};

      action.data.map((park) => {
        //first lets check if we have the values
        if (park.freguesia && park.local)
          //lets use objects... to avoid clenaing the array after
          locationList[park.freguesia] = {
            ...locationList[park.freguesia],
            [park.local]: { local: park.local, nome: park.nome },
          };
        return true;
      });

      let parkList = action.data;

      newState = {
        ...state,
        status: status.set,
        list: parkList,
        location: locationList,
        geoJson: action.data.map((park) => {
          // const latArray = park.latitude.trim().split(/[^\d\w\-\.]+/);
          // const lngArray = park.longitude.trim().split(/[^\d\w\-\.]+/);
          return {
            type: "Feature",
            properties: {
              name: park.nome,
              freguesia: park.freguesia,
              local: park.local,
              id: park.gid,
              eid: "",
              numero: park.numero,
              numero_ant: park.número_ant,
              show_on_map: true,
              type: "park",
              typeType: growerTypes.indexOf(park.tipo),
              parkType: park.tipo,
              // lat: Number(
              //   `${latArray[0]}.${latArray[1]}${latArray[2].split('.')[0]}`
              // ),
              // lng: Number(
              //   `${lngArray[0]}.${lngArray[1]}${lngArray[2].split('.')[0]}`
              // ),
            },
            geometry: park.geojson ? JSON.parse(park.geojson) : "",
          };
        }),
      };
      return presistData("parklist", newState);

    case USER_LOGGED_OUT:
      return initialState;
    default:
      return state;
  }
};
