import React from "react";
import Table from "./Table";
import { ImageContainer } from "./styled";
import {
  apiAddress,
  growerState,
  growerTypes,
  modules,
} from "../../config/general";

export default function(props) {
  const processedData = (data) => {
    return (
      data &&
      data instanceof Array &&
      data.map((row) => {
        //process objects as strings
        Object.keys(row).map((d) => {
          if (row[d] && row[d] instanceof Object) {
            row[d] = JSON.stringify(row[d]);
          }
        });

        if (row.oringinalAddress) {
          const addressData = JSON.parse(row.oringinalAddress);
          row = { ...row, freguesiaSGEV: row.freguesia, ...addressData };
        }

        return {
          ...row,
          type: row.type ? growerTypes[row.type] : "",
          status: row.status ? growerState[row.status] : "",
          educationDone: row.educationDone ? "Completa" : "Por Completar",
        };
      })
    );
  };

  let columnData = [
    {
      id: "row_number",
      name: "row_number",
      options: {
        sort: true,
        display:
          props.rankingType !== "all" &&
          props.selected &&
          props.selected !== "",
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = tableMeta.rowData[0];
          // const thisData = props.data[tableMeta.rowIndex];
          return (
            <div style={{ textAlign: "center" }}>
              <h2>{thisData}</h2>
            </div>
          );
        },
      },
      numeric: false,
      disablePadding: false,
      label: "Ranks",
    },
    // {
    //   name: "photo",
    //   label: "Foto",
    //   options: {
    //     sort: true,
    //     filter: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const thisData = props.data[tableMeta.rowIndex];
    //       const thisPhoto = thisData.photo && thisData.photo[0];
    //       return (
    //         <ImageContainer
    //           src={thisPhoto ? apiAddress + thisPhoto.path : ""}
    //         />
    //       );
    //     },
    //   },
    // },

    {
      id: "name",
      name: "name",
      numeric: false,
      disablePadding: false,
      label: "Nome",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = props.data[tableMeta.rowIndex];
          const attr = thisData.uattr && JSON.parse(thisData.uattr);
          return (
            <strong>
              {attr && attr.warning && <i className={`icon-fire icons`} />}{" "}
              {value}
            </strong>
          );
        },
      },
    },
    {
      id: "email",
      name: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "freguesia",
      name: "freguesia",
      numeric: false,
      disablePadding: false,
      label: "Freguesia",
    },
    {
      id: "phone",
      name: "phone",
      numeric: false,
      disablePadding: false,
      label: "Telefone",
    },
    {
      id: "mobile",
      name: "mobile",
      numeric: false,
      disablePadding: false,
      label: "Telemovel",
    },
    {
      id: "type",
      name: "type",
      numeric: false,
      disablePadding: false,
      label: "Tipo",
    },
    {
      id: "educationDone",
      name: "educationDone",
      numeric: false,
      disablePadding: false,
      label: "Formação",
    },
    {
      id: "educationModules",
      name: "educationModules",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = props.data[tableMeta.rowIndex];
          return (
            thisData.education instanceof Array &&
            thisData.education.map((mod) => modules[mod]).join(", ")
          );
        },
      },
      label: "Modulos",
    },
    {
      id: "status",
      name: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "label",
      name: "idparcela",
      options: {
        filter: true,
      },
      label: "Parcela",
    },
    {
      id: "pid",
      name: "idHorta",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = props.data[tableMeta.rowIndex];
          const thisPark =
            props.geoJson &&
            props.geoJson.geoJson &&
            props.geoJson.geoJson.find(
              (park) => park.properties.numero === thisData.pid
            );

          return (
            thisPark && thisPark.properties && thisPark.properties.numero_ant
          );
        },
      },
      label: "Horta",
    },
    {
      id: "id",
      name: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
    },
    {
      id: "createdat",
      name: "createdat",
      numeric: false,
      disablePadding: false,
      label: "Data Registo",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const thisData = props.data[tableMeta.rowIndex];
          return value || thisData.createdAt;
        },
      },
    },
    {
      id: "lat",
      name: "lat",
      numeric: false,
      disablePadding: false,
      label: "lat",
      options: {
        display: "false",
      },
    },
    {
      id: "lon",
      name: "lon",
      numeric: false,
      disablePadding: false,
      label: "lon",
      options: {
        display: "false",
      },
    },
    {
      id: "rua",
      name: "rua",
      numeric: false,
      disablePadding: false,
      label: "Rua",
      options: {
        display: "false",
      },
    },
    {
      id: "porta",
      name: "porta",
      numeric: false,
      disablePadding: false,
      label: "Porta",
      options: {
        display: "false",
      },
    },
    {
      id: "fracao",
      name: "fracao",
      numeric: false,
      disablePadding: false,
      label: "Fração",
      options: {
        display: "false",
      },
    },
    {
      id: "cp",
      name: "cp",
      numeric: false,
      disablePadding: false,
      label: "Codigo Postal",
      options: {
        display: "false",
      },
    },
    {
      id: "freguesiaSGEV",
      name: "freguesiaSGEV",
      numeric: false,
      disablePadding: false,
      label: "Freguesia SGEV",
      options: {
        display: "false",
      },
    },
  ];

  const extraData =
    props.data &&
    props.data.length > 0 &&
    Object.keys(props.data[0]).reduce((acumulador, valorAtual) => {
      if (!columnData.find((dat) => dat.name === valorAtual)) {
        columnData.push({
          id: valorAtual,
          name: valorAtual,
          numeric: false,
          disablePadding: false,
          label: valorAtual,
          options: {
            display: "false",
            customBodyRender: (value, tableMeta, updateValue) => {
              const thisData = props.data[tableMeta.rowIndex];
              if (value && value instanceof Object) {
                return JSON.stringify(value);
              }

              return value;
            },
          },
        });
        return [...(acumulador || [])];
      }
    }, []);
  /// lets add the rest of the data to download
  columnData = [...columnData, ...(extraData || [])];

  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      rowsSelected={props.rowsSelected}
      setSelectedRows={props.setSelectedRows}
      selectCustomAction={props.selectCustomAction}
      selectable={true}
      click={props.click}
      header={columnData}
    />
  );
}
