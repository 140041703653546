import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
  && {
    font-size: 0.9em;
    color: #474f58;
    font-weight: normal;
    letter-spacing: 0.13px;
    margin: 1em 0 0 0;
    padding: 1em 0;

    span {
      display: block;
      font-style: italic;
      padding: 0.5em 0;
      color: #888;
      font-size: 0.9em;
      line-height: 0.9em;
    }
  }
`;

const Bar = styled.div`
  && {
    height: 4px;
    width: 52px;
    background: ${props => props.color || '#4da1ff'};
    border-radius: 2px;
    margin: 0 0 1em 0;
  }
`;

class SectionTitle extends React.Component {
  render() {
    return (
      <div {...this.props}>
        <Title>{this.props.children}</Title>
        <Bar color={this.props.color} />
      </div>
    );
  }
}

export default SectionTitle;
