export const UPDATE_SEARCH = 'UPDATE_CALENDAR_SEARCH';
export const GETTING = 'CALENDAR_GETTING';
export const RECEIVED = 'CALENDAR_RECEIVED';
export const ERROR = 'CALENDAR_ERROR';
export const UPDATED = 'CALENDAR_UPDATED';
export const TOGGLE_PARK = 'ROUTE_TOGGLE_PARK';
export const ROUTE_UPDATE = 'ROUTE_UPDATE';
export const ROUTE_SAVING = 'ROUTE_SAVING';
export const ROUTE_SAVED = 'ROUTE_SAVED';
export const ROUTE_EDIT = 'ROUTE_EDIT';

export const ROUTE_DELETE = 'ROUTE_DELETE';
