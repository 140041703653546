import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { Col, Row } from "react-flexbox-grid";

import PreferedPark from "../../../components/PerferdPark/PreferedPark";

import MenuIcon from "../../../components/Buttons/MenuIcon";
import { getParcelsByFreguesia } from "../../Georeference/actions";

import { getRankginsById, setGrower, setGrowerDetails } from "../actions";

import {
  HortaTitle,
  OptionWrap,
  RankStyles,
  Section,
  SectionWrapTitle,
} from "./styled.js";

class RankingsGrower extends Component {
  constructor(props) {
    super(props);
    props.getRankginsById(props.data.id);
    if (props.data && props.data.freguesia)
      props.getParcelsByFreguesia(props.data.freguesia);

    // this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSaveGrowerParcel = this.handleSaveGrowerParcel.bind(this);
  }
  // handleSearchChange(e) {
  //   if (e.target.name === "selected") {
  //     this.props.setGrowerDetails({
  //       element: "preferedPark",
  //       value: e.target.value,
  //     });
  //   } else {
  //     this.setState({ selected: "" });
  //     this.props.setGrowerDetails({
  //       element: "preferedPark",
  //       value: "all",
  //     });
  //   }
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  handleSaveGrowerParcel(parks) {
    this.props.setGrowerDetails({
      element: "preferedPark",
      value: parks,
    });
    this.props.setGrower(this.props.data.id);
    const self = this;
    setTimeout(() => self.props.getRankginsById(self.props.data.id), 1300);
  }

  sortParkRanks() {
    let sortableRanks = [];
    Object.keys(this.props.userRanking).map((parkIndex, i) => {
      const userRank = this.props.userRanking[parkIndex];
      let parkRankNumer = 0;
      Object.keys(userRank).map((rankInd, i) => {
        const thisPlace =
          userRank[rankInd] && Number(userRank[rankInd].row_number);
        if (thisPlace < parkRankNumer || parkRankNumer === 0) {
          parkRankNumer = thisPlace;
        }
        return false;
      });
      sortableRanks.push([parkIndex, parkRankNumer]);
      return false;
    });

    sortableRanks.sort((a, b) => {
      return a[1] - b[1];
    });

    return sortableRanks.map((rank) => rank[0]);
  }

  render() {
    const sortedParks = this.sortParkRanks();

    //odd cases where the user has no ranking
    const NoRank = sortedParks.filter((parkIndex, i) => {
      const userRank = this.props.userRanking[parkIndex];
      return (
        userRank["ranking1"] ||
        userRank["ranking2"] ||
        userRank["rankingFreguesia"]
      );
    });
    return (
      <Section>
        <Row>
          <Col md={12}>
            <Row>
              <Col xs={12} sm={12} md={8} lg={8} className={"m-b-15"}>
                {sortedParks.map((parkIndex, i) => {
                  const userRank = this.props.userRanking[parkIndex];
                  const thisPark = this.props.parks.find(
                    (park) => park.numero === parkIndex
                  );

                  const available =
                    this.props.parcels &&
                    this.props.parcels.empty &&
                    this.props.parcels.empty[parkIndex] &&
                    this.props.parcels.empty[parkIndex].length;

                  const taken =
                    this.props.parcels &&
                    this.props.parcels.full &&
                    this.props.parcels.full[parkIndex] &&
                    this.props.parcels.full[parkIndex].length;

                  const hasRanking =
                    userRank["ranking1"] ||
                    userRank["ranking2"] ||
                    userRank["rankingFreguesia"];

                  if (!thisPark || !hasRanking) return null;

                  return (
                    <div key={i}>
                      <HortaTitle>
                        {thisPark.designacao} ({available || 0}/
                        {taken + available || 0})
                        <span>{thisPark.freguesia}</span>
                      </HortaTitle>

                      <Row>
                        {userRank["ranking1"] && (
                          <Col md={4}>
                            <OptionWrap>
                              <RankStyles>
                                <div className={"left"}>
                                  <strong>Bairro</strong> <br />
                                  <span className={`icon-compass icons`} />{" "}
                                  {userRank["ranking1"].distance} m
                                  <br />
                                  <span className={`icon-clock icons`} />{" "}
                                  {(userRank["ranking1"].duration / 60).toFixed(
                                    2
                                  )}{" "}
                                  m
                                </div>
                                <div className={"right"}>
                                  <span className={`place`}>
                                    {userRank["ranking1"].row_number}
                                  </span>
                                  <span className={`icon-trophy icons`} />
                                </div>
                              </RankStyles>
                            </OptionWrap>
                          </Col>
                        )}

                        {userRank["ranking2"] && (
                          <Col md={4}>
                            <OptionWrap>
                              <RankStyles>
                                <div className={"left"}>
                                  <strong>Proximidade</strong> <br />
                                  <span className={`icon-compass icons`} />{" "}
                                  {userRank["ranking2"].distance} m
                                  <br />
                                  <span className={`icon-clock icons`} />{" "}
                                  {(userRank["ranking2"].duration / 60).toFixed(
                                    2
                                  )}{" "}
                                  m
                                </div>
                                <div className={"right"}>
                                  <span className={`place`}>
                                    {userRank["ranking2"].row_number}
                                  </span>
                                  <span className={`icon-trophy icons`} />
                                </div>
                              </RankStyles>
                            </OptionWrap>
                          </Col>
                        )}
                        {userRank["rankingFreguesia"] && (
                          <Col md={4}>
                            <OptionWrap>
                              <RankStyles>
                                <div className={"left"}>
                                  <strong>Freguesia</strong> <br />
                                  <span
                                    className={`icon-calendar icons`}
                                  />{" "}
                                  {moment(
                                    userRank["rankingFreguesia"].createdAt
                                  ).format("MM/DD/YY")}
                                  <br />
                                  <span className={`icon-clock icons`} />{" "}
                                  {moment(
                                    userRank["rankingFreguesia"].createdAt
                                  ).format("HH:MM:SS")}
                                </div>
                                <div className={"right"}>
                                  <span className={`place`}>
                                    {userRank["rankingFreguesia"].row_number}
                                  </span>
                                  <span className={`icon-trophy icons`} />
                                </div>
                              </RankStyles>
                            </OptionWrap>
                          </Col>
                        )}
                      </Row>
                    </div>
                  );
                })}
                {NoRank.length === 0 && (
                  <Col md={8}>
                    <OptionWrap>
                      <strong>Aviso</strong>
                      <br />
                      <br />
                      Este utilizador tem uma horta preferencial fora dos novos
                      criterios, por favor contacte e atualize a sua horta
                      preferencial
                    </OptionWrap>
                  </Col>
                )}
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} className={"m-b-15"}>
                <SectionWrapTitle>
                  <MenuIcon className={`icon-pin icons`} /> Horta preferencial
                </SectionWrapTitle>
                <PreferedPark
                  sortedParks={sortedParks}
                  user={this.props.data}
                  userRanking={this.props.userRanking}
                  setGrowerHortas={(parks) =>
                    this.handleSaveGrowerParcel(parks)
                  }
                />
              </Col>
            </Row>
            {/* <PreferedPark user={this.props.data} /> */}
          </Col>
        </Row>
      </Section>
    );
  }
}

RankingsGrower.propTypes = {
  userRanking: PropTypes.object.isRequired,
};

RankingsGrower.defaultProps = {
  userRanking: {},
};

export default connect(
  (state) => {
    return {
      data: state.user.editGrowerData,
      parks: state.parklist.list,
      userRanking: state.user.userRanking,
      parcels: state.georeference.parcels,
    };
  },
  { setGrowerDetails, setGrower, getRankginsById, getParcelsByFreguesia }
)(RankingsGrower);
