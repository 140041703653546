import React from "react";
import { GeoJSON } from "react-leaflet";
import Leaflet from "leaflet";
import "leaflet-extra-markers";
import { apiAddress } from "../../config/general";
import moment from "moment";

export default function Growers(props) {
  const pointToLayerElements = props => {
    // if (props.properties.label.toUpperCase().trim() === "CHAVE") {
    //   return Leaflet.circleMarker(
    //     [props.geometry.coordinates[1], props.geometry.coordinates[0]],
    //     { radius: 4, draggable: props.editElements }
    //   );
    // }

    let color = "yellow";
    let icon = "icon-user icons";

    let shape = "square";
    let prefix = "fa";

    const markerType = Leaflet.ExtraMarkers.icon({
      icon: icon,
      markerColor: color,

      shape: shape,
      prefix: prefix
    });

    return Leaflet.marker([props.coordinates[1], props.coordinates[0]], {
      icon: markerType,
      draggable: props.editElements
    });
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: () => {
        // this.openPopup(feature, layer);
        // console.log(feature.properties.Freguesia);
        return typeof props.onClickMarker === "function"
          ? props.onClickMarker(feature.properties.Freguesia || "")
          : null;
      }
    });
    let template = `<div class='detailsWraper' style='width:300px'>`;
    /// image
    if (feature.properties.files && feature.properties.files.length > 0) {
      template += `<div class='element-image' style="background-image:url('${apiAddress}${feature.properties.files[0].path}')" ></div>`;
    }

    /// title
    template += `<h3>${feature.properties.name}</h3>`;
    template += `<ul  style='width:300px'>
    <li>${feature.properties.phone}</li>
    <li>${feature.properties.mobile}</li>
    <li style='display:block; word-wrap:break-word;word-break: break-all;
    white-space: normal;'>${feature.properties.note}</li>
    <li>${moment(feature.properties.birthday).format("DD-MMM-YYYY HH:MM")}</li>
    <li>${moment(feature.properties.createdat).format("DD-MMM-YYYY HH:MM")}</li>
    </ul>`;

    /// vegetaiton

    /// list

    layer.bindTooltip(template, {
      // offset: Leaflet.point(-10, -10),
      interactive: true,
      permanent: false,
      sticky: true
    });

    return layer;
  };

  let growers = [];

  if (props.data) {
    props.data.map(grower => {
      if (grower.lat && grower.lon) {
        return growers.push({
          type: "Point",
          coordinates: [grower.lon, grower.lat],
          properties: grower
        });
      }
      return grower;
    });
  }

  return props.data ? (
    <GeoJSON
      key={`${new Date()}-growers`}
      data={growers}
      style={{
        fillColor: "#000",
        color: "#000"
      }}
      pointToLayer={pointToLayerElements}
      onEachFeature={props.onEachFeature || onEachFeature}
    />
  ) : null;
}
