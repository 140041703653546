import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { Paper } from "@material-ui/core";

import TextField from "../../components/form-wrappers/TextField";
import MainButton from "../../components/Buttons/MainButton";
import { login } from "./actions";

/*
 *
 * Component Styles
 */

const LoginImage = styled.div`
  && {
    height: 100%;
    background: url("../../images/login.jpg") no-repeat center center;
    background-size: cover;
  }
`;

const LoginContainer = styled.div`
  && {
    min-width: 320px;
    width: 35%;
    height: 100%;
    min-height: 330px;
    float: left;
    margin: auto;
    @media (max-width: 455px) {
      width: 100%;
      height: 50%;
      min-height: 330px;

      bottom: 0;
      position: absolute;
    }
    @media (max-height: 500px) {
      min-height: 330px;
    }
  }
`;

const StyledPaper = styled((props) => <Paper {...props} />)`
  && {
    padding: 0 2em;
    overflow: auto;
    border-radius: none;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
`;

const StyledLogo = styled.div`
  && {
    text-align: center;
    margin-bottom: 4em;
    @media (max-width: 455px), (max-height: 500px) {
      margin-bottom: 0;
    }
    img {
      width: 70%;
      max-width: 230px;
      @media (max-width: 455px), (max-height: 500px) {
        width: 100px;
      }
    }
  }
`;

const Input = styled(TextField)`
  && {
    margin: 1em 0 0 0;
    width: 100%;
    text-align: left;
  }
`;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.setLogin = this.setLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      email: "",
      password: "",
      valid: false,
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  setLogin() {
    this.props.login(this.state.email, this.state.password);
  }

  render() {
    return (
      <LoginImage>
        <LoginContainer>
          <StyledPaper>
            <StyledLogo>
              <img width={"50%"} alt="" src={"/images/icons/cascais.svg"} />
            </StyledLogo>

            <Input
              label="Email "
              onChange={(e) => this.handleChange(e)}
              name="email"
              type={"email"}
              autoComplete={"off"}
              value={this.state.email}
              input={{}}
              meta={{ invalid: false }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.setLogin();
                }
              }}
            />
            <Input
              label="Password "
              onChange={(e) => this.handleChange(e)}
              name="password"
              type={"password"}
              autoComplete={"off"}
              value={this.state.password}
              input={{}}
              meta={{ invalid: false }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.setLogin();
                }
              }}
            />
            <br />
            <MainButton onClick={() => this.setLogin()} color="primary">
              Entrar
            </MainButton>
          </StyledPaper>
        </LoginContainer>
      </LoginImage>
    );
  }
}

const withConnect = connect(
  (state) => {
    return {
      error: state.user.error,
    };
  },
  {
    login,
  }
);

export default withConnect(LoginPage);
