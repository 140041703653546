import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import {
  contactTypes,
  growerTypes,
  growerState,
} from "../../../config/general";
import { connect } from "react-redux";
import LocationFilter from "../../../components/Filters/LocationFilter";
import moment from "moment";
import { debounce } from "lodash";
import { apiAddress } from "../../../config/general";
import { Row, Col } from "react-flexbox-grid";
import GrowerCard from "../../../components/Card/GrowerCard";
import Select from "../../../components/form-wrappers/Select";
import { MenuItem } from "@material-ui/core";
import { toggleViewGrowers } from "../../../containers/App/actions";

import MenuIcon from "../../../components/Buttons/MenuIcon";
import { getParcelsByFreguesia } from "../../Georeference/actions";

import {
  setGrowerDetails,
  setGrower,
  getRankginsById,
  getAllGrowersRelations,
} from "../actions";

import {
  Section,
  ParkRelation,
  OptionWrap,
  RelationWrap,
  NextButton,
  RelationButton,
  RankStyles,
  InputPure,
  SectionWrapTitle,
  HortaTitle,
} from "./styled.js";

class RankingsGrower extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      results: [],
      selectedUser: "",
      type: "",
      searchType: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.delRelationToUser = this.delRelationToUser.bind(this);
    this.requestUser = debounce(this.requestUser, 1000);
    // this.handleSearchChange = debounce(this.handleSearchChange, 1);
  }

  componentDidMount() {
    this.props.getAllGrowersRelations(this.props.data.id);
  }

  async requestUser(value) {
    try {
      const users = await axios.get(
        `${apiAddress}api/v1/contacts/search/${value}`
      );
      this.setState({ results: users.data.msg });
    } catch (error) {
      console.log(error);
    }
  }

  async setRelationToUser(toUser) {
    try {
      const { type } = this.state;
      let setData = {};
      if (type === "Auxiliar") {
        setData = {
          origin: this.props.data.id,
          target: toUser,
          type: "auxiliar",
        };
      } else if (type === "Auxiliado") {
        setData = {
          origin: toUser,
          target: this.props.data.id,
          type: "auxiliar",
        };
      }

      const relation = await axios.post(`/api/v1/relations/users`, setData);
      if (relation?.data?.status) {
        toastr.info("Relação criada com sucesso");
        this.props.getAllGrowersRelations(this.props.data.id);
      } else {
        toastr.error("Aconteceu um erro a criar a ligação");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async delRelationToUser(relId) {
    try {
      const relation = await axios.get(`/api/v1/relations/delete/${relId}`);
      if (relation?.data?.msg !== "Internal server error") {
        toastr.info("Relação apagada com sucesso");
        this.props.getAllGrowersRelations(this.props.data.id);
      } else {
        toastr.error("Aconteceu um erro a apagar a ligação");
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleSearchChange(e) {
    this.setState({ search: e.target.value });
    this.requestUser(e.target.value);
  }

  render() {
    const { search } = this.state;
    const { parks, relations } = this.props;
    return (
      <Section>
        <Row>
          <Col md={12}>
            <Row>
              <Col xs={12} sm={12} md={8} lg={8} className={"m-b-15"}>
                {relations &&
                  relations.map((relation, i) => {
                    if (!relation) return null;
                    const parkData = parks.find(
                      (p) =>
                        p.numero === relation?.pid ||
                        p.numero === relation?.elementData?.pid
                    );
                    const elementType = relation?.elementData?.ElementType;

                    if (
                      (relation?.type && relation?.type.match(/rank/i)) ||
                      relation?.type === "school"
                    ) {
                      console.log(relation);
                      return (
                        <div key={i}>
                          <Row>
                            <Col md={8}>
                              <RelationWrap>
                                <Row>
                                  <Col xs={12} sm={2}>
                                    <span
                                      className={`icon-compass icons sideIcon`}
                                    />{" "}
                                  </Col>
                                  <Col xs={12} sm={10}>
                                    <div className={"left"}>
                                      <strong>
                                        {parkData?.nome ||
                                          relation?.relatedElement?.nome}
                                      </strong>{" "}
                                      <br />
                                      <span
                                        style={{
                                          fontSize: ".9em",
                                          opacity: ".7",
                                        }}
                                      >
                                        <strong>
                                          {elementType?.label ||
                                            relation?.relatedElement?.local}
                                        </strong>
                                        {", "}
                                        {moment(
                                          relation.createdAt ||
                                            relation?.relatedElement
                                              ?.data_atualizacao
                                        ).format("DD/MM/YYYY HH:mm")}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </RelationWrap>
                            </Col>
                          </Row>
                          <br />
                        </div>
                      );
                    } else if (relation?.type) {
                      const userData = relation?.userData?.find(
                        (u) => u.id !== this.props.data.id
                      );
                      let relationType = relation?.type;

                      if (`${this.props.data.id}` === `${relation.targetId}`) {
                        relationType = "Auxiliado";
                      }
                      return (
                        <div key={i}>
                          <Row>
                            <Col md={8}>
                              <RelationWrap>
                                <Row>
                                  <Col
                                    xs={12}
                                    sm={2}
                                    onClick={() => {
                                      this.props.toggleViewGrowers &&
                                        this.props.toggleViewGrowers(
                                          userData.id
                                        );
                                      this.props.updateToInfo &&
                                        this.props.updateToInfo();
                                    }}
                                  >
                                    <span
                                      className={`icon-user icons sideIcon`}
                                    />{" "}
                                    <strong style={{ fontSize: ".8em" }}>
                                      {relationType}
                                    </strong>
                                  </Col>
                                  <Col xs={10} sm={7}>
                                    <div
                                      className={"left"}
                                      onClick={() => {
                                        this.props.toggleViewGrowers &&
                                          this.props.toggleViewGrowers(
                                            userData.id
                                          );
                                        this.props.updateToInfo &&
                                          this.props.updateToInfo();
                                      }}
                                    >
                                      <strong>{userData?.name}</strong> <br />
                                      {userData?.email && (
                                        <>
                                          <strong> {userData?.email}</strong>{" "}
                                          <br />
                                        </>
                                      )}
                                      <span
                                        style={{
                                          fontSize: ".9em",
                                          opacity: ".7",
                                        }}
                                      >
                                        {userData?.educationDone
                                          ? "Formado"
                                          : "Não Formado"}
                                        {", "}
                                        {growerState[userData?.status]}
                                        {", "}
                                        {userData?.phone}
                                        {", "}
                                        {moment(relation.createdAt).format(
                                          "DD/MM/YYYY HH:mm"
                                        )}
                                      </span>
                                      <br />
                                    </div>
                                  </Col>
                                  <Col
                                    xs={12}
                                    sm={3}
                                    style={{ fontSize: ".8em" }}
                                  >
                                    <NextButton
                                      color="primary"
                                      onClick={() => {
                                        const toastrConfirmOptions = {
                                          onOk: () =>
                                            this.delRelationToUser(relation.id),
                                          onCancel: () =>
                                            console.log("CANCEL: clicked"),
                                        };

                                        toastr.confirm(
                                          "Quer remover esta ligação",
                                          toastrConfirmOptions
                                        );
                                      }}
                                    >
                                      Remover
                                    </NextButton>{" "}
                                  </Col>
                                </Row>
                              </RelationWrap>
                              {relation.relatedElement &&
                                relation.relatedElement.length > 0 &&
                                relation.relatedElement.map((park) => {
                                  const parkData = parks.find(
                                    (p) => p.numero === park.pid
                                  );

                                  return (
                                    <ParkRelation>
                                      <RelationWrap>
                                        <Row>
                                          <Col xs={2}>
                                            <span
                                              className={`icon-compass icons sideIcon`}
                                            />{" "}
                                          </Col>
                                          <Col xs={10}>
                                            <div className={"left"}>
                                              <strong>{parkData?.nome}</strong>{" "}
                                              <br />
                                              <span
                                                style={{
                                                  fontSize: ".9em",
                                                  opacity: ".7",
                                                }}
                                              >
                                                <strong>
                                                  {
                                                    park?.elementData
                                                      ?.ElementType?.label
                                                  }
                                                </strong>
                                                {", "}
                                                {moment(
                                                  relation.createdAt
                                                ).format("DD/MM/YYYY HH:mm")}
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </RelationWrap>
                                    </ParkRelation>
                                  );
                                })}
                            </Col>
                          </Row>
                          <br />
                        </div>
                      );
                    }
                  })}
              </Col>{" "}
              <Col xs={12} sm={12} md={4} lg={4} className={"m-b-15"}>
                <SectionWrapTitle>
                  <MenuIcon className={`icon-pin icons`} /> Adicionar nova
                  relação
                </SectionWrapTitle>
                <OptionWrap>
                  Pode copiar o link para convidar alguém a criar uma ligação
                  <NextButton
                    size={"xs"}
                    color={"#000"}
                    ml={"1em"}
                    onClick={() => {
                      // Copy the text inside the text field
                      navigator?.clipboard?.writeText(
                        `https://areapessoal.terrasdecascais.pt/register/${this.props.data.id}`
                      );
                      toastr.info("Link copiado com sucesso!");
                    }}
                  >
                    Copiar Link
                  </NextButton>
                </OptionWrap>
                <br />
                <OptionWrap>
                  <Select
                    fullWidth
                    name={"tipo"}
                    label={"Tipo de ligação"}
                    onChange={(e) => this.setState({ type: e.target.value })}
                  >
                    {contactTypes.map((type, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={type}
                          // onClick={() => this.setState({ type: type })}
                        >
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {this.state.type && (
                    <Select
                      fullWidth
                      name={"tipo"}
                      label={"Tipo de utilizador a pesquisar"}
                      onChange={(e) =>
                        this.setState({ searchType: e.target.value })
                      }
                    >
                      {growerTypes.map((type, i) => {
                        return (
                          <MenuItem key={i} value={i}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                  {this.state.type && (
                    <InputPure
                      value={this.state.search}
                      name={"user"}
                      onChange={this.handleSearchChange}
                      placeholder={"Pesquisar Utilizador"}
                    />
                  )}
                </OptionWrap>
              </Col>
            </Row>
          </Col>
          {this.state.results && this.state.results.length > 0 && (
            <Col xs={12} sm={12} className={"m-b-15"}>
              <SectionWrapTitle>
                <MenuIcon className={`icon-pin icons`} /> Contactos Disponiveis
              </SectionWrapTitle>

              <Row>
                {this.state.results &&
                  this.state.results
                    .filter((user) => {
                      return user.type === `${this.state.searchType}`;
                    })
                    .map((user, i) => {
                      return (
                        <Col md={4} lg={4}>
                          <GrowerCard
                            fullDisplay={true}
                            readOnly={true}
                            noCommunication={true}
                            grower={user}
                            index={i}
                            key={i}
                          />{" "}
                          <RelationButton
                            color="primary"
                            onClick={() => this.setRelationToUser(user.id)}
                          >
                            Adicionar como {this.state.type}
                            <MenuIcon className={`icon-check icons`} />
                          </RelationButton>
                        </Col>
                      );
                      // return (
                      //   <OptionWrap>
                      //     <strong>{user.name}</strong>
                      //     <br />
                      //     {user.email}, {user.vat}
                      //     {user.label}
                      //   </OptionWrap>
                      // );
                    })}
              </Row>
            </Col>
          )}
        </Row>
      </Section>
    );
  }
}

RankingsGrower.propTypes = {
  userRanking: PropTypes.object.isRequired,
};

RankingsGrower.defaultProps = {
  userRanking: {},
};

export default connect(
  (state) => {
    return {
      data: state.user.editGrowerData,
      relations: state.user.relations,
      parks: state.parklist.list,
      userRanking: state.user.userRanking,
      parcels: state.georeference.parcels,
    };
  },
  {
    setGrowerDetails,
    setGrower,
    getRankginsById,
    getParcelsByFreguesia,
    toggleViewGrowers,
    getAllGrowersRelations,
  }
)(RankingsGrower);
