import { Chip, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { IoMdSchool } from "react-icons/io";
import { store } from "../../store/configure";
import { ActionsWrap, ButtonIcon } from "./styled";
import { growerTypes } from "../../config/general";
import Table from "./Table";

export default function(props) {
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [selectedUserParks, setSelectedUserParks] = useState({});
  const processedData = (data) => {
    if (!data) return [];
    return data.map((row) => {
      return { ...row, equipa: row.Team ? row.Team.label : "" };
    });
  };

  /**
   * viewGrower is the grower that is being previewed
   */
  const viewGrower = (grower) => {
    store.dispatch({ type: "TOGLE_VIEW_GROWER", data: grower });
  };

  const processedDynamicCollumns = (data) => {
    const columnData = [
      {
        id: "id",
        name: "id",
        numeric: false,
        disablePadding: true,
        label: "id",
        options: {
          display: false,
        },
      },

      {
        id: "educationDone",
        name: "educationDone",
        numeric: false,
        disablePadding: true,
        label: "educationDone",
        options: {
          display: false,
        },
      },

      {
        id: "education",
        name: "education",
        numeric: false,
        disablePadding: true,
        label: "education",
        options: {
          display: false,
        },
      },
      {
        id: "status",
        name: "status",
        numeric: false,
        disablePadding: true,
        label: "status",
        options: {
          display: false,
        },
      },
      {
        id: "type",
        name: "type",
        numeric: false,
        disablePadding: true,
        label: "Tipo",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Chip color={"default"} label={growerTypes[value]} />;
          },
        },
      },
      {
        id: "attrStatus",
        name: "attrStatus",
        numeric: false,
        disablePadding: true,
        label: "attrStatus",
        options: {
          display: false,
        },
      },
      {
        id: "status",
        name: "status",
        numeric: false,
        disablePadding: false,
        label: "Estado",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === 6) {
              return <Chip color={"primary"} label={"Atribuido Pendente"} />;
            } else if (value === 9) {
              return <Chip color={"default"} label={"Suplente"} />;
            }
          },
        },
      },
      {
        id: "name",
        name: "name",
        numeric: false,
        disablePadding: false,
        label: "Nome",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <div
                  onClick={() => viewGrower(tableMeta.rowData[0])}
                  style={{
                    fontWeight: "bold",
                    width: "200px",
                    cursor: "pointer",
                  }}
                >
                  {value}
                </div>
              </>
            );
          },
        },
      },
      {
        id: "freguesia",
        name: "freguesia",
        numeric: false,
        disablePadding: false,
        label: "Freguesia",
      },
      {
        id: "phone",
        name: "phone",
        numeric: false,
        disablePadding: false,
        label: "Telefone",
      },
      {
        id: "número_ant",
        name: "número_ant",
        numeric: false,
        disablePadding: false,
        label: "Horta",
      },
      {
        id: "label",
        name: "label",
        numeric: false,
        disablePadding: false,
        label: "Parcela",
      },

      // {
      //   id: "note",
      //   name: "note",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Observações",
      //   options: {
      //     filter: true,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <div
      //           onClick={() => viewGrower(tableMeta.rowData[0])}
      //           style={{ fontSize: ".8em" }}
      //         >
      //           {/* <textarea
      //             multiline
      //             name="note"
      //             value={value}
      //             type="multiline"
      //             rows="3"
      //             style={{
      //               padding: "0.3em",
      //               fontFamily: "Arial",
      //               display: "block",
      //               width: "100%",
      //             }}
      //           /> */}
      //           {value.substring(0, 200)}
      //           {value.length > 200 && "..."}
      //         </div>
      //       );
      //     },
      //   },
      // },
      {
        id: "actions",
        name: "Actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const status = tableMeta.rowData[3];
            return (
              <div>
                <ActionsWrap width={"100px"}>
                  {/* {userEducated && (
                    <Tooltip title={`Atribuir Hortelão`}>
                      <ButtonIcon formed active={Number(userStatus) === 10}>
                        <FaMapMarkerAlt
                          onClick={() => {
                            if (userStatus === 10) return;
                            props.updateUserStatus(10, tableMeta.rowData[0]);
                          }}
                        />
                      </ButtonIcon>
                    </Tooltip>
                  )} */}

                  {/* {status !== 6 && (
                    <Tooltip title={`Formação conlcuida`}>
                      <ButtonIcon
                        // primary={educationDone}
                        // active={status === 9}
                        primary
                        style={{
                          fontSize: "1em",
                          fontWeight: "bold",
                          display: "flex",
                          width: "100%",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "6px",
                        }}
                        onClick={() =>
                          props.updateUserEducation(
                            "diploma",
                            tableMeta.rowData[0]
                          )
                        }
                      >
                        <span
                          style={{
                            fontSize: "1.2em",
                            marginRight: ".5em",
                            display: "block",
                            marginTop: ".3em",
                          }}
                        >
                          <IoMdSchool />{" "}
                        </span>
                        Diploma
                      </ButtonIcon>
                    </Tooltip>
                  )} */}
                  {status === 6 && (
                    <Tooltip title={`Atribuir utilizador`}>
                      <ButtonIcon
                        style={{
                          fontSize: "1em",
                          fontWeight: "bold",
                          display: "flex",
                          width: "100%",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "6px",
                        }}
                        onClick={() => {
                          props.updateUserState(1, tableMeta.rowData[0]);
                        }}
                        // active={education?.includes("0")}
                      >
                        <span
                          style={{
                            fontSize: "1.2em",
                            marginRight: ".5em",
                            display: "block",
                            marginTop: ".3em",
                          }}
                        >
                          <span className="icons icon-size-actual" />
                        </span>
                        Atribuir
                      </ButtonIcon>
                    </Tooltip>
                  )}
                </ActionsWrap>
              </div>
            );
          },
        },
      },
      {
        id: "ranks",
        name: "ranks",
        numeric: false,
        disablePadding: false,
        sortable: false,
        label: "Data",
        options: {
          filter: false,
          sort: false,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return "";
          },
        },
      },
    ];

    return columnData;
  };

  return (
    <Table
      orderBy={"id"}
      data={processedData(props.data)}
      rowsExpanded={rowsExpanded}
      setRowsExpanded={setRowsExpanded}
      // click={props.click}
      // renderExpandableRow={(rowData, rowMeta) => {
      //   const thisData = dataReady.find((d) => d.id === rowData[0]);
      //   return (
      //     <tr style={{ width: "100%" }}>
      //       <td style={{ width: "100%" }} colSpan={"20"}>
      //         <Edit
      //           noModal={true}
      //           edit={thisData}
      //           index={rowMeta.rowIndex}
      //           rowsExpanded={rowsExpanded}
      //           reloadData={props.reloadData}
      //           setRowsExpanded={setRowsExpanded}
      //         />
      //       </td>
      //     </tr>
      //   );
      // }}
      header={processedDynamicCollumns(props.data)}
    />
  );
}
