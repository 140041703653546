import { Tooltip } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MenuItem } from "@material-ui/core";
import { Col, Row } from "react-flexbox-grid";
import { BsFillCalendar2XFill } from "react-icons/bs";
import { FaPhoneSlash } from "react-icons/fa";
import { RxLapTimer } from "react-icons/rx";
import { toastr } from "react-redux-toastr";
import MenuIcon from "../../components/Buttons/MenuIcon";
import SectionTitle from "../../components/Text/SectionTitle";
import { apiAddress, growerState } from "../../config/general";
import MainButton from "../../components/Buttons/MainButton";
import { store } from "../../store/configure";
import { Card, OptionWrapRank, StyledMainButton } from "./styled";
import Select from "../../components/form-wrappers/Select";

/**
 * TODO: add selected state to parcel / by get relation and show relatio
 * if user is status 6
 * @param {} param0
 * @returns
 */
const Place = ({
  pid,
  quotas,
  getAttributions,
  getParkQuotas,
  sideListGetUserSuplentes,
}) => {
  const [ranking0, setRanking0] = useState([]);
  const [ranking1, setRanking1] = useState([]);
  const [ranking2, setRanking2] = useState([]);
  const [availableParcels, setAvailableParcels] = useState([]);
  const [userSelectPark, setUserSelectPark] = useState([]);

  useEffect(() => {
    //get ranking for park
    getUserByRank(0);
    getUserByRank(1);
    getUserByRank(2);

    //get quotas for park
    // getParkQuotas();

    //get available parcels for park
    getAvailableParcels();
    return () => {};
  }, [pid]);

  /**
   * update user state to suplete
   */

  /***Set actions hooks */
  //update user state
  const updateRelation = async (user, parcel, type) => {
    //get user from selectedUsers
    let updatedNote = user.note || "";

    const setNewAttrState = async () => {
      try {
        //let set the relation
        const relation = await axios.post(`/api/v1/relations/${type}`, {
          origin: user.id,
          pid: pid,
          target: parcel,
          type: type,
        });
        //now let update the user state

        const res = await axios.post(
          `${apiAddress}api/v1/contacts/status/${user.id}`,
          { status: 6, note: updatedNote }
        );
        if (document.getElementsByClassName(`close-toastr`)[0]) {
          document.getElementsByClassName(`close-toastr`)[0].click();
        }
        // now we need to update userlist
        getUserByRank(0);
        getUserByRank(1);
        getUserByRank(2);
        sideListGetUserSuplentes(); //userLeft
        getParkQuotas();
        getAttributions(); //userRight
      } catch (error) {
        // toastr.error("Aconteceu um obter lista");
        console.log(error);
      }
    };

    const toastrConfirmOptions = {
      // onOk: () => setNewAttrState(),
      // onCancel: () => console.log("CANCEL: clicked"),
      // closeOnShadowClick: false,
      // enableEscapeKey: false,
      // enableEnterKey: false,
      timeOut: 0, // Default value is 0
      removeOnHover: false, // Default value is false
      removeOnHoverTimeOut: 0, // Default value is 1000
      component: (
        <div>
          <div
            style={{
              fontSize: "1.2em",
              lineHeight: "1",
              fontWeight: "bold",
              marginBottom: "1em",
            }}
          ></div>
          <textarea
            multiline
            id={`note-${user}`}
            name="note"
            defaultValue={updatedNote}
            onChange={(e) => {
              updatedNote = e.target.value;
            }}
            type="multiline"
            rows="7"
            onKe
            style={{
              padding: "0.5em",
              fontFamily: "Arial",
              display: "block",
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              margin: ".5em 0",
              justifyContent: "flex-end",
            }}
          >
            <MainButton
              size={"small"}
              color={"primary"}
              onClick={() => setNewAttrState()}
            >
              Salvar Alterações
            </MainButton>
          </div>
        </div>
      ),
    };

    toastr.message(
      "Adicione um comentário antes de proceder com esta alteração",
      toastrConfirmOptions
    );
  };

  /**
   * get park Ranks
   */
  const getAvailableParcels = async () => {
    try {
      if (!pid || pid === "all") return null;
      const res = await axios.get(
        `${apiAddress}api/v1/relations/availableParcels/${pid}`
      );
      setAvailableParcels(res?.data?.msg || {});
    } catch (error) {
      // toastr.error("Aconteceu um erro a criar o material");
      console.log(error);
    }
  };

  /**
   * get park Ranks
   */
  // const getParkQuotas = async () => {
  //   try {
  //     if (!pid || pid === "all") return null;
  //     const res = await axios.get(
  //       `${apiAddress}api/v1/relations/quotas/${pid}`
  //     );
  //     setQuotas(res?.data?.msg || {});
  //   } catch (error) {
  //     // toastr.error("Aconteceu um erro a criar o material");
  //     console.log(error);
  //   }
  // };

  /**
   * get users for a park
   */
  const getUserByRank = async (rank) => {
    try {
      const res = await axios.get(
        `${apiAddress}api/v1/rankings/${rank}/${pid}/6,9/50/false`
      );
      switch (rank) {
        case 0:
          setRanking0(
            res?.data?.msg?.filter((u) => {
              if (u.status === 7 && Number(u.attrStatus) !== 6) {
                return false;
              }
              return true;
            }) || []
          );
          break;
        case 1:
          setRanking1(
            res?.data?.msg?.filter((u) => {
              if (u.status === 7 && Number(u.attrStatus) !== 6) {
                return false;
              }
              return true;
            }) || []
          );
          break;
        case 2:
          setRanking2(
            res?.data?.msg?.filter((u) => {
              if (u.status === 7 && Number(u.attrStatus) !== 6) {
                return false;
              }
              return true;
            }) || []
          );
          break;
        default:
          break;
      }
    } catch (error) {
      toastr.error("Aconteceu um erro a criar o material");
      console.log(error);
    }
  };

  /**
   * viewGrower is the grower that is being previewed
   */
  const viewGrower = (grower) => {
    store.dispatch({ type: "TOGLE_VIEW_GROWER", data: grower });
  };

  const setSelected = (grower, parcel, rankingType) => {
    //check if user object is already in selectedUsers and remove it if it is
    const user = userSelectPark.find((u) => u.id === grower);
    if (user && (user.parcel === parcel || parcel === "")) {
      const newSelectedUsers = userSelectPark.filter((u) => u.id !== grower);
      setUserSelectPark(newSelectedUsers);
    } else if (user && user.parcel !== parcel) {
      const newSelectedUsers = userSelectPark.map((u) => {
        if (u.id === grower) {
          return { ...u, parcel };
        }
        return u;
      });
      setUserSelectPark(newSelectedUsers);
    } else {
      //add user to selectedUsers
      const newSelectedUsers = [
        ...userSelectPark,
        { id: grower, parcel, rankingType, pid },
      ];
      setUserSelectPark(newSelectedUsers);
    }
  };

  /**
   * Render selection available options array
   * but remove the ones that are already selected
   */
  const isParcleSelected = (parcel) => {
    return userSelectPark.find((u) => u.parcel === parcel);
  };
  /**
   * Ranking card render
   */

  const RankingCard = ({ grower, i, type, selected, total }) => {
    const isSelected = userSelectPark.find((u) => u.id === grower.id);
    const userAttrStatus = grower?.attrStatus || 0;

    //needed for reload check if value is availble in availableParcels
    const isParcelAvailable = isSelected
      ? availableParcels?.find(
          (p) => p.id === isSelected?.parcel || p.origin === isSelected?.parcel
        )
      : true;
    //if user is selected on another parcel or ranking type return null
    if (
      isSelected &&
      (isSelected.rankingType !== type || isSelected.pid !== pid)
    )
      return null;
    return (
      <Card key={i} color={"#28587f"}>
        <Row style={{ alignItems: "center" }}>
          {/* <Col xs={3} sm={3} md={3} lg={3}>
            <div onClick={() => viewGrower(grower.id)}>
              <span className={"rank"}>{i + 1}</span>
            </div>
          </Col> */}
          <Col xs={9} sm={9} md={9} lg={9}>
            <div onClick={() => viewGrower(grower.id)}>
              <span className={"nameRank"}>{grower.name}</span>
              <div className={"state"}>{growerState[grower.status]} </div>
            </div>
          </Col>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col xs={10} sm={10} md={10} lg={10}>
            <div
              onClick={() => viewGrower(grower.id)}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                lineHeight: "1",
              }}
            >
              <div className={"flex"}>
                <div className={"line"}>
                  <MenuIcon className={`icon-direction icons`} />
                  <span className={"freguesia"}>{grower.distance}m</span> (
                  <span className={"freguesia"}>
                    {(grower.duration / 60).toFixed(2)}min
                  </span>
                  )
                </div>
              </div>

              <div className={"date"}>
                <MenuIcon className={`icon-calendar icons`} />
                <span className={"createdAt"}>
                  {moment(grower.createdAt || grower.createdat).format(
                    "DD/MM/YYYY HH:MM"
                  )}
                </span>
              </div>

              <div className={"education"}>
                <MenuIcon className={`icon-graduation icons`} />
                <span>{grower.educationDone ? "Formado" : "Por Formar"} </span>
              </div>
              {/* <div className={"contacts"}>
                <MenuIcon className={`icon-envelope icons`} />
                <a className={"email"} href={`mailto:${grower.email}`}>
                  e-mail link
                </a>
              </div> */}
            </div>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2}>
            <div style={{ fontSize: "1.4em" }}>
              {/* {Number(userAttrStatus) === 4 && (
                <Tooltip title={`Formar Hortelão`}>
                  <IoIosSchool />
                </Tooltip>
              )}
              {Number(userAttrStatus) === 4 && (
                <Tooltip title={`Atribuir Hortelão`}>
                  <FaMapMarkerAlt />
                </Tooltip>
              )} */}

              {Number(userAttrStatus) === 4 && (
                <Tooltip title={`Aguarda Resposta`}>
                  <RxLapTimer />
                </Tooltip>
              )}
              {Number(userAttrStatus) === 1 && (
                <Tooltip title={`Não Atende`}>
                  <FaPhoneSlash />
                </Tooltip>
              )}

              {Number(userAttrStatus) === 3 && (
                <Tooltip title={`Não está disponível`}>
                  <BsFillCalendar2XFill />
                </Tooltip>
              )}
            </div>
          </Col>
          <Col>
            <div className={"note"}>{grower.note}</div>
          </Col>
        </Row>
        {isParcelAvailable && selected <= total && (
          <>
            <div style={{ marginTop: ".8em" }}>
              <Select
                fullWidth
                name={"Parcel"}
                label={"Parcela"}
                value={
                  isSelected?.rankingType === type && isSelected?.pid === pid
                    ? isSelected.parcel || ""
                    : ""
                }
                margin="dense"
                onChange={(e) => {
                  setSelected(grower.id, e.target.value, type);
                }}
              >
                <MenuItem key={-1} value={""}>
                  Todos
                </MenuItem>{" "}
                {availableParcels?.map((parcel, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={parcel.origin || parcel.id}
                      disabled={isParcleSelected(parcel.origin || parcel.id)}
                    >
                      {parcel.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {isSelected && (
              <StyledMainButton
                onClick={() =>
                  updateRelation(
                    grower,
                    isSelected?.parcel,
                    isSelected?.rankingType
                  )
                }
              >
                Atribuir
              </StyledMainButton>
            )}
          </>
        )}
        {/* <StyledMainButton>Contactado</StyledMainButton>
        <StyledMainButton>Confirmado</StyledMainButton> */}
      </Card>
    );
  };

  const selectedRanking0 = userSelectPark?.filter(
    (s) => s.rankingType === "ranking0"
  )?.length;
  const selectedRanking1 = userSelectPark?.filter(
    (s) => s.rankingType === "ranking1"
  )?.length;
  const selectedRanking2 = userSelectPark?.filter(
    (s) => s.rankingType === "ranking2"
  )?.length;

  return (
    <div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div style={{ padding: "2em", maxWidth: "1200px", margin: "0 auto" }}>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <SectionTitle>
                  {/* <i className={`icon-${menuInspections.icon} icons`} />{" "} */}
                  <strong>Ranking Bairro</strong>
                  <div>
                    {selectedRanking0 || 0} Selecionados / {quotas.ranking0}{" "}
                    Lugares
                  </div>
                </SectionTitle>
                <OptionWrapRank>
                  {ranking0?.map((grower, i) => (
                    <RankingCard
                      grower={grower}
                      type={"ranking0"}
                      selected={selectedRanking0}
                      total={quotas.ranking0}
                      i={i}
                    />
                  ))}
                </OptionWrapRank>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <SectionTitle>
                  {/* <i className={`icon-${menuInspections.icon} icons`} />{" "} */}
                  <strong>Ranking Proximidade</strong>
                  <div>
                    {selectedRanking1 || 0} Selecionados / {quotas.ranking1}{" "}
                    Lugares
                  </div>
                </SectionTitle>
                <OptionWrapRank>
                  {ranking1?.map((grower, i) => (
                    <RankingCard
                      grower={grower}
                      type={"ranking1"}
                      selected={selectedRanking1}
                      total={quotas.ranking1}
                      i={i}
                    />
                  ))}
                </OptionWrapRank>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <SectionTitle>
                  {/* <i className={`icon-${menuInspections.icon} icons`} />{" "} */}
                  <strong>Ranking Freguesia</strong>
                  <div>
                    {selectedRanking2 || 0} Selecionados / {quotas.ranking2}{" "}
                    Lugares
                  </div>
                </SectionTitle>
                <OptionWrapRank>
                  {ranking2?.map((grower, i) => (
                    <RankingCard
                      grower={grower}
                      type={"ranking2"}
                      i={i}
                      selected={selectedRanking2}
                      total={quotas.ranking2}
                    />
                  ))}
                </OptionWrapRank>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={12}>
                <StyledMainButton>Confirmar Selecção</StyledMainButton>
              </Col>
            </Row> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Place;
