import React, { useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";
import { LeafletConsumer } from "react-leaflet";
import Leaflet from "leaflet";
import "leaflet-extra-markers";
import { apiAddress } from "../../config/general";

export default function Elements(props) {
  let map = null;
  let elements = [];
  // const [elements, setElements] = useState([]);
  const [geoJson, setGeoJson] = useState(props.data);

  // useEffect(() => {
  //   // console.log(
  //   //   "update",
  //   //   JSON.stringify(props.data) === JSON.stringify(geoJson)
  //   // );
  //   // if (props.updateElement) {
  //   //   props.totalTooltips.map((ele) => {
  //   //     if (ele && ele.remove) ele.remove();
  //   //   });
  //   //   // elements = [];
  //   //   props.delTotalTooltip();
  //   //   props.finishedRemovingElements();
  //   //   setGeoJson(props.data);
  //   //   // setSelected(props.selected);
  //   // }

  //   // if (!update) setUpdate(true);
  //   return () => {
  //     if (
  //       JSON.stringify(props.data) !== JSON.stringify(geoJson) ||
  //       props.updateElements
  //     ) {
  //       props.totalTooltips.map((ele) => {
  //         if (ele && ele.remove) ele.remove();
  //       });
  //       props.delTotalTooltip();
  //       // elements = [];
  //       props.finishedRemovingElements();
  //     }
  //   };
  // }, [props.data, props.updateElements, props.totalTooltips]);

  const pointToLayerElements = (props) => {
    if (
      props.properties.label &&
      props.properties.label.toUpperCase().trim() === "CHAVE"
    ) {
      return Leaflet.circleMarker(
        [props.geometry.coordinates[1], props.geometry.coordinates[0]],
        { radius: 4, draggable: props.editElements }
      );
    }

    const currnCat = props.properties.category || "GENERIC";

    let color = props.properties.selected ? "red" : "orange";
    color = props.properties.relation ? "cyan" : color;

    let icon = "fa-number";
    let shape = props.properties.relation ? "square" : "penta";
    let prefix = "fa";
    switch (currnCat.toUpperCase().trim()) {
      case "REGA":
        color = "cyan";
        icon = "icon-drop icons";
        shape = "circle";
        prefix = "";
        break;
      case "VEGETAÇÃO":
        color = "green";
        icon = "icon-share icons";
        shape = "circle";
        prefix = "";
        break;
      default:
        break;
    }

    const markerType = Leaflet.ExtraMarkers.icon({
      icon: icon,
      markerColor: color,
      number:
        props.properties.label &&
        props.properties.label.toUpperCase().substring(0, 1),
      shape: shape,
      prefix: prefix,
    });

    return Leaflet.marker(
      [props.geometry.coordinates[1], props.geometry.coordinates[0]],
      { icon: markerType, draggable: props.editElements }
    );
  };

  const onEachElement = (feature, layer) => {
    let outputHtml = "";
    let isParcel =
      feature.properties.label &&
      feature.properties.label.toLowerCase().match(/parcela|lote/);
    Object.keys(feature.properties).map((curr) => {
      if (
        curr !== "id" &&
        curr !== "typeId" &&
        curr !== "type" &&
        curr !== "origin" &&
        curr !== "label" &&
        curr !== "files" &&
        curr !== "observations"
      ) {
        if (curr === "createdAt" || curr === "implementationDate") {
          outputHtml += `<li><span>${
            curr === "implementationDate" ? "date" : curr
          }</span>: ${feature.properties[curr] &&
            feature.properties[curr].split("T")[0]}<br/></li>`;
        } else {
          outputHtml += `<li><span>${curr}</span>: ${feature.properties[curr]}<br/></li>`;
        }
      }
      return null;
    });

    let template = `<div class='detailsWraper'>`;
    /// image
    if (feature.properties.files && feature.properties.files.length > 0) {
      template += `<div class='element-image' style="background-image:url('${apiAddress}${feature.properties.files[0].path}')" ></div>`;
    }

    /// title
    template += `<h3>${feature.properties.label}</h3><p>${feature.properties.observations}</p>`;

    /// vegetaiton

    if (feature.vegetation) {
      template += `<ul class='element-vegetation'>
      <li><span>categoria:</span> ${feature.vegetation.category}</li>
      <li><span>nome cientifico.:</span> ${feature.vegetation.cientificName}</li>
      <li><span>familia:</span> ${feature.vegetation.family}</li>
      <li><span>nome:</span> ${feature.vegetation.normalName}</li>
      </ul>`;
    }

    /// list

    template += `<ul>${outputHtml}</ul></div>`;

    if (isParcel) {
      template = `<span>${feature.properties.label}</span>`;
    }

    // if (map && feature.geometry.type === "Polygon") {
    //   // console.log(props.totalTooltips.length, props.data.length);
    //   if (props.totalTooltips.length <= props.data.length) {
    //     var label = Leaflet.marker(layer.getBounds().getCenter() || {}, {
    //       icon: Leaflet.divIcon({
    //         html: "",
    //         iconSize: [0, 0],
    //       }),
    //     }).addTo(map);

    //     label.bindTooltip(template, {
    //       // offset: Leaflet.point(-10, -10),
    //       permanent: true,
    //       sticky: !isParcel,
    //     });
    //     addToList(label);
    //   }
    // } else {
    layer.bindTooltip(template, {
      // offset: Leaflet.point(-10, -10),
      interactive: true,
      permanent: isParcel,
      sticky: !isParcel,
      direction: "right",
    });
    // addToList({ remove: () => {} });
    // }

    // layer.bindTooltip('my tooltip text').openTooltip();
    layer.on({
      click: () => {
        return typeof props.onClickMarker === "function"
          ? props.onClickMarker(feature, "element")
          : null;
      },
      mouseover: () => {
        // console.log(feature);
      },
    });
    if (layer.feature.geometry.type !== "Point") {
      layer.setStyle({
        fillColor:
          feature.properties && feature.properties.color
            ? feature.properties.color
            : "#4da1ff",
        color:
          feature.properties && feature.properties.color
            ? feature.properties.color
            : "#4da1ff",
      });
    }

    return layer;
  };

  // const onEachEle = (e) => {
  //   // elements = [...elements, e];
  //   props.setTotalTooltip(e);
  // };

  return (
    <LeafletConsumer>
      {(context) => {
        map = context.map;
        return (
          <GeoJSON
            key={`${new Date()}-elements`}
            data={geoJson}
            ref={props.reference}
            // style={this.style}
            pointToLayer={pointToLayerElements}
            onEachFeature={(feature, layer) =>
              props.onEachElement
                ? props.onEachElement(feature, layer)
                : onEachElement(feature, layer)
            }
          />
        );
      }}
    </LeafletConsumer>
  );
}
