import React, { Component } from "react";
import PropTypes from "prop-types";

import { TextField, FormControl } from "@material-ui/core";
import styled from "styled-components";
const FormWrapper = styled.div`
  && {
    label {
    }
    > div {
      textarea {
        background: #fff;
      }
      label[data-shrink="true"] {
        color: #4da1ff;
      }
      > div {
        &:after {
          border-bottom: 2px solid #4da1ff !important;
        }
        &:hover {
          &:before {
            border-bottom: 2px solid #4da1ff !important;
          }
        }
      }
    }
  }
`;

export default class TextFieldForm extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    input: {},
  };

  render() {
    const { label, className, input, ...custom } = this.props;
    return (
      <FormWrapper>
        <FormControl className={className}>
          {/* <InputLabel className={className} error={invalid} htmlFor={label}>
            {label}
          </InputLabel> */}
          <TextField
            className={className}
            background="white"
            id={label}
            label={label}
            variant="outlined"
            margin="dense"
            {...input}
            {...custom}
          />
        </FormControl>
      </FormWrapper>
    );
  }
}
