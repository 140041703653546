import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";

import Lightbox from "react-image-lightbox";
import { toastr } from "react-redux-toastr";
import { Card } from "../../components/Card/styled";

import LineButton from "../../components/Buttons/LineButton";
import SectionTitle from "../../components/Text/SectionTitle";
import MenuIcon from "../../components/Buttons/MenuIcon";
import AutoComplete from "../../components/form-wrappers/AutoComplete";
import { apiAddress } from "../../config/general";
import { getRelationsByElementId } from "../Georeference/actions";
import SchoolResponsibles from "../Schools/SchoolResponsibles";

import GrowerCard from "../../components/Card/GrowerCard";
import { getCurrentGrower } from "../../containers/Georeference/Selector";

import {
  updateMaintenance,
  getMaintenances,
  getInterventions,
  deleteMaintenance,
} from "./actions";

import PageTitle from "../../components/Text/PageTitle";

import {
  OptionWrap,
  ActionWrap,
  Files,
  Input,
  ImageContainer,
  ElementType,
  Image,
  Calendar,
  SaveButton,
  DisableButton,
  ImageWrapper,
  RemoveItem,
  MaintenanceWrap,
  FileContainer,
} from "../../components/InspMain/styled";

class Edit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      edit: false,
      damages: [],
      photoIndex: 0,
      isOpen: false,
      hiddenPhotos: false,
    };
    this.deleteImage = this.deleteImage.bind(this);
    this.callback = this.callback.bind(this);
    this.removedfile = this.removedfile.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.delete = this.delete.bind(this);
    this.handleChangeInterventions = this.handleChangeInterventions.bind(this);
    this.serverURL = apiAddress;

    // const t = this;
    // setTimeout(() => {
    //   t.setState({ hiddenPhotos: false });
    // }, 0);
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: true,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedParkId, //should be the park id
      },
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };

    props.getRelationsByElementId(
      props.data &&
        props.data.Element &&
        (props.data.Element.origin || props.data.Element.id),
      props.data && props.data.Element && props.data.Element.numero
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === "edited" || this.props.status === "deleted") {
      this.props.getMaintenances();
      this.props.closeModal();
    }
  }

  getDate(date) {
    const now = new Date(date);
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  handleChangeAttribute(e, value) {
    this.setState({
      data: {
        ...this.state.data,
        attr: {
          ...this.state.data.attr,
          [e.target.name]: !this.state.data.attr[e.target.name],
        },
      },
    });
  }

  toggleEdit() {
    if (!this.state.edit) this.props.getInterventions();
    this.setState({ edit: !this.state.edit });
  }

  delete() {
    const toastrConfirmOptions = {
      onOk: () => this.props.deleteMaintenance({ ...this.state.data }),
      onCancel: () => console.log("CANCEL: clicked"),
    };
    toastr.confirm(
      "Tem a certeza? Esta acção não pode ser revertida",
      toastrConfirmOptions
    );
  }

  removedfile(e) {
    let foundId = null;
    this.state.data.files.map((files, index) => {
      if (files.name === e.upload.filename) {
        foundId = index;
      }
      return files;
    });
    if (foundId) this.deleteImage(foundId);
  }

  deleteImage(filesIndex) {
    this.setState({
      data: {
        ...this.state.data,
        files: this.state.data.files.filter((files, i) => i !== filesIndex),
      },
    });
  }
  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.data.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];
    this.setState({
      data: {
        ...this.state.data,
        files,
      },
    });
  }

  handleChange(e, value) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleChangeInterventions(value) {
    this.setState({ intervention: value });
  }

  handleSaveMaintenance() {
    this.toggleEdit();
    this.props.updateMaintenance({
      ...this.state.data,
      intervention: this.state.intervention,
    });
  }
  closeModal() {
    this.props.history.push(`/interventions`);
  }

  renderPhotos(data) {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const self = this;

    /// not bound to this is moved out of render
    const eventHandlers = {
      init: (dropzone) => {
        dropzone.autoDiscover = false;
      },
      removedfile: self.removedfile,
      error: (e) => {
        console.log(e);
      },
      success: self.callback,
    };

    if (data.files) {
      return (
        <div>
          {data.files && data.files.length > 0 && (
            <div>
              {" "}
              <SectionTitle>Files</SectionTitle>
              <ImageContainer>
                {data.files.map((file, index) => {
                  const sliptName = file.name.split(".");
                  const fileExtension = sliptName[sliptName.length - 1];
                  if (
                    fileExtension === "gif" ||
                    fileExtension === "jpg" ||
                    fileExtension === "png"
                  ) {
                    return (
                      <ImageWrapper key={index}>
                        <Image
                          onClick={() =>
                            this.setState({
                              isOpen: true,
                              photoIndex: index,
                            })
                          }
                          style={{
                            backgroundImage: `url('${this.serverURL}${file.path}')`,
                          }}
                        />

                        {this.state.edit && (
                          <RemoveItem
                            onClick={() => this.deleteImage(index)}
                            className={`icon-close icons`}
                          />
                        )}
                      </ImageWrapper>
                    );
                  } else {
                    return (
                      <ImageWrapper key={index}>
                        <FileContainer>
                          <a
                            href={`${this.serverURL}${file.path}`}
                            target={"_blank"}
                          >
                            <span
                              className={"filepicker-file-icon"}
                              data-filetype={`${fileExtension}`}
                            />
                            {file.name}
                          </a>
                        </FileContainer>

                        {this.state.edit && (
                          <RemoveItem
                            onClick={() => this.deleteImage(index)}
                            className={`icon-close icons`}
                          />
                        )}
                      </ImageWrapper>
                    );
                  }
                })}
              </ImageContainer>
            </div>
          )}
          {this.state.edit && (
            <Files
              config={config}
              eventHandlers={eventHandlers}
              djsConfig={djsConfig}
            />
          )}
        </div>
      );
    }
  }
  renderRelationCard() {
    const { individualRelations } = this.props;
    return (
      individualRelations &&
      individualRelations.elements &&
      individualRelations.elements.map((tar, i) => {
        return (
          <Card key={i}>
            <span className={"element"}>{tar.label}</span>
          </Card>
        );
      })
    );
  }
  renderGrowerCard() {
    const { growerRelations, individualRelations } = this.props;
    let growers = [];
    if (individualRelations && individualRelations.grower) {
      growers = individualRelations.grower;
    } else if (growerRelations && growerRelations.length > 0) {
      growers = growerRelations;
    }

    return (
      growers &&
      growers.map((grower, i) => {
        return (
          <GrowerCard
            fullDisplay={true}
            readOnly={true}
            grower={grower}
            key={i}
          />
        );
      })
    );
  }

  render() {
    const { photoIndex, isOpen, data } = this.state;
    const images = data.files;

    let color;
    switch (data.type) {
      case "Danos":
        color = "#eb3d3d";
        break;
      case "Inicial":
        color = "#ebcd3d";
        break;
      default:
        color = "#6fc44c";
        break;
    }
    return (
      <MaintenanceWrap name={data.id}>
        {data && (
          <div>
            <Row>
              <Col xs={12} md={9}>
                <SectionTitle color={color}>
                  <i className={`icon-wrench icons`} /> {data.type}
                  {data.Element && data.Element.ElementType && (
                    <ElementType>
                      {data.Element.ElementType.category},
                      {data.Element.ElementType.label}
                    </ElementType>
                  )}
                  {data.User && (
                    <span>
                      {data.User.name},{" "}
                      {moment(data.date).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                  )}
                </SectionTitle>
                <OptionWrap>
                  {data.Intervention && data.Intervention.label && (
                    <AutoComplete
                      updateState={this.handleChangeInterventions}
                      options={this.props.interventionList}
                      defaultValue={data.Intervention}
                      placeholder={"Pesquise ou adicione um novo dano"}
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.attr && data.attr.reading && (
                    <Input
                      label="Total"
                      onChange={(e) => this.handleAttrChange(e)}
                      name="reading"
                      input={{}}
                      defaultValue={data.attr.reading}
                      meta={{ invalid: false }}
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.endDate && (
                    <Calendar
                      id="date"
                      name="date"
                      input={{}}
                      label="Data Inicial"
                      meta={{ invalid: false }}
                      defaultValue={this.getDate(data.date)}
                      type="datetime-local"
                      onChange={(e) => this.handleChange(e)}
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.endDate && (
                    <Calendar
                      id="endDate"
                      name="endDate"
                      input={{}}
                      label="Data Final"
                      meta={{ invalid: false }}
                      defaultValue={this.getDate(data.endDate)}
                      type="datetime-local"
                      onChange={(e) => this.handleChange(e)}
                      disabled={!this.state.edit}
                    />
                  )}
                  {data.details && (
                    <Input
                      multiline
                      label="Descrição"
                      onChange={(e) => this.handleChange(e)}
                      name="details"
                      input={{}}
                      defaultValue={data.details}
                      type="multiline"
                      meta={{ invalid: false }}
                      rows="8"
                      disabled={!this.state.edit}
                    />
                  )}
                </OptionWrap>

                <ActionWrap>
                  {!data.endDate && (
                    <Calendar
                      id="date"
                      name="date"
                      input={{}}
                      label=""
                      meta={{ invalid: false }}
                      defaultValue={this.getDate(data.date)}
                      type="datetime-local"
                      onChange={(e) => this.handleChange(e)}
                      disabled={!this.state.edit}
                    />
                  )}
                  {this.props.status !== "saving" && (
                    <div>
                      {this.state.edit ? (
                        <div style={{ textAlign: "right" }}>
                          <SaveButton
                            color="primary"
                            onClick={() => this.handleSaveMaintenance()}
                          >
                            Salvar
                            <MenuIcon className={`icon-check icons`} />
                          </SaveButton>
                        </div>
                      ) : (
                        (this.props.userWrite === "all" ||
                          this.props.userWrite === "interventions" ||
                          this.props.userPermissions?.write?.includes("all") ||
                          this.props.userPermissions?.write?.includes(
                            "interventions"
                          )) && (
                          <div style={{ textAlign: "right" }}>
                            <LineButton
                              color="primary"
                              onClick={() => this.toggleEdit()}
                            >
                              Editar
                              <MenuIcon className={`icon-check icons`} />
                            </LineButton>
                            {this.props.isAdmin &&
                              (this.props.userWrite === "all" ||
                                this.props.userWrite === "interventions" ||
                                this.props.userPermissions?.write?.includes(
                                  "all"
                                ) ||
                                this.props.userPermissions?.write?.includes(
                                  "interventions"
                                )) && (
                                <DisableButton onClick={() => this.delete()}>
                                  <MenuIcon className={`icon-close icons`} />{" "}
                                  Apagar
                                </DisableButton>
                              )}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </ActionWrap>
              </Col>

              {this.props.index === 0 && (
                <Col xs={12} md={3}>
                  <SectionTitle>Contactos</SectionTitle>
                  {this.props.isSchool && (
                    <SchoolResponsibles
                      schoolName={this.props.isSchool}
                      noTitle={true}
                    />
                  )}
                  {this.renderGrowerCard()}
                  {this.renderRelationCard()}
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12} md={12}>
                {!this.state.hiddenPhotos && this.renderPhotos(data)}
              </Col>
            </Row>
          </div>
        )}
        {!data && (
          <div>
            <PageTitle>Upss...</PageTitle>
            <Row>
              <Col xs={12} md={9}>
                <SectionTitle>Detalhes</SectionTitle>
                <OptionWrap>
                  Não conseguimos encontrar os detalhes para esta Manutenção.
                </OptionWrap>
              </Col>
            </Row>
          </div>
        )}
        {isOpen && (
          <Lightbox
            mainSrc={`${this.serverURL}${images[photoIndex].path}`}
            nextSrc={`${this.serverURL}${
              images[(photoIndex + 1) % images.length].path
            }`}
            prevSrc={`${this.serverURL}${
              images[(photoIndex + images.length - 1) % images.length].path
            }`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </MaintenanceWrap>
    );
  }
}

export default connect(
  (state) => {
    return {
      isAdmin: state.user.data.admin,
      individualRelations: state.georeference.individualRelations,
      growerRelations: getCurrentGrower(state),
      type: state.maintenances.type,
      activeStep: state.maintenances.activeStep,
      status: state.maintenances.status,
      interventionList: state.maintenances.interventions,
      userWrite: state.user.data.write,
      userPermissions: state.user.data.permissions,
      token: state.user.data.token,
    };
  },
  {
    deleteMaintenance,
    updateMaintenance,
    getMaintenances,
    getInterventions,
    getRelationsByElementId,
  }
)(Edit);
