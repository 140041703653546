import React from "react";
import styled from "styled-components";

const Title = styled.h3`
  && {
    font-size: 0.9em;
    color: #4a4a4a;
    font-weight: bold;
    margin: 1.5em 0 0em 0;
    letter-spacing: -0.5px;
    opacity: 0.6;
    @media print {
      font-size: 1em;
    }
    span {
      font-size: 0.7em;
      display: block;
      color: #9d9d9d;
    }
  }
`;

const Bar = styled.div`
  && {
    height: 2.5px;
    width: 32px;
    background: ${(props) => props.color || "#9d9d9d"};
    border-radius: 2px;
    margin: 0.3em 0 0.5em 0;
  }
`;

export default function SideSubTitle(props) {
  return (
    <div>
      <Title>
        {props.children}
        <Bar color={props.color} />
      </Title>
    </div>
  );
}
