import React from "react";
import styled from "styled-components";

const ContentWrap = styled.div`
  && {
    background: #fff;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    @media print {
      background: #fff;
    }
    width: 320px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 80px;
    z-index: 10;
    overflow: auto;

    @media (max-width: 900px) {
      left: 0;
      width: 100%;
      padding-bottom: 160px;
    }

    .col-xs-12,
    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .m-b-15 {
      width: 100%;
      max-width: 100%;
    }
  }
`;
const Padder = styled.div`
  && {
    padding: ${(props) => (props.nopadding ? "0" : "0% 5% 0")};
    max-width: 1200px;
  }
`;
class SideModal extends React.Component {
  render() {
    return (
      <ContentWrap {...this.props} className={"animated fadeIn"}>
        <Padder nopadding={this.props.nopadding}>{this.props.children}</Padder>
      </ContentWrap>
    );
  }
}

export default SideModal;
