import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { Tooltip } from "@material-ui/core";
import { ButtonGroup } from "@material-ui/core";

import SectionTitle from "../../components/Text/SectionTitle";
import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import ViewReport from "../Reports/ViewReport";
import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";
import { default as InspectionsEdit } from "../Inspections/Edit";
import Edit from "./Edit";
import AddComment from "./steps/AddComment";

import {
  getMaintenancesHistory,
  addNewToggle,
  setHistory,
  setMaintenanceDone,
} from "./actions";
import { setSearch } from "../App/actions";

import {
  OptionWrap,
  ButtonWrapper,
  Section,
  FinishButton,
  Close,
  PrintButton,
  LocationTitle,
} from "../../components/InspMain/styled";

class View extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      group:
        props.groupId && typeof props.groupId !== "boolean"
          ? props.groupId
          : props.group,
      viewDailyReport: null,
      view: "info",
    };
    this.AddNewMaintenance = this.AddNewMaintenance.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.viewEventReport = this.viewEventReport.bind(this);

    this.closeViewDailyReport = this.closeViewDailyReport.bind(this);
    // props.setSearch(
    //   {
    //     element: "edit",
    //     value: this.state.group,
    //   },
    //   true
    // );

    props.getMaintenancesHistory(this.state.group || props.viewGroup);
  }

  closeModal() {
    if (typeof this.props.onClose === "function") return this.props.onClose();
    this.props.setSearch({
      element: "edit",
      value: null,
    });

    this.props.history &&
      this.props.history.push(`/${this.props.callback || "interventions"}`);
  }

  AddNewMaintenance() {
    this.props.setHistory([this.state.group]);
    this.props.addNewToggle();
  }

  handleFinishMaintenance() {
    this.props.setMaintenanceDone(this.state.group);
  }

  generatePDF() {
    window && window.print();
  }

  closeViewDailyReport() {
    this.setState({ viewDailyReport: null });
  }

  viewEventReport(park) {
    const data = this.props.historyData;
    let date = null;
    if (data && data.length > 0) {
      date = data[0].date;
    }
    this.setState({ viewDailyReport: { ...park, date } });
  }
  render() {
    const data = this.props.historyData;
    let selectedPID = "";
    let isApproved = false;

    if (data && data.length > 0) {
      selectedPID =
        data[0].Inspection && data[0].Inspection.id
          ? data[0].Inspection.pid
          : data[0].pid;
      isApproved = data[0].status === 1;
    }

    const selectedPark = this.props.parks.find(
      (park) => park.numero === selectedPID
    );
    let isSchool =
      selectedPark?.tipo === "Hortas nas Escolas"
        ? selectedPark?.designacao
        : false;

    //lets stop render if the data group is not found
    if (
      !this.props.historyData.find(
        (item) => item.id === (this.state.group || this.props.viewGroup)
      )
    ) {
      return <></>;
    }

    return (
      <div>
        <FullScreenModal>
          <Tooltip title="Fechar">
            <Close
              onClick={() => this.closeModal()}
              className={`icon-close icons`}
            />
          </Tooltip>
          <Tooltip title="Imprimir">
            <PrintButton
              onClick={() => this.generatePDF()}
              className={`icon-printer icons`}
            />
          </Tooltip>

          <Section>
            {data && (
              <div>
                <Row>
                  <Col xs={12} md={6}>
                    <PageTitle>
                      {selectedPark && selectedPark.nome}
                      <LocationTitle>
                        {selectedPark && selectedPark.freguesia
                          ? `${selectedPark && selectedPark.freguesia} ,`
                          : ""}
                        {selectedPark && selectedPark.local
                          ? `${selectedPark && selectedPark.local} ,`
                          : ""}
                        {selectedPark && selectedPark.numero
                          ? `${selectedPark && selectedPark.numero}`
                          : ""}
                      </LocationTitle>
                    </PageTitle>
                  </Col>
                  <Col xs={12} md={6}>
                    <ButtonWrapper>
                      <ButtonGroup>
                        <MainButton
                          active={this.state.view === "info" ? "true" : "false"}
                          onClick={() => this.setState({ view: "info" })}
                        >
                          <MenuIcon className={`icon-notebook icons`} />{" "}
                          Informação
                        </MainButton>
                        <MainButton
                          active={
                            this.state.view === "history" ? "true" : "false"
                          }
                          onClick={() => this.setState({ view: "history" })}
                        >
                          <MenuIcon className={`icon-calendar icons`} />{" "}
                          Histórico
                        </MainButton>
                      </ButtonGroup>
                    </ButtonWrapper>
                  </Col>
                </Row>
                {this.state.view === "info" &&
                  data.map((maintenance, index) => {
                    if (
                      maintenance.Inspection &&
                      maintenance.Inspection.id !== null
                    ) {
                      return (
                        <div key={index}>
                          <InspectionsEdit data={maintenance.Inspection} />
                          <Edit
                            isSchool={isSchool}
                            data={maintenance}
                            closeModal={this.closeModal}
                            index={index}
                          />
                        </div>
                      );
                    }
                    return (
                      <Edit
                        isSchool={isSchool}
                        key={index}
                        index={index}
                        data={maintenance}
                        closeModal={this.closeModal}
                      />
                    );
                  })}
                {this.state.view === "info" &&
                  this.props.status !== "saving" &&
                  this.props.role !== "EV" && (
                    <AddComment
                      selectedParkId={selectedPark && selectedPark.numero} //Temporari untill we use the correct table
                      group={this.state.group}
                      data={data.find(
                        (r) => r.Intervention && r.Intervention.label
                      )}
                      isApproved={isApproved}
                    />
                  )}
                {this.state.view === "info" &&
                  this.props.isAdmin &&
                  !isApproved &&
                  this.props.role !== "EV" && (
                    <FinishButton
                      onClick={() => this.handleFinishMaintenance()}
                    >
                      Arquivar
                      <MenuIcon className={`icon-like icons`} />
                    </FinishButton>
                  )}
                {this.state.view === "history" && (
                  <ViewReport
                  // selectedParkId={selectedPark.pid}
                  />
                )}
              </div>
            )}

            {!data && (
              <div>
                <PageTitle>Upss...</PageTitle>
                <Row>
                  <Col xs={12} md={9}>
                    <SectionTitle>Detalhes</SectionTitle>
                    <OptionWrap>
                      Não conseguimos encontrar os detalhes para esta
                      manutenção.
                    </OptionWrap>
                  </Col>
                </Row>
              </div>
            )}
          </Section>
        </FullScreenModal>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.maintenances.type,
      activeStep: state.maintenances.activeStep,
      historyData: state.maintenances.reportHistory,
      status: state.maintenances.status,
      parks: state.parklist.list,
      role: state.user.data.role,
      isAdmin: state.user.data.admin,
      viewGroup: state.app.search.group,
    };
  },
  {
    getMaintenancesHistory,
    setMaintenanceDone,
    addNewToggle,
    setHistory,
    setSearch,
  }
)(View);
