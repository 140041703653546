import React, { useState } from "react";
import { useContainerQuery } from "react-container-query";
import styled from "styled-components";
import moment from "moment";
import { Col, Row } from "react-flexbox-grid";
import MainButton from "../../components/Buttons/MainButton";
import SelectComponent from "../../components/form-wrappers/Select";
import { MenuItem } from "@material-ui/core";
import PageTitle from "../../components/Text/PageTitle";

import TextField from "../../components/form-wrappers/TextField";

// import { getMessage } from "../actions";

const query = {
  lcolumn: {
    maxWidth: 599,
  },
  lrow: {
    minWidth: 600,
  },
};
const MaterialWrapper = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
  }
`;
const MaterialElementItem = styled.div`
  && {
    background: #fff;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    padding: 0.4em 1em;
    margin: 0.8em 0;
    border-radius: 8px;
    font-size: 0.9em;
    div {
      margin: 0;
    }
    input {
      font-size: 0.8em;
      height: 12px;
    }
  }
`;

const MaterialElement = styled.div`
  && {
    width: 30%;
    min-width: 300px;
    padding: 1em;
  }
`;
const EditWrapper = styled.div`
  && {
    padding: 2%;
    background: #fafafa;
    input,
    select,
    textarea {
      background: #ffffff;
    }
    div {
      &:has(+ textarea) {
        background: #ffffff;
      }
    }
    .lrow {
      width: 100%;
      margin: 0 auto;
      /* max-width: 1100px; */
      justify-content: space-between;
      .side {
        width: 30%;
      }
    }
  }
`;

const Input = styled(TextField)`
  && {
    width: 100%;
    margin: 0.5em 0 0 0;
    text-align: left;
    textarea {
      height: auto;
    }
  }
`;
const ButtonWrap = styled.div`
  && {
    padding: 0em 0 1em 0;
    margin-top: -1em;
  }
`;

const EditOrders = (props) => {
  const [params, containerRef] = useContainerQuery(query);

  const {
    id,
    school,
    date,
    area,
    attr: { order, orderStatus },
    material,
  } = props.edit;

  const thisMaterials =
    material.map((m) => {
      const mate = props.materials.filter((mat) => {
        if (mat.type === m) {
          return mat;
        }
      });
      return {
        type: m,
        materials: mate,
      };
    }) || [];

  //for editing
  return (
    <EditWrapper>
      <div
        ref={containerRef}
        className={`${params.lcolumn ? "lcolumn" : ""} ${
          params.lrow ? "lrow" : ""
        }`}
      >
        <>
          <PageTitle noMargin={true}>
            Ordem de {school}
            <span style={{ fontSize: ".8em!important" }}>
              {moment(date).format("DD/MM/YYYY HH:mm")}{" "}
            </span>
          </PageTitle>{" "}
          <div
            style={{
              display: "flex",
              width: "50%",
              marginTop: "1em",
              alignItems: "center",
            }}
          >
            <SelectComponent
              value={orderStatus}
              label="Estado"
              name="status"
              meta={{ invalid: false }}
              style={{ width: "200px", margin: 0 }}
              onChange={(e) => {
                props.updateRowStatus(id, e.target.value);
              }}
            >
              <MenuItem value={"active"}>Ativo</MenuItem>
              {/* <MenuItem value={"ordered"}>Encomendado</MenuItem> */}
              <MenuItem value={"finished"}>Finalizado</MenuItem>
              <MenuItem value={"emailed"}>Comunicado</MenuItem>
            </SelectComponent>
            <MainButton
              style={{
                textAlign: "center",
                display: "block",
                margin: "-1em 0 0 1em",
                with: "100px",
              }}
              target={"_blank"}
              onClick={() => props.setOrder(id)}
            >
              Atualizar
            </MainButton>
            {(orderStatus === "finished" || orderStatus === "emailed") && (
              <MainButton
                active={"true"}
                style={{
                  textAlign: "center",
                  display: "block",
                  margin: "-1em 0 0 1em",
                  with: "100px",
                }}
                target={"_blank"}
                onClick={() => props.sendOrderEmail(id)}
              >
                Enviar comunicação
              </MainButton>
            )}
          </div>
          <MaterialWrapper>
            {thisMaterials.map((m) => {
              return (
                <MaterialElement key={m.EditMaterial}>
                  <strong style={{ margin: "0 0 2em 0", display: "block" }}>
                    {m.type}
                  </strong>
                  {m.materials.map((mat) => {
                    //get row value
                    const thisMatVal = order?.find((o) => o.id === mat.id);
                    return (
                      <MaterialElementItem>
                        <Row style={{ alignItems: "center" }}>
                          <Col md={6}>{mat.name}</Col>
                          <Col md={6}>
                            <Row style={{ alignItems: "center" }}>
                              <Col md={10}>
                                <Input
                                  value={thisMatVal.value}
                                  margin="dense"
                                  label="Itens"
                                  type={"number"}
                                  width={"70%"}
                                  onChange={
                                    (e) => {
                                      props.updateMatAmount(
                                        id,
                                        mat.id,
                                        e.target.value
                                      );
                                    }
                                    // setData({ ...data, name: e.target.value })
                                  }
                                  name="Itens"
                                />{" "}
                              </Col>
                              <Col md={1}>
                                <i
                                  className="icons icon-reload"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    props.updateMatAmount(
                                      id,
                                      mat.id,
                                      props.calculateMaterialAmount(area, mat)
                                    );
                                  }}
                                ></i>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </MaterialElementItem>
                    );
                  })}
                </MaterialElement>
              );
            })}{" "}
          </MaterialWrapper>{" "}
        </>
      </div>
    </EditWrapper>
  );
};

export default EditOrders;
