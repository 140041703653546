import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { Tooltip } from "@material-ui/core";
import { ButtonGroup } from "@material-ui/core";

import SectionTitle from "../../components/Text/SectionTitle";
import MainButton from "../../components/Buttons/MainButton";
import MenuIcon from "../../components/Buttons/MenuIcon";
import ViewReport from "../Reports/ViewReport";

import FullScreenModal from "../../components/Layout/FullScreenModal";
import PageTitle from "../../components/Text/PageTitle";
import Edit from "./Edit";

import {
  getInspectionsHistory,
  getInspectionsGroup,
  getInspection,
  addNewToggle,
  setHistory,
  setInspectionDone,
  getInspections,
} from "./actions";
import { setSearch } from "../App/actions";
import {
  OptionWrap,
  ButtonWrapper,
  Section,
  FinishButton,
  Close,
  PrintButton,
  LocationTitle,
} from "../../components/InspMain/styled";

class View extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      group:
        props.groupId && typeof props.groupId !== "boolean"
          ? props.groupId
          : props.group,
      viewDailyReport: null,
      view: "info",
    };
    this.addNewInscption = this.addNewInscption.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleFinishInspection = this.handleFinishInspection.bind(this);
    this.viewEventReport = this.viewEventReport.bind(this);
    this.closeViewDailyReport = this.closeViewDailyReport.bind(this);
    console.log("View", props.groupId);
    props.getInspection(this.state.group || props.viewGroup);
  }

  closeModal() {
    if (this.props.status === "saved")
      this.props.getInspection(this.state.group);
    if (typeof this.props.onClose === "function") return this.props.onClose();
    this.props.setSearch({
      element: "edit",
      value: null,
    });
    this.props.history.push(`/${this.props.callback || "inspections"}`);
  }

  addNewInscption(group, pid = "") {
    this.props.setHistory(group, pid);
    this.props.addNewToggle();
  }

  handleFinishInspection() {
    this.props.setInspectionDone(this.state.group);
  }

  generatePDF() {
    // this.setState({ print: true });
    window && window.print();
  }

  closeViewDailyReport() {
    this.setState({ viewDailyReport: null });
  }

  viewEventReport(park) {
    const data = this.props.historyData;
    let date = null;
    if (data && data.length > 0) {
      date = data[0].date;
    }
    this.setState({ viewDailyReport: { ...park, date } });
  }

  render() {
    const data =
      this.props.role !== "EV"
        ? this.props.historyData
        : this.props.historyGroup;
    const selectedPID = data.length > 0 ? data[0].pid : "";

    const selectedPark = this.props.parks.find((park) => {
      if (park.numero === selectedPID) return true;
      if (park.eid === selectedPID) return true;
      return false;
    });
    let isSchool =
      selectedPark?.tipo === "Hortas nas Escolas"
        ? selectedPark?.designacao
        : false;

    const isApproved = data && data.length > 0 && data[0].status === 1;

    //lets stop render if the data group is not found
    if (
      !this.props.historyData.find(
        (item) => item.id === (this.state.group || this.props.viewGroup)
      )
    ) {
      return <></>;
    }

    //proceed to render
    return (
      <div>
        <FullScreenModal>
          <Tooltip title="Fechar">
            <Close
              onClick={() => this.closeModal()}
              className={`icon-close icons`}
            />
          </Tooltip>
          <Tooltip title="Imprimir">
            <PrintButton
              onClick={() => this.generatePDF()}
              className={`icon-printer icons`}
            />
          </Tooltip>
          {/* <Tooltip title="Relatório da Visita">
            <DailyReportButton
              onClick={() => this.viewEventReport(selectedPark)}
              className={`icon-event icons`}
            />
          </Tooltip> */}
          <Section>
            {data && (
              <div>
                <Row>
                  <Col xs={12} md={6}>
                    <PageTitle>
                      {selectedPark && selectedPark.nome}
                      <LocationTitle>
                        {selectedPark && selectedPark.freguesia
                          ? `${selectedPark && selectedPark.freguesia} ,`
                          : ""}
                        {selectedPark && selectedPark.local
                          ? `${selectedPark && selectedPark.local} ,`
                          : ""}
                        {selectedPark && selectedPark.numero
                          ? `${selectedPark && selectedPark.numero}`
                          : ""}

                        {/* {thisElementType &&
                      thisElementType.properties.label &&
                      `${
                        thisElementType.properties.category
                          ? thisElementType.properties.category + ','
                          : ''
                      } ${thisElementType.properties.label}`} */}
                      </LocationTitle>
                    </PageTitle>
                  </Col>
                  <Col xs={12} md={6}>
                    <ButtonWrapper>
                      <ButtonGroup>
                        <MainButton
                          active={this.state.view === "info" ? "true" : "false"}
                          onClick={() => this.setState({ view: "info" })}
                        >
                          <MenuIcon className={`icon-notebook icons`} />{" "}
                          Informação
                        </MainButton>
                        <MainButton
                          active={
                            this.state.view === "history" ? "true" : "false"
                          }
                          onClick={() => this.setState({ view: "history" })}
                        >
                          <MenuIcon className={`icon-calendar icons`} />{" "}
                          Histórico
                        </MainButton>
                      </ButtonGroup>
                    </ButtonWrapper>
                  </Col>
                </Row>
                {this.state.view === "info" && (
                  <div>
                    {data.map((inspection, index) => (
                      <Edit
                        isSchool={isSchool}
                        key={index}
                        index={index}
                        data={inspection}
                        closeModal={this.closeModal}
                      />
                    ))}

                    {this.props.isAdmin && (
                      <FinishButton
                        onClick={() => this.handleFinishInspection()}
                      >
                        {!isApproved
                          ? this.props.role !== "EV"
                            ? "Aprovar"
                            : "Fechar"
                          : "Reabrir"}

                        <MenuIcon
                          className={`icon-${
                            !isApproved ? "like" : "close"
                          } icons`}
                        />
                      </FinishButton>
                    )}
                  </div>
                )}
                {this.state.view === "history" && (
                  <ViewReport
                  // selectedParkId={selectedPark.pid}
                  />
                )}
              </div>
            )}
            {!data && (
              <div>
                <PageTitle>Upss...</PageTitle>
                <Row>
                  <Col xs={12} md={9}>
                    <SectionTitle>Detalhes</SectionTitle>
                    <OptionWrap>
                      Não conseguimos encontrar os detalhes para esta inspeção.
                    </OptionWrap>
                  </Col>
                </Row>
              </div>
            )}
          </Section>
        </FullScreenModal>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.inspections.type,
      activeStep: state.inspections.activeStep,
      historyData: state.inspections.reportHistory,
      historyGroup: state.inspections.historyGroup,
      status: state.inspections.status,
      parks: state.parklist.list,
      isAdmin: state.user.data.admin,
      role: state.user.data.role,
      viewGroup: state.app.search.group,
    };
  },
  {
    getInspectionsHistory,
    getInspectionsGroup,
    getInspection,
    addNewToggle,
    setHistory,
    setSearch,
    setInspectionDone,
    getInspections,
  }
)(View);
