import styled from "styled-components";
import ContentWrap from "../../components/Layout/ContentWrap";
import PageTitle from "../../components/Text/PageTitle";
import MainButton from "../../components/Buttons/MainButton";
import Select from "../../components/form-wrappers/Select";

export const OptionWrap = styled(ContentWrap)`
  && {
    padding: 2em;
    margin: 1em 0;
    text-align: center;
  }
`;

export const DateWrap = styled(ContentWrap)`
  && {
    padding: 1em;
    margin: 1em 0 2em 0;
    text-align: center;
  }
`;
export const StyledPageTitleWrap = styled.div`
  && {
    display: inline-block;
  }
`;
export const Timeline = styled.div`
  && {
    display: block;
    padding-left: 2em;
    border-left: 3px dotted #c4dbf4;
    margin-left: 2em;
    position: relative;
    &::before {
      content: " ";
      width: 15px;
      height: 15px;
      left: -14px;
      top: 2em;
      background: #f4f6f8;
      border: 5px solid #4da1ff;
      border-radius: 100%;
      position: absolute;
    }
  }
`;
export const StyledPageTitle = styled(PageTitle)`
  && {
    margin-left: 120px;
  }
`;
export const Section = styled.div`
  && {
    margin: 2em auto;
    max-width: 1400px;
  }
`;
export const Bar = styled.div`
  && {
    height: 4px;
    width: 52px;
    background: ${(props) => props.color || "#4da1ff"};
    border-radius: 2px;
    margin: 0.4em 0 1em 0;
  }
`;
export const DayPageTitle = styled.span`
  && {
    display: inline-block;
    span {
      font-size: 1.4em;
      color: #4da1ff !important;
    }
    @media print {
      page-break-after: always;
    }
  }
`;
export const Icon = styled.div`
  && {
    margin-right: 1.5%;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    border-radius: 4px;
    display: inline-block;
    padding: 0;
    background: #fff;
    color: #4da1ff;
    span {
      border-bottom: 1px solid #fff;
      display: block;
      text-align: center;
      color: #4da1ff;
      font-weight: bold;
      font-size: 1.3em;
      padding: 0em 1.3em;
      &:first-child {
        margin-top: 0.5em;
      }
      &:last-child {
        color: #fff;
        font-size: 0.8em;
        margin-top: 0.5em;
        padding: 0.3em 1em;
        border-bottom: none;
        background: #4da1ff;
      }
    }
  }
`;
export const Close = styled.span`
  && {
    font-size: 2.5em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    :hover {
      color: #4da1ff;
      transform: rotate(90deg);
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
  }
`;
export const PrintButton = styled.span`
  && {
    font-size: 1.3em;
    margin: 0 0.5em 0 0;
    text-align: center;
    color: #989898;
    vertical-align: middle;
    position: absolute;
    right: 77px;
    top: 20px;
    z-index: 11;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid;
    padding: 0.4em;
    :hover {
      color: #4da1ff;
      transition: 0.3s all; /* rotate gradually instead of instantly */
    }
    @media print {
      display: none;
    }
  }
`;

export const LocationTitle = styled.span`
  && {
    font-size: 0.8em;
    display: block;
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  /* height: calc(100vh - 49px); */
  @media (max-width: 768px) {
    display: block;
    height: 100%;
    overflow: auto;
  }
`;
export const Flag = styled.div`
  && {
    width: 1em;
    height: 3em;
    border-radius: 4px;
    background: ${(props) => (props.warging === "red" ? "#9C27B0" : "#FFC107")};
  }
`;

export const TableWrap = styled.div`
  && {
    display: ${(props) => (props.display === "display" ? "block" : "none")};
    overflow-x: auto;
    overflow-y: visible;
    margin: 0;
    > div {
      padding-top: 0;
    }
  }
`;

export const StyledMainButton = styled(MainButton)`
  && {
    width: 100%;
    margin-top: 1em;
    font-size: 0.8em;
  }
`;

export const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: calc(100vh - 180px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
      @media (max-width: 768px) {
        height: calc(100vh - 230px);
      }
    }
  }
`;

export const MaintenanceFilter = styled(Select)`
  && {
    border: 0;
    font-size: 0.9em;
    color: #a3a1a1;
  }
`;
