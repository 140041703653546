export const ADD_NEW_TOGGLE = 'ADD_NEW_INSPECTIONS_TOGGLE';
export const SET_PHOTOS = 'SET_INSPECTIONS_PHOTOS';
export const SET_TYPE = 'SET_INSPECTIONS_TYPE';
export const SET_DETAILS = 'SET_INSPECTIONS_DETAILS';
export const SET_ACTIVE_STEP = 'SET_INSPECTIONS_ACTIVE_STEP';

export const SAVING = 'INSPECTIONS_SAVING_REQUEST';
export const SAVED = 'INSPECTIONS_SAVED_SUCCESS';
export const DELETED = 'INSPECTIONS_DELETED_SUCCESS';
export const ERROR = 'INSPECTIONS_ERROR_FAILURE';
export const RESET = 'INSPECTIONS_RESET';

export const HISTORY_ERROR = 'INSPECTIONS_HISTORY_FAILURE';
export const HISTORY_GETTING = 'INSPECTIONS_HISTORY_REQUEST';
export const HISTORY_RECEIVED = 'INSPECTIONS_HISTORY_SUCCESS';
export const GROUP_ERROR = 'INSPECTIONS_GROUP_FAILURE';
export const GROUP_GETTING = 'INSPECTIONS_GROUP_REQUEST';
export const GROUP_RECEIVED = 'INSPECTIONS_GROUP_SUCCESS';

export const GETTING = 'INSPECTIONS_REQUEST';
export const RECEIVED = 'INSPECTIONS_SUCCESS';
export const UPDATED = 'INSPECTIONS_UPDATED';
export const APPROVED_INSPECTION_GROUP = 'APPROVED_INSPECTION_GROUP';

export const UPDATED_HISTORY = 'INSPECTIONS_UPDATED_HISTORY';

export const DAMAGES_GETTING = 'DAMAGES_REQUEST';
export const DAMAGES_RECEIVED = 'DAMAGES_SUCCESS';
export const DAMAGES_UPDATED = 'DAMAGES_UPDATED';
