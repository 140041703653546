import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import Select from '../form-wrappers/Select';

import { getTeams } from '../../containers/User/actions';

const Section = styled.div`
  && {
    margin: 0.5em 0;
  }
`;
const FilterList = styled.div`
  && {
    .inspection-filter {
      height: calc(100vh - 210px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
    }
  }
`;
class TeamFilter extends React.Component {
  constructor(props) {
    super(props);
    if (!props.teams || props.teams.length === 0) props.getTeams();
  }

  render() {
    const { teams, selected, userTeam } = this.props;
    if (!teams) return null;
    let displayedTeams = [];
    return (
      <FilterList>
        <Section>
          <Select
            fullWidth
            multiple={!this.props.noMulti}
            name={'team'}
            label={'Equipas'}
            value={selected || ''}
            onChange={this.props.handleChange}
            inputProps={{
              name: 'team',
              id: 'team',
            }}
          >
            {!userTeam && <MenuItem key={-1} value={''} />}
            {teams.map((team, index) => {
              if (
                userTeam &&
                userTeam.type === 'internal' &&
                !displayedTeams.includes(team.id)
              ) {
                if (team.type === 'internal') {
                  displayedTeams.push(team.id);
                  return (
                    <MenuItem key={index} value={team.id}>
                      {team.label}
                    </MenuItem>
                  );
                }
                return null;
              } else if (!displayedTeams.includes(team.id)) {
                displayedTeams.push(team.id);
                return (
                  <MenuItem key={index} value={team.id}>
                    {team.label}
                  </MenuItem>
                );
              } else {
                return team;
              }
            })}
          </Select>
        </Section>
      </FilterList>
    );
  }
}

export default connect(
  state => {
    return {
      teams: state.user.teams,
      userTeam: state.user.data.team,
    };
  },
  { getTeams }
)(TeamFilter);
