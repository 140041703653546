import React from "react";
import { store } from "../../store/configure";

import { Data, Half, Section } from "./styled";

const DateRangeFilter = ({
  startDate,
  endDate,
  handleSearchChange = () => {},
}) => {
  const list = store.getState().parklist.list;

  return (
    <Section>
      {/* {!noTitle && <SectionTitleStyles>Data</SectionTitleStyles>} */}
      <Half>
        <Data
          id="startDate"
          name="startDate"
          label="Data inicial"
          input={{}}
          meta={{ invalid: false }}
          type="date"
          onBlur={handleSearchChange}
          defaultValue={startDate}
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          // defaultValue={startDate}
        />
        <Data
          id="endDate"
          name="endDate"
          label="Data Final"
          variant="outlined"
          margin="dense"
          input={{}}
          meta={{ invalid: false }}
          type="date"
          onBlur={handleSearchChange}
          defaultValue={endDate}
          InputLabelProps={{
            shrink: true,
          }}
          // defaultValue={startDate}
        />
      </Half>
    </Section>
  );
};

export default DateRangeFilter;
