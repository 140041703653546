import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "./Table";
import { TableCell, TableFooter, TableRow } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import Edit from "../../containers/Schools/EditOrders";
import { toastr } from "react-redux-toastr";
import { apiAddress } from "../../config/general";
import writeXlsxFile from "write-excel-file";

export default function(props) {
  const [data, setData] = useState([]);
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [materials, setMaterials] = useState([]);

  /**
   * Trigers when the component is mounted
   */

  useEffect(() => {
    getMaterials();
    return () => {};
  }, []);

  useEffect(() => {
    if (materials.length > 0 && props.data) {
      setMaterialsDefault(props.data || []);
    }
    return () => {};
  }, [props.data, materials]);

  useEffect(() => {
    if (props.exportExcel) {
      exportToExcel();
      props.setExportExcel(false);
    }
  }, [props.exportExcel]);

  /**
   * Save Order
   */
  const setOrder = async (id) => {
    try {
      const setData = data.find((d) => d.id === id)?.attr || {};
      await axios.post(`api/v1/schools/order/${id}`, setData);
      props.reloadData();
      toastr.success(
        "Ordem",
        `Dados atualizados com sucesso para ${setData?.school?.school}`
      );
    } catch (error) {
      console.log(error);
      toastr.error(
        "Ordem",
        `Ocorreu um erro ao atualizar os dados para ${setData?.school?.school}`
      );
    }
  };

  const sendOrderEmail = async (id) => {
    try {
      await axios.get(`api/v1/schools/emailOrder/${id}`);
      props.reloadData();
      toastr.success(
        "Ordem",
        `Email enviado com sucesso para ${setData?.school?.school}`
      );
    } catch (error) {
      console.log(error);
      toastr.error(
        "Ordem",
        `Ocorreu um erro ao enviar o email para ${setData?.school?.school}`
      );
    }
  };

  /**
   * Get needed materials for the school
   */
  const getMaterials = async () => {
    try {
      const result = await axios.get("/api/v1/materials");
      setMaterials(result.data);
    } catch (error) {
      console.log({ error });
    }
  };

  /**
   * Process Data for table to be able to export
   * @param {*} data
   * @returns
   */
  const processedData = (pdata) => {
    if (!pdata) return [];
    return pdata.map((row) => {
      const customCols = {};
      materials.map((material) => {
        if (row?.attr?.orderStatus === "active") {
          customCols["orderStatus"] = "Ativo";
          // } else if (row?.attr?.orderStatus === "ordered") {
          //   customCols["orderStatus"] = "Encomendado";
        } else if (row?.attr?.orderStatus === "finished") {
          customCols["orderStatus"] = "Finalizado";
        } else if (row?.attr?.orderStatus === "emailed") {
          customCols["orderStatus"] = "Comunicado";
        }

        //if we have the material in the order then return the value
        if (row?.attr?.order) {
          const mat = row.attr.order.find((m) => m.id === material.id);
          if (mat) {
            customCols[material.id] = mat.value;
            return material;
          }
        }

        customCols[material.id] = 0;
        return material;
      });
      return { ...row, ...customCols };
    });
  };

  const processTotalSheet = (pdata) => {
    if (!pdata || !materials) return [];
    const total = [];
    const schema = [
      {
        column: "Material",
        wrap: true,
        width: 20,
        value: (data) => data?.name,
      },
      {
        column: "Tipo",
        wrap: true,
        width: 20,
        value: (data) => data?.type,
      },
      {
        column: "Total",
        wrap: true,
        width: 20,
        value: (data) => data?.total,
      },
    ];
    const totalLevels = []; //total, ...each material level breakdown

    //create a list of levels headers
    materials
      .filter((m) => {
        //if porps.materialFilter bigger than 1 then filter by it
        if (props.materialFilter && props.materialFilter.length > 0) {
          return props.materialFilter.includes(m.type) && m.status === 1;
        }

        return m.status === 1;
      })
      .map((material) => {
        if (material?.levels?.length > 0) {
          material.levels.map((level) => {
            // only push if we don't have it already
            if (level.area && !totalLevels.includes(Number(level.area))) {
              totalLevels.push(Number(level.area));
            }
          });
        }
        return material;
      });

    //sort the levels and create schema
    totalLevels
      .sort((a, b) => b - a)
      .map((level) => {
        schema.push({
          column: `Nível ${level}`,
          wrap: true,
          width: 15,
          value: (data) => {
            if (data?.levels[level]) {
              return (
                `${data?.levels[level]} (${data?.levelTotalAmount?.find(
                  (l) => Number(l.area) === Number(level)
                )?.numberOfItems || 0}u)` || 0
              );
            } else {
              return 0;
            }
          },
        });
        return level;
      });
    //create the total sheet
    pdata.map((row) => {
      materials
        .filter((m) => {
          //if porps.materialFilter bigger than 1 then filter by it
          if (props.materialFilter && props.materialFilter.length > 0) {
            return props.materialFilter.includes(m.type) && m.status === 1;
          }

          return m.status === 1;
        })
        .map((material) => {
          const mat = row?.attr?.order.find((m) => m.id === material.id);
          //only add if we have the material in the order
          if (mat && Number(mat.value) > 0) {
            let tIndex = total.findIndex((t) => t.id === mat.id);
            //find the level of the material and add accordingly
            const thisLevel = material?.levels
              ?.sort((a, b) => Number(a.area) - Number(b.area))
              ?.find((l) => Number(row?.area) <= Number(l.area));
            const thisLevelArea = thisLevel?.area || 0;
            //make default values
            if (tIndex === -1) {
              total.push({
                id: mat.id,
                name: `${mat.name}`,
                type: `${material.type}`,
                total: Number(mat.value || 0),
                levelTotalAmount: material?.levels,
                levels: { [thisLevelArea]: 1 },
              });
            } else {
              total[tIndex] = {
                ...total[tIndex],
                total: total[tIndex].total + Number(mat.value || 0),
                levels: {
                  ...total[tIndex].levels,
                  [thisLevelArea]: total[tIndex].levels[thisLevelArea]
                    ? total[tIndex].levels[thisLevelArea] + 1
                    : 1,
                },
              };
            }
          }
          return material;
        });
      return row;
    });
    return {
      schemaTotal: schema,
      dataTotal: total.sort((a, b) =>
        a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase())
      ),
    };
  };

  // processTotalSheet(processedData(data));
  /**
   * Update single product amount in order
   * @param {*} id
   * @param {*} prodID
   * @param {*} value
   */
  const updateMatAmount = (id, productID, value) => {
    //now we have the materials lets check if we have the material
    const updateMaterialRow = data.map((o) => {
      if (o.id === id && o?.attr?.order) {
        //we are updating the material amount
        const updatedOrder = o?.attr?.order.map((mat) => {
          if (mat.id === productID) {
            return { ...mat, value };
          }
          return mat;
        });
        return { ...o, attr: { ...o.attr, order: updatedOrder } };
      }
      return { ...o };
    });
    setData(updateMaterialRow);
  };

  /**
   * Get needed materials for the school
   */
  const updateRowStatus = (id, status) => {
    const updateMaterialRow = data.map((o) => {
      if (o.id === id) {
        return {
          ...o,
          attr: { ...o.attr, orderStatus: status },
        };
      }
      return { ...o };
    });
    setData(updateMaterialRow);
  };

  /**
   * Calculate materials values either from default or from custom attr
   * @param {*} area
   * @param {*} material
   * @returns
   */
  const calculateMaterialAmount = (area, material) => {
    //get horta area and levels
    const levels = material?.levels;
    if (!material?.isDefault) {
      return 0;
    }
    //get meterial level
    const amount = levels
      ?.sort((a, b) => Number(a.area) - Number(b.area))
      ?.find((level) => {
        //sort desc and find first level that is smaller than area
        if (Number(level.area) >= Number(area)) {
          return level;
        }
        return false;
      });
    return amount?.numberOfItems || 0;
  };

  /**
   * Set initial object for materials
   * @param {*} newData
   */
  const setMaterialsDefault = (newData) => {
    const defaultData = newData.map((row) => {
      const mate = materials.filter((mat) => {
        return row?.material?.includes(mat.type);
      });

      if (!row?.attr?.order) {
        return {
          ...row,
          attr: {
            ...row.attr,
            orderStatus: row.attr?.orderStatus || "active",
            order: mate.map((mat) => {
              return {
                id: mat.id,
                name: mat.name,
                value: mat.isDefault
                  ? calculateMaterialAmount(row.area, mat)
                  : 0,
              };
            }),
          },
        };
      } else {
        return {
          ...row,
          attr: {
            ...row.attr,
            orderStatus: row.attr?.orderStatus || "active",
            order: mate.map((mat) => {
              const matDefault = row?.attr?.order.find((m) => m.id === mat.id);
              if (matDefault) {
                return matDefault;
              } else {
                return {
                  id: mat.id,
                  name: mat.name,
                  value: mat.isDefault
                    ? calculateMaterialAmount(row.area, mat)
                    : 0,
                };
              }
            }),
          },
        };
      }
    });

    setData(defaultData);
  };

  /**
   * Table Columns
   */

  const columnData = [
    {
      id: "id",
      name: "id",
      numeric: false,
      disablePadding: true,
      label: "id",
      options: {
        display: false,
      },
    },

    {
      id: "orderStatus",
      name: "orderStatus",
      numeric: false,
      disablePadding: true,
      label: "Encomenda",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              color={value === "Finalizado" ? "primary" : "secondary"}
              label={value}
            />
          );
        },
      },
    },
    {
      id: "school",
      name: "school",
      numeric: false,
      disablePadding: false,
      label: "Escola",
    },
    {
      id: "area",
      name: "area",
      numeric: false,
      disablePadding: false,
      label: "Area",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value} m2`;
        },
      },
    },
    ...materials
      .filter((m) => {
        //if porps.materialFilter bigger than 1 then filter by it
        if (props.materialFilter && props.materialFilter.length > 0) {
          return props.materialFilter.includes(m.type) && m.status === 1;
        }

        return m.status === 1;
      })
      .sort((a, b) =>
        a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase())
      )
      .map((material) => {
        return {
          id: material.id,
          name: material.name,
          label: material.name,

          options: {
            filter: true,
            customHeadLabelRender: (label) => {
              return (
                <div
                  style={{
                    writingMode: "tb-rl",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      fontSize: ".8em",
                      opacity: 0.5,
                      lineHeight: 1,
                    }}
                  >
                    {material?.type?.substr(0, 20)}
                    {material?.type?.length > 20 ? "..." : ""}
                  </span>
                  <strong
                    style={{
                      display: "block",
                      lineHeight: 1,
                    }}
                  >
                    {material.name}
                  </strong>
                </div>
              );
            },
            // customBodyRender: (value, tableMeta, updateValue) => {
            //   //find this row order in data
            //   const row = data.find((r) => r.id === tableMeta.rowData[0]);
            //   //if we have the material in the order then return the value
            //   if (row?.attr?.order) {
            //     const mat = row.attr.order.find((m) => m.id === material.id);
            //     if (mat) {
            //       return mat.value;
            //     }
            //   }

            //   return 0;
            //   // return <Chip label={thisAmount || 0} />;
            // },
          },
        };
      }),
  ];

  /**
   * Export to excel
   */
  const exportToExcel = async () => {
    //prepdata 1 school per row
    const schemaIndividual = [
      {
        column: "Estado",
        wrap: true,
        width: 20,
        value: (data) => data?.attr?.orderStatus,
      },
      {
        column: "Escola",
        wrap: true,
        width: 50,
        value: (data) => data?.school,
      },
      {
        column: "Area",
        wrap: true,
        width: 20,
        align: "center",
        value: (data) => data?.area,
      },
      ...materials
        .filter((m) => {
          //if porps.materialFilter bigger than 1 then filter by it
          if (props.materialFilter && props.materialFilter.length > 0) {
            return props.materialFilter.includes(m.type) && m.status === 1;
          }

          return m.status === 1;
        })
        .sort((a, b) =>
          a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase())
        )
        .map((mat) => {
          return {
            column: `${mat.name} (${mat?.type?.substr(0, 20)})`,
            wrap: true,
            align: "center",
            width: 20,
            value: (data) => data[mat.id],
          };
        }),
    ];

    // need to add one last row with totals
    const outPutData = processedData(data);
    //only export if data is not activo
    const outPutDataFiltered = outPutData.filter(
      (row) => row?.attr?.orderStatus !== "active"
    );
    const { dataTotal, schemaTotal } = processTotalSheet(outPutDataFiltered);
    // const expData = [header, ...newData];
    await writeXlsxFile([outPutDataFiltered, dataTotal], {
      schema: [schemaIndividual, schemaTotal],
      sheets: ["Escolas", "Totais"],
      //columnData, // (optional) column widths, etc.
      headerStyle: {
        backgroundColor: "#eeeeee",
        fontFamily: "Arial",
        fontWeight: "bold",
        align: "left",
        wrap: true,
      },
      fileName: "file.xlsx",
      sheet: "Data",
    });
  };

  /**
   * Render table
   */
  return (
    materials && (
      <Table
        orderBy={"id"}
        data={processedData(data)}
        rowsExpanded={rowsExpanded}
        setRowsExpanded={setRowsExpanded}
        customTableBodyFooterRender={(opts) => {
          return (
            <TableFooter style={{ background: "#fafafa" }}>
              <TableRow>
                {opts.selectableRows !== "none" ? <TableCell /> : null}
                {opts.columns.map((col, index) => {
                  if (index === 0)
                    return <TableCell key={index}>Totals</TableCell>;
                  if (index === 1 || index === 2)
                    return <TableCell key={index}></TableCell>;
                  let total = opts.data.reduce((accu, item) => {
                    if (!isNaN(item.data[index])) {
                      return Number(accu) + Number(item.data[index]);
                    } else {
                      return accu;
                    }
                  }, 0);

                  if (col.display === "true") {
                    return (
                      <TableCell key={index} style={{ fontSize: "1.1em" }}>
                        <strong>{total || ""}</strong>
                      </TableCell>
                    );
                  }
                  return null;
                })}
              </TableRow>
            </TableFooter>
          );
        }}
        // click={props.click}
        renderExpandableRow={(rowData, rowMeta) => {
          const thisData = data.find((d) => d.id === rowData[0]);

          return (
            <tr style={{ width: "100%" }}>
              <td style={{ width: "100%" }} colSpan={"20"}>
                <Edit
                  edit={thisData}
                  setOrder={setOrder}
                  sendOrderEmail={sendOrderEmail}
                  updateMatAmount={updateMatAmount}
                  calculateMaterialAmount={calculateMaterialAmount}
                  updateRowStatus={updateRowStatus}
                  materials={materials}
                  index={rowMeta.rowIndex}
                  rowsExpanded={rowsExpanded}
                  reloadData={props.reloadData}
                  setRowsExpanded={setRowsExpanded}
                />
              </td>
            </tr>
          );
        }}
        header={columnData}
      />
    )
  );
}
