import { MenuItem, Select } from "@material-ui/core";
import moment from "moment";
import React from "react";
import DropzoneComponent from "react-dropzone-component";
import { Col, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import styled from "styled-components";
import MenuIcon from "../../../components/Buttons/MenuIcon";
import {
  ElementType,
  Input,
  NextButton,
  OptionWrap,
} from "../../../components/InspMain/styled";
import SectionTitle from "../../../components/Text/SectionTitle";
import { apiAddress } from "../../../config/general";
import messages from "../messages";

import { ContactsDisplay } from "../styled";

import { setDetails, setMessage, updateMessage } from "../actions";
import { getMessageContactProfile } from "../Selector";

import JoditEditor from "jodit-react";

const Calendar = styled(Input)`
  && {
    margin: 0;
  }
`;
const MessagePreview = styled.div`
  && {
    text-align: left;
  }
`;
const FileList = styled.div`
  && {
    text-align: left;
    ul,
    li {
      text-align: left;
    }
    li {
      margin: 15px 0;
    }
  }
`;

const ReplacementList = styled.ul`
  && {
    margin: 0;
    padding: 0;
    font-size: 0.8em;
    list-style-type: none;
    li {
      list-style-type: none;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
`;
const StyledOptionWrap = styled(OptionWrap)`
  && {
    text-align: left;
    margin: 1em 0;
  }
`;

const Files = styled(DropzoneComponent)`
  && {
    background-color: rgba(74, 144, 226, 0.1);
    border: 2px dashed rgba(74, 144, 226, 1);
    color: #4990e2;
    padding: 1em 1em 2em 1em;
    margin: 1em 0 1.5em;
    .dz-message {
      margin: 0;
    }
    .dz-preview.dz-image-preview {
      background: transparent;
    }
    .filepicker-file-icon {
      color: #4990e2;
      &::after {
        background-color: #4990e2;
      }
      &::before {
        border: solid 2px #4990e2;
      }
    }
    .dz-image {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
`;

class DetailsStart extends React.PureComponent {
  constructor(props) {
    super(props);
    console.log(props.edit, "test");
    this.state = {
      date: this.getCurrentDate(),
      messageTemplate: 0,
      type: props.communicationType || props.edit.type,
      subject: props.edit.title || "",
      message: props.edit.message || "",
      status: props.edit.status || "0",
      files: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.callback = this.callback.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      acceptedFiles: "image/*, application/*",
      addRemoveLinks: false,
      resizeWidth: 1100,
      resizeHeight: 1100,
      resizeQuality: 0.6,
      headers: { Authorization: "Bearer " + props.token },
      params: {
        fid: props.selectedParkId, //should be the park id
      },
      previewTemplate: "<span />",
    };

    this.componentConfig = {
      iconFiletypes: ["docs", "images"],
      showFiletypeIcon: true,
      postUrl: `${apiAddress}api/v1/files`,
    };
  }

  // componentDidMount() {
  //   this.setState({
  //     subject: this.props.edit.title || "",
  //     message: this.props.edit.message || ""
  //   });
  // }
  handleModelChange(message) {
    this.setState({
      message,
    });
  }

  // Simple callbacks work too, of course
  callback(file) {
    const fileData = JSON.parse(file.xhr.response);
    const files = [
      ...this.state.files,
      {
        path: fileData.msg.details.path,
        name: fileData.msg.details.originalname,
        size: fileData.msg.details.size,
        id: fileData.msg.id,
      },
    ];

    this.setState({ files });
  }

  getCurrentDate() {
    const now = new Date();
    const addZero = (i) => (i < 10 ? "0" + i : i);
    return `${now.getFullYear()}-${addZero(now.getMonth() + 1)}-${addZero(
      now.getDate()
    )}T${addZero(now.getHours())}:${addZero(now.getMinutes())}`;
  }

  handleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSaveCommunication() {
    if (this.props.edit && this.props.edit.id) {
      this.props.setDetails({ ...this.state, id: this.props.edit.id });
      this.props.updateMessage();
    } else {
      this.props.setDetails({ ...this.state });
      this.props.setMessage();
    }
    this.props.close();
  }

  renderGrowerCard() {
    // if (
    //   this.props.contact.length === 1 &&
    //   Array.isArray(this.props.contact) &&
    //   this.props.edit.contacts.includes(this.props.contact[0].id)
    // ) {
    //   return (
    //     <GrowerCard
    //       fullDisplay={true}
    //       readOnly={true}
    //       grower={this.props.contact[0]}
    //     />
    //   );
    // } else {
    return (
      <OptionWrap>
        <ContactsDisplay>
          {this.props.contact.map((cont) => {
            if (!cont) {
              return (
                <div className={"contact"}>
                  Informação indisponível, altere a pesquisa de utilizadors para
                  carregar este campo
                </div>
              );
            }
            return (
              <div className={"contact"}>
                {cont && cont.name},{" "}
                {cont.address ? cont.address.freguesia : ""}
              </div>
            );
          })}
        </ContactsDisplay>
      </OptionWrap>
    );
    // }
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const self = this;

    /// not bound to this is moved out of render
    const eventHandlers = {
      init: (dropzone) => {
        dropzone.autoDiscover = false;
        // self.preloadImages(dropzone);
      },
      removedfile: self.removedfile,
      error: (e) => {
        console.log(e);
      },
      success: self.callback,
    };
    const isMail = this.props.edit.type === "1" || this.state.type === "1";

    const configWY = {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
    };

    const type = this.props.edit.type;
    let mensagem = "";
    if (type === "0") {
      mensagem += "Telefonema";
    } else if (type === "1") {
      mensagem += "Email";
    } else if (type === "2") {
      mensagem += "Carta";
    } else {
      mensagem += "Comunicação";
    }
    let statusText = "";
    if (this.props.edit.status === 0) {
      statusText = "Por Enviar";
    } else if (this.props.edit.status === 1) {
      statusText = "Ativo";
    } else if (this.props.edit.status === 2) {
      statusText = "Enviado";
    } else if (this.props.edit.status === 3) {
      statusText = "Desativo";
    }
    // console.log(this.props.edit);
    return (
      <div>
        <Row>
          <Col xs={12} md={9}>
            <SectionTitle>
              <i className={`icon-speech icons`} /> {mensagem}
              <ElementType>{statusText}</ElementType>
              <span>
                {this.props.edit.User && this.props.edit.User.name},{" "}
                {moment(this.props.edit.sendDate).format("YYYY-MM-DD HH:mm:ss")}
              </span>
            </SectionTitle>

            <OptionWrap>
              <Input
                label="Assunto"
                onChange={(e) => this.handleChange(e)}
                name="subject"
                disabled={this.props.readOnly}
                input={{}}
                value={this.state.subject}
                defaultValue={""}
                meta={{ invalid: false }}
              />

              {!this.props.readOnly && !isMail && (
                <Input
                  multiline
                  label="Mensagem"
                  onChange={(e) => this.handleChange(e)}
                  disabled={this.props.readOnly}
                  name="message"
                  input={{}}
                  value={this.state.message}
                  type="multiline"
                  meta={{ invalid: false }}
                  rows="8"
                />
              )}
              {!this.props.readOnly && isMail && (
                <div style={{ textAlign: "left" }}>
                  <br />

                  <JoditEditor
                    // ref={editor}
                    value={this.state.message}
                    disabled={this.props.readOnly}
                    config={configWY}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => this.handleModelChange(newContent)} // preferred to use only this option to update the content for performance reasons
                    // onChange={(newContent) => {
                    //   this.handleModelChange(newContent);
                    // }}
                  />
                  <br />

                  <Select
                    fullWidth
                    name={"messageTemplate"}
                    label={"Mensagens"}
                    value={this.state.messageTemplate}
                    onChange={(e) => {
                      this.handleChange(e);
                      console.log(e);
                      this.setState({
                        subject: messages[e.target.value]?.title,
                        message: messages[e.target.value]?.message,
                      });
                    }}
                    inputProps={{
                      name: "messageTemplate",
                      id: "messageTemplate",
                    }}
                  >
                    <MenuItem value={0}>Escolher</MenuItem>

                    {Object.keys(messages).map((mess) => {
                      return (
                        <MenuItem value={mess}>{messages[mess].title}</MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )}
            </OptionWrap>
            <br />
            {this.props.readOnly && (
              <OptionWrap>
                <MessagePreview
                  dangerouslySetInnerHTML={{
                    __html: this.state.message,
                  }}
                />
              </OptionWrap>
            )}
            {!this.props.readOnly && isMail && (
              <div>
                <SectionTitle>Ficheiros</SectionTitle>
                <OptionWrap>
                  <Files
                    config={config}
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
                  />

                  {this.state.files.length > 0 && (
                    <strong>
                      Para utilizar no email copie os caminhos de cada ficheiro.
                    </strong>
                  )}
                </OptionWrap>

                {this.state.files.length > 0 && (
                  <FileList>
                    {this.state.files.map((file, i) => (
                      <StyledOptionWrap key={i}>
                        Nome: {file.name}
                        <br />
                        <strong>
                          {apiAddress}
                          {file.path}
                        </strong>
                      </StyledOptionWrap>
                    ))}
                  </FileList>
                )}
              </div>
            )}

            <br />
          </Col>
          {!this.props.readOnly && (
            <Col xs={12} md={3}>
              <SectionTitle>Opções</SectionTitle>
              <OptionWrap>
                <Calendar
                  id="date"
                  name="date"
                  label="data"
                  input={{}}
                  meta={{ invalid: false }}
                  type="datetime-local"
                  onChange={(e) => this.handleChange(e)}
                  defaultValue={this.state.date}
                />
                <br />

                <Select
                  fullWidth
                  name={"status"}
                  label={"Estado"}
                  value={this.state.status}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "status",
                    id: "status",
                  }}
                >
                  <MenuItem value={`0`}>Por Enviar</MenuItem>
                  <MenuItem value={`1`}>Ativo</MenuItem>
                  <MenuItem value={`2`}>Enviado</MenuItem>
                  <MenuItem value={`3`}>Desativo</MenuItem>
                </Select>
                <br />
                <br />
                {this.props.status !== "saving" && (
                  <NextButton
                    color="primary"
                    onClick={() => this.handleSaveCommunication()}
                  >
                    Salvar
                    <MenuIcon className={`icon-check icons`} />
                  </NextButton>
                )}
              </OptionWrap>

              {isMail && (
                <div>
                  <SectionTitle>Padrões de substituição</SectionTitle>
                  <OptionWrap>
                    <ReplacementList>
                      <li>
                        Nome: <strong>{`{{name}}`}</strong>
                      </li>
                      <li>
                        Email: <strong>{`{{email}}`}</strong>
                      </li>
                      <li>
                        Ranking Bairro: <strong>{`{{ranking1}}`}</strong>
                      </li>
                      <li>
                        Data de Inscrição: <strong>{`{{createdAt}}`}</strong>
                      </li>
                      <li>
                        Freguesia: <strong>{`{{freguesia}}`}</strong>
                      </li>
                      <li>
                        Ranking Proximidade: <strong>{`{{ranking2}}`}</strong>
                      </li>
                      <li>
                        Ranking Freguesia:{" "}
                        <strong>{`{{rankingFreguesia}}`}</strong>
                      </li>
                    </ReplacementList>
                  </OptionWrap>
                </div>
              )}

              <SectionTitle>{this.props.contact.length} Contactos</SectionTitle>
              {this.renderGrowerCard()}
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      type: state.communications.type,
      token: state.user.data.token,
      contacts: state.communications.contacts,
      contact: getMessageContactProfile(state),
    };
  },
  { setMessage, updateMessage, setDetails }
)(DetailsStart);
