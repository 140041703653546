import React from "react";
import styled from "styled-components";
import MainButton from "../../components/Buttons/MainButton";
import { Switch } from "@material-ui/core";

export const Toggle = styled((props) => <Switch {...props} />)`
  && {
    .MuiSwitch-bar-143,
    .jss143 {
      color: #4da1ff;
      background: #4da1ff;
    }
    .MuiSwitch-colorPrimary-140.MuiSwitch-checked-139,
    .jss139 {
      color: #4da1ff;
    }
  }
`;

export const ActionButton = styled(MainButton)`
  && {
    position: absolute;
    top: -1.5em;
    right: 0;
    font-size: 0.6em;
    padding: 0 1.3em;
    margin: 0.5em;
    background: #5bce5b;
    border: 1px solid #69bb69;
  }
`;

export const SidebarGeo = styled.div`
  && {
    position: absolute;
    width: 100%;
  }
`;

export const DeleteButton = styled(MainButton)`
  && {
    position: absolute;
    top: -1.5em;
    right: 0;
    font-size: 0.6em;
    padding: 0 1.3em;
    margin: 0.5em;
    background: #df5757;
    border-color: #f44336;
    &:hover {
      color: #df5757;
      .icons {
        color: #df5757;
      }
    }
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 455px) {
    &.nosidebar {
      .mapgeo > div {
        height: 100vh;
      }
      .actionsBut {
        bottom: 6.5em;
      }
    }
  }

  /* height: calc(100vh - 49px); */
  @media (max-width: 900px) {
    display: block;
  }
`;

export const ActionsContent = styled.div`
  && {
    position: fixed;
    right: 1em;
    bottom: 4.5em;
    z-index: 9;

    @media (max-width: 900px) {
      bottom: 7.5em;
      right: 0.5em;
      z-index: 1;
    }
    @media (max-width: 455px) {
      bottom: 33vh;
    }
  }
`;
export const FiltersContent = styled.div`
  && {
    position: fixed;
    right: 1em;
    bottom: 1em;
    z-index: 9;
    @media (max-width: 900px) {
      bottom: 4.5em;
      right: 0.5em;
    }
    @media (max-width: 455px) {
      bottom: 6.5em;
      left: 0.5em;
      z-index: 1;
      width: 80%;
    }
  }
`;

export const ActionWrapper = styled.div`
  && {
    margin: 0.3em 0.2em;
    background: #4da1ff;
    padding: 0.7em;
    display: inline-block;
    border-radius: 5px;
    max-width: 100px;
    box-shadow: rgb(0 0 0 / 12%) 0px 3.5px 5.5px;
    color: #fff;
    cursor: pointer;
    > div {
      font-size: 0.8em;
      ::before {
        border-bottom: none;
      }
    }
    &:hover {
      color: #4da1ff;
      background: #fff;
    }
    &.active {
      color: #4da1ff;
      background: #fff;
    }
  }
`;
export const ActionGroupWrapper = styled.div`
  && {
    display: block;
    text-align: right;
    width: 250px;
    div {
      display: inline-block;
    }
  }
`;

export const MapContent = styled.div`
  && {
    width: 100%;
    margin: 0;
    height: 100vh;
    position: fixed;
    top: 0;
  }
`;
export const FilterList = styled.div`
  && {
    .maintenance-filter {
      height: calc(100vh - 220px);
      padding-right: 18px;
      .scrollbar {
        border-radius: 1em;
      }
      @media (max-width: 768px) {
        height: calc(100vh - 230px);
      }
    }
  }
`;
