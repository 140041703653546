export const GETTING = "COMMUNICATIONS/GETTING_REQUEST";
export const SET_DETAILS = "COMMUNICATIONS/SET_DETAILS";
export const MESSAGE_RECEIVED = "COMMUNICATIONS/MESSAGE_RECEIVED_SUCCESS";
export const RECEIVED = "COMMUNICATIONS/RECEIVED_SUCCESS";
export const SET_CONTACTS = "COMMUNICATIONS/SET_CONTACTS";
export const SAVING = "COMMUNICATIONS/SAVING_REQUEST";
export const SAVED = "COMMUNICATIONS/SAVED_SUCCESS";
export const ERROR = "COMMUNICATIONS/ERROR_FAILURE";
export const RESET = "COMMUNICATIONS/RESET";
export const UPDATED = "COMMUNICATIONS/UPDATED";
export const FINISHED = "COMMUNICATIONS/FINISHED";
